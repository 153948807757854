<template>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="export-wrapper">
            <div class="export-wrapper__header">
                <div class="export-wrapper__header__leading">
                    <ChevronLeft
                        class="export-wrapper__header__icon"
                        @click="onBackClick"
                    />
                    <span class="export-wrapper__header__title">{{ t('exports.wrapper.title') }}</span>
                </div>
                <div class="export-wrapper__header__side">
                    <pbl-button
                        :loading="isGeneratingPdf"
                        :disabled="!isReadyForDownload || isGeneratingPdf"
                        @click="onDownloadClick"
                    >
                        {{ t('exports.wrapper.download') }}
                    </pbl-button>
                </div>
            </div>
            <div class="export-wrapper__content">
                <div
                    class="export-wrapper__content-page"
                    @click.stop.capture
                >
                    <div ref="printContentRef">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import html2pdf from 'html2pdf.js'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { useIsFetching } from '@tanstack/vue-query'
import { ChevronLeft } from 'lucide-vue-next'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    fileName?: string
}>()

const fileName = computed(() => props.fileName ?? 'export')

const { t } = useI18n()
const router = useRouter()
const { isPending, isError } = useConstantsQuery()
const fetchCount = useIsFetching()

const isGeneratingPdf = ref(false)

const printContentRef = ref<HTMLDivElement | null>(null)
const isReadyForDownload = computed(() => !!printContentRef.value && fetchCount.value === 0)

const onBackClick = () => {
    router.back()
}

const onDownloadClick = async () => {
    if (!printContentRef.value) {
        return
    }

    isGeneratingPdf.value = true

    try {
        await html2pdf()
            .set({
                margin: [5, 1, 1, 1],
                filename: `${fileName.value}.pdf`,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            })
            .from(printContentRef.value)
            .save()
    } finally {
        isGeneratingPdf.value = false
    }
}
</script>

<style scoped lang="scss">
.export-wrapper {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 50px);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: $spacing-m;

        padding: $spacing-s $spacing-m;

        background-color: white;
        border-bottom: 1px solid $pbl-border-muted;

        &__leading {
            display: flex;
            align-items: center;
            gap: $spacing-2xs;
        }

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
        }

        &__icon {
            cursor: pointer;
        }
    }

    &__content {
        display: flex;
        justify-content: center;

        height: 100%;
        overflow-y: scroll;

        &-page {
            user-select: none;
            cursor: default !important;

            border: 1px solid $pbl-border-muted;
            background-color: white;
            margin: $spacing-m 0;

            width: 21cm;
            min-height: 29.7cm;

            height: 100%;
        }
    }
}
</style>
