<template>
    <LoadingPanel
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="tableRowData.length > 0"
            class="recommendations-types-table"
        >
            <div ref="tableRef" />
        </div>
        <div
            v-else
            class="recommendations-types-table__empty-state"
        >
            <pbl-icon name="x-circle" />
            <span>{{ t('admin.recommendationTypes.table.noData') }}</span>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useRecommendationTypesListQuery } from '@/api/queries/admin/recommendation-types/useRecommendationTypesListQuery'
import { createElement, PencilLine } from 'lucide'
import { useConditionStatusEditRecommendationTypeModal } from '@/pages/admin/recommendation-types/modal/edit-recommendation/useConditionStatusEditRecommendationTypeModal'

const { t, locale } = useI18n()
const { openEditRecommendation } = useConditionStatusEditRecommendationTypeModal()

const { data, isPending, isError } = useRecommendationTypesListQuery(0, 0)

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => {
    if (!data.value?.recommendationTypes?.items) {
        return []
    }

    return data.value.recommendationTypes.items
})

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: 'fitColumns',
        height: '100%',
        reactiveData: true,
        pagination: true,
        paginationSize: 20,
        data: newTableRowData,
        columns: [
            {
                title: t('admin.recommendationTypes.table.headers.englishName'),
                field: 'englishName',
                headerSort: false,
            },
            {
                title: t('admin.recommendationTypes.table.headers.germanName'),
                field: 'germanName',
                headerSort: false,
            },
            {
                title: '',
                field: 'id',
                headerSort: false,
                maxWidth: 100,
                formatter: (cell: CellComponent) => {
                    const recommendationId = cell.getValue()

                    const cellDiv = document.createElement('div')

                    const editIcon = createElement(PencilLine)
                    editIcon.addEventListener('click', () => openEditRecommendation(recommendationId))
                    editIcon.classList.add('recommendation-action-cell-icon')
                    cellDiv.appendChild(editIcon)

                    return cellDiv
                },
            },
        ],
        initialSort: [{ column: 'englishName', dir: 'asc' }],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.recommendations-types-table {
    margin: -($spacing-s);

    &__empty-state {
        height: 150px;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }

        span {
            font-size: 1rem;
        }
    }
}

.recommendation-action-cell-icon {
    height: 18px;
    width: 18px;
    color: $pbl-primary;

    &:hover {
        color: darken($pbl-primary, 20);
    }
}
</style>
