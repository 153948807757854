<template>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="data"
            class="status-over-time-chart"
        >
            <v-chart
                ref="chartRef"
                class="status-over-time-chart__chart"
                :option="option"
                autoresize
            />
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { use } from 'echarts/core'
import { EChartsOption, EChartsType } from 'echarts'
import { BarChart, LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import {
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
    TitleComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { useConditionStatusYearlyQuery } from '@/api/queries/transformers/portfolio-trends/useConditionStatusYearlyQuery'
import { CHART_ICON } from '@/utils/chart-utils'
import type { CallbackDataParams } from 'echarts/types/dist/shared'

use([
    TitleComponent,
    LineChart,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
])

const props = defineProps<{
    plantSids: number[]
}>()

const plantSids = computed(() => {
    return props.plantSids
})

const { t } = useI18n()
const { data, isPending, isError } = useConditionStatusYearlyQuery(plantSids)

const chartRef = ref<EChartsType | null>(null)
const isBarChartActive = ref(true)
const isPercentageViewActive = ref(true)
const MIN_VALUE_TO_SHOW_LABEL = 4

const series = computed(() => {
    let normalData = data.value?.numberOfNormal?.map((e: number) => e || '-')
    let inferiorData = data.value?.numberOfInferior?.map((e: number) => e || '-')
    let criticalData = data.value?.numberOfCritical?.map((e: number) => e || '-')

    if (isPercentageViewActive.value) {
        normalData =
            data.value?.numberOfNormal?.map((e: number, index: number) => {
                const total =
                    e + (data.value?.numberOfInferior?.[index] || 0) + (data.value?.numberOfCritical?.[index] || 0)
                return e ? +((e / total) * 100).toFixed(1) : '-'
            }) || []

        inferiorData =
            data.value?.numberOfInferior?.map((e: number, index: number) => {
                const total =
                    (data.value?.numberOfNormal?.[index] || 0) + e + (data.value?.numberOfCritical?.[index] || 0)
                return e ? +((e / total) * 100).toFixed(1) : '-'
            }) || []

        criticalData =
            data.value?.numberOfCritical?.map((e: number, index: number) => {
                const total =
                    (data.value?.numberOfNormal?.[index] || 0) + (data.value?.numberOfInferior?.[index] || 0) + e
                return e ? +((e / total) * 100).toFixed(1) : '-'
            }) || []
    }

    return [
        {
            name: t(StatusTypes[ConditionStatus.Normal].labelKey),
            color: StatusTypes[ConditionStatus.Normal].color,
            data: normalData,
        },
        {
            name: t(StatusTypes[ConditionStatus.Inferior].labelKey),
            color: StatusTypes[ConditionStatus.Inferior].color,
            data: inferiorData,
        },
        {
            name: t(StatusTypes[ConditionStatus.Critical].labelKey),
            color: StatusTypes[ConditionStatus.Critical].color,
            data: criticalData,
        },
    ]
})

const option = computed((): EChartsOption => {
    const options: EChartsOption = {
        title: {
            show: false,
            text: t('transformers.portfolioTrends.portfolioStatusOverTime.headline'),
        },
        toolbox: {
            show: true,
            itemSize: 16,
            itemGap: 14,
            showTitle: true,
            feature: {
                mySwitchToLineChart: {
                    title: t('chart.action.switchToLineChart'),
                    icon: CHART_ICON.LINE,
                    iconStyle: {
                        color: !isBarChartActive.value && !isPercentageViewActive.value ? '#0178dc' : '#333',
                    },
                    onclick: () => {
                        isBarChartActive.value = false
                        isPercentageViewActive.value = false
                    },
                },
                mySwitchToBarChart: {
                    title: t('chart.action.switchToBarChart'),
                    icon: CHART_ICON.BAR,
                    iconStyle: {
                        color: isBarChartActive.value && !isPercentageViewActive.value ? '#0178dc' : '#333',
                    },
                    onclick: () => {
                        isBarChartActive.value = true
                        isPercentageViewActive.value = false
                    },
                },
                mySwitchToPercentageView: {
                    title: t('chart.action.switchToStackedView'),
                    icon: CHART_ICON.STACK,
                    iconStyle: {
                        color: isBarChartActive.value && isPercentageViewActive.value ? '#0178dc' : '#333',
                    },
                    onclick: () => {
                        isPercentageViewActive.value = true
                        isBarChartActive.value = true
                    },
                },
                saveAsImage: {
                    icon: CHART_ICON.SAVE,
                    title: t('chart.action.saveAsImage'),
                },
            },
            iconStyle: {
                color: '#333',
                borderColor: 'none',
            },
            emphasis: {
                iconStyle: {
                    color: '#0178dc',
                    borderColor: 'none',
                },
            },
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            axisPointer: {
                type: 'shadow',
            },
            valueFormatter: (value) => (isPercentageViewActive.value ? `${value}%` : `${value}`),
        },
        textStyle: {
            fontFamily: 'Roboto',
            color: '#adadad',
        },
        legend: {
            textStyle: {
                fontWeight: 500,
            },
        },
        grid: {
            top: 100,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: isPercentageViewActive.value ? '{value}%' : '{value}',
            },
            max: isPercentageViewActive.value ? 100 : undefined,
        },
        xAxis: {
            type: 'category',
            boundaryGap: isBarChartActive.value,
            data: data.value?.years ?? [],
        },
        aria: {
            enabled: true,
            decal: {
                show: true,
                decals: [
                    {
                        symbol: 'none',
                    },
                    {
                        symbol: 'rect',
                        rotation: -0.75,
                        dashArrayX: [1, 0],
                        dashArrayY: [4, 3],
                    },
                    {
                        symbol: 'rect',
                        dashArrayX: [
                            [0, 6, 6, 0],
                            [6, 6],
                        ],
                        dashArrayY: [6, 0],
                    },
                ],
            },
        },
        series: [],
    }

    series.value.forEach((series) => {
        if (!Array.isArray(options.series)) {
            return
        }

        options.series.push({
            name: series.name,
            type: isBarChartActive.value ? 'bar' : 'line',
            stack: isPercentageViewActive.value ? 'total' : '',
            color: series.color,
            label: {
                show: true,
                color: '#4a4a4a',
                fontWeight: 'bold',
                position: isPercentageViewActive.value ? 'inside' : 'top',
                backgroundColor: isPercentageViewActive.value ? 'rgba(255, 255, 255, 1)' : '',
                formatter: (params: CallbackDataParams) => {
                    if (!isPercentageViewActive.value || (params.data as number) > MIN_VALUE_TO_SHOW_LABEL) {
                        return isPercentageViewActive.value ? `${params.data}%` : `${params.data}`
                    }

                    return ''
                },
            },
            data: series.data,
        })
    })

    return options
})
</script>

<style scoped lang="scss">
.status-over-time-chart {
    &__chart {
        width: 100%;
        height: 500px;
    }
}
</style>

<style lang="scss">
.container {
    &__name {
        font-size: $default-font-size;
        color: #666;
        font-weight: 500;
    }

    &__content {
        margin: 10px 0 0;

        &__item-div {
            margin: 10px 0 0;
            display: flex;
            gap: 16px;
            align-items: flex-start;

            &__color-div {
                display: flex;
                gap: 10px;
                align-items: center;

                &__color-circle {
                    display: inline-block;
                    border-radius: 10px;
                    width: 10px;
                    height: 10px;
                }

                &__series-name {
                    font-size: $default-font-size;
                    color: #666;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            &__tooltip {
                font-size: $default-font-size;
                color: #666;
                font-weight: 500;
                line-height: 18px;
                max-width: 200px;
                white-space: normal;
            }
        }
    }
}
</style>
