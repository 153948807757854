<template>
    <FormFieldWrapper
        :label="label"
        :cta-label="hasClearAction ? t('formInputs.common.actions.clear') : undefined"
        @click:cta="onCtaClick"
    >
        <pbl-input
            :value="modelValue"
            :placeholder="placeholder"
            :type="type"
            :disabled="disabled"
            :invalid="invalid"
            :maxlength="maxlength"
            @input="onPblInput"
        >
            <span
                v-if="invalid"
                slot="subline"
            >
                {{ errorMessage }}
            </span>
            <span
                v-if="showMaxlength"
                slot="subline"
                class="length-counter"
            >
                {{ inputLength }} / {{ maxlength }}
            </span>
        </pbl-input>
    </FormFieldWrapper>
</template>

<script setup lang="ts">
import FormFieldWrapper from '@/components/form-inputs/form-field-wrapper/FormFieldWrapper.vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    modelValue: string | undefined

    label?: string
    placeholder?: string
    type?: string
    disabled?: boolean
    invalid?: boolean
    errorMessage?: string
    maxlength?: number
    hasClearAction?: boolean
}>()

const { t } = useI18n()

const inputLength = computed<number>(() => {
    return props.modelValue?.length ? props.modelValue.length : 0
})

const showMaxlength = computed<boolean>(() => !!props.maxlength && props.maxlength / 2 <= inputLength.value)

const emit = defineEmits<{
    'update:modelValue': [value: string]
}>()

const onPblInput = (event: InputEvent) => {
    const value = (event.target as HTMLInputElement)?.value ?? ''

    emit('update:modelValue', value)
}

const onCtaClick = () => {
    if (!props.hasClearAction) {
        return
    }

    emit('update:modelValue', '')
}
</script>

<style scoped lang="scss">
.length-counter {
    float: right;
    color: $pbl-text;
}
</style>
