import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useBookmarksDeleteMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { bookmarkId: number }) =>
            hcaClientFactory.bookmarksApi.deleteBookmark(payload.bookmarkId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['bookmarks'] })
            await queryClient.invalidateQueries({ queryKey: ['transformersHierarchy'] })
        },
    })
}
