<template>
    <pbl-toggle
        :key="keyRef"
        :checked="modelValue"
        :disabled="disabled"
        @change="onPblToggleChange"
    >
        {{ label }}
    </pbl-toggle>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'

defineProps<{
    modelValue: boolean
    label?: string
    disabled?: boolean
}>()

const emit = defineEmits<{
    'update:modelValue': [value: boolean]
}>()

const keyRef = ref(1)

const onPblToggleChange = (event: CustomEvent<{ checked: boolean }>) => {
    emit('update:modelValue', event.detail.checked)

    nextTick(() => {
        keyRef.value += 1
    })
}
</script>
