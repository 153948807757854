import { InjectionKey, Plugin } from 'vue'
import { PebbleToastMessage, PebbleToasts } from '@pebble/toasts/dist/pebble-toasts'

export type PebbleToastsPluginConfig = {
    containerId: string
    position: string
    duration: number
    closeButton: boolean
    fullWidth: boolean
    newestOnTop: boolean
    onClick: (message: PebbleToastMessage) => void
}

export const PebbleToastsInjectionKey: InjectionKey<PebbleToasts> = Symbol('pebbleToasts')

export const pebbleToastsPlugin: Plugin<Partial<PebbleToastsPluginConfig>> = {
    install: (app, config: Partial<PebbleToastsPluginConfig>) => {
        app.provide(PebbleToastsInjectionKey, new PebbleToasts(config))
    },
}
