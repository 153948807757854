import { MaybeRefOrGetter, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformerVisualInspectionQuery = (transformerId: MaybeRefOrGetter<string>) => {
    return useQuery({
        queryKey: ['transformers', transformerId, 'visual-inspection'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.componentStatusApi.listVisualInspectionsByTransformerSid(
                toValue(transformerId),
                0,
                0,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
