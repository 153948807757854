import { defineStore } from 'pinia'
import { ExtendedUamUserPermissions } from '@/models/uam/ExtendedUamUserPermissions'
import { computed, ref } from 'vue'
import { Permission } from '@/configuration/permissions/permission'

export const useUserStore = defineStore('userStore', () => {
    const userData = ref<ExtendedUamUserPermissions | undefined>(undefined)

    const name = computed(() => userData.value?.name?.replaceAll(',', ''))

    const setUserData = (payload: ExtendedUamUserPermissions) => {
        userData.value = payload
    }

    const hasPermission = (permission: Permission) => {
        return userData.value?.permissions?.some((e) => e.name === permission)
    }

    return {
        userData,
        name,
        setUserData,
        hasPermission,
    }
})
