import { useQuery } from '@tanstack/vue-query'
import { MaybeRef, toValue } from 'vue'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'

export const useTransformerComponentStatusQuery = (transformerId: MaybeRef<string>) => {
    return useQuery({
        queryKey: ['transformers', transformerId, 'componentStatus'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.componentStatusApi.getConditionStatusByTransformer(toValue(transformerId), {
                signal,
            })
            return result
        },
        enabled: whenAuthenticated,
    })
}
