<template>
    <ExportWrapper :file-name="fileName">
        <ExportConditionStatusTitleSection />
        <div class="master-data-wrapper">
            <MasterDataPanel :transformer-id="transformerId" />
        </div>
        <div class="table-wrapper">
            <ConditionStatusLatestReportsSection export-layout />
        </div>
        <div class="section-wrapper">
            <ExportConditionStatusRecommendationsSection :preload-size="recommendationsPreloadSize" />
        </div>
        <template v-if="!isSectionHidden">
            <div class="table-wrapper">
                <ConditionStatusAnalysisTable
                    :analysis-type="AnalysisType.GasInOil"
                    :report-display-count="MAX_REPORT_COUNT"
                />
            </div>
            <div class="table-wrapper">
                <ConditionStatusAnalysisTable
                    :analysis-type="AnalysisType.Furans"
                    :report-display-count="MAX_REPORT_COUNT"
                />
            </div>
            <div class="table-wrapper">
                <ConditionStatusAnalysisTable
                    :analysis-type="AnalysisType.InsulatingOil"
                    :report-display-count="MAX_REPORT_COUNT"
                />
            </div>
            <div class="section-wrapper">
                <ExportConditionStatusCommentsSection :preload-size="commentsPreloadSize" />
            </div>
            <div
                v-if="isFullOilSelection"
                class="chart-wrapper"
            >
                <ContentPanel
                    :headline="t('transformers.conditionStatus.content.oilHistoricalTrends.title')"
                    :hide-content="!hasOilChartData"
                >
                    <template
                        v-if="!hasOilChartData"
                        #context
                    >
                        <ColorBadge
                            :color="StatusTypes[ConditionStatus.None].color"
                            :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                            small
                        />
                    </template>
                    <ConditionStatusOilHistoricalTrendsChart
                        :start-date="oilSelection.startDate"
                        :end-date="oilSelection.endDate"
                        :gas-in-oil-parameter-group="oilSelection.gasInOilParameterGroup"
                        hide-toolbox
                        @update:has-data="onUpdateOilChartHasData"
                    />
                </ContentPanel>
            </div>
        </template>
        <div class="section-wrapper">
            <ExportConditionStatusVisualInspectionSection />
        </div>
        <ExportFooterSection />
    </ExportWrapper>
</template>

<script setup lang="ts">
import { provideTransformerId } from '@/composables/useTransformerId'
import { computed, ref, toRefs } from 'vue'
import ExportWrapper from '@/pages/exports/ExportWrapper.vue'
import ExportConditionStatusTitleSection from '@/pages/exports/transformers/condition-status/sections/ExportConditionStatusTitleSection.vue'
import ConditionStatusLatestReportsSection from '@/pages/transformers/condition-status/sections/latest-reports/ConditionStatusLatestReportsSection.vue'
import MasterDataPanel from '@/components/master-data/MasterDataPanel.vue'
import ConditionStatusAnalysisTable from '@/pages/transformers/condition-status/sections/analyses/ConditionStatusAnalysisTable.vue'
import { AnalysisType } from '@/configuration/analysis/analysis-types'
import ConditionStatusOilHistoricalTrendsChart from '@/pages/transformers/condition-status/sections/oil-historical-trends/ConditionStatusOilHistoricalTrendsChart.vue'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { OilHistoricalTrendsSelector } from '@/pages/transformers/condition-status/sections/oil-historical-trends/oil-historical-trends-types'
import ExportFooterSection from '@/pages/exports/common/ExportFooterSection.vue'
import ExportConditionStatusRecommendationsSection from '@/pages/exports/transformers/condition-status/sections/ExportConditionStatusRecommendationsSection.vue'
import ExportConditionStatusCommentsSection from '@/pages/exports/transformers/condition-status/sections/ExportConditionStatusCommentsSection.vue'
import ExportConditionStatusVisualInspectionSection from '@/pages/exports/transformers/condition-status/sections/ExportConditionStatusVisualInspectionSection.vue'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { DATE_FORMAT } from '@/utils/date-utils'
import { format } from 'date-fns'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { CONSTRUCTION_TYPE } from '@/utils/master-data-utils'

const props = defineProps<{
    transformerId: string
}>()

const MAX_REPORT_COUNT = 3

const hasOilChartData = ref(true)

const { t } = useI18n()
const { transformerId } = toRefs(props)

const { query } = useRoute()

const { data: transformerDetailsData } = useTransformerDetailsQuery(transformerId)

const isSectionHidden = computed(() => {
    return transformerDetailsData.value?.transformerTypeEnumSid === CONSTRUCTION_TYPE.TROCKEN
})

const fileName = computed(() => {
    if (!transformerDetailsData.value) {
        return undefined
    }

    const plantGroupName = transformerDetailsData.value.plantGroupName
    const plantName = transformerDetailsData.value.plantName
    const transformerSid = transformerDetailsData.value.transformerSid
    const currentDate = format(new Date(), DATE_FORMAT)

    return `HCA_ComponentStatus_${plantGroupName}_${plantName}_${transformerSid}_${currentDate}`
})

const oilSelection = computed<OilHistoricalTrendsSelector>(() => {
    const gasInOilParameterNumber = parseInt(query.gasInOilParameterGroup as string)

    return {
        startDate: query.startDate as string | undefined,
        endDate: query.endDate as string | undefined,
        gasInOilParameterGroup: isNaN(gasInOilParameterNumber)
            ? (query.gasInOilParameterGroup as string | undefined)
            : gasInOilParameterNumber,
    }
})

const isFullOilSelection = computed<boolean>(() => {
    return !!oilSelection.value.startDate && !!oilSelection.value.endDate && !!oilSelection.value.gasInOilParameterGroup
})

const recommendationsPreloadSize = computed(() => {
    const size = parseInt(query.recommendationsLength as string)

    return isNaN(size) ? undefined : size
})

const commentsPreloadSize = computed(() => {
    const size = parseInt(query.commentsLength as string)

    return isNaN(size) ? undefined : size
})

provideTransformerId(transformerId)

const onUpdateOilChartHasData = (value: boolean) => (hasOilChartData.value = value)
</script>

<style scoped lang="scss">
:deep(.content-panel) {
    border: 1px solid $pbl-divider;
    box-shadow: unset;
}

.section-wrapper {
    padding: $spacing-xs $spacing-s;
}

.master-data-wrapper {
    padding: $spacing-xs $spacing-s;
}

.table-wrapper {
    break-inside: avoid;
    padding: $spacing-xs $spacing-s;

    :deep(.badge) {
        font-size: 15px;
        padding: $spacing-2xs $spacing-xs;
    }

    :deep(.analysis-table) {
        margin-top: 8px;
    }
}

.chart-wrapper {
    break-inside: avoid;
    padding: $spacing-xs $spacing-s;
}
</style>
