import { createI18n } from 'vue-i18n'
import en_GB from '@/locales/en-GB.json'
import de_DE from '@/locales/de-DE.json'
import { SelectOptions } from '@/components/form-inputs/common/select-option'

enum Locales {
    'en-GB' = 0,
    'de-DE',
}

export const LocalesMap = {
    'en-GB': 'englishName',
    'de-DE': 'germanName',
} as const

export const HYDRO_LANG = 'HYDRO_LANG' as const
export const SUPPORTED_LOCALES = ['en-GB', 'de-DE'] as const

export const SUPPORTED_LOCALES_OPTIONS: SelectOptions<(typeof SUPPORTED_LOCALES)[number]> = [
    {
        label: 'English',
        value: 'en-GB',
    },
    {
        label: 'German',
        value: 'de-DE',
    },
]

const determinePreferredLocale = (): string => {
    const storedLang = localStorage.getItem(HYDRO_LANG)

    if (storedLang && SUPPORTED_LOCALES.includes(storedLang as (typeof SUPPORTED_LOCALES)[number])) {
        return storedLang
    }

    const navigatorLanguage = navigator.language as (typeof SUPPORTED_LOCALES)[number]

    if (SUPPORTED_LOCALES.includes(navigatorLanguage)) {
        return SUPPORTED_LOCALES[Locales[navigatorLanguage]]
    }

    const foundLocaleIndex = SUPPORTED_LOCALES.map((locale) => locale.slice(0, 2)).indexOf(
        navigatorLanguage.slice(0, 2),
    )

    return foundLocaleIndex !== -1 ? SUPPORTED_LOCALES[foundLocaleIndex] : SUPPORTED_LOCALES[Locales['en-GB']]
}

export const i18n = createI18n({
    legacy: false,
    locale: determinePreferredLocale(),
    messages: {
        /* eslint-disable @typescript-eslint/no-explicit-any */
        'en-GB': en_GB as any,
        'de-DE': de_DE as any,
        /* eslint-enable @typescript-eslint/no-explicit-any */
    },
})
