<template>
    <ContentPanel :headline="t('admin.thresholds.oilAnalyses.static.headline')">
        <div class="oil-analyses">
            <div
                v-if="LanguageCode.GERMAN !== locale"
                class="oil-analyses__section--inline"
            >
                <div class="oil-analyses__section--inline__heading">
                    {{ t('admin.thresholds.oilAnalyses.static.reportStatus.title') }}
                </div>
                <ul>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.reportStatus.yellowLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.reportStatus.redLogic') }}</li>
                </ul>
                <div class="oil-analyses__section--inline__heading">
                    {{ t('admin.thresholds.oilAnalyses.static.analysisStatus.title') }}
                </div>
                <ul>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.analysisStatus.yellowLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.analysisStatus.redLogic') }}</li>
                </ul>
            </div>

            <div
                v-if="LanguageCode.GERMAN === locale"
                class="oil-analyses__section"
            >
                <div class="oil-analyses__section__heading">
                    {{ t('admin.thresholds.oilAnalyses.static.oilAnalysisLogic.title') }}
                </div>
                <ul>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.oilAnalysisLogic.greenLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.oilAnalysisLogic.yellowLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.oilAnalysisLogic.redLogic') }}</li>
                </ul>
                <div class="oil-analyses__section__heading">
                    {{ t('admin.thresholds.oilAnalyses.static.visualInspectionLogic.title') }}
                </div>
                <ul>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.visualInspectionLogic.greenLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.visualInspectionLogic.yellowLogic') }}</li>
                    <li>{{ t('admin.thresholds.oilAnalyses.static.visualInspectionLogic.redLogic') }}</li>
                </ul>
            </div>

            <pbl-button
                :loading="isLoading"
                compact
                @click="onDownloadButtonClick"
            >
                <span class="oil-analyses__button-text">
                    {{ t('admin.thresholds.oilAnalyses.viewHistoricalThresholds') }}
                </span>
            </pbl-button>
        </div>
    </ContentPanel>
</template>

<script setup lang="ts">
import ContentPanel from '@/components/panel/ContentPanel.vue'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { computed, ref } from 'vue'
import { AxiosError, AxiosHeaders } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { useI18n } from 'vue-i18n'
import { downloadFile, getFilename } from '@/utils/file-utils'
import { LanguageType, LanguageCode } from '@/configuration/language/language-types'

const { t, locale } = useI18n()
const toasts = usePebbleToasts()

const isLoading = ref(false)
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const languageType = computed(
    () => (LanguageType[locale.value as unknown as LanguageType] as unknown as number) ?? LanguageType['en-GB'],
)

const onDownloadButtonClick = async () => {
    try {
        isLoading.value = true

        const response = await hcaClientFactory.thresholdsApi.exportThresholds(languageType.value, timezone, {
            responseType: 'blob',
        })
        const data = response.data as Blob | undefined

        if (!data || !response.headers) {
            return
        }

        const filename = getFilename(response.headers as AxiosHeaders) ?? 'unknown'
        downloadFile(data, filename)
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    } finally {
        isLoading.value = false
    }
}
</script>

<style scoped lang="scss">
.oil-analyses {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    &__section {
        font-size: $default-font-size;

        &__heading {
            font-weight: 500;
        }

        &--inline {
            display: grid;
            grid-template-columns: min-content max-content;
            gap: $spacing-2xs 0;

            font-size: $default-font-size;

            &__heading {
                font-weight: 500;
                white-space: nowrap;
                align-self: center;
            }

            ul {
                list-style-type: none;
            }
        }
    }

    &__button-text {
        font-weight: 500;
    }
}
</style>
