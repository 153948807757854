import { RecommendationParams } from '@/types/recommendations'
import { inject, InjectionKey, provide, ref, Ref } from 'vue'

const defaultParams: RecommendationParams = {
    plantGroupSid: [],
    plantSid: [],
    componentType: [],
}

export const RecommendationParamsInjectionKey: InjectionKey<Ref<RecommendationParams>> = Symbol(
    'RecommendationParamsInjectionKey',
)

export const useRecommendationParams = () => inject(RecommendationParamsInjectionKey) ?? ref(defaultParams)

export const provideRecommendationParams = (recommendationParams: Ref<RecommendationParams>) =>
    provide(RecommendationParamsInjectionKey, recommendationParams)
