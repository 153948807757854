<template>
    <div class="report-analysis-table">
        <div ref="tableRef" />
    </div>
</template>

<script setup lang="ts">
import { AnalysisDto, CommentParameterDto, OverrideCommentDto } from '@/api/generated/HCA'
import { computed, ref, toRefs, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, ColumnDefinition, Options } from 'tabulator-tables'
import { useAnalysisTableUtils } from '@/pages/transformers/condition-status/sections/analyses/useAnalysisTableUtils'
import { useI18n } from 'vue-i18n'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { AlertCircle, AlertTriangle, createElement, IconNode, MessageSquareText, RefreshCcwDot } from 'lucide'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { chooseLangString } from '@/utils/i18n-utils'

type TableRowData = {
    measurementName: string
    measurementValue: string
    limitValue?: string
    limitComment?: string
    status: number

    overrideComment?: OverrideCommentDto
    latestComment?: CommentParameterDto
}

const props = defineProps<{
    reportNumber: string
    analysisData: AnalysisDto
}>()

const { reportNumber, analysisData } = toRefs(props)

const { t, locale } = useI18n()
const { createAnalysisTableTooltipElement, createAnalysisTableLimitTooltipElement } = useAnalysisTableUtils()

const { data: constantsData } = useConstantsQuery()

const statusIcons: Record<number, { iconNode: IconNode; color: string }> = {
    [ConditionStatus.Inferior]: {
        iconNode: AlertTriangle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Inferior)?.color ?? '',
    },
    [ConditionStatus.Critical]: {
        iconNode: AlertCircle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Critical)?.color ?? '',
    },
}

// @ts-expect-error ColumnDefinition doesn't properly type the Tooltip return type.
const columns = computed<ColumnDefinition[]>(() => {
    if (!analysisData.value) {
        return []
    }

    return [
        {
            title: t('reports.detailsPage.analysisTable.table.reportNumber'),
            columns: [
                {
                    title: t('reports.detailsPage.analysisTable.table.measurement'),
                    field: 'measurementName',
                    headerSort: false,
                },
            ],
        },
        {
            title: t('reports.detailsPage.analysisTable.table.limit'),
            field: 'limitValue',
            cssClass: 'analysis-table-limit-cell',
            headerSort: false,
            tooltip: (event: UIEvent, cell: CellComponent) => {
                const rowData = cell.getRow().getData()

                const limitText = rowData.limitComment

                if (!limitText) {
                    return ''
                }

                return createAnalysisTableLimitTooltipElement(limitText)
            },
        },
        {
            title: reportNumber.value,
            columns: [
                {
                    title: format(analysisData.value.samplingDate ?? new Date(), DATE_FORMAT),
                    field: 'parameterValue',
                    headerSort: false,
                    minWidth: 150,
                    formatter: (cell: CellComponent) => {
                        const rowData: TableRowData = cell.getRow().getData()

                        const displayedText = rowData.measurementValue
                        const status = rowData.status

                        const statusIconData = statusIcons[status]
                        const overrideComment = rowData.overrideComment
                        const commentParameter = rowData.latestComment

                        const cellDiv = document.createElement('div')
                        cellDiv.className = 'analysis-table-content-cell'

                        const textSpan = document.createElement('span')
                        textSpan.textContent = displayedText
                        cellDiv.appendChild(textSpan)

                        if (statusIconData) {
                            const statusIcon = createElement(statusIconData.iconNode)
                            statusIcon.classList.add('analysis-table-icon')
                            statusIcon.setAttribute('stroke', statusIconData.color)
                            statusIcon.setAttribute('stroke-width', '3px')
                            cellDiv.appendChild(statusIcon)
                        }

                        if (overrideComment) {
                            const overrideIcon = createElement(RefreshCcwDot)
                            overrideIcon.classList.add('analysis-table-icon')
                            cellDiv.appendChild(overrideIcon)
                        }

                        if (commentParameter) {
                            const commentIcon = createElement(MessageSquareText)
                            commentIcon.classList.add('analysis-table-icon')
                            cellDiv.appendChild(commentIcon)
                        }

                        return cellDiv
                    },
                    tooltip: (event: UIEvent, cell: CellComponent) => {
                        const rowData: TableRowData = cell.getRow().getData()

                        if (!rowData.latestComment && !rowData.overrideComment) {
                            return ''
                        }

                        return createAnalysisTableTooltipElement(rowData)
                    },
                },
            ],
        },
    ]
})

const tableData = computed(() => {
    if (!analysisData.value || !constantsData.value) {
        return []
    }

    const rowData: TableRowData[] = []

    rowData.push({
        measurementName: t('reports.detailsPage.analysisTable.constantRows.samplingPoint'),
        measurementValue: analysisData.value.samplingPoint?.toString() ?? '',
        limitValue: '-',
        status: 0,
    })

    rowData.push({
        measurementName: t('reports.detailsPage.analysisTable.constantRows.temperature'),
        measurementValue: analysisData.value.temperature?.toString() ?? '',
        limitValue: '-',
        status: 0,
    })

    analysisData.value.parameters?.forEach((parameter) => {
        const parameterData = constantsData.value?.parameters?.find((e) => e.id === parameter.parameterId)

        rowData.push({
            measurementName: chooseLangString(locale, parameterData),
            measurementValue: parameter.stringValue ?? parameter.numberValue?.toString() ?? '-',
            limitValue: parameter.limit?.toString() ?? '-',
            limitComment:
                (locale.value === 'en-GB'
                    ? parameterData?.englishAutomaticNotice
                    : parameterData?.germanAutomaticNotice) ?? '-',
            status: parameter.status ?? 0,
            overrideComment: parameter.overrideComment,
            latestComment: parameter.latestComment,
        })
    })

    rowData.push({
        measurementName: t('reports.detailsPage.analysisTable.constantRows.sampler'),
        measurementValue: analysisData.value.sampler?.toString() ?? '',
        limitValue: '-',
        status: 0,
    })

    rowData.push({
        measurementName: t('reports.detailsPage.analysisTable.constantRows.comment'),
        measurementValue: analysisData.value.comment?.toString() ?? '',
        limitValue: '-',
        status: 0,
    })

    return rowData
})

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

watch([tableRef, tableData], ([newTableRef, newTableData]) => {
    if (!newTableRef) {
        return
    }

    if (newTableData.length === 0) {
        return
    }

    const tabulatorOptions: Options = {
        layoutColumnsOnNewData: true,
        reactiveData: true,
        pagination: false,
        layout: 'fitDataStretch',
        height: '100%',
        headerSort: false,
        columnHeaderVertAlign: 'bottom',
        columnDefaults: {
            vertAlign: 'middle',
        },
        data: newTableData,
        columns: columns.value,
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.report-analysis-table {
    margin: -($spacing-s);
}
</style>

<style lang="scss">
.analysis-table-limit-cell:not(.tabulator-col) {
    background-color: #d9ebfa !important;
}

.analysis-table-content-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
}

.analysis-table-icon {
    height: 1.1rem;
    width: 1.1rem;
}

.analysis-table-tooltip {
    display: flex;
    gap: $spacing-s;

    background-color: $pbl-text !important;
    color: $pbl-foreground;

    max-width: 1000px;

    &__override {
        flex-grow: 1;
        display: flex;

        flex-direction: column;
        gap: $spacing-s;

        max-width: 300px;

        &__title {
            font-weight: 500;
        }

        &__content {
            white-space: pre-wrap;
            text-overflow: ellipsis;
        }

        &__details {
            display: flex;
            justify-content: start;
        }
    }

    &__comment {
        flex-grow: 1;
        display: flex;

        flex-direction: column;
        gap: $spacing-s;

        max-width: 300px;

        &__title {
            font-weight: 500;
        }

        &__content {
            white-space: pre-wrap;
            text-overflow: ellipsis;
        }

        &__details {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__separator {
        min-height: 100%;
        width: 1px;

        background-color: white;
    }
}

.analysis-status-header {
    display: flex;
    min-height: 40px;

    &__status {
        display: flex;

        align-items: center;
        gap: $spacing-s;

        &-label {
            font-weight: 500;
            font-size: $default-font-size;
        }
    }
}
</style>
