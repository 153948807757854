import { type Authenticator, useCoodeAuth, COODE_MSAL_DEFAULT_CONFIG } from '@coode/fe-sdk'
import { coodeSdkConfig } from '@/authentication/coodeSdkConfig'

/**
 * Returns a configured MSAL authenticator instance
 * with current use-case configuration from {CoodeSdkConfig}
 *
 * @constant
 * @returns {Authenticator}
 */
export const useAppAuthenticator = (): Authenticator => {
    const { auth } = coodeSdkConfig
    const msalConfig = auth?.msal ?? COODE_MSAL_DEFAULT_CONFIG

    return useCoodeAuth(msalConfig, auth?.additionalScopesToConsent, auth?.eventCallback)
}
