import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { MaybeRef, toValue } from 'vue'

export const useDashboardNotificationsQuery = (
    componentType: MaybeRef<number>,
    notificationTypes: MaybeRef<number[]>,
    pastDays: MaybeRef<number>,
) => {
    return useQuery({
        queryKey: ['dashboard', 'notifications', componentType, notificationTypes, pastDays],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.dashboardApi.listEvents(
                toValue(componentType),
                undefined,
                undefined,
                toValue(notificationTypes),
                toValue(pastDays),
                0,
                0,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
    })
}
