import { useAuthStore } from '@/store/authStore'
import type { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'

interface RequestConfig {
    url: string
    body?: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any
    additionalConfig?: AxiosRequestConfig
}

type AccessTokenProvider = () => Promise<string | undefined>

type BaseServiceOptions = {
    baseURL?: string
    accessTokenProvider?: AccessTokenProvider
}

export abstract class BaseService {
    private axiosInstance: AxiosInstance
    private accessTokenProvider: AccessTokenProvider

    /**
     * Configures ApiService to use a new Axios instance,
     * stores the accessTokenProvider if supplied, or defaults
     * to a provider that returns the accessToken stored inside userStore,
     * and configures auth interceptors for the Axios instance.
     *
     * @see {useUserStore} for the default token
     *
     * @constructor
     * @param options
     */
    constructor(options?: BaseServiceOptions) {
        this.axiosInstance = axios.create({
            baseURL: options?.baseURL,
        })

        this.accessTokenProvider = options?.accessTokenProvider ?? (() => Promise.resolve(useAuthStore().accessToken))

        this.configureAxiosInterceptors()
    }

    protected get<T, R = AxiosResponse<T>>({
        url,
        body = {},
        params = {},
        additionalConfig = {},
    }: RequestConfig): Promise<R> {
        return this.request('GET', url, body, params, additionalConfig)
    }

    protected post<T, R = AxiosResponse<T>>({
        url,
        body = {},
        params = {},
        additionalConfig = {},
    }: RequestConfig): Promise<R> {
        return this.request('POST', url, body, params, additionalConfig)
    }

    protected put<T, R = AxiosResponse<T>>({
        url,
        body = {},
        params = {},
        additionalConfig = {},
    }: RequestConfig): Promise<R> {
        return this.request('PUT', url, body, params, additionalConfig)
    }

    protected patch<T, R = AxiosResponse<T>>({
        url,
        body = {},
        params = {},
        additionalConfig = {},
    }: RequestConfig): Promise<R> {
        return this.request('PATCH', url, body, params, additionalConfig)
    }

    protected delete<T, R = AxiosResponse<T>>({
        url,
        body = {},
        params = {},
        additionalConfig = {},
    }: RequestConfig): Promise<R> {
        return this.request('DELETE', url, body, params, additionalConfig)
    }

    private request<T, R = AxiosResponse<T>>(
        method: Method,
        url: string,
        body?: unknown,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params?: any,
        additionalConfig: AxiosRequestConfig = {},
    ): Promise<R> {
        const requestConfig: AxiosRequestConfig = {
            url,
            method,
            data: body,
            params: params,
            ...additionalConfig,
        }

        return this.axiosInstance.request<T, R>(requestConfig)
    }

    /**
     * Configures the Axios instance of this ApiService
     * to use the object's AccessTokenProvider and handle
     * authentication errors.
     */
    private configureAxiosInterceptors(): void {
        this.axiosInstance.interceptors.request.use(async (request) => {
            if (request.headers) {
                const token = await this.accessTokenProvider()

                if (token) {
                    request.headers.Authorization = `Bearer ${token}`
                }
            }
            return request
        })

        this.axiosInstance.interceptors.response.use(
            (response) => Promise.resolve(response),
            async (error: AxiosError) => {
                if (error.response?.status === StatusCodes.UNAUTHORIZED) {
                    try {
                        await useAuthStore().renewToken()
                    } catch (e) {
                        console.log('error: ', e)
                    }
                }

                return Promise.reject(error)
            },
        )
    }
}

export function isAxiosError(error: unknown): error is AxiosError {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!error && error instanceof Object && 'request' in error
}
