import { inject, InjectionKey, provide } from 'vue'

export type ThresholdEditOpenModalPayload = {
    id: number
}

export type ThresholdEditModalActions = {
    openThreshold: (payload: ThresholdEditOpenModalPayload) => void
}

const ThresholdEditModalInjectionKey: InjectionKey<ThresholdEditModalActions> = Symbol('ThresholdEditModalInjectionKey')

export const useThresholdEditModal = () => {
    return inject(ThresholdEditModalInjectionKey)
}

export const provideThresholdEditModal = (actions: ThresholdEditModalActions) =>
    provide(ThresholdEditModalInjectionKey, actions)
