import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { OverrideStatusDto } from '@/api/generated/HCA'

export const useTransformersStatusAssessmentOverrideMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { parameterId: number; statusDto: OverrideStatusDto }) =>
            hcaClientFactory.componentStatusApi.overrideParameterStatusById(payload.parameterId, payload.statusDto),
        onSuccess: async (response, payload) => {
            await queryClient.invalidateQueries({ queryKey: ['transformers', payload.statusDto.transformerSid] })
        },
    })
}
