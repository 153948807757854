import { useMutation } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useReportsExportMutation = () => {
    return useMutation({
        mutationFn: async (payload: {
            exportType: number
            startDate: string | undefined
            endDate: string | undefined
            plantSid: number[] | undefined
            componentTypes: number[] | undefined
            componentSids: string[] | undefined
            languageType: number
        }) =>
            hcaClientFactory.reportsApi.exportReport(
                payload.exportType,
                [],
                payload.startDate,
                payload.endDate,
                payload.plantSid,
                payload.componentTypes,
                payload.componentSids,
                payload.languageType,
                { responseType: 'blob' },
            ),
    })
}
