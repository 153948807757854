<template>
    <div
        v-if="data"
        class="component-selector"
    >
        <div class="component-selector__form">
            <FormMultiSelectField
                :key="JSON.stringify(plantGroupSid)"
                v-model="plantGroupSid"
                :label="t('recommendations.selector.labels.plantGroup')"
                :options="plantGroupOptions"
                input-id="plantGroupSid"
            />
            <FormMultiSelectField
                :key="JSON.stringify(plantSid)"
                v-model="plantSid"
                :label="t('recommendations.selector.labels.plant')"
                :options="plantOptions"
                :is-disabled="plantGroupSid?.length === 0"
                input-id="plantSid"
                @update:model-value="onUpdateTransformerValue"
            />
            <FormMultiSelectField
                :key="JSON.stringify(componentType)"
                v-model="componentType"
                :label="t('recommendations.selector.labels.componentType')"
                :options="componentTypeOptions"
                input-id="componentType"
                @update:model-value="onUpdateTransformerValue"
            />
            <FormFieldWrapper
                input-id="startDate"
                :label="t('recommendations.selector.labels.startDate')"
            >
                <pbl-input
                    v-model="startDate"
                    type="month"
                    @input="onUpdateTransformerValue"
                />
            </FormFieldWrapper>
            <FormFieldWrapper
                input-id="endDate"
                :label="t('recommendations.selector.labels.endDate')"
            >
                <pbl-input
                    v-model="endDate"
                    :invalid="!isTimeframeValid"
                    type="month"
                    @input="onUpdateTransformerValue"
                >
                    <span
                        v-if="!isTimeframeValid"
                        slot="subline"
                    >
                        {{ t('recommendations.selector.errors.invalidTimeFrame') }}
                    </span>
                </pbl-input>
            </FormFieldWrapper>
            <FormSingleSelectField
                v-model="componentId"
                :label="t('recommendations.selector.labels.componentId')"
                :placeholder-text="t('recommendations.selector.labels.componentId')"
                :cta-label="componentId ? t('formInputs.common.actions.clear') : ''"
                :options="transformerListOptions"
                @update:model-value="onUpdateTransformerValue"
                @click:cta="onClearComponentId"
            />
        </div>
        <div class="component-selector__buttons">
            <HorizontalButton
                :text="t('recommendations.selector.actions.reset')"
                :disabled="!isAbleToReset"
                icon-name="arrow-counterclockwise"
                @click="onSelectionReset"
            />
        </div>
    </div>
    <div
        v-else-if="isPending"
        class="loading-state"
    >
        <pbl-spinner />
        <span>
            {{ t('recommendations.selector.loading') }}
        </span>
    </div>
    <div
        v-else-if="isError"
        class="error-state"
    >
        <pbl-icon name="exclamation-triangle" />
        <span>
            {{ t('recommendations.selector.errors.failedToLoadHierarchy') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import FormMultiSelectField from '@/components/form-inputs/form-multiselect-field/FormMultiselectField.vue'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { computed, ref, watch } from 'vue'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { useI18n } from 'vue-i18n'
import HorizontalButton from '@/components/buttons/HorizontalButton.vue'
import FormFieldWrapper from '@/components/form-inputs/form-field-wrapper/FormFieldWrapper.vue'
import { ComponentType, ComponentTypes } from '@/configuration/component/component-types'
import { flattenPlantsHierarchy, usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'
import { RecommendationParams } from '@/types/recommendations'
import { compareAsc } from 'date-fns'
import {
    flattenTransformersHierarchy,
    transformerOptions,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'

const props = defineProps<{
    modelValue?: RecommendationParams
}>()

const emit = defineEmits<{
    'update:modelValue': [payload: RecommendationParams]
}>()

const { t } = useI18n()

const { data, isPending, isError } = usePlantsHierarchyQuery()

const plantGroupSid = ref<number[]>([])
const plantSid = ref<number[]>([])
const componentType = ref<number[]>([])
const startDate = ref<string | undefined>(undefined)
const endDate = ref<string | undefined>(undefined)
const componentId = ref<string | undefined>(undefined)

const isTimeframeValid = computed(() => {
    if (!startDate.value || !endDate.value) {
        return true
    }

    return compareAsc(new Date(startDate.value), new Date(endDate.value)) === -1
})

const recommendationParams = computed<RecommendationParams>(() => {
    return {
        plantGroupSid: plantGroupSid.value,
        plantSid: plantSid.value,
        componentType: componentType.value,
        startDate: startDate.value,
        endDate: endDate.value,
        componentId: componentId.value,
    }
})

const plantGroupList = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return data.value.plantGroups
})

const plantGroupOptions = computed<SelectOptions<number>>(
    () =>
        plantGroupList.value.map((e) => ({
            label: e.name ?? '-',
            value: e.sid ?? 0,
        })) ?? [],
)

const plantOptions = computed<SelectOptions<number>>(() => {
    if (!plantGroupSid.value) {
        return []
    }

    const selectedPlantGroups = data.value?.plantGroups?.filter((e) => plantGroupSid.value?.includes(e.sid ?? -1)) ?? []

    if (selectedPlantGroups.length === 0) {
        return []
    }

    return selectedPlantGroups.flatMap(
        (e) =>
            e.plants?.map((e) => ({
                label: e.name ?? '-',
                value: e.sid ?? 0,
            })) ?? [],
    )
})

const componentTypeOptions = computed<SelectOptions<number>>(
    () =>
        ComponentTypes?.map((e) => ({
            label: t(e.labelKey) ?? '-',
            value: e.value ?? 0,
        })) ?? [],
)

const plantList = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return flattenPlantsHierarchy(data.value.plantGroups)
})

const { data: transformersHierarchy } = useTransformersHierarchyQuery()

const transformerList = computed(() => {
    if (!transformersHierarchy.value?.plantGroups) {
        return []
    }

    return flattenTransformersHierarchy(transformersHierarchy.value.plantGroups)
})

const transformerListOptions = computed(() => {
    if (plantGroupSid.value.length && plantSid.value.length) {
        const filteredTransformerList = transformerList.value.filter((e) => plantSid.value.includes(e.plant.sid))
        return transformerOptions(filteredTransformerList)
    }

    return transformerOptions(transformerList.value)
})

const isAbleToReset = computed(
    () =>
        !!(
            plantGroupSid.value.length ||
            plantSid.value.length ||
            componentType.value.length ||
            startDate.value ||
            endDate.value ||
            componentId.value
        ),
)

const onUpdateTransformerValue = () => {
    emit('update:modelValue', recommendationParams.value)
}

const onSelectionReset = () => {
    plantGroupSid.value = []
    plantSid.value = []
    componentType.value = []
    startDate.value = undefined
    endDate.value = undefined
    componentId.value = undefined

    onUpdateTransformerValue()
}

const onClearComponentId = () => {
    componentId.value = undefined
    onUpdateTransformerValue()
}

watch(plantGroupSid, (newPlantGroupSid) => {
    if (newPlantGroupSid.length === 0) {
        plantSid.value = []
        onUpdateTransformerValue()
    }
})

watch(plantSid, (newPlantSid) => {
    if (newPlantSid.length === 0) {
        onClearComponentId()
    }
})

watch(
    [() => props.modelValue, plantGroupList, plantList, transformerList],
    ([newModelValue, newPlantGroupList, newPlantList, newTransformerList]) => {
        if (!newModelValue || newPlantList.length === 0 || newTransformerList.length === 0) {
            return
        }

        componentId.value = newModelValue.componentId

        startDate.value = newModelValue.startDate
        endDate.value = newModelValue.endDate

        componentType.value = newModelValue.componentType.every((value) => Object.values(ComponentType).includes(value))
            ? newModelValue.componentType
            : []

        if (newModelValue.componentId) {
            const selectedTransformer = newTransformerList.find((e) => e.transformer.id === newModelValue.componentId)

            if (!selectedTransformer) {
                onUpdateTransformerValue()
                return
            }

            plantGroupSid.value = [...new Set([...plantGroupSid.value, selectedTransformer.plantGroup.sid])]
            plantSid.value = [...new Set([...plantSid.value, selectedTransformer.plant.sid])]

            onUpdateTransformerValue()
        } else {
            const selectedPlant = newPlantList
                .filter((e) => newModelValue.plantSid.includes(e.plant.sid))
                .filter((e) => newModelValue.plantGroupSid.includes(e.plantGroup.sid))
            const selectedPlantGroup = newPlantGroupList.filter((e) =>
                newModelValue.plantGroupSid.includes(e.sid ?? -1),
            )

            plantGroupSid.value = selectedPlantGroup.map((e) => e.sid ?? -1)
            plantSid.value = selectedPlant.map((e) => e.plant.sid)
        }
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.component-selector {
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    gap: $spacing-s;

    &__form {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: $spacing-s;
    }

    &__buttons {
        margin-top: 2.2rem;
        margin-left: $spacing-2xs;
    }
}

.loading-state {
    display: flex;

    align-items: center;
    gap: $spacing-xs;

    pbl-spinner {
        --size: 1.5rem;
    }
}

.error-state {
    display: flex;

    align-items: center;
    gap: $spacing-2xs;

    color: $pbl-invalid;

    pbl-icon {
        --size: 1.5rem;

        color: $pbl-invalid-contrast;
    }
}
</style>
