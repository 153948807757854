<template>
    <div class="dropdown">
        <div class="dropdown__input-field">
            <pbl-icon name="search" />
            <input
                ref="inputRef"
                v-model="search"
            />
        </div>
        <div class="dropdown__separator" />
        <div class="dropdown__options">
            <template
                v-for="(option, index) in displayedOptions"
                :key="option.label"
            >
                <div
                    :class="[
                        {
                            'dropdown__options-item--bottom-separated': displayedOptions.length - 1 !== index,
                            'dropdown__options-item--selected': option.value === selectedValue,
                        },
                        'dropdown__options-item',
                    ]"
                    @click="onExistingItemClick(option)"
                >
                    {{ option.label }}
                    <slot
                        name="optionTrailing"
                        :option="option"
                    />
                </div>
            </template>
            <div
                v-if="noResults"
                class="dropdown__options-text"
            >
                {{ t('formInputs.common.noResults') }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T, E">
import { computed, onMounted, ref } from 'vue'
import { useVueFuse } from 'vue-fuse'
import { SelectOption, SelectOptions } from '@/components/form-inputs/common/select-option'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    selectedValue?: T
    options: SelectOptions<T, E>
}>()

const emit = defineEmits<{
    'select:option': [option: SelectOption<T, E>]
    close: []
}>()

const { t } = useI18n()

const inputRef = ref<HTMLInputElement | null>(null)

onMounted(() => {
    inputRef.value?.focus()
})

const { search, results, noResults } = useVueFuse(props.options, {
    keys: ['label'],
    threshold: 0.2,
})

const displayedOptions = computed(() => {
    if (search.value.trim() === '') {
        return props.options
    }

    return results.value
})

const onExistingItemClick = (option: SelectOption<T, E>) => {
    emit('select:option', option)
    emit('close')
}
</script>

<style scoped lang="scss">
.dropdown {
    $row-min-height: 30px;

    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));

    cursor: default;
    z-index: 100;

    max-height: 250px;

    border-radius: 4px;
    border: 1px solid $pbl-border;
    background-color: $pbl-foreground;

    display: flex;
    flex-direction: column;

    &__separator {
        height: 1px !important;
        background-color: $pbl-border-muted;
    }

    &__input-field {
        padding: $spacing-xs;

        display: flex;
        align-items: center;
        gap: $spacing-3xs $spacing-2xs;

        input {
            font-size: 0.95em;

            appearance: none;
            border: none;
            outline: none;

            width: 100%;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;

        overflow-y: auto;

        &-item {
            display: flex;
            justify-content: space-between;

            font-size: 0.95em;
            padding: $spacing-xs $spacing-xs;

            cursor: pointer;

            &:hover {
                background-color: $pbl-primary-selected;
            }

            &--bolded {
                font-weight: 500;
            }

            &--bottom-separated {
                border-bottom: 1px solid $pbl-border-muted;
            }

            &--selected {
                font-weight: 500;
                background-color: lighten($pbl-primary-selected, 5%);
            }
        }

        &-text {
            font-size: 0.95em;
            padding: $spacing-xs $spacing-xs;
            color: $pbl-text-muted;
        }
    }
}
</style>
