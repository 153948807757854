<template>
    <div class="title-section">
        <span class="title-section__title">{{ t('exports.conditionStatus.title') }}</span>
        <span class="title-section__subtitle">{{ pageSubtitle }}</span>
    </div>
</template>

<script setup lang="ts">
import { useTransformerId } from '@/composables/useTransformerId'
import { computed } from 'vue'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const transformerId = useTransformerId()
const { data: transformerDetailsData } = useTransformerDetailsQuery(transformerId)

const pageSubtitle = computed(() => {
    if (!transformerDetailsData.value) {
        return ''
    }

    const plantGroupName = transformerDetailsData.value.plantGroupName
    const plantName = transformerDetailsData.value.plantName
    const transformerName = transformerDetailsData.value.transformerName
    const transformerSid = transformerDetailsData.value.transformerSid

    return `${plantGroupName} / ${plantName} / ${transformerName} / ${transformerSid}`
})
</script>

<style scoped lang="scss">
.title-section {
    margin: $spacing-s $spacing-s;

    display: flex;
    flex-direction: column;
    gap: $spacing-3xs;

    &__title {
        font-weight: 500;
    }
}
</style>
