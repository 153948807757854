import { NavigationStructure } from '@/components/navigation/common-navigation'

export const TransformersNavigationConfiguration: NavigationStructure = [
    {
        titleKey: 'navigation.transformers.portfolioOverview',
        destinationRouteName: 'Transformers_PortfolioOverview',
        matchRouteNames: ['Transformers_PortfolioOverview'],
    },
    {
        titleKey: 'navigation.transformers.conditionStatus',
        destinationRouteName: 'Transformers_ConditionStatus',
        matchRouteNames: ['Transformers_ConditionStatus', 'Reports_View_Condition'],
    },
    {
        titleKey: 'navigation.transformers.portfolioTrends',
        destinationRouteName: 'Transformers_PortfolioTrends',
        matchRouteNames: ['Transformers_PortfolioTrends'],
    },
]
