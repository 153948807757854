export enum ConditionStatus {
    None = 0,
    Normal = 1,
    Inferior = 2,
    Critical = 3,
}

export const StatusTypes = [
    {
        labelKey: 'common.statusTypes.none',
        value: ConditionStatus.None,
        color: '#ADADAD',
    },
    {
        labelKey: 'common.statusTypes.normal',
        value: ConditionStatus.Normal,
        color: '#27AE60',
    },
    {
        labelKey: 'common.statusTypes.inferior',
        value: ConditionStatus.Inferior,
        color: '#F2994A',
    },
    {
        labelKey: 'common.statusTypes.critical',
        value: ConditionStatus.Critical,
        color: '#BB3333',
    },
] as const
