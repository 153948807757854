<template>
    <div class="tab-navigation">
        <template
            v-for="entry in navigationStructure"
            :key="entry.titleKey"
        >
            <TabbedNavigationItem
                :entry="entry"
                @click="onItemClick(entry)"
            />
            <div class="tab-navigation__separator" />
        </template>
    </div>
</template>

<script setup lang="ts">
import { NavigationEntry, NavigationStructure } from '@/components/navigation/common-navigation'
import TabbedNavigationItem from '@/components/navigation/tabbed/TabbedNavigationItem.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

defineProps<{
    navigationStructure: NavigationStructure
}>()

const onItemClick = async (entry: NavigationEntry) => {
    await router.push({ name: entry.destinationRouteName })
}
</script>

<style scoped lang="scss">
.tab-navigation {
    background: $pbl-foreground;
    border-bottom: 1px solid $pbl-border-muted;

    display: flex;

    &__separator {
        min-height: 100%;
        width: 1px;

        background-color: $pbl-border-muted;
    }
}
</style>
