<template>
    <Teleport to="body">
        <Transition name="slide-in">
            <pbl-side-panel
                v-if="open"
                class="app-drawer"
                :headline="t('header.appDrawer.title')"
                :open="open"
                @close="emit('close')"
            >
                <div class="app-drawer__content">
                    <img
                        class="app-drawer__brand-logo"
                        src="/coode-logo-rgb.svg"
                        alt="COODE logo"
                    />
                    <div
                        v-for="section in configuration"
                        :key="section.title"
                        class="app-drawer__list"
                    >
                        <h3
                            v-if="section.entries.length !== 0"
                            class="app-drawer__list-title"
                        >
                            {{ section.title }}
                        </h3>
                        <AppDrawerItem
                            v-for="useCase in section.entries"
                            :key="useCase.id"
                            :use-case="useCase"
                            @click="onAppItemClicked(useCase.url)"
                        />
                    </div>
                </div>
            </pbl-side-panel>
        </Transition>
        <Transition name="darken">
            <div
                v-if="open"
                class="background"
                @click="emit('close')"
            />
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import AppDrawerItem from '@/components/header/app-drawer/AppDrawerItem.vue'
import { UseCase } from '@/api/models/meta-data/UseCase'
import { useMetaDataStore } from '@/store/metaDataStore'

type SectionConfiguration = {
    title: string
    entries: UseCase[]
}

defineProps<{
    open: boolean
}>()

const emit = defineEmits<{
    close: []
}>()

const { t } = useI18n()
const useCaseStore = useMetaDataStore()

const configuration = computed<SectionConfiguration[]>(() => [
    {
        title: 'Apps with access',
        entries: useCaseStore.useCasesWithAccess,
    },
    {
        title: 'Apps without access',
        entries: useCaseStore.useCasesWithoutAccess,
    },
])

const onAppItemClicked = (href: string) => {
    window.open(href, '_blank')
}
</script>

<style scoped lang="scss">
.app-drawer {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    --padding: 0;

    &__content {
        padding: $spacing-s 0;

        display: flex;
        flex-direction: column;
        gap: $spacing-m;
    }

    &__brand-logo {
        height: 50px;
    }

    &__list {
        display: flex;
        flex-direction: column;

        &-title {
            padding-left: $spacing-s;
        }
    }
}

.background {
    position: fixed;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    background-color: rgba(0, 0, 0, 0.1);
}

.slide-in-enter-active,
.slide-in-leave-active {
    transition: all 0.2s ease-in-out;
}

.slide-in-enter-from,
.slide-in-leave-to {
    transform: translateX(-100%);
}

.darken-enter-active,
.darken-leave-active {
    transition: all 0.2s linear;
}

.darken-enter-from,
.darken-leave-to {
    opacity: 0;
}
</style>
