import { inject, InjectionKey, provide } from 'vue'

export type ReportsExportModalActions = {
    openExport: () => void
}

export const ReportsExportModalInjectionKey: InjectionKey<ReportsExportModalActions> = Symbol(
    'ReportsExportModalInjectionKey',
)

export const useReportsExportModal = () => {
    const actions = inject(ReportsExportModalInjectionKey)

    if (!actions) {
        throw new Error('Invalid use of ReportsExportModal')
    }

    return actions
}

export const provideReportsExportModal = (actions: ReportsExportModalActions) =>
    provide(ReportsExportModalInjectionKey, actions)
