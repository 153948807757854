<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('admin.thresholds.title')"
            class="status-dialog"
        >
            <div
                :key="key"
                class="status-wrapper"
            >
                <div class="status-modal">
                    <ThresholdsPageStaticOilAnalysesSection />
                    <ThresholdsPageOilAnalysesSection hide-actions />
                    <ThresholdsPageStaticVisualInspectionSection />
                </div>
            </div>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { provideStatusAssessmentCriteriaModal } from '@/pages/admin/thresholds/modals/status-assessment-criteria/useStatusAssessmentCriteriaModal'
import { useI18n } from 'vue-i18n'
import ThresholdsPageStaticVisualInspectionSection from '@/pages/admin/thresholds/sections/ThresholdsPageStaticVisualInspectionSection.vue'
import ThresholdsPageOilAnalysesSection from '@/pages/admin/thresholds/sections/ThresholdsPageOilAnalysesSection.vue'
import ThresholdsPageStaticOilAnalysesSection from '@/pages/admin/thresholds/sections/ThresholdsPageStaticOilAnalysesSection.vue'
import { ref } from 'vue'
import { PebbleDialog } from '@pebble/core'

const { t } = useI18n()

const key = ref(0)
const dialogRef = ref<PebbleDialog | null>(null)

const openModal = () => {
    key.value += 1
    dialogRef.value?.show()
}

provideStatusAssessmentCriteriaModal({
    openModal,
})
</script>

<style scoped lang="scss">
.status-dialog {
    --width: 70vw;
}

.status-wrapper {
    height: 80vh;
    overflow: auto;
}

.status-modal {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    padding: $spacing-xs;
}
</style>
