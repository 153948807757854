import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusViewRecommendationModalActions = {
    openViewRecommendation: (id: number) => void
}

const conditionStatusViewRecommendationModalActionsInjectionKey: InjectionKey<ConditionStatusViewRecommendationModalActions> =
    Symbol('conditionStatusViewRecommendationModalActionsInjectionKey')

export const useConditionStatusViewRecommendationModal = () => {
    return inject(conditionStatusViewRecommendationModalActionsInjectionKey)
}

export const provideConditionStatusViewRecommendationModal = (actions: ConditionStatusViewRecommendationModalActions) =>
    provide(conditionStatusViewRecommendationModalActionsInjectionKey, actions)
