import { defineStore } from 'pinia'
import { UseCase } from '@/api/models/meta-data/UseCase'
import { MetaDataService } from '@/api/services/MetaDataService'
import { Notification } from '@/api/models/meta-data/Notification'
import { SignalRConnection } from '@/utils/signalr-connection'

const metaDataService = new MetaDataService()

export const useMetaDataStore = defineStore('metaDataStore', {
    state: () => ({
        useCases: Array<UseCase>(),
        notifications: Array<Notification>(),
        totalNotificationsCount: 0,
    }),
    getters: {
        useCasesWithAccess: (state) => state.useCases.filter((e) => e.hasAccess),
        useCasesWithoutAccess: (state) => state.useCases.filter((e) => !e.hasAccess),
    },
    actions: {
        async setupMetaDataInformation(): Promise<void> {
            await Promise.all([this.fetchUseCases(), this.initializeNotifications()])
        },
        async fetchUseCases(): Promise<void> {
            if (this.useCases.length !== 0) {
                return
            }

            this.useCases = await metaDataService.getUseCases()
        },
        async initializeNotifications(): Promise<void> {
            const signalRConnection = new SignalRConnection(import.meta.env.VITE_NOTIFICATIONS_SIGNALR_HUB_URL)

            signalRConnection.startConnection()
            signalRConnection.connection.on('Notification', this.fetchNotifications)

            await this.fetchNotifications()
        },
        async fetchNotifications(): Promise<void> {
            const response = await metaDataService.getAllNotifications()

            this.notifications = response.items
            this.totalNotificationsCount = response.totalCount
        },
        async clearAllNotifications(): Promise<void> {
            await metaDataService.clearAllNotifications()
            await metaDataService.markNotificationsAsRead(...this.notifications.map((e) => e.id))

            await this.fetchNotifications()
        },
        async markNotificationAsRead(notificationId: number): Promise<void> {
            const notification = this.notifications.find((e) => e.id === notificationId)

            if (!notification) {
                return
            }

            await metaDataService.markNotificationsAsRead(notificationId)
            await this.fetchNotifications()
        },
    },
})
