import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useListThresholdsQuery = () => {
    return useQuery({
        queryKey: ['thresholds', 'list'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.thresholdsApi.listThresholds(undefined, { signal })

            return result
        },
        enabled: whenAuthenticated,
        staleTime: Infinity,
    })
}
