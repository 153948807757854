export enum ActionType {
    ReportSubmitted = 1,
    ReportInvalidated,
    UndoReportInvalidation,
    CommentSubmitted,
    CommentEdited,
    CommentDeleted,
    ConditionStatusManuallyOverridden,
    ThresholdEdited,
    RecommendationSubmitted,
    RecommendationEdited,
    VisualInspectionReportSubmitted,
    ComponentConditionStatusCritical,
    ChangeInComponentConditionStatus,
    LastSynced,
}

export const ActionTypes = [
    {
        labelKey: 'dashboard.notifications.actions.1',
        shortLabelKey: 'dashboard.notifications.shortActions.1',
        value: ActionType.ReportSubmitted,
    },
    {
        labelKey: 'dashboard.notifications.actions.2',
        shortLabelKey: 'dashboard.notifications.shortActions.2',
        value: ActionType.ReportInvalidated,
    },
    {
        labelKey: 'dashboard.notifications.actions.3',
        shortLabelKey: 'dashboard.notifications.shortActions.3',
        value: ActionType.UndoReportInvalidation,
    },
    {
        labelKey: 'dashboard.notifications.actions.4',
        shortLabelKey: 'dashboard.notifications.shortActions.4',
        value: ActionType.CommentSubmitted,
    },
    {
        labelKey: 'dashboard.notifications.actions.5',
        shortLabelKey: 'dashboard.notifications.shortActions.5',
        value: ActionType.CommentEdited,
    },
    {
        labelKey: 'dashboard.notifications.actions.6',
        shortLabelKey: 'dashboard.notifications.shortActions.6',
        value: ActionType.CommentDeleted,
    },
    {
        labelKey: 'dashboard.notifications.actions.7',
        shortLabelKey: 'dashboard.notifications.shortActions.7',
        value: ActionType.ConditionStatusManuallyOverridden,
    },
    {
        labelKey: 'dashboard.notifications.actions.8',
        shortLabelKey: 'dashboard.notifications.shortActions.8',
        value: ActionType.ThresholdEdited,
    },
    {
        labelKey: 'dashboard.notifications.actions.9',
        shortLabelKey: 'dashboard.notifications.shortActions.9',
        value: ActionType.RecommendationSubmitted,
    },
    {
        labelKey: 'dashboard.notifications.actions.10',
        shortLabelKey: 'dashboard.notifications.shortActions.10',
        value: ActionType.RecommendationEdited,
    },
    {
        labelKey: 'dashboard.notifications.actions.11',
        shortLabelKey: 'dashboard.notifications.shortActions.11',
        value: ActionType.VisualInspectionReportSubmitted,
    },
    {
        labelKey: 'dashboard.notifications.actions.12',
        shortLabelKey: 'dashboard.notifications.shortActions.12',
        value: ActionType.ComponentConditionStatusCritical,
    },
    {
        labelKey: 'dashboard.notifications.actions.13',
        shortLabelKey: 'dashboard.notifications.shortActions.13',
        value: ActionType.ChangeInComponentConditionStatus,
    },
    {
        labelKey: 'dashboard.notifications.actions.14',
        shortLabelKey: 'dashboard.notifications.shortActions.14',
        value: ActionType.LastSynced,
    },
] as const
