import { computed, MaybeRef, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticatedAnd } from '@/utils/query-utils'

export const useRecommendationTypeQuery = (recommendationId: MaybeRef<number>) => {
    return useQuery({
        queryKey: ['recommendation-types', recommendationId],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.recommendationTypesApi.getRecommendationType(toValue(recommendationId), {
                signal,
            })

            return result
        },
        enabled: whenAuthenticatedAnd(computed(() => !!toValue(recommendationId))),
        staleTime: Infinity,
    })
}
