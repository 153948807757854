<template>
    <pbl-panel>
        <div class="condition-status-header">
            <div class="condition-status-header__first">
                <div class="condition-status-header__first__status">
                    <span class="condition-status-header__first__status-label">
                        {{ t('transformers.conditionStatus.content.conditionStatus.label') }}
                    </span>
                    <div v-if="data">
                        <ColorBadge
                            v-if="currentGeneralStatus"
                            :color="currentGeneralStatus.color"
                            :text="t(currentGeneralStatus.labelKey)"
                        />
                    </div>
                    <pbl-spinner v-else-if="isPending" />
                    <div
                        v-else-if="isError"
                        class="condition-status-header__first__status-error"
                    >
                        <pbl-icon name="exclamation-triangle" />
                        <span>
                            {{ t('common.failedToLoad') }}
                        </span>
                    </div>
                </div>
                <div class="condition-status-header__first__actions">
                    <div
                        v-if="lastSyncedDate"
                        class="condition-status-header__first__actions__meta"
                    >
                        <span> {{ t('transformers.conditionStatus.content.conditionStatus.lastUpdated') }}: </span>
                        <span>{{ lastSyncedDate }}</span>
                    </div>
                    <pbl-button
                        variant="secondary"
                        :disabled="isDataSyncPending"
                        :loading="isDataSyncPending"
                        @click="onSyncDataButtonClick"
                    >
                        {{ t('transformers.conditionStatus.content.conditionStatus.sync') }}
                    </pbl-button>
                    <pbl-button @click="onExportButtonClick">
                        {{ t('transformers.conditionStatus.content.conditionStatus.export') }}
                    </pbl-button>
                </div>
            </div>
        </div>
    </pbl-panel>
</template>

<script setup lang="ts">
import { useTransformerComponentStatusQuery } from '@/api/queries/transformers/useTransformerComponentStatusQuery'
import { useI18n } from 'vue-i18n'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { computed } from 'vue'
import { StatusTypes } from '@/configuration/status/condition-status'
import { useRouter } from 'vue-router'
import { useExportStateStore } from '@/store/exportStateStore'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { useTransformerDataSyncMutation } from '@/api/queries/transformers/useTransformersDataSyncQuery'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { AxiosError } from 'axios'

const props = defineProps<{
    transformerId: string
}>()

const { t } = useI18n()
const router = useRouter()
const toasts = usePebbleToasts()

const exportStateStore = useExportStateStore()

const { data, isPending, isError } = useTransformerComponentStatusQuery(props.transformerId)

const { mutateAsync: mutateDataSyncAsync, isPending: isDataSyncPending } = useTransformerDataSyncMutation()

const currentGeneralStatus = computed(() => {
    if (!data.value) {
        return undefined
    }

    return StatusTypes.find((e) => e.value === data.value?.generalStatus)
})

const lastSyncedDate = computed(() => {
    if (!data.value?.lastSyncedDate) {
        return undefined
    }

    return format(new Date(data.value.lastSyncedDate), DATE_TIME_FORMAT)
})

const onExportButtonClick = () => {
    router.push({
        name: 'Export_ConditionStatus',
        params: {
            transformerId: props.transformerId,
        },
        query: exportStateStore.conditionStatusExportParameters,
    })
}

const onSyncDataButtonClick = async () => {
    try {
        await mutateDataSyncAsync({
            componentSids: [props.transformerId],
        })
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
}
</script>

<style scoped lang="scss">
.condition-status-header {
    &__first {
        display: flex;
        justify-content: space-between;

        min-height: 40px;

        &__actions {
            display: flex;
            gap: $spacing-s;
            align-items: center;

            &__meta {
                color: $pbl-text-muted;
            }
        }

        &__status {
            display: flex;

            align-items: center;
            gap: $spacing-s;

            pbl-spinner {
                --size: 1.5rem;
                margin-bottom: 1px;
            }

            &-label {
                font-weight: 500;
            }

            &-error {
                display: flex;

                align-items: center;
                gap: $spacing-2xs;

                color: $pbl-invalid;

                span {
                    margin-top: 1px;
                    font-weight: 500;
                }

                pbl-icon {
                    --size: 1.5rem;

                    color: $pbl-invalid-contrast;
                }
            }
        }
    }

    &__second {
        display: flex;
        justify-content: flex-end;
        gap: $spacing-s;
        margin-top: 10px;
    }
}
</style>
