import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { AddRecommendationTypeRequest } from '@/api/generated/HCA'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useRecommendationsAddMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { data: AddRecommendationTypeRequest }) =>
            hcaClientFactory.recommendationTypesApi.addRecommendationType(payload.data),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['recommendation-types'] })
            await queryClient.invalidateQueries({ queryKey: ['constants'] })
        },
    })
}
