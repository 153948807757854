<template>
    <div class="loading-page">
        <pbl-spinner class="loading-page__spinner" />
        <div class="loading-page__text">
            <h3 class="loading-page__text__title">
                {{ t('common.loading.general') }}
            </h3>
            <h4
                v-if="currentStepText"
                class="loading-page__text__subtitle"
            >
                {{ currentStepText }}
            </h4>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useMainStore } from '@/store/mainStore'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const mainStore = useMainStore()

const currentStepText = computed<string | undefined>(() => {
    if (!mainStore.isAuthenticationReady) {
        return t('common.loading.authentication')
    }

    if (!mainStore.isTranslationReady) {
        return t('common.loading.translation')
    }

    return undefined
})
</script>

<style scoped lang="scss">
.loading-page {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    min-height: 100vh;
    gap: $spacing-m;

    &__spinner {
        --size: 4em;
    }

    &__text {
        display: flex;
        flex-direction: column;

        align-items: center;
        gap: $spacing-2xs;

        &__title {
            margin: 0;
        }

        &__subtitle {
            margin: 0;
            color: $pbl-text-muted;
        }
    }
}
</style>
