import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { useAuthStore } from '@/store/authStore'
import { COMMON_SCOPES } from '@/authentication/coodeSdkConfig'

export class SignalRConnection {
    public connection: HubConnection

    public constructor(url?: string) {
        this.connection = new HubConnectionBuilder()
            .withUrl(url || 'signalr-hubs', {
                accessTokenFactory: this.getAccessToken,
                withCredentials: true,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .build()

        this.connection.onclose((error?: Error) => {
            if (error) {
                console.log(`Connection closed with error: ${error}`)
                return
            }
            console.log('Connection closed')
        })
    }

    public startConnection() {
        if (this.connection.state === 'Disconnected') {
            this.connection
                .start()
                .then(() => console.log('Connected to SignalR Hub'))
                .catch((e) => console.error(e))
        }
    }

    private async getAccessToken(): Promise<string> {
        const metaDataToken = await useAuthStore().getTokenForScope(COMMON_SCOPES.META_DATA)
        return metaDataToken ?? ''
    }
}
