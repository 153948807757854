import { type CoodeSdkOptions, type Environments, LogLevel } from '@coode/fe-sdk'

/**
 * Common OAuth Scopes for current use-case
 *
 * Uses Vite Environment Variable:
 * THIS_USE_CASE_CLIENT_ID               , as current use-case OAuth Client ID
 * VITE_USER_ACCESS_MANAGEMENT_CLIENT_ID , as UAM OAuth Client ID
 * VITE_META_DATA_CLIENT_ID              , as Metadata OAuth Client ID
 * VITE_TRANSLATION_SERVICE_CLIENT_ID    , as TranslationService OAuth Client ID
 *
 * @constant {Record<ApplicationType as string, string>}
 */
export const COMMON_SCOPES = {
    THIS_USE_CASE: `api://${import.meta.env.VITE_THIS_USE_CASE_CLIENT_ID}/coode`,
    META_DATA: `api://${import.meta.env.VITE_META_DATA_CLIENT_ID}/coode`,
    USER_ACCESS_MANAGEMENT: `api://${import.meta.env.VITE_USER_ACCESS_MANAGEMENT_CLIENT_ID}/coode`,
    TRANSLATION_SERVICE: `api://${import.meta.env.VITE_TRANSLATION_SERVICE_CLIENT_ID}/coode`,
} as const

/**
 * CoodeSdkConfig for current use-case
 *
 * Uses Vite Environment Variables:
 * MODE                         , to select URLs for COODE core services
 * VITE_THIS_USE_CASE_CLIENT_ID , as OAuth Client ID
 * VITE_AUTHORITY_URL           , as OAuth Authority URL
 *
 * Configures URLs and OAuth Scopes for:
 * - User Access Management Service (required by SDK)
 * - Metadata Feed Service
 *
 * @implements {CoodeSdkOptions}
 * @constant
 */
export const coodeSdkConfig: CoodeSdkOptions = {
    environment: import.meta.env.MODE as Environments,
    auth: {
        msal: {
            auth: {
                clientId: import.meta.env.VITE_THIS_USE_CASE_CLIENT_ID,
                authority: import.meta.env.VITE_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Verbose,
                },
            },
        },
    },
    insights: {
        appInsightsConnectionString: '',
    },
}
