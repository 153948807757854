import type { useI18n } from 'vue-i18n'
import { PhraseEntry } from '@/api/models/translation/GetPhrasesResponse'

type LangStringObject = {
    englishName?: string | null
    germanName?: string | null
}

export const chooseLangString = <T extends LangStringObject>(
    locale: ReturnType<typeof useI18n>['locale'],
    object?: T,
    defaultValue: string = '-',
) => {
    if (!object) {
        return defaultValue
    }

    if (locale.value === 'en-GB') {
        return object.englishName ?? defaultValue
    }

    if (locale.value === 'de-DE') {
        return object.germanName ?? defaultValue
    }

    return defaultValue
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export const composeLocaleObject = (localeEntries: PhraseEntry[]) => {
    const localeObject: Record<string, any> = {}

    for (const entry of localeEntries) {
        const keyPaths = entry.key.split('.')
        const lastKeyPath = keyPaths.pop()

        if (!lastKeyPath) {
            break
        }

        let destinationObject: Record<string, any> = localeObject

        for (const keyPath of keyPaths) {
            if (destinationObject[keyPath] === undefined) {
                destinationObject[keyPath] = {}
            }

            destinationObject = destinationObject[keyPath]
        }

        destinationObject[lastKeyPath] = entry.value
    }

    return localeObject
}
/* eslint-enable @typescript-eslint/no-explicit-any */
