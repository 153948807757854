<template>
    <UnhandledErrorBoundary>
        <GlobalConfirmationModalHandler>
            <MainLayout v-if="isReady">
                <RouterView />
            </MainLayout>
            <LoadingPage v-else />
        </GlobalConfirmationModalHandler>
    </UnhandledErrorBoundary>
</template>

<script setup lang="ts">
import LoadingPage from '@/pages/other/LoadingPage.vue'
import { computed, onMounted } from 'vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { useAuthStore } from '@/store/authStore'
import { useMainStore } from '@/store/mainStore'
import UnhandledErrorBoundary from '@/components/error-boundary/UnhandledErrorBoundary.vue'
import { useMetaDataStore } from '@/store/metaDataStore'
import GlobalConfirmationModalHandler from '@/components/global-confirmation-modal/GlobalConfirmationModalHandler.vue'

const mainStore = useMainStore()
const authStore = useAuthStore()
const metaDataStore = useMetaDataStore()

const isReady = computed(() => mainStore.isAppReady)

onMounted(async () => {
    await authStore.initializeAuthentication()
    await metaDataStore.setupMetaDataInformation()
})
</script>
