import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useConstantsQuery = () => {
    return useQuery({
        queryKey: ['constants'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.generalApi.getConstants(undefined, { signal })
            return result
        },
        staleTime: Infinity,
    })
}
