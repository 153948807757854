<template>
    <div class="view-report-page">
        <ViewReportStaticHeaderContent
            :report-number="reportNumber"
            :report-type="reportTypeAsNumber"
            :transformer-id="transformerId"
        />
        <div class="view-report-page__content">
            <ViewReportActionsSection
                :report-number="reportNumber"
                :report-type="reportTypeAsNumber"
            />
            <MasterDataPanel
                v-if="transformerId"
                :transformer-id="transformerId"
            />
            <ViewReportOilAnalysisSection
                v-if="reportTypeAsNumber === ReportType.OilAnalysis"
                :report-number="reportNumber"
                :report-type="reportTypeAsNumber"
            />
            <ViewReportVisualInspectionSection
                v-if="reportTypeAsNumber === ReportType.VisualInspection && data?.data?.length === 1"
                :report-number="reportNumber"
                :visual-inspection-data="data.data[0]"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import ViewReportStaticHeaderContent from '@/pages/reports/view-report-page/ViewReportStaticHeaderContent.vue'
import { computed, toRefs } from 'vue'
import ViewReportActionsSection from '@/pages/reports/view-report-page/sections/ViewReportActionsSection.vue'
import MasterDataPanel from '@/components/master-data/MasterDataPanel.vue'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'
import ViewReportOilAnalysisSection from '@/pages/reports/view-report-page/sections/ViewReportOilAnalysisSection.vue'
import ViewReportVisualInspectionSection from '@/pages/reports/view-report-page/sections/ViewReportVisualInspectionSection.vue'
import { ReportType } from '@/configuration/report/report-types'

const props = defineProps<{
    reportNumber: string
    reportType: string
}>()

const { reportNumber, reportType } = toRefs(props)
const reportTypeAsNumber = computed(() => Number(reportType.value))

const { data } = useReportGetQuery(reportNumber, reportTypeAsNumber)

const transformerId = computed(() => {
    if (!data.value) {
        return undefined
    }

    const firstAnalysisWithComponentSid = data.value?.data?.find((e) => !!e.componentSid)
    return firstAnalysisWithComponentSid?.componentSid ?? undefined
})
</script>

<style scoped lang="scss">
.view-report-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__content {
        @include spacing-page;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        gap: $spacing-m;
    }
}
</style>
