import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusEditRecommendationModalActions = {
    openEditRecommendation: (id: number) => void
}

const conditionStatusEditRecommendationModalActionsInjectionKey: InjectionKey<ConditionStatusEditRecommendationModalActions> =
    Symbol('conditionStatusEditRecommendationModalActionsInjectionKey')

export const useConditionStatusEditRecommendationModal = () => {
    return inject(conditionStatusEditRecommendationModalActionsInjectionKey)
}

export const provideConditionStatusEditRecommendationModal = (actions: ConditionStatusEditRecommendationModalActions) =>
    provide(conditionStatusEditRecommendationModalActionsInjectionKey, actions)
