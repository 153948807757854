import { NavigationEntry, NavigationStructure } from '@/components/navigation/common-navigation'
import { computed } from 'vue'
import { Container, FolderKanban, LayoutPanelLeft, NotepadText, Pencil, Settings, Webhook } from 'lucide-vue-next'
import { usePermissions } from '@/composables/usePermissions'

const permanentNavigationStructure: NavigationStructure = [
    {
        titleKey: 'navigation.sidebar.dashboard',
        lucideIcon: LayoutPanelLeft,
        matchRouteNames: ['DashboardRoot'],
        destinationRouteName: 'DashboardRoot',
    },
    {
        titleKey: 'navigation.sidebar.transformers',
        lucideIcon: Container,
        matchRouteNames: ['TransformersRoot', 'Export_ConditionStatus'],
        destinationRouteName: 'Transformers_PortfolioOverview',
    },
    {
        titleKey: 'navigation.sidebar.generators',
        lucideIcon: FolderKanban,
        matchRouteNames: ['GeneratorsPage'],
        destinationRouteName: 'GeneratorsPage',
    },
    {
        titleKey: 'navigation.sidebar.turbines',
        lucideIcon: Webhook,
        matchRouteNames: ['TurbinesPage'],
        destinationRouteName: 'TurbinesPage',
    },
    {
        titleKey: 'navigation.sidebar.recommendations',
        lucideIcon: Pencil,
        matchRouteNames: ['RecommendationsRoot'],
        destinationRouteName: 'RecommendationsRoot',
    },
]

const viewReportsOptionalNavigationEntry: NavigationEntry = {
    titleKey: 'navigation.sidebar.reports',
    lucideIcon: NotepadText,
    matchRouteNames: ['ReportsRoot', 'Export_ViewReport'],
    destinationRouteName: 'Reports_ViewAll',
}

const adminOptionalNavigationEntry: NavigationEntry = {
    titleKey: 'navigation.sidebar.admin',
    lucideIcon: Settings,
    matchRouteNames: ['AdminRoot'],
    destinationRouteName: 'Admin_Thresholds',
}

export const useSidebarNavigationConfiguration = () => {
    const { userPermissions } = usePermissions()

    const navigationStructure = computed(() => {
        const navigation = [...permanentNavigationStructure]

        if (userPermissions.value.canViewReports) {
            navigation.push(viewReportsOptionalNavigationEntry)
        }

        if (userPermissions.value.canEditThresholdsAndRecommendations) {
            navigation.push(adminOptionalNavigationEntry)
        }

        return navigation
    })

    return {
        navigationStructure,
    }
}
