<template>
    <LoadingPanel
        :headline="t('transformers.portfolioTrends.portfolioStatusOverTime.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="status-over-time">
            <FormSingleSelectField
                v-model="selectedOption"
                :placeholder-text="
                    t('transformers.portfolioTrends.portfolioStatusOverTime.selector.placeholders.plant')
                "
                class="status-over-time__field"
                :options="combinedOptions"
            />
            <div
                v-if="plantSids.length === 0"
                class="status-over-time__empty-state"
            >
                <pbl-icon name="check2-square" />
                <span>
                    {{ t('transformers.portfolioTrends.portfolioStatusOverTime.emptyState') }}
                </span>
            </div>
            <PortfolioTrendsStatusOverTimeChart
                v-else
                :plant-sids="plantSids"
            />
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { SelectOptions, sortOptionsByLabel } from '@/components/form-inputs/common/select-option'
import PortfolioTrendsStatusOverTimeChart from '@/pages/transformers/portfolio-trends/plant-status-over-time/PortfolioTrendsStatusOverTimeChart.vue'
import { flattenPlantsHierarchy, usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'

interface SelectOptionsExtradata {
    plantSids?: Array<number>
}

const ALL_PLANTS_SELECTION_VALUE = 0 as const
const selectedOption = ref<number>(ALL_PLANTS_SELECTION_VALUE)

const { t } = useI18n()
const { data, isPending, isError } = usePlantsHierarchyQuery()

const groupOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() =>
    sortOptionsByLabel(
        data.value?.plantGroups?.map((e) => ({
            label: t('transformers.portfolioTrends.portfolioStatusOverTime.selector.options.groupOption', {
                groupName: e.name ?? '',
            }),
            value: e.sid ?? -1,
            extraData: {
                plantSids: e.plants?.map((e) => e.sid ?? -1) ?? [],
            },
        })) ?? [],
    ),
)

const plantOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() =>
    sortOptionsByLabel(
        data.value?.plantGroups?.flatMap(
            (e) =>
                e.plants?.map((e) => ({
                    label: e.name ?? '-',
                    value: e.sid ?? -1,
                    extraData: {
                        plantSids: e.sid ? [e.sid] : [],
                    },
                })) ?? [],
        ) ?? [],
    ),
)

const allPlants = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return flattenPlantsHierarchy(data.value?.plantGroups)
})

const allPlantsOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() => [
    {
        label: t('transformers.portfolioTrends.portfolioStatusOverTime.selector.options.allPlants'),
        value: ALL_PLANTS_SELECTION_VALUE,
        extraData: {
            plantSids: allPlants.value.map((e) => e.plant.sid),
        },
    },
])

const combinedOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() => [
    ...allPlantsOptions.value,
    ...groupOptions.value,
    ...plantOptions.value,
])

const plantSids = computed(
    () => combinedOptions.value.find((e) => e.value === selectedOption.value)?.extraData?.plantSids ?? [],
)
</script>

<style scoped lang="scss">
.status-over-time {
    &__field {
        width: 240px;
    }

    &__empty-state {
        padding: 100px 0;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }
    }
}
</style>
