import { addMonths, addYears, endOfMonth, format, parseISO, startOfMonth } from 'date-fns'

export const DATE_FORMAT_API = 'yyyy-MM-dd'
export const DATE_FORMAT = 'dd/MM/yyyy'
export const MONTH_YEAR_DATE_FORMAT = 'yyyy-MM'
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm'

export const TIMEFRAME_OLDEST_RECORD = '1998-03'
export const TIMEFRAME_DEFAULT_END_DATE = format(new Date(), MONTH_YEAR_DATE_FORMAT)

export const timeframeStartDate = (diffYears: number): string => {
    return format(addMonths(addYears(new Date(), -diffYears), 1), MONTH_YEAR_DATE_FORMAT)
}

export const startDateFormat = (startDate: string) => {
    const isoDate = startOfMonth(new Date(startDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
}

export const endDateFormat = (endDate: string) => {
    const isoDate = endOfMonth(new Date(endDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
}
