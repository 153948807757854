<template>
    <span
        v-if="isPending || isError"
        class="analysis-title"
    >
        {{ analysisTitle }}
    </span>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <div v-if="data && data.reports?.length !== 0">
            <div class="analysis-status-header">
                <div class="analysis-status-header__status">
                    <span class="analysis-status-header__status-label">{{ analysisTitle }}</span>
                    <div>
                        <ColorBadge
                            v-if="currentGeneralStatus"
                            :color="currentGeneralStatus.color"
                            :text="t(currentGeneralStatus.labelKey)"
                            small
                        />
                    </div>
                </div>
            </div>
            <div class="analysis-table">
                <div
                    ref="tableRef"
                    class="analysis-table__table"
                />
            </div>
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { AnalysisType, AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { useTransformerReportsQuery } from '@/api/queries/transformers/useTransformerReportsQuery'
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { useI18n } from 'vue-i18n'
import { CellComponent, ColumnDefinition, MenuObject, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { ParameterDto } from '@/api/generated/HCA'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { useConditionStatusOverrideModal } from '@/pages/transformers/condition-status/modals/override/useConditionStatusOverrideModal'
import { useTransformerId } from '@/composables/useTransformerId'
import {
    AlertCircle,
    AlertTriangle,
    createElement,
    IconNode,
    MessageSquarePlus,
    MessageSquareText,
    RefreshCcwDot,
} from 'lucide'
import { useAnalysisTableUtils } from '@/pages/transformers/condition-status/sections/analyses/useAnalysisTableUtils'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { chooseLangString } from '@/utils/i18n-utils'
import { useRouter } from 'vue-router'
import { ReportType } from '@/configuration/report/report-types'
import { usePermissions } from '@/composables/usePermissions'
import { useConditionStatusCommentModal } from '@/pages/transformers/condition-status/modals/comment/useConditionStatusCommentModal'

type RowData = Record<string, string | number | ParameterDto>

const props = defineProps<{
    analysisType: AnalysisType
    reportDisplayCount?: number
}>()

const transformerId = useTransformerId()

const { userPermissions } = usePermissions()
const { t, locale } = useI18n()
const router = useRouter()
const overrideModal = useConditionStatusOverrideModal()
const commentModal = useConditionStatusCommentModal()
const { createAnalysisTableTooltipElement, createAnalysisTableLimitTooltipElement } = useAnalysisTableUtils()

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useTransformerReportsQuery(transformerId, () => props.analysisType)

const statusIcons: Record<number, { iconNode: IconNode; color: string }> = {
    [ConditionStatus.Inferior]: {
        iconNode: AlertTriangle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Inferior)?.color ?? '',
    },
    [ConditionStatus.Critical]: {
        iconNode: AlertCircle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Critical)?.color ?? '',
    },
}

const analysisTitle = computed(() => {
    const record = AnalysisTypes.find((e) => e.value === props.analysisType)

    if (!record) {
        return 'Unknown'
    }

    return t(record.labelKey)
})

const currentGeneralStatus = computed(() => {
    if (!data.value) {
        return undefined
    }

    return StatusTypes.find((e) => e.value === data.value?.status)
})

const onReportNumberClick = (reportNumber: string) => {
    router.push({
        name: 'Reports_View_Condition',
        params: {
            reportNumber,
            reportType: ReportType.OilAnalysis,
        },
    })
}

const cellContextMenu = computed((): MenuObject<CellComponent>[] => {
    const cellContextMenu: MenuObject<CellComponent>[] = []

    if (userPermissions.value.canEditTransformerComments) {
        cellContextMenu.push({
            label: (): HTMLElement => {
                const cellDiv = document.createElement('div')
                cellDiv.classList.add('analysis-table-context-menu-item')

                const iconDiv = createElement(MessageSquarePlus)
                cellDiv.appendChild(iconDiv)

                const spanDiv = document.createElement('span')
                spanDiv.textContent = t('transformers.conditionStatus.content.recommendationModal.comments.addComment')
                cellDiv.appendChild(spanDiv)

                return cellDiv
            },
            action: (event: CustomEvent, cell: CellComponent) => {
                const parameterId = cell.getValue().id
                const parameterData = constantsData.value?.parameters?.find((e) => e.id === cell.getValue().parameterId)

                if (!parameterId) {
                    return
                }

                const parentColumn = cell.getColumn().getParentColumn()

                if (parentColumn === false) {
                    return
                }

                const analyticalNumber = parentColumn.getDefinition().title

                commentModal?.openComment({
                    comment: {
                        reportNumber: analyticalNumber,
                        analysisType: props.analysisType,
                        parameterId: parameterData?.id,
                    },
                })
            },
        })
    }

    if (userPermissions.value.canOverrideTransformerConditionStatus) {
        cellContextMenu.push({
            label: (): HTMLElement => {
                const cellDiv = document.createElement('div')
                cellDiv.classList.add('analysis-table-context-menu-item')

                const iconDiv = createElement(RefreshCcwDot)
                cellDiv.appendChild(iconDiv)

                const spanDiv = document.createElement('span')
                spanDiv.textContent = t('transformers.conditionStatus.content.overrideModal.title')
                cellDiv.appendChild(spanDiv)

                return cellDiv
            },
            action: (event: CustomEvent, cell: CellComponent) => {
                const parameterId = cell.getValue().id
                const parameterData = constantsData.value?.parameters?.find((e) => e.id === cell.getValue().parameterId)

                if (!parameterData?.canBeOverwritten) {
                    return
                }

                const parameterName = chooseLangString(locale, parameterData)
                const status = cell.getValue().status

                if (!parameterId) {
                    return
                }

                const parentColumn = cell.getColumn().getParentColumn()

                if (parentColumn === false) {
                    return
                }

                const analyticalNumber = parentColumn.getDefinition().title

                overrideModal?.openOverride({
                    status,
                    parameterId,
                    parameterName,
                    analyticalNumber,
                    analysisType: props.analysisType,
                })
            },
            disabled: (cell: CellComponent) => {
                const parameterData = constantsData.value?.parameters?.find((e) => e.id === cell.getValue().parameterId)

                return !parameterData?.canBeOverwritten
            },
        })
    }

    return cellContextMenu
})

// @ts-expect-error ColumnDefinition doesn't properly type the Tooltip return type.
const columns = computed<ColumnDefinition[]>(() => {
    if (!data.value) {
        return undefined
    }

    let reportColumns =
        data.value?.reports?.map((report, index) => ({
            title: report.analyticalNumber ?? 'N/A',
            titleFormatter: (cell: CellComponent) => {
                const value = cell.getValue()

                const cellDiv = document.createElement('div')
                cellDiv.textContent = value
                cellDiv.classList.add('analysis-table-header-link-cell')
                cellDiv.addEventListener('click', () => onReportNumberClick(value))

                return cellDiv
            },
            headerContextMenu: [
                {
                    label: () => {
                        const cellDiv = document.createElement('div')
                        cellDiv.classList.add('analysis-table-context-menu-item')

                        const iconDiv = createElement(MessageSquareText)
                        cellDiv.appendChild(iconDiv)

                        const spanDiv = document.createElement('span')
                        spanDiv.textContent = t(
                            'transformers.conditionStatus.content.recommendationModal.comments.addComment',
                        )
                        cellDiv.appendChild(spanDiv)

                        return cellDiv
                    },
                    action: () => {
                        commentModal?.openComment({
                            comment: {
                                reportNumber: report.analyticalNumber,
                                analysisType: props.analysisType,
                            },
                        })
                    },
                    disabled: () => !userPermissions.value.canEditTransformerComments,
                },
            ],
            columns: [
                {
                    title: report.date ? format(new Date(report.date), DATE_FORMAT) : 'N/A',
                    field: report.analyticalNumber ?? 'N/A',
                    headerSort: false,
                    minWidth: 150,
                    formatter: (cell: CellComponent) => {
                        const displayedText =
                            cell.getValue().stringValue ?? cell.getValue().numberValue?.toString() ?? ''
                        const status = cell.getValue().status

                        const statusIconData = statusIcons[status]
                        const overrideComment = cell.getValue().overrideComment
                        const commentParameter = cell.getValue().latestComment

                        const cellDiv = document.createElement('div')
                        cellDiv.className = 'analysis-table-content-cell'

                        const textSpan = document.createElement('span')
                        textSpan.textContent = displayedText
                        cellDiv.appendChild(textSpan)

                        if (statusIconData) {
                            const statusIcon = createElement(statusIconData.iconNode)
                            statusIcon.classList.add('analysis-table-icon')
                            statusIcon.setAttribute('stroke', statusIconData.color)
                            statusIcon.setAttribute('stroke-width', '3px')
                            cellDiv.appendChild(statusIcon)
                        }

                        if (overrideComment) {
                            const overrideIcon = createElement(RefreshCcwDot)
                            overrideIcon.classList.add('analysis-table-icon')
                            cellDiv.appendChild(overrideIcon)
                        }

                        if (commentParameter) {
                            const commentIcon = createElement(MessageSquareText)
                            commentIcon.classList.add('analysis-table-icon')
                            cellDiv.appendChild(commentIcon)
                        }

                        return cellDiv
                    },
                    tooltip: (event: UIEvent, cell: CellComponent) => {
                        const value: ParameterDto = cell.getValue()

                        if (!value.latestComment && !value.overrideComment) {
                            return ''
                        }

                        return createAnalysisTableTooltipElement(value)
                    },
                    cellClick: (event: UIEvent, cell: CellComponent) => {
                        if (!userPermissions.value.canOverrideTransformerConditionStatus) {
                            return
                        }

                        const parameterId = cell.getValue().id
                        const parameterData = constantsData.value?.parameters?.find(
                            (e) => e.id === cell.getValue().parameterId,
                        )

                        if (!parameterData?.canBeOverwritten) {
                            return
                        }

                        const parameterName = chooseLangString(locale, parameterData)
                        const status = cell.getValue().status

                        if (!parameterId) {
                            return
                        }

                        const parentColumn = cell.getColumn().getParentColumn()

                        if (parentColumn === false) {
                            return
                        }

                        const analyticalNumber = parentColumn.getDefinition().title

                        overrideModal?.openOverride({
                            status,
                            parameterId,
                            parameterName,
                            analyticalNumber,
                            analysisType: props.analysisType,
                        })
                    },
                    contextMenu: cellContextMenu.value,
                },
            ],
            frozen: index === 0,
        })) ?? []

    if (props.reportDisplayCount) {
        reportColumns = reportColumns.slice(0, props.reportDisplayCount)
    }

    return [
        {
            title: t('transformers.conditionStatus.content.oilAnalyses.table.reportNumber'),
            columns: [
                {
                    title: t('transformers.conditionStatus.content.oilAnalyses.table.measurement'),
                    field: 'parameterName',
                    headerSort: false,
                    minWidth: 150,
                },
            ],
            frozen: true,
        },
        {
            title: t('transformers.conditionStatus.content.oilAnalyses.table.limit'),
            field: 'limit',
            cssClass: 'analysis-table-limit-cell',
            headerSort: false,
            frozen: true,
            tooltip: (event: UIEvent, cell: CellComponent) => {
                const rowData = cell.getRow().getData()

                const limitText = rowData['limitTooltip']

                if (!limitText) {
                    return ''
                }

                return createAnalysisTableLimitTooltipElement(limitText)
            },
        },
        ...reportColumns,
    ]
})

const tableData = computed(() => {
    if (!data.value || !constantsData.value) {
        return []
    }

    const availableParameters = new Set<number>()

    data.value.reports?.forEach((report) => {
        report.parameters?.forEach((parameter) => {
            if (parameter.parameterId) {
                availableParameters.add(parameter.parameterId)
            }
        })
    })

    const rowData: RowData[] = []

    rowData.push({
        parameterId: -2,
        parameterName: t('transformers.conditionStatus.content.oilAnalyses.constantRows.samplingPoint'),
    })

    rowData.push({
        parameterId: -1,
        parameterName: t('transformers.conditionStatus.content.oilAnalyses.constantRows.temperature'),
    })

    availableParameters.forEach((availableParameter) => {
        const constantsRecord = constantsData.value?.parameters?.find((e) => e.id === availableParameter)

        if (!constantsRecord) {
            return
        }

        rowData.push({
            parameterId: availableParameter,
            parameterName: chooseLangString(locale, constantsRecord),
        })
    })

    data.value.limits?.forEach((limit) => {
        const rowRecord = rowData.find((e) => e.parameterId === limit.parameterId)
        const parameterData = constantsData.value?.parameters?.find((e) => e.id === limit.parameterId)

        if (!rowRecord || !parameterData) {
            return
        }

        rowRecord['limit'] = limit.limit?.toString() ?? '-'

        if (locale.value === 'en-GB') {
            rowRecord['limitTooltip'] = parameterData.englishAutomaticNotice ?? ''
        }

        if (locale.value === 'de-DE') {
            rowRecord['limitTooltip'] = parameterData.germanAutomaticNotice ?? ''
        }
    })

    data.value.reports?.forEach((report) => {
        const columnId = report.analyticalNumber ?? 'N/A'

        const samplingPointRowRecord = rowData.find((e) => e.parameterId === -2)

        if (samplingPointRowRecord) {
            samplingPointRowRecord[columnId] = {
                stringValue: report.samplingPoint ?? '',
            }
        }

        const temperatureRowRecord = rowData.find((e) => e.parameterId === -1)

        if (temperatureRowRecord) {
            temperatureRowRecord[columnId] = {
                numberValue: report.temperature,
            }
        }

        report.parameters?.forEach((parameter) => {
            const rowRecord = rowData.find((e) => e.parameterId === parameter.parameterId)

            if (rowRecord) {
                rowRecord[columnId] = parameter
            }
        })
    })

    return rowData
})

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

watch([tableRef, columns, data], ([newTableRef, newColumns]) => {
    if (!newTableRef) {
        return
    }

    if (newColumns.length === 0) {
        return
    }

    const tabulatorOptions: Options = {
        layoutColumnsOnNewData: true,
        reactiveData: true,
        pagination: false,
        layout: 'fitDataTable',
        height: '100%',
        headerSort: false,
        columnHeaderVertAlign: 'bottom',
        columns: newColumns,
        columnDefaults: {
            vertAlign: 'middle',
        },
        data: tableData.value,
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.analysis-table {
    margin-top: $spacing-s;

    &__table {
        border: 1px solid $pbl-border-muted;
    }
}

.analysis-title {
    font-size: 0.95em;
    font-weight: 500;
}

.loading-state {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    gap: $spacing-s;

    min-height: 100px;
}
</style>

<style lang="scss">
.analysis-table-header-link-cell {
    color: $pbl-primary;
    font-weight: 600;
    cursor: pointer;
}

.analysis-table-context-menu-item {
    display: flex;
    align-items: center;
    gap: $spacing-xs;

    padding: $spacing-3xs;

    span {
        font-weight: 500;
    }

    svg {
        height: 16px;
        width: 16px;
    }
}

.analysis-table-limit-cell:not(.tabulator-col) {
    background-color: #d9ebfa !important;
}

.analysis-table-content-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
}

.analysis-table-icon {
    height: 1.1rem;
    width: 1.1rem;
}

.analysis-table-tooltip {
    display: flex;
    gap: $spacing-s;

    background-color: $pbl-text !important;
    color: $pbl-foreground;

    max-width: 1000px;

    &__override {
        flex-grow: 1;
        display: flex;

        flex-direction: column;
        gap: $spacing-s;

        max-width: 300px;

        &__title {
            font-weight: 500;
        }

        &__content {
            white-space: pre-wrap;
            text-overflow: ellipsis;
        }

        &__details {
            display: flex;
            justify-content: start;
        }
    }

    &__comment {
        flex-grow: 1;
        display: flex;

        flex-direction: column;
        gap: $spacing-s;

        max-width: 300px;

        &__title {
            font-weight: 500;
        }

        &__content {
            white-space: pre-wrap;
            text-overflow: ellipsis;
        }

        &__details {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__separator {
        min-height: 100%;
        width: 1px;

        background-color: white;
    }
}

.analysis-status-header {
    display: flex;
    min-height: 40px;

    &__status {
        display: flex;

        align-items: center;
        gap: $spacing-s;

        &-label {
            font-weight: 500;
            font-size: $default-font-size;
        }
    }
}
</style>
