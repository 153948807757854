import { BaseService } from '@/api/services/BaseService'
import { useAuthStore } from '@/store/authStore'
import { COMMON_SCOPES } from '@/authentication/coodeSdkConfig'
import { ApiResponse } from '@/api/models/ApiResponse'
import { UseCase } from '@/api/models/meta-data/UseCase'
import { Notification } from '@/api/models/meta-data/Notification'
import { ApiPaginatedResponse } from '@/api/models/ApiPaginatedResponse'

export class MetaDataService extends BaseService {
    constructor() {
        super({
            baseURL: import.meta.env.VITE_META_DATA_API_URL,
            accessTokenProvider: () => useAuthStore().getTokenForScope(COMMON_SCOPES.META_DATA),
        })
    }

    async getUseCases(): Promise<UseCase[]> {
        const response = await this.get<ApiResponse<{ items: UseCase[] }>>({
            url: 'use-cases',
        })

        return response.data.result.items
    }

    async getAllNotifications(): Promise<ApiPaginatedResponse<Notification>> {
        const queryParams = new URLSearchParams()

        queryParams.set('ListNotifications', 'true')
        queryParams.set('ListTasks', 'true')
        queryParams.set('GetRead', 'false')
        queryParams.set('UseCaseIds', import.meta.env.VITE_THIS_USE_CASE_ID)
        queryParams.set('page', '1')
        queryParams.set('size', '50')
        queryParams.set('term', '')

        const response = await this.get<ApiResponse<ApiPaginatedResponse<Notification>>>({
            url: `notifications?${queryParams}`,
        })

        return response.data.result
    }

    async clearAllNotifications(): Promise<void> {
        await this.post({
            url: 'notifications/clear-all',
        })
    }

    async markNotificationsAsRead(...notifications: number[]): Promise<void> {
        await this.post({
            url: 'notifications/mark-as-read',
            body: notifications,
        })
    }
}
