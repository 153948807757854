<template>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="plant-status-summary-chart__content">
            <template v-if="data">
                <v-chart
                    class="plant-status-summary-chart__content__chart"
                    :option="option"
                    autoresize
                />
            </template>
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { use } from 'echarts/core'
import { EChartsOption } from 'echarts'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import {
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
    TitleComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { usePortfolioPlantStatusSummaryQuery } from '@/api/queries/transformers/portfolio-overview/usePortfolioPlantStatusSummaryQuery'
import { router } from '@/router'
import { CHART_ICON } from '@/utils/chart-utils'

use([
    TitleComponent,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
])

const props = defineProps<{
    plantGroupSid: number
}>()

const plantGroupSid = computed(() => {
    return props.plantGroupSid
})

const { t } = useI18n()
const { data, isPending, isError } = usePortfolioPlantStatusSummaryQuery(plantGroupSid)

const series = computed(() => [
    {
        id: ConditionStatus.Normal,
        name: t(StatusTypes[ConditionStatus.Normal].labelKey),
        color: StatusTypes[ConditionStatus.Normal].color,
        data: data.value?.numberOfNormalTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.Inferior,
        name: t(StatusTypes[ConditionStatus.Inferior].labelKey),
        color: StatusTypes[ConditionStatus.Inferior].color,
        data: data.value?.numberOfInferiorTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.Critical,
        name: t(StatusTypes[ConditionStatus.Critical].labelKey),
        color: StatusTypes[ConditionStatus.Critical].color,
        data: data.value?.numberOfCriticalTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.None,
        name: t(StatusTypes[ConditionStatus.None].labelKey),
        color: StatusTypes[ConditionStatus.None].color,
        data: data.value?.numberOfNotAssessedTransformers?.map((e: number) => e || '-'),
    },
])

const option = computed((): EChartsOption => {
    const options: EChartsOption = {
        title: {
            show: false,
            text: t('transformers.portfolioOverview.plantStatusSummary.headline'),
        },
        toolbox: {
            show: true,
            itemSize: 16,
            itemGap: 14,
            showTitle: true,
            feature: {
                saveAsImage: {
                    icon: CHART_ICON.SAVE,
                    title: t('chart.action.saveAsImage'),
                },
            },
            iconStyle: {
                color: '#333',
                borderColor: 'none',
            },
            emphasis: {
                iconStyle: {
                    color: '#0178dc',
                    borderColor: 'none',
                },
            },
        },
        tooltip: {
            trigger: 'axis',
            enterable: true,
            confine: true,
            position: (point) => [point[0], point[1]],
            axisPointer: {
                type: 'shadow',
            },
            formatter: (args) => {
                if (!Array.isArray(args)) {
                    return ''
                }

                const mainContainer = document.createElement('div')
                mainContainer.classList.add('container')

                const nameDiv = document.createElement('div')
                nameDiv.classList.add('container__name')
                nameDiv.textContent = args[0].name
                mainContainer.appendChild(nameDiv)

                const contentDiv = document.createElement('div')
                contentDiv.classList.add('container__content')

                args.forEach((element) => {
                    const tooltipData = data.value?.tooltipData?.[element.dataIndex][element.seriesId ?? -1] ?? '-'

                    const itemDiv = document.createElement('div')
                    itemDiv.classList.add('container__content__item-div')

                    const colorDiv = document.createElement('div')
                    colorDiv.classList.add('container__content__item-div__color-div')

                    const colorCircle = document.createElement('span')
                    colorCircle.classList.add('container__content__item-div__color-div__color-circle')
                    colorCircle.style.backgroundColor = element.color?.toString() ?? '#666'
                    colorDiv.appendChild(colorCircle)

                    const seriesNameSpan = document.createElement('span')
                    seriesNameSpan.classList.add('container__content__item-div__color-div__series-name')
                    seriesNameSpan.textContent = element.seriesName ?? ''
                    colorDiv.appendChild(seriesNameSpan)

                    itemDiv.appendChild(colorDiv)

                    const tooltipSpan = document.createElement('span')
                    tooltipSpan.classList.add('container__content__item-div__tooltip')
                    itemDiv.appendChild(tooltipSpan)

                    if (Array.isArray(tooltipData)) {
                        tooltipData.forEach((element, index) => {
                            if (index !== 0) {
                                const dividerSpan = document.createElement('span')
                                dividerSpan.textContent = ', '
                                tooltipSpan.appendChild(dividerSpan)
                            }

                            const linkSpan = document.createElement('span')
                            linkSpan.textContent = element.transformerName ?? '-'
                            linkSpan.classList.add('container__content__item-div__tooltip__link')
                            linkSpan.addEventListener('click', () => onTransformerClick(element.transformerSid ?? ''))
                            tooltipSpan.appendChild(linkSpan)
                        })
                    } else {
                        tooltipSpan.textContent = '-'
                    }

                    contentDiv.appendChild(itemDiv)
                })

                mainContainer.appendChild(contentDiv)

                return mainContainer
            },
        },
        textStyle: {
            fontFamily: 'Roboto',
            color: '#adadad',
        },
        legend: {
            textStyle: {
                fontWeight: 500,
            },
        },
        grid: {
            top: 100,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: data.value?.plants ?? [],
        },
        aria: {
            enabled: true,
            decal: {
                show: true,
                decals: [
                    {
                        symbol: 'none',
                    },
                    {
                        symbol: 'rect',
                        rotation: -0.75,
                        dashArrayX: [1, 0],
                        dashArrayY: [4, 3],
                    },
                    {
                        symbol: 'rect',
                        dashArrayX: [
                            [0, 6, 6, 0],
                            [6, 6],
                        ],
                        dashArrayY: [6, 0],
                    },
                ],
            },
        },
        series: [],
    }

    series.value.forEach((series) => {
        if (!Array.isArray(options.series)) {
            return
        }

        options.series.push({
            id: series.id,
            name: series.name,
            type: 'bar',
            stack: 'total',
            color: series.color,
            barWidth: '90%',
            barMaxWidth: 60,
            label: {
                show: true,
                color: '#4a4a4a',
                fontWeight: 'bold',
                backgroundColor: 'rgba(255, 255, 255, 1)',
            },
            data: series.data,
        })
    })

    return options
})

const onTransformerClick = (transformerSid: string) => {
    router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: transformerSid,
        },
    })
}
</script>

<style scoped lang="scss">
.plant-status-summary-chart {
    &__content {
        &__chart {
            width: 100%;
            height: 700px;
        }
    }
}
</style>

<style lang="scss">
.container {
    &__name {
        font-size: $default-font-size;
        color: #666;
        font-weight: 500;
    }

    &__content {
        margin: 10px 0 0;

        &__item-div {
            margin: 10px 0 0;
            display: flex;
            gap: 16px;
            align-items: flex-start;

            &__color-div {
                display: flex;
                gap: 10px;
                align-items: center;

                &__color-circle {
                    display: inline-block;
                    border-radius: 10px;
                    width: 10px;
                    height: 10px;
                }

                &__series-name {
                    font-size: $default-font-size;
                    color: #666;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            &__tooltip {
                font-size: $default-font-size;
                color: '#333';
                font-weight: 500;
                line-height: 18px;
                max-width: 200px;
                white-space: normal;

                &__link {
                    color: $pbl-primary;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
