import { useInfiniteQuery } from '@tanstack/vue-query'
import { MaybeRef, toValue } from 'vue'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

const DEFAULT_PAGE_SIZE = 3

export const useRecommendationsByComponentQuery = (
    componentSid: MaybeRef<string>,
    preloadSize: MaybeRef<number | undefined>,
) => {
    return useInfiniteQuery({
        queryKey: ['recommendations', componentSid],
        queryFn: async ({ signal, pageParam }) => {
            const preloadPageSize = toValue(preloadSize)

            const {
                data: { result },
            } = await hcaClientFactory.recommendationsApi.listComponentRecommendations(
                toValue(componentSid),
                pageParam,
                preloadPageSize ?? DEFAULT_PAGE_SIZE,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (toValue(preloadSize) !== undefined) {
                return undefined
            }

            if (lastPage?.totalCount === undefined) {
                return 1
            }

            if (lastPage.totalCount <= lastPageParam * DEFAULT_PAGE_SIZE) {
                return undefined
            } else {
                return lastPageParam + 1
            }
        },
        getPreviousPageParam: () => undefined,
    })
}
