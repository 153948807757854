import {
    CommentsApi,
    ComponentStatusApi,
    Configuration,
    GeneralApi,
    MasterDataApi,
    RecommendationsApi,
    ReportsApi,
    PortfolioOverviewApi,
    PortfolioTrendsApi,
    RecommendationTypesApi,
    BookmarksApi,
    DataImportApi,
    DashboardApi,
    ThresholdsApi,
} from '@/api/generated/HCA'
import { useAuthStore } from '@/store/authStore'
import { COMMON_SCOPES } from '@/authentication/coodeSdkConfig'
import { i18n } from '@/utils/i18n'

class HcaClientFactory {
    private readonly defaultConfiguration: Configuration

    private generalApiInstance?: GeneralApi
    private masterDataApiInstance?: MasterDataApi
    private componentStatusApiInstance?: ComponentStatusApi
    private commentsApiInstance?: CommentsApi
    private recommendationsApiInstance?: RecommendationsApi
    private reportsApiInstance?: ReportsApi
    private portfolioOverviewApiInstance?: PortfolioOverviewApi
    private portfolioTrendsApiInstance?: PortfolioTrendsApi
    private recommendationTypesApiInstance?: RecommendationTypesApi
    private bookmarksApiInstance?: BookmarksApi
    private dataImportApiInstance?: DataImportApi
    private dashboardApiInstance?: DashboardApi
    private thresholdsApiInstance?: ThresholdsApi

    constructor() {
        this.defaultConfiguration = new Configuration({
            basePath: import.meta.env.VITE_THIS_USE_CASE_API_URL,
            apiKey: async () => {
                const accessToken = await useAuthStore().getTokenForScope(COMMON_SCOPES.THIS_USE_CASE)
                return accessToken ? `Bearer ${accessToken}` : ''
            },
            baseOptions: {
                transformRequest: (data: unknown, headers: Record<string, string>) => {
                    headers['Accept-Language'] = i18n.global.locale.value.toString()
                    return data
                },
            },
        })
    }

    public get generalApi(): GeneralApi {
        if (!this.generalApiInstance) {
            this.generalApiInstance = new GeneralApi(this.defaultConfiguration)
        }

        return this.generalApiInstance
    }

    public get masterDataApi(): MasterDataApi {
        if (!this.masterDataApiInstance) {
            this.masterDataApiInstance = new MasterDataApi(this.defaultConfiguration)
        }

        return this.masterDataApiInstance
    }

    public get componentStatusApi(): ComponentStatusApi {
        if (!this.componentStatusApiInstance) {
            this.componentStatusApiInstance = new ComponentStatusApi(this.defaultConfiguration)
        }

        return this.componentStatusApiInstance
    }

    public get commentsApi(): CommentsApi {
        if (!this.commentsApiInstance) {
            this.commentsApiInstance = new CommentsApi(this.defaultConfiguration)
        }

        return this.commentsApiInstance
    }

    public get recommendationsApi(): RecommendationsApi {
        if (!this.recommendationsApiInstance) {
            this.recommendationsApiInstance = new RecommendationsApi(this.defaultConfiguration)
        }

        return this.recommendationsApiInstance
    }

    public get reportsApi(): ReportsApi {
        if (!this.reportsApiInstance) {
            this.reportsApiInstance = new ReportsApi(this.defaultConfiguration)
        }

        return this.reportsApiInstance
    }

    public get portfolioOverviewApi(): PortfolioOverviewApi {
        if (!this.portfolioOverviewApiInstance) {
            this.portfolioOverviewApiInstance = new PortfolioOverviewApi(this.defaultConfiguration)
        }

        return this.portfolioOverviewApiInstance
    }

    public get portfolioTrendsApi(): PortfolioTrendsApi {
        if (!this.portfolioTrendsApiInstance) {
            this.portfolioTrendsApiInstance = new PortfolioTrendsApi(this.defaultConfiguration)
        }

        return this.portfolioTrendsApiInstance
    }

    public get recommendationTypesApi(): RecommendationTypesApi {
        if (!this.recommendationTypesApiInstance) {
            this.recommendationTypesApiInstance = new RecommendationTypesApi(this.defaultConfiguration)
        }

        return this.recommendationTypesApiInstance
    }

    public get bookmarksApi(): BookmarksApi {
        if (!this.bookmarksApiInstance) {
            this.bookmarksApiInstance = new BookmarksApi(this.defaultConfiguration)
        }

        return this.bookmarksApiInstance
    }

    public get dataImportApi(): DataImportApi {
        if (!this.dataImportApiInstance) {
            this.dataImportApiInstance = new DataImportApi(this.defaultConfiguration)
        }

        return this.dataImportApiInstance
    }

    public get dashboardApi(): DashboardApi {
        if (!this.dashboardApiInstance) {
            this.dashboardApiInstance = new DashboardApi(this.defaultConfiguration)
        }

        return this.dashboardApiInstance
    }

    public get thresholdsApi(): ThresholdsApi {
        if (!this.thresholdsApiInstance) {
            this.thresholdsApiInstance = new ThresholdsApi(this.defaultConfiguration)
        }

        return this.thresholdsApiInstance
    }
}

export const hcaClientFactory = new HcaClientFactory()
