import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import DashboardRootPage from '@/pages/dashboard/DashboardRootPage.vue'
import TransformersRootPage from '@/pages/transformers/TransformersRootPage.vue'
import PortfolioOverviewPage from '@/pages/transformers/portfolio-overview/PortfolioOverviewPage.vue'
import ConditionStatusPage from '@/pages/transformers/condition-status/ConditionStatusPage.vue'
import RecommendationsPage from '@/pages/recommendations/RecommendationsPage.vue'
import PortfolioTrendsPage from '@/pages/transformers/portfolio-trends/PortfolioTrendsPage.vue'
import ReportsPage from '@/pages/reports/view-all-reports-page/ReportsPage.vue'
import AdminRootPage from '@/pages/admin/AdminRootPage.vue'
import ViewReportPage from '@/pages/reports/view-report-page/ViewReportPage.vue'
import RecommendationTypesPage from '@/pages/admin/recommendation-types/RecommendationTypesPage.vue'
import ExportConditionStatusPage from '@/pages/exports/transformers/condition-status/ExportConditionStatusPage.vue'
import ExportViewReportPage from '@/pages/exports/reports/view-report/ExportViewReportPage.vue'
import GeneratorsPage from '@/pages/generators/GeneratorsPage.vue'
import TurbinesPage from '@/pages/turbines/TurbinesPage.vue'
import ThresholdsPage from '@/pages/admin/thresholds/ThresholdsPage.vue'

const routes: RouteRecordRaw[] = [
    {
        path: '',
        name: 'Index',
        redirect: {
            name: 'Transformers_PortfolioOverview',
        },
    },
    {
        path: '/dashboard',
        name: 'DashboardRoot',
        component: DashboardRootPage,
    },
    {
        path: '/transformers',
        name: 'TransformersRoot',
        component: TransformersRootPage,
        children: [
            {
                path: '',
                name: 'Transformers_Default',
                redirect: {
                    name: 'Transformers_PortfolioOverview',
                },
            },
            {
                path: 'portfolio-overview',
                name: 'Transformers_PortfolioOverview',
                component: PortfolioOverviewPage,
            },
            {
                path: 'condition-status/:transformerId?',
                name: 'Transformers_ConditionStatus',
                component: ConditionStatusPage,
                props: true,
            },
            {
                path: 'condition-report/:reportNumber/:reportType',
                name: 'Reports_View_Condition',
                component: ViewReportPage,
                props: true,
            },
            {
                path: 'portfolio-trends',
                name: 'Transformers_PortfolioTrends',
                component: PortfolioTrendsPage,
            },
        ],
    },
    {
        path: '/generators',
        name: 'GeneratorsPage',
        component: GeneratorsPage,
    },
    {
        path: '/turbines',
        name: 'TurbinesPage',
        component: TurbinesPage,
    },
    {
        path: '/recommendations',
        name: 'RecommendationsRoot',
        component: RecommendationsPage,
    },
    {
        path: '/reports',
        name: 'ReportsRoot',
        children: [
            {
                path: '',
                name: 'Reports_ViewAll',
                component: ReportsPage,
            },
            {
                path: 'view/:reportNumber/:reportType',
                name: 'Reports_View',
                component: ViewReportPage,
                props: true,
            },
            {
                path: '*',
                redirect: {
                    name: 'Reports_ViewAll',
                },
            },
        ],
    },
    {
        path: '/admin',
        name: 'AdminRoot',
        component: AdminRootPage,
        children: [
            {
                path: '',
                name: 'Admin_Default',
                redirect: {
                    name: 'Admin_Thresholds',
                },
            },
            {
                path: 'thresholds',
                name: 'Admin_Thresholds',
                component: ThresholdsPage,
            },
            {
                path: 'recommendation-types',
                name: 'Admin_RecommendationTypes',
                component: RecommendationTypesPage,
            },
        ],
    },
    {
        path: '/export/condition-status/:transformerId',
        name: 'Export_ConditionStatus',
        component: ExportConditionStatusPage,
        props: true,
    },
    {
        path: '/export/view-report/:reportNumber/:reportType',
        name: 'Export_ViewReport',
        component: ExportViewReportPage,
        props: true,
    },
]

export const router = createRouter({
    history: createWebHistory(),
    routes,
})
