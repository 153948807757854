<template>
    <LoadingPanel
        v-model:collapsed="collapsedState.visualInspections"
        :headline="t('transformers.conditionStatus.content.visualInspections.title')"
        :is-pending="isPending"
        :is-error="isError"
        collapsible
    >
        <ConditionStatusVisualInspectionsTable v-if="data" />
    </LoadingPanel>
</template>

<script setup lang="ts">
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import ConditionStatusVisualInspectionsTable from '@/pages/transformers/condition-status/sections/visual-inspections/ConditionStatusVisualInspectionsTable.vue'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useI18n } from 'vue-i18n'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { storeToRefs } from 'pinia'

const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const { collapsedState } = storeToRefs(conditionStatusCollapseStore)

const { t } = useI18n()
const { data, isPending, isError } = useConstantsQuery()
</script>
