<template>
    <LoadingPanel
        :headline="t('reports.table.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="tableRowData.length > 0"
            class="reports-table"
        >
            <div ref="tableRef" />
        </div>
        <div
            v-else
            class="reports-table__empty-state"
        >
            <pbl-icon name="x-circle" />
            <span>{{ t('reports.table.noData') }}</span>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import { DATE_FORMAT, DATE_FORMAT_API } from '@/utils/date-utils'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns'
import { router } from '@/router'
import { useReportParams } from '@/composables/useReportParams'
import { useReportsListQuery } from '@/api/queries/reports/useReportsListQuery'
import { StatusTypes } from '@/configuration/status/condition-status'
import { Check, Info, IconNode, createElement } from 'lucide'
import { ReportType, ReportTypes } from '@/configuration/report/report-types'
import { ComponentTypes } from '@/configuration/component/component-types'
import { flattenPlantsHierarchy, usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'

const reportParams = useReportParams()

const { t, locale } = useI18n()

const startDateFormat = computed(() => {
    const startDate = reportParams.value.startDate
    if (!startDate) {
        return undefined
    }

    const isoDate = startOfMonth(new Date(startDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
})

const endDateFormat = computed(() => {
    const endDate = reportParams.value.endDate
    if (!endDate) {
        return undefined
    }

    const isoDate = endOfMonth(new Date(endDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
})

const reportTypeFormat = computed(() =>
    reportParams.value.reportType === ReportType.All ? undefined : reportParams.value.reportType,
)

const plantList = computed(() => {
    if (!plantsData.value?.plantGroups) {
        return []
    }

    return flattenPlantsHierarchy(plantsData.value.plantGroups)
})

const plantSids = computed(() => {
    if (reportParams.value.plantSid.length > 0) {
        return reportParams.value.plantSid
    }

    return plantList.value
        .filter((e) => reportParams.value.plantGroupSid.includes(e.plantGroup.sid))
        .map((e) => e.plant.sid)
})

const { data: plantsData } = usePlantsHierarchyQuery()
const { data, isPending, isError } = useReportsListQuery(
    plantSids,
    reportParams.value.componentType,
    reportTypeFormat,
    startDateFormat,
    endDateFormat,
)

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => {
    if (!data.value?.reports) {
        return []
    }

    return data.value.reports
})

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: 'fitDataTable',
        height: '100%',
        pagination: true,
        paginationSize: 20,
        reactiveData: true,
        data: newTableRowData,
        columnDefaults: {
            headerSort: false,
        },
        columns: [
            {
                title: t('reports.table.headers.groupName'),
                field: 'groupName',
            },
            {
                title: t('reports.table.headers.plantName'),
                field: 'plantName',
                headerSort: true,
            },
            {
                title: t('reports.table.headers.componentType'),
                field: 'componentType',
                formatter(cell: CellComponent) {
                    const componentType = ComponentTypes.find((e) => e.value === cell.getValue())

                    if (!componentType) {
                        return '-'
                    }

                    return t(componentType.labelKey)
                },
            },
            {
                title: t('reports.table.headers.componentSid'),
                field: 'componentSid',
                headerSort: true,
                formatter: (cell: CellComponent) => {
                    const componentSid = cell.getValue()

                    const cellDiv = document.createElement('div')
                    cellDiv.className = 'reports-table-cell'

                    const linkSpan = document.createElement('span')
                    linkSpan.textContent = componentSid
                    linkSpan.classList.add('reports-table-cell__link')
                    linkSpan.addEventListener('click', openStatusPage)
                    cellDiv.appendChild(linkSpan)

                    return cellDiv
                },
            },
            {
                title: t('reports.table.headers.componentName'),
                field: 'componentName',
            },
            {
                title: t('reports.table.headers.date'),
                field: 'date',
                headerSort: true,
                formatter: (cell: CellComponent) => {
                    return format(new Date(cell.getValue()), DATE_FORMAT)
                },
            },
            {
                title: t('reports.table.headers.reportType'),
                field: 'reportType',
                headerSort: true,
                formatter: (cell: CellComponent) => {
                    const record = ReportTypes.find((e) => e.value === cell.getValue())

                    if (!record) {
                        return '-'
                    }

                    return t(record.labelKey)
                },
            },
            {
                title: t('reports.table.headers.status'),
                field: 'status',
                headerSort: true,
                formatter: (cell: CellComponent) => {
                    const record = StatusTypes.find((e) => e.value === cell.getValue())

                    if (!record) {
                        return '-'
                    }

                    cell.getElement().style.backgroundColor = record.color
                    cell.getElement().style.color = 'white'
                    cell.getElement().style.fontWeight = '500'

                    return t(record.labelKey)
                },
            },
            {
                title: t('reports.table.headers.reportNumber'),
                field: 'reportNumber',
                formatter: (cell: CellComponent) => {
                    const componentSid = cell.getValue()

                    const cellDiv = document.createElement('div')
                    cellDiv.className = 'reports-table-cell'

                    const linkSpan = document.createElement('span')
                    linkSpan.textContent = componentSid
                    linkSpan.classList.add('reports-table-cell__link')
                    cellDiv.appendChild(linkSpan)

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const data = cell.getRow().getData()

                    if (!data.reportNumber || !data.reportType) {
                        return
                    }

                    openReportDetailsPage(data.reportNumber, data.reportType)
                },
            },
            {
                title: t('reports.table.headers.isExcluded'),
                field: 'isExcluded',
                headerSort: true,
                formatter: (cell: CellComponent) => {
                    const isExcluded = cell.getValue()
                    const reportIconData = reportIcons['isExcluded']

                    const cellDiv = document.createElement('div')
                    cellDiv.className = 'reports-table-cell'

                    if (isExcluded && reportIconData) {
                        const statusIcon = createElement(reportIconData.iconNode)
                        statusIcon.classList.add('reports-table-cell__icon')
                        statusIcon.setAttribute('stroke', reportIconData.color)
                        statusIcon.setAttribute('stroke-width', '3px')
                        cellDiv.appendChild(statusIcon)
                    }

                    return cellDiv
                },
                titleFormatter: () => {
                    const cellDiv = document.createElement('div')
                    cellDiv.className = 'reports-excluded-title-cell'

                    const titleSpan = document.createElement('span')
                    titleSpan.textContent = t('reports.table.headers.isExcluded')
                    cellDiv.appendChild(titleSpan)

                    const infoIcon = createElement(Info)
                    infoIcon.classList.add('reports-excluded-title-cell__icon')
                    cellDiv.appendChild(infoIcon)

                    return cellDiv
                },
                headerTooltip: () => t('reports.table.excludedTooltip'),
            },
        ],
        initialSort: [{ column: 'date', dir: 'desc' }],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})

const reportIcons: Record<string, { iconNode: IconNode; color: string }> = {
    isExcluded: {
        iconNode: Check,
        color: '#4a4a4a',
    },
}

const openStatusPage = (event: Event) => {
    const componentSid = (event.target as HTMLElement)?.innerText ?? ''
    router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: componentSid,
        },
    })
}

const openReportDetailsPage = (reportNumber: string, reportType: number) => {
    router.push({
        name: 'Reports_View',
        params: {
            reportNumber,
            reportType,
        },
    })
}

watch(
    [() => reportParams.value.componentId, pebbleTable],
    ([newComponentId, newPebbleTable]) => {
        if (!newPebbleTable) {
            return
        }

        if (!newComponentId) {
            pebbleTable.value?.clearFilter(true)
            return
        }

        pebbleTable.value?.setFilter('componentSid', 'like', newComponentId)
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.reports-table {
    margin: -($spacing-s);

    &__empty-state {
        height: 150px;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }

        span {
            font-size: 1rem;
        }
    }
}
</style>

<style lang="scss">
.reports-table-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;

    &__link {
        color: $pbl-primary;
        cursor: pointer;
    }

    &__icon {
        height: 1.1rem;
        width: 1.1rem;
    }
}

.reports-excluded-title-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;

    &__icon {
        height: 0.8rem;
        width: 0.8rem;

        margin-bottom: 1px;
    }
}
</style>
