<template>
    <div class="footer-section">
        <div>
            <span class="footer-section__field-title">{{ t('exports.common.exportedAt') }}</span>
            <span class="footer-section__field">{{ ` ${exportDate}` }}</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const exportDate = ref(format(new Date(), DATE_TIME_FORMAT))
</script>

<style scoped lang="scss">
.footer-section {
    margin: $spacing-xs $spacing-m $spacing-m;

    display: flex;
    flex-direction: column;
    gap: $spacing-3xs;

    font-size: $default-font-size;

    &__field-title {
        font-weight: 500;
    }
}
</style>
