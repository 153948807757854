import { MaybeRefOrGetter, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformerReportNumbersQuery = (transformerId: MaybeRefOrGetter<string>) => {
    return useQuery({
        queryKey: ['transformers', transformerId, 'reportNumbers'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.commentsApi.listReportNumbers(
                toValue(transformerId),
                undefined,
                undefined,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
    })
}
