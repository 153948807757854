import { defineStore } from 'pinia'
import { Authenticator, LocalStorageHelper, UserAccessManagementHttpClient } from '@coode/fe-sdk'
import { useAppAuthenticator } from '@/authentication/useAppAuthenticator'
import { useUamClient } from '@/authentication/useUamClient'
import { ExtendedUamUserPermissions } from '@/models/uam/ExtendedUamUserPermissions'
import { useUserStore } from '@/store/userStore'
import { COMMON_SCOPES } from '@/authentication/coodeSdkConfig'
import { useMainStore } from '@/store/mainStore'

type AuthStoreState = {
    accessToken?: string
    authenticator?: Authenticator
    uamClient?: UserAccessManagementHttpClient
}

export const useAuthStore = defineStore('authStore', {
    state: (): AuthStoreState => ({
        accessToken: undefined,
        authenticator: undefined,
        uamClient: undefined,
    }),
    getters: {
        isAuthenticated: (state) => !!state.accessToken,
    },
    actions: {
        /**
         * Prepares the application to handle authentication,
         * triggers a user sign-in flow,
         * fetches and stores an authentication token for current use-case,
         * fetches and stores permissions and assetPermissions from UAM.
         *
         * Needs to run before any network requests
         * or permission checks are used.
         *
         * Uses Vite Environment Variable:
         *  - VITE_THIS_USE_CASE_ID, as the UseCaseId of this app
         */
        async initializeAuthentication(): Promise<void> {
            const useCaseId = import.meta.env.VITE_THIS_USE_CASE_ID

            const authenticator = useAppAuthenticator()
            await authenticator.loadAuthModule()
            this.authenticator = authenticator

            this.signIn()

            this.uamClient = useUamClient(authenticator)
            await this.uamClient.setPermissions(Number(useCaseId))
            this.populateUserData()

            await this.renewToken()
            useMainStore().setAuthenticationAsReady()
        },

        /**
         * If the app is not in an authenticated state,
         * it renews the access token for current use-case and stores it,
         * it triggers a population of user data & permissions.
         *
         * @see {renewToken}
         * @see {populateUserData}
         */
        async checkSession(): Promise<void> {
            if (this.isAuthenticated) return

            await this.renewToken()
            this.populateUserData()
        },

        /**
         * Attempts to trigger a silent sign-in flow,
         * if it fails, it will trigger a login with redirect flow.
         *
         * @see {Authenticator.attemptSsoSilent}
         */
        signIn(): void {
            this.authenticator?.attemptSsoSilent('loginRedirect')
        },

        /**
         * Logs out the current user
         */
        signOut(): void {
            this.accessToken = undefined
            this.authenticator?.logout()
        },

        /**
         * Populates user data, permissions and asset permissions
         * from local storage.
         *
         * Local Storage is populated by {uamClient} inside {initializeAuthentication}
         * @see {UserAccessManagementHttpClient.setPermissions}
         */
        populateUserData(): void {
            const profile = LocalStorageHelper.getPermissionsLocalStorage() as ExtendedUamUserPermissions
            const userStore = useUserStore()

            userStore.setUserData(profile)
        },

        /**
         * Returns an access token for the supplied scope.
         *
         * This is used to authenticate requests for services other
         * than current use-case backend services. (i.e. requests for Metadata)
         *
         * @param scope OAuth scope for the requested resource
         * @returns {string | null} The access token as a string, or null if token could not be fetched
         */
        async getTokenForScope(scope: string): Promise<string | undefined> {
            return this.authenticator?.getToken(scope)
        },

        /**
         * Renews the stored token for current use-case.
         */
        async renewToken(): Promise<void> {
            this.accessToken = await this.getTokenForScope(COMMON_SCOPES.THIS_USE_CASE)
        },
    },
})
