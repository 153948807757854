<template>
    <div class="condition-header">
        <ConditionStatusComponentSelector
            :model-value="transformerId"
            @update:model-value="onTransformerValueUpdate"
        />
        <ConditionStatusHeaderActionsSection
            v-if="transformerId && isContentDisplayed"
            :key="transformerId"
            :transformer-id="transformerId"
        />
    </div>
</template>
<script setup lang="ts">
import ConditionStatusComponentSelector from '@/pages/transformers/condition-status/ConditionStatusComponentSelector.vue'
import { useRouter } from 'vue-router'
import ConditionStatusHeaderActionsSection from '@/pages/transformers/condition-status/sections/ConditionStatusHeaderActionsSection.vue'

defineProps<{
    transformerId?: string
    isContentDisplayed: boolean
}>()

const router = useRouter()

const onTransformerValueUpdate = (value: string | undefined) => {
    router.replace({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: value,
        },
    })
}
</script>

<style scoped lang="scss">
.condition-header {
    @include spacing-page;

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    background-color: white;
    border-bottom: 1px solid $pbl-border-muted;

    &__title {
        font-size: 1.25rem;
        font-weight: 500;
    }
}
</style>
