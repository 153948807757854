import { RecommendationParams } from '@/types/recommendations'
import { ComponentType } from '@/configuration/component/component-types'
import { TIMEFRAME_OLDEST_RECORD, TIMEFRAME_DEFAULT_END_DATE } from '@/utils/date-utils'

export const RECOMMENDATIONS_PARAMS_KEY = 'RECOMMENDATION_PARAMS' as const

export const DEFAULT_RECOMMENDATIONS_PARAMS = {
    plantGroupSid: [],
    plantSid: [],
    componentType: [ComponentType.Transformer],
    startDate: TIMEFRAME_OLDEST_RECORD,
    endDate: TIMEFRAME_DEFAULT_END_DATE,
} as const satisfies RecommendationParams
