<template>
    <div
        :class="[
            {
                'button--disabled': disabled,
            },
            `button--${variant}`,
            'button',
        ]"
        @click="onButtonClick"
    >
        <pbl-spinner v-if="loading" />
        <pbl-icon
            v-else-if="iconName"
            :name="iconName"
        />
        {{ text }}
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    text: string
    iconName?: string
    disabled?: boolean
    variant?: 'primary' | 'secondary'
    loading?: boolean
}>()

const emit = defineEmits<{
    click: []
}>()

const onButtonClick = () => {
    if (props.disabled) {
        return
    }

    emit('click')
}
</script>

<style scoped lang="scss">
.button {
    display: flex;
    gap: $spacing-2xs;

    align-items: center;
    justify-content: center;

    color: $pbl-primary;
    font-weight: 500;

    user-select: none;
    cursor: pointer;

    &--disabled {
        color: $pbl-text-muted;

        cursor: not-allowed;
    }

    &--primary {
        color: $pbl-primary;

        pbl-spinner {
            --background-color: #{$pbl-primary};
            --color: #{change-color($pbl-primary, $alpha: 0.15)};
        }
    }

    &--secondary {
        color: $pbl-text-muted;

        pbl-spinner {
            --background-color: #{$pbl-text-muted};
            --color: #{change-color($pbl-text-muted, $alpha: 0.15)};
        }
    }

    pbl-icon {
        --size: 1.2rem;
    }

    pbl-spinner {
        --size: 1.2rem;
    }
}
</style>
