<template>
    <div class="notification-item">
        <div class="notification-item__source-info">
            <span>{{ useCaseName }}</span>
            <span>{{ formattedDate }}</span>
        </div>
        <div class="notification-item__title">
            <div
                v-if="!notification.isRead"
                class="notification-item__read-circle"
            />
            {{ notification.title }}
        </div>
        <div class="notification-item__description">
            {{ notification.description }}
        </div>
        <div class="notification-item__buttons">
            <template
                v-for="action in notification.actions"
                :key="action.id"
            >
                <button
                    class="notification-item__buttons-item"
                    @click="onNotificationActionClick(action)"
                >
                    {{ action.title }}
                </button>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Notification, NotificationAction } from '@/api/models/meta-data/Notification'
import { computed } from 'vue'
import { useMetaDataStore } from '@/store/metaDataStore'
import { format } from 'date-fns'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    notification: Notification
}>()

const toasts = usePebbleToasts()
const { t } = useI18n()

const useCaseName = computed(
    () =>
        useMetaDataStore().useCases.find((e) => e.id === props.notification.useCaseId)?.name ??
        props.notification.useCaseId,
)
const formattedDate = computed(() => format(new Date(props.notification.timestamp), 'dd/MM/yyyy HH:mm'))

const onNotificationActionClick = async (notificationAction: NotificationAction) => {
    window.open(notificationAction.redirectUrl, '_blank')

    if (props.notification.isRead) {
        return
    }

    try {
        await useMetaDataStore().markNotificationAsRead(props.notification.id)
    } catch {
        toasts?.error({
            message: t('header.notifications.errors.failedToMarkAsRead'),
        })
    }
}
</script>

<style scoped lang="scss">
.notification-item {
    display: flex;
    flex-direction: column;
    gap: $spacing-2xs;

    &:not(&:first-child) {
        border-top: 1px solid $pbl-border;
    }

    padding: $spacing-xs $spacing-xs;

    &__source-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: $pbl-text-muted;
        font-size: 0.9em;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: $spacing-2xs;

        white-space: normal;
        font-weight: 600;
    }

    &__read-circle {
        height: 10px;
        width: 10px;
        margin-top: -1px;

        background-color: $pbl-primary;
        border-radius: 50%;
    }

    &__description {
        white-space: normal;
        font-size: 0.9em;
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: $spacing-xs;

        padding-left: $spacing-3xs;

        &-item {
            cursor: pointer;

            padding: 0;
            margin-top: $spacing-3xs;
            background-color: transparent;
            border: 0;

            color: $pbl-primary;
            font-size: 1em;
            font-weight: 500;
            text-align: left;

            &:hover:not(&:active) {
                color: adjust-color($pbl-primary, $lightness: -20%);
            }
        }
    }
}
</style>
