import { computed, MaybeRef, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticatedAnd } from '@/utils/query-utils'

export const useTransformerDetailsQuery = (transformerId: MaybeRef<string | undefined>) => {
    return useQuery({
        queryKey: ['transformers', transformerId, 'details'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.masterDataApi.getTransformerDetails(toValue(transformerId) ?? '', {
                signal,
            })
            return result
        },
        enabled: whenAuthenticatedAnd(computed(() => !!toValue(transformerId))),
    })
}
