<template>
    <LoadingPanel
        :headline="t('transformers.portfolioOverview.plantStatusSummary.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="plant-status-summary">
            <FormSingleSelectField
                v-model="plantGroupSid"
                :placeholder-text="
                    t('transformers.portfolioOverview.plantStatusSummary.selector.placeholders.plantGroup')
                "
                class="plant-status-summary__field"
                :options="plantGroupOptions"
            />
            <div
                v-if="!plantGroupSid"
                class="plant-status-summary__empty-state"
            >
                <pbl-icon name="check2-square" />
                <span>
                    {{ t('transformers.portfolioOverview.plantStatusSummary.emptyState') }}
                </span>
            </div>
            <PortfolioOverviewPlantStatusSummaryChart
                v-else
                :plant-group-sid="plantGroupSid"
            />
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { usePortfolioGroupSummaryQuery } from '@/api/queries/transformers/portfolio-overview/usePortfolioGroupSummaryQuery'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import PortfolioOverviewPlantStatusSummaryChart from '@/pages/transformers/portfolio-overview/plant-status-summary/PortfolioOverviewPlantStatusSummaryChart.vue'
import { PlantGroup } from '@/configuration/plant-group/plant-group'

const emit = defineEmits<{
    'reset:group': []
}>()

const props = defineProps<{
    plantGroupSidParam?: number
}>()

const plantGroupSid = ref<number | undefined>(undefined)

const { t } = useI18n()
const { data, isPending, isError } = usePortfolioGroupSummaryQuery()

const plantGroups = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return data.value.plantGroups
})

const plantGroupSids = computed(() => {
    if (!data.value?.plantGroupSids) {
        return []
    }

    return data.value.plantGroupSids
})

const plantGroupOptions = computed<SelectOptions<number>>(() =>
    plantGroupSids.value
        .map((value, index) => ({
            label: plantGroups.value[index] ?? value,
            value: value ?? 0,
        }))
        .reverse(),
)

watch(
    () => props.plantGroupSidParam,
    (newPlantGroupSid) => {
        if (newPlantGroupSid) {
            plantGroupSid.value = newPlantGroupSid
            emit('reset:group')
        }
    },
)

watch(
    plantGroupSids,
    (newPlantGroupSids) => {
        plantGroupSid.value = newPlantGroupSids.find((e) => e !== PlantGroup.RESERVED) ?? PlantGroup.RESERVED
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.plant-status-summary {
    &__field {
        width: 240px;
        margin-bottom: $spacing-m;
    }

    &__empty-state {
        padding: 50px 0;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }
    }
}
</style>
