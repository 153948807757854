import { CommentParameterDto, OverrideCommentDto, ParameterDto } from '@/api/generated/HCA'
import { useI18n } from 'vue-i18n'
import { StatusTypes } from '@/configuration/status/condition-status'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'

export const useAnalysisTableUtils = () => {
    const { t } = useI18n()

    const createOverrideTooltipElement = (overrideComment: OverrideCommentDto): HTMLElement => {
        const overrideDiv = document.createElement('div')
        overrideDiv.classList.add('analysis-table-tooltip__override')

        const title = t('transformers.conditionStatus.content.oilAnalyses.overrideComment.title', {
            from: t(StatusTypes.find((e) => e.value === overrideComment.previousStatus)?.labelKey ?? ''),
            to: t(StatusTypes.find((e) => e.value === overrideComment.newStatus)?.labelKey ?? ''),
        })

        const date = format(new Date(overrideComment.date ?? new Date()), DATE_FORMAT)
        const kid = overrideComment.kid ?? 'N/A'

        let commentText = overrideComment.text ?? ''

        if (commentText.length > 300) {
            commentText = commentText.substring(0, 300) + '...'
        }

        const titleSpan = document.createElement('span')
        titleSpan.classList.add('analysis-table-tooltip__override__title')
        titleSpan.textContent = title
        overrideDiv.appendChild(titleSpan)

        const contentDiv = document.createElement('div')
        contentDiv.classList.add('analysis-table-tooltip__override__content')
        contentDiv.textContent = commentText
        overrideDiv.appendChild(contentDiv)

        const detailsSpan = document.createElement('span')
        detailsSpan.classList.add('analysis-table-tooltip__override__details')
        detailsSpan.textContent = `${date} | ${kid}`
        overrideDiv.appendChild(detailsSpan)

        return overrideDiv
    }

    const createCommentTooltipElement = (commentParameter: CommentParameterDto): HTMLElement => {
        const commentDiv = document.createElement('div')
        commentDiv.classList.add('analysis-table-tooltip__comment')

        const title = t('transformers.conditionStatus.content.oilAnalyses.comment.title')
        const date = format(new Date(commentParameter.date ?? new Date()), DATE_FORMAT)
        const kid = commentParameter.kid ?? 'N/A'

        let commentText = commentParameter.text ?? ''

        if (commentText.length > 300) {
            commentText = commentText.substring(0, 300) + '...'
        }

        const titleSpan = document.createElement('span')
        titleSpan.classList.add('analysis-table-tooltip__comment__title')
        titleSpan.textContent = title
        commentDiv.appendChild(titleSpan)

        const contentDiv = document.createElement('div')
        contentDiv.classList.add('analysis-table-tooltip__comment__content')
        contentDiv.textContent = commentText
        commentDiv.appendChild(contentDiv)

        const detailsSpan = document.createElement('span')
        detailsSpan.classList.add('analysis-table-tooltip__comment__details')
        detailsSpan.textContent = `${date} | ${kid}`
        commentDiv.appendChild(detailsSpan)

        return commentDiv
    }

    const createAnalysisTableTooltipElement = (parameter: ParameterDto): HTMLElement => {
        const tooltipDiv = document.createElement('div')
        tooltipDiv.classList.add('analysis-table-tooltip')

        if (parameter.overrideComment) {
            tooltipDiv.appendChild(createOverrideTooltipElement(parameter.overrideComment))
        }

        if (parameter.overrideComment && parameter.latestComment) {
            const separatorDiv = document.createElement('div')
            separatorDiv.classList.add('analysis-table-tooltip__separator')

            tooltipDiv.appendChild(separatorDiv)
        }

        if (parameter.latestComment) {
            tooltipDiv.appendChild(createCommentTooltipElement(parameter.latestComment))
        }

        return tooltipDiv
    }

    const createAnalysisTableLimitTooltipElement = (tooltipText: string): HTMLElement => {
        const tooltipDiv = document.createElement('div')
        tooltipDiv.classList.add('analysis-table-tooltip')

        const textSpan = document.createElement('span')
        textSpan.textContent = tooltipText
        tooltipDiv.appendChild(textSpan)

        return tooltipDiv
    }

    return {
        createAnalysisTableTooltipElement,
        createAnalysisTableLimitTooltipElement,
    }
}
