<template>
    <div
        :class="[
            {
                'sidebar-item--active': isActive,
            },
            'sidebar-item',
        ]"
    >
        <template v-if="entry.lucideIcon">
            <Component
                :is="entry.lucideIcon"
                class="sidebar-item__lucide-icon"
            />
        </template>
        <pbl-icon
            v-else-if="entry.iconName"
            :name="entry.iconName"
        />
        <span>{{ t(entry.titleKey) }}</span>
    </div>
</template>

<script setup lang="ts">
import { NavigationEntry } from '@/components/navigation/common-navigation'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

const props = defineProps<{
    entry: NavigationEntry
}>()

const { t } = useI18n()
const route = useRoute()

const isActive = computed(() =>
    route.matched.some((e) => typeof e.name === 'string' && props.entry.matchRouteNames.includes(e.name)),
)
</script>

<style scoped lang="scss">
.sidebar-item {
    user-select: none;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    gap: $spacing-2xs;

    align-items: center;
    justify-content: center;

    padding: $spacing-xs;

    &:hover:not(&--active) {
        color: $pbl-primary;
    }

    &--active {
        background-color: $pbl-foreground-active;
    }

    span {
        font-weight: 500;

        font-size: 0.875rem;
        line-height: 1.2rem;

        padding: 0.3rem 0;

        overflow-wrap: break-word;
        hyphens: auto;
        max-width: 125px;

        text-align: center;
    }

    &__lucide-icon {
        height: 1.5rem;
        width: 1.5rem;

        margin-top: $spacing-3xs;
    }

    pbl-icon {
        --size: 1.5rem;

        margin-top: $spacing-3xs;
    }
}
</style>
