import { AxiosHeaders } from 'axios'

export const getFilename = (headers: AxiosHeaders) => {
    const contentDisposition = headers.get('Content-Disposition') as string

    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(contentDisposition)

        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '')
        }
    }
}

export const downloadFile = (blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = fileName

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(url)
}
