import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'
import { MaybeRef, toValue } from 'vue'

export const useConditionStatusAgeQuery = (
    plantSids: MaybeRef<number[]>,
    endDate: MaybeRef<string>,
    transformerAge: MaybeRef<number>,
) => {
    return useQuery({
        queryKey: ['transformers', 'portfolioTrends', 'conditionStatusAge', plantSids, endDate, transformerAge],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.portfolioTrendsApi.getTransformerStatusesByAge(
                toValue(plantSids),
                toValue(endDate),
                toValue(transformerAge),
                {
                    signal,
                },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
