export enum ReportExportType {
    OilAnalysisData = 1,
    OilAnalysisStatus = 2,
    SummaryComponentConditionStatus = 5,
}

export const ReportExportTypes = [
    {
        labelKey: 'common.reportExportTypes.oilAnalysisData',
        value: ReportExportType.OilAnalysisData,
    },
    {
        labelKey: 'common.reportExportTypes.oilAnalysisStatus',
        value: ReportExportType.OilAnalysisStatus,
    },
    {
        labelKey: 'common.reportExportTypes.summaryComponentConditionStatus',
        value: ReportExportType.SummaryComponentConditionStatus,
    },
] as const

export const AllGroupsOptionExceptionForExportType = {
    includeAllGroupsOption: [ReportExportType.OilAnalysisStatus, ReportExportType.SummaryComponentConditionStatus],
}
