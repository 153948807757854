<template>
    <div
        :class="[
            {
                'report-analysis-section--columned': exportLayout,
            },
            'report-analysis-section',
        ]"
    >
        <LoadingPanel
            :headline="t(AnalysisTypes.find((e) => e.value === AnalysisType.GasInOil)?.labelKey ?? '')"
            :is-pending="isPending"
            :is-error="isError"
            :hide-content="exportLayout && !gasInOilData"
            class="report-analysis-section__section"
        >
            <ViewReportOilAnalysisTable
                v-if="gasInOilData"
                :report-number="reportNumber"
                :analysis-data="gasInOilData"
            />
            <EmptyStatePanelContent v-else />
            <template #context>
                <div slot="context">
                    <ColorBadge
                        v-if="StatusTypes.find((e) => e.value === gasInOilData?.status)"
                        :color="StatusTypes.find((e) => e.value === gasInOilData?.status)?.color ?? ''"
                        :text="t(StatusTypes.find((e) => e.value === gasInOilData?.status)?.labelKey ?? '')"
                        small
                    />
                    <ColorBadge
                        v-else
                        :color="StatusTypes[ConditionStatus.None].color"
                        :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                        small
                    />
                </div>
            </template>
        </LoadingPanel>
        <LoadingPanel
            :headline="t(AnalysisTypes.find((e) => e.value === AnalysisType.InsulatingOil)?.labelKey ?? '')"
            :is-pending="isPending"
            :is-error="isError"
            :hide-content="exportLayout && !insulatingOilData"
            class="report-analysis-section__section"
        >
            <ViewReportOilAnalysisTable
                v-if="insulatingOilData"
                :report-number="reportNumber"
                :analysis-data="insulatingOilData"
            />
            <EmptyStatePanelContent v-else />
            <template #context>
                <div slot="context">
                    <ColorBadge
                        v-if="StatusTypes.find((e) => e.value === insulatingOilData?.status)"
                        :color="StatusTypes.find((e) => e.value === insulatingOilData?.status)?.color ?? ''"
                        :text="t(StatusTypes.find((e) => e.value === insulatingOilData?.status)?.labelKey ?? '')"
                        small
                    />
                    <ColorBadge
                        v-else
                        :color="StatusTypes[ConditionStatus.None].color"
                        :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                        small
                    />
                </div>
            </template>
        </LoadingPanel>
        <LoadingPanel
            :headline="t(AnalysisTypes.find((e) => e.value === AnalysisType.Furans)?.labelKey ?? '')"
            :is-pending="isPending"
            :is-error="isError"
            :hide-content="exportLayout && !furansData"
            class="report-analysis-section__section"
        >
            <ViewReportOilAnalysisTable
                v-if="furansData"
                :report-number="reportNumber"
                :analysis-data="furansData"
            />
            <EmptyStatePanelContent v-else />
            <template #context>
                <div slot="context">
                    <ColorBadge
                        v-if="StatusTypes.find((e) => e.value === furansData?.status)"
                        :color="StatusTypes.find((e) => e.value === furansData?.status)?.color ?? ''"
                        :text="t(StatusTypes.find((e) => e.value === furansData?.status)?.labelKey ?? '')"
                        small
                    />
                    <ColorBadge
                        v-else
                        :color="StatusTypes[ConditionStatus.None].color"
                        :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                        small
                    />
                </div>
            </template>
        </LoadingPanel>
    </div>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { AnalysisType, AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import ViewReportOilAnalysisTable from '@/pages/reports/view-report-page/sections/tables/ViewReportOilAnalysisTable.vue'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'

const props = defineProps<{
    reportNumber: string
    reportType: number
    exportLayout?: boolean
}>()

const { reportNumber, reportType } = toRefs(props)

const { t } = useI18n()

const { data, isPending, isError } = useReportGetQuery(reportNumber, reportType)

const gasInOilData = computed(() => data.value?.data?.find((e) => e.analysisType === AnalysisType.GasInOil))
const insulatingOilData = computed(() => data.value?.data?.find((e) => e.analysisType === AnalysisType.InsulatingOil))
const furansData = computed(() => data.value?.data?.find((e) => e.analysisType === AnalysisType.Furans))
</script>

<style scoped lang="scss">
.report-analysis-section {
    display: flex;
    gap: $spacing-m;

    &--columned {
        flex-direction: column;
    }

    &__section {
        break-inside: avoid;
    }
}
</style>
