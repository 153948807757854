import { computed, MaybeRef, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticatedAnd } from '@/utils/query-utils'

export const useRecommendationQuery = (recommendationId: MaybeRef<number>) => {
    return useQuery({
        queryKey: ['recommendation', recommendationId],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.recommendationsApi.getRecommendation(toValue(recommendationId), { signal })

            // TODO: Move this sorting into BE logic
            result?.comments?.sort((a, b) => {
                if (!a.date || !b.date) {
                    return 0
                }

                return new Date(b.date).getTime() - new Date(a.date).getTime()
            })

            return result
        },
        enabled: whenAuthenticatedAnd(computed(() => !!toValue(recommendationId))),
        staleTime: Infinity,
    })
}
