<template>
    <div class="recommendations-page">
        <div class="recommendations-page__header">
            <span class="recommendations-page__header__title">
                {{ t('admin.recommendationTypes.table.headline') }}
            </span>
        </div>
        <div class="recommendations-page__content">
            <div class="recommendations-page__content__add">
                <pbl-button
                    compact
                    @click="openAddRecommendation()"
                >
                    <pbl-icon
                        slot="icon"
                        variant="secondary"
                        name="plus"
                    ></pbl-icon>
                    {{ t('admin.recommendationTypes.recommendationModal.title.add') }}
                </pbl-button>
            </div>
            <div class="recommendations-page__content__table">
                <RecommendationTypesTable />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import RecommendationTypesTable from '@/pages/admin/recommendation-types/RecommendationTypesTableSection.vue'
import { useI18n } from 'vue-i18n'
import { useConditionStatusAddRecommendationTypeModal } from '@/pages/admin/recommendation-types/modal/add-recommendation/useConditionStatusAddRecommendationTypeModal'

const { t } = useI18n()
const { openAddRecommendation } = useConditionStatusAddRecommendationTypeModal()
</script>

<style scoped lang="scss">
.recommendations-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__header {
        @include spacing-page;

        display: flex;
        flex-direction: column;
        gap: $spacing-m;

        background-color: white;
        border-bottom: 1px solid $pbl-border-muted;

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    &__content {
        @include spacing-page;
        overflow-y: scroll;

        &__add {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 10px;
        }

        &__table {
            display: flex;
            flex-direction: column;

            gap: $spacing-m;
        }

        &__empty-state {
            padding: 50px 0;

            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            gap: $spacing-s;

            color: $pbl-text-muted;

            pbl-icon {
                --size: 2rem;
            }

            span {
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }
}
</style>
