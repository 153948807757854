<template>
    <LoadingPanel
        :headline="t('transformers.portfolioTrends.portfolioStatusByAge.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="status-by-age">
            <div class="status-by-age__selector">
                <FormSingleSelectField
                    v-model="selectedOption"
                    :placeholder-text="
                        t('transformers.portfolioTrends.portfolioStatusByAge.selector.placeholders.plant')
                    "
                    :options="combinedOptions"
                    class="status-by-age__selector__field"
                />
                <FormInputField
                    v-model="selectedEndDate"
                    type="month"
                    class="status-by-age__selector__field"
                />
                <FormSingleSelectField
                    v-model="selectedTransformerAge"
                    :placeholder-text="
                        t('transformers.portfolioTrends.portfolioStatusByAge.selector.placeholders.transformerAge')
                    "
                    :options="transformerAgeTypeOptions"
                    class="status-by-age__selector__field"
                />
            </div>
            <div
                v-if="plantSids.length === 0 || !endDate"
                class="status-by-age__empty-state"
            >
                <pbl-icon name="check2-square" />
                <span>
                    {{ t('transformers.portfolioTrends.portfolioStatusByAge.emptyState') }}
                </span>
            </div>
            <PortfolioTrendsStatusByAgeChart
                v-else
                :plant-sids="plantSids"
                :end-date="endDate"
                :transformer-age="selectedTransformerAge"
            />
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { SelectOptions, sortOptionsByLabel } from '@/components/form-inputs/common/select-option'
import PortfolioTrendsStatusByAgeChart from '@/pages/transformers/portfolio-trends/plant-status-by-age/PortfolioTrendsStatusByAgeChart.vue'
import { flattenPlantsHierarchy, usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'
import { TIMEFRAME_DEFAULT_END_DATE, endDateFormat } from '@/utils/date-utils'
import { TransformerAgeType, TransformerAgeTypes } from '@/configuration/transformer-age/transformer-age-types'

interface SelectOptionsExtradata {
    plantSids?: Array<number>
}

const ALL_PLANTS_SELECTION_VALUE = 0 as const
const selectedOption = ref<number>(ALL_PLANTS_SELECTION_VALUE)
const selectedEndDate = ref<string | undefined>(TIMEFRAME_DEFAULT_END_DATE)
const selectedTransformerAge = ref<number>(TransformerAgeType.All)

const { t } = useI18n()
const { data, isPending, isError } = usePlantsHierarchyQuery()

const groupOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() =>
    sortOptionsByLabel(
        data.value?.plantGroups?.map((e) => ({
            label: t('transformers.portfolioTrends.portfolioStatusByAge.selector.options.groupOption', {
                groupName: e.name ?? '',
            }),
            value: e.sid ?? -1,
            extraData: {
                plantSids: e.plants?.map((e) => e.sid ?? -1) ?? [],
            },
        })) ?? [],
    ),
)

const plantOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() =>
    sortOptionsByLabel(
        data.value?.plantGroups?.flatMap(
            (e) =>
                e.plants?.map((e) => ({
                    label: e.name ?? '-',
                    value: e.sid ?? -1,
                    extraData: {
                        plantSids: e.sid ? [e.sid] : [],
                    },
                })) ?? [],
        ) ?? [],
    ),
)

const allPlants = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return flattenPlantsHierarchy(data.value?.plantGroups)
})

const allPlantsOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() => [
    {
        label: t('transformers.portfolioTrends.portfolioStatusByAge.selector.options.allPlants'),
        value: ALL_PLANTS_SELECTION_VALUE,
        extraData: {
            plantSids: allPlants.value.map((e) => e.plant.sid),
        },
    },
])

const combinedOptions = computed<SelectOptions<number, SelectOptionsExtradata>>(() => [
    ...allPlantsOptions.value,
    ...groupOptions.value,
    ...plantOptions.value,
])

const plantSids = computed(
    () => combinedOptions.value.find((e) => e.value === selectedOption.value)?.extraData?.plantSids ?? [],
)

const endDate = computed(() => selectedEndDate.value && endDateFormat(selectedEndDate.value))

const transformerAgeTypeOptions = computed<SelectOptions<number>>(
    () =>
        TransformerAgeTypes?.map((e) => ({
            label: t(e.labelKey) ?? '-',
            value: e.value ?? 0,
        })) ?? [],
)
</script>

<style scoped lang="scss">
.status-by-age {
    &__selector {
        display: flex;
        gap: 10px;

        &__field {
            width: 240px;
        }
    }

    &__empty-state {
        padding: 100px 0;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }
    }
}
</style>
