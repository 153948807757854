<template>
    <div class="condition-status-page">
        <ConditionStatusStaticHeaderContent
            :transformer-id="transformerId"
            :is-content-displayed="isContentDisplayed"
        />
        <div class="condition-status-page__content">
            <ConditionStatusContent
                v-if="isContentDisplayed && transformerId"
                :key="transformerId"
                :transformer-id="transformerId"
            />
            <pbl-panel v-else>
                <div class="condition-status-page__content__empty-state">
                    <pbl-icon name="check2-square" />
                    <span>
                        {{ t('transformers.conditionStatus.emptyStateBody') }}
                    </span>
                </div>
            </pbl-panel>
        </div>
    </div>
</template>

<script setup lang="ts">
import ConditionStatusContent from '@/pages/transformers/condition-status/ConditionStatusContent.vue'
import {
    flattenTransformersHierarchy,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import ConditionStatusStaticHeaderContent from '@/pages/transformers/condition-status/ConditionStatusStaticHeaderContent.vue'

const props = defineProps<{
    transformerId?: string
}>()

const { t } = useI18n()

const { data } = useTransformersHierarchyQuery()

const transformerList = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return flattenTransformersHierarchy(data.value.plantGroups)
})

const isContentDisplayed = computed(
    () =>
        !!(
            data.value &&
            props.transformerId &&
            transformerList.value.find((e) => e.transformer.id === props.transformerId)
        ),
)
</script>

<style scoped lang="scss">
.condition-status-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__content {
        @include spacing-page;
        overflow-y: scroll;

        &__empty-state {
            padding: 50px 0;

            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            gap: $spacing-s;

            color: $pbl-text-muted;

            pbl-icon {
                --size: 2rem;
            }

            span {
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }
}
</style>
