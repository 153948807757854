<template>
    <ThresholdEditModal>
        <div class="thresholds-page">
            <div class="thresholds-page__header">
                <span class="thresholds-page__header__title">
                    {{ t('admin.thresholds.title') }}
                </span>
            </div>
            <div class="thresholds-page__content">
                <ThresholdsPageStaticOilAnalysesSection />
                <ThresholdsPageOilAnalysesSection />
                <ThresholdsPageStaticVisualInspectionSection />
            </div>
        </div>
    </ThresholdEditModal>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import ThresholdsPageStaticOilAnalysesSection from '@/pages/admin/thresholds/sections/ThresholdsPageStaticOilAnalysesSection.vue'
import ThresholdsPageOilAnalysesSection from '@/pages/admin/thresholds/sections/ThresholdsPageOilAnalysesSection.vue'
import ThresholdsPageStaticVisualInspectionSection from '@/pages/admin/thresholds/sections/ThresholdsPageStaticVisualInspectionSection.vue'
import ThresholdEditModal from '@/pages/admin/thresholds/modals/threshold/ThresholdEditModal.vue'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.thresholds-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__header {
        @include spacing-page;

        display: flex;
        flex-direction: column;
        gap: $spacing-m;

        background-color: white;
        border-bottom: 1px solid $pbl-border-muted;

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }

    &__content {
        @include spacing-page;
        overflow-y: scroll;

        display: flex;
        flex-direction: column;
        gap: $spacing-s;
    }
}
</style>
