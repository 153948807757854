<template>
    <slot />
</template>

<script setup lang="ts">
import { onErrorCaptured } from 'vue'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { isProductionEnvironment } from '@/utils/environment'

const toasts = usePebbleToasts()

onErrorCaptured((error) => {
    let message = isProductionEnvironment ? 'An unknown error has occurred!' : `Unhandled Error: ${error.message}`

    toasts?.error({
        message,
        overrides: {
            // Auto-dismiss the unknown error message if we're in production
            duration: isProductionEnvironment ? 1500 : 0,
        },
    })

    if (!isProductionEnvironment) {
        console.error('Captured unhandled error: ', { error })
    }
})
</script>
