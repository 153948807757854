<template>
    <div class="view-report-static-header">
        <div class="view-report-static-header__info">
            <div class="view-report-static-header__info__title">
                <ChevronLeft
                    class="view-report-static-header__info__title-icon"
                    @click="onBackClick"
                />
                <span>{{ pageTitle }}</span>
            </div>
            <span
                v-if="pageSubtitle"
                class="view-report-static-header__info__subtitle"
            >
                {{ pageSubtitle }}
            </span>
        </div>
        <div class="view-report-static-header__export">
            <pbl-button @click="onExportClick">
                {{ t('reports.detailsPage.actions.export') }}
            </pbl-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ChevronLeft } from 'lucide-vue-next'
import { computed, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { ReportTypes } from '@/configuration/report/report-types'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'

const props = defineProps<{
    reportNumber: string
    reportType: number
    transformerId?: string
}>()

const { reportNumber, reportType, transformerId } = toRefs(props)

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const { data } = useReportGetQuery(reportNumber, reportType)
const { data: transformerDetailsData } = useTransformerDetailsQuery(transformerId)

const pageTitle = computed(() => {
    if (route.name === 'Reports_View_Condition') {
        return t('reports.detailsPage.pageTitle.reportTitle.conditionStatus', {
            transformerId: transformerId.value ?? '-',
            reportNumber: reportNumber.value,
        })
    }

    return t('reports.detailsPage.pageTitle.reportTitle.default', {
        reportNumber: reportNumber.value,
    })
})

const reportDate = computed(() => data.value?.data?.[0]?.samplingDate)

const pageSubtitle = computed(() => {
    if (!transformerDetailsData.value || !data.value) {
        return ''
    }

    const plantGroupName = transformerDetailsData.value.plantGroupName
    const plantName = transformerDetailsData.value.plantName
    const transformerName = transformerDetailsData.value.transformerName
    const transformerSid = transformerDetailsData.value.transformerSid
    const reportTypeString = t(ReportTypes.find((e) => e.value === reportType.value)?.labelKey ?? '-')

    const reportDateFormatted = format(new Date(reportDate.value ?? new Date()), DATE_FORMAT)

    return `${plantGroupName} / ${plantName} / ${transformerName} / ${transformerSid} / ${reportTypeString} / ${reportDateFormatted}`
})

const onExportClick = () => {
    router.push({
        name: 'Export_ViewReport',
        params: {
            reportNumber: reportNumber.value,
            reportType: reportType.value.toString(),
        },
    })
}

const onBackClick = () => {
    router.back()
}
</script>

<style scoped lang="scss">
.view-report-static-header {
    @include spacing-page;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    border-bottom: 1px solid $pbl-border-muted;

    &__info {
        display: flex;
        flex-direction: column;
        gap: $spacing-s;

        &__title {
            display: flex;
            align-items: center;

            gap: $spacing-2xs;

            &-icon {
                margin-bottom: -2px;
                height: 20px;
                width: 20px;

                cursor: pointer;
            }

            span {
                font-size: 1.25rem;
                font-weight: 500;
            }
        }
    }

    &__export {
        margin-right: 2rem;
    }
}
</style>
