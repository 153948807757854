<template>
    <div class="condition-status-oil-historical-trends">
        <LoadingPanel
            v-model:collapsed="collapsedState.oilHistoricalTrends"
            :headline="t('transformers.conditionStatus.content.oilHistoricalTrends.title')"
            collapsible
        >
            <div class="condition-status-oil-historical-trends__panel">
                <ConditionStatusOilHistoricalTrendsSelector
                    :gas-in-oil-parameter-group="gasInOilParameterGroup"
                    :start-date="startDate"
                    :end-date="endDate"
                    @update:selector="onUpdateSelector"
                />
                <ConditionStatusOilHistoricalTrendsChart
                    :gas-in-oil-parameter-group="gasInOilParameterGroup"
                    :start-date="selectedStartDate"
                    :end-date="selectedEndDate"
                />
            </div>
        </LoadingPanel>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { TIMEFRAME_DEFAULT_END_DATE, endDateFormat, startDateFormat, timeframeStartDate } from '@/utils/date-utils'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import ConditionStatusOilHistoricalTrendsSelector from '@/pages/transformers/condition-status/sections/oil-historical-trends/ConditionStatusOilHistoricalTrendsSelector.vue'
import ConditionStatusOilHistoricalTrendsChart from '@/pages/transformers/condition-status/sections/oil-historical-trends/ConditionStatusOilHistoricalTrendsChart.vue'
import { OilHistoricalTrendsSelector } from '@/pages/transformers/condition-status/sections/oil-historical-trends/oil-historical-trends-types'
import { useExportStateStore } from '@/store/exportStateStore'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { storeToRefs } from 'pinia'

const exportStateStore = useExportStateStore()
const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const { collapsedState } = storeToRefs(conditionStatusCollapseStore)

const { t } = useI18n()

const gasInOilParameterGroup = ref<string | number | undefined>(undefined)
const startDate = ref<string | undefined>(timeframeStartDate(15))
const endDate = ref<string | undefined>(TIMEFRAME_DEFAULT_END_DATE)

const selectedStartDate = computed(() => {
    return startDate.value && startDateFormat(startDate.value)
})

const selectedEndDate = computed(() => {
    return endDate.value && endDateFormat(endDate.value)
})

const onUpdateSelector = (selector: OilHistoricalTrendsSelector) => {
    gasInOilParameterGroup.value = selector.gasInOilParameterGroup
    startDate.value = selector.startDate
    endDate.value = selector.endDate

    exportStateStore.conditionStatusExportParameters.startDate = selector.startDate
    exportStateStore.conditionStatusExportParameters.endDate = selector.endDate
    exportStateStore.conditionStatusExportParameters.gasInOilParameterGroup = selector.gasInOilParameterGroup
}
</script>

<style scoped lang="scss">
.condition-status-oil-historical-trends {
    &__panel {
        display: flex;
        flex-direction: column;
        gap: $spacing-m;
    }
}
</style>
