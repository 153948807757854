<template>
    <div class="title-section">
        <span class="title-section__title">{{ pageTitle }}</span>
        <span class="title-section__subtitle">{{ pageSubtitle }}</span>
        <span
            v-if="isExcludedFromStats"
            class="title-section__muted"
        >
            {{ t('exports.viewReport.excludedFromStats') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, toRefs } from 'vue'
import { ReportTypes } from '@/configuration/report/report-types'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'

const props = defineProps<{
    reportNumber: string
    reportType: number
    transformerId: string
}>()

const { reportNumber, reportType, transformerId } = toRefs(props)

const { t } = useI18n()

const { data: reportData } = useReportGetQuery(reportNumber, reportType)
const { data: transformerDetailsData } = useTransformerDetailsQuery(transformerId)

const isExcludedFromStats = computed(() => reportData.value?.isExcluded ?? false)

const reportDate = computed(() => reportData.value?.data?.[0]?.samplingDate)

const pageTitle = computed(() => t('exports.viewReport.title', { reportNumber: reportNumber.value ?? '' }))

const pageSubtitle = computed(() => {
    if (!transformerDetailsData.value) {
        return ''
    }

    const plantGroupName = transformerDetailsData.value.plantGroupName
    const plantName = transformerDetailsData.value.plantName
    const transformerName = transformerDetailsData.value.transformerName
    const transformerSid = transformerDetailsData.value.transformerSid
    const reportTypeString = t(ReportTypes.find((e) => e.value === reportType.value)?.labelKey ?? '-')

    const reportDateFormatted = format(new Date(reportDate.value ?? new Date()), DATE_FORMAT)

    return `${plantGroupName} / ${plantName} / ${transformerName} / ${transformerSid} / ${reportTypeString} / ${reportDateFormatted}`
})
</script>

<style scoped lang="scss">
.title-section {
    margin: $spacing-s $spacing-s;

    display: flex;
    flex-direction: column;
    gap: $spacing-3xs;

    &__title {
        font-weight: 500;
    }

    &__muted {
        font-weight: 500;
        color: $pbl-text-muted;
    }
}
</style>
