import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useReportSetExclusionMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { analyticalNumber: string; isExcluded: boolean }) =>
            hcaClientFactory.reportsApi.setIsExcludeFlag(payload.analyticalNumber, payload.isExcluded),
        onSuccess: async (response, payload) => {
            await queryClient.invalidateQueries({ queryKey: ['reports', 'details', payload.analyticalNumber] })
        },
    })
}
