<template>
    <FormSingleSelectField
        v-model="value"
        :label="label"
        :options="options"
        :disabled="disabled"
        :invalid="!meta.valid && meta.validated"
        :error-message="errorMessage ? t(errorMessage) : undefined"
        :placeholder-text="placeholder"
        :cta-label="hasClearAction ? t('formInputs.common.actions.clear') : undefined"
        @click:cta="onClickCta"
    />
</template>

<script setup lang="ts" generic="T">
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { useField } from 'vee-validate'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    name: string

    label: string
    options: SelectOptions<T>

    disabled?: boolean
    placeholder?: string
    hasClearAction?: boolean
}>()

const { t } = useI18n()
const { value, errorMessage, meta } = useField<T | undefined>(() => props.name)

const onClickCta = () => {
    if (!props.hasClearAction) {
        return
    }

    value.value = undefined
}
</script>
