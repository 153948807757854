<template>
    <ContentPanel :headline="t('admin.thresholds.visualInspection.static.headline')">
        <div class="visual-inspection">
            <div class="visual-inspection__section">
                <div class="visual-inspection__section__heading">
                    {{ t('admin.thresholds.visualInspection.static.reportStatus.title') }}
                </div>
                <ul>
                    <li>{{ t('admin.thresholds.visualInspection.static.reportStatus.yellowLogic') }}</li>
                    <li>{{ t('admin.thresholds.visualInspection.static.reportStatus.redLogic') }}</li>
                </ul>
            </div>
        </div>
    </ContentPanel>
</template>

<script setup lang="ts">
import ContentPanel from '@/components/panel/ContentPanel.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.visual-inspection {
    display: flex;
    flex-direction: column;

    &__section {
        display: grid;
        grid-template-columns: min-content max-content;
        gap: $spacing-2xs 0;

        font-size: $default-font-size;

        &__heading {
            font-weight: 500;

            white-space: nowrap;
            align-self: center;
        }

        ul {
            list-style-type: none;
        }
    }
}
</style>
