<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.latestReports.title')"
        :is-pending="isPending"
        :is-error="isError"
        :hide-content="data?.latestConditionStatuses?.length === 0"
    >
        <template
            v-if="exportLayout && data?.latestConditionStatuses?.length === 0"
            #context
        >
            <ColorBadge
                :color="StatusTypes[ConditionStatus.None].color"
                :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                small
            />
        </template>
        <div
            v-if="data && data.latestConditionStatuses?.length !== 0"
            class="latest-reports"
        >
            <div ref="tableRef" />
        </div>
        <EmptyStatePanelContent v-else />
    </LoadingPanel>
</template>

<script setup lang="ts">
import { useTransformerComponentStatusQuery } from '@/api/queries/transformers/useTransformerComponentStatusQuery'
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useTransformerId } from '@/composables/useTransformerId'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import ColorBadge from '@/components/badge/ColorBadge.vue'

const props = defineProps<{
    exportLayout?: boolean
}>()

const transformerId = useTransformerId()

const { t, locale } = useI18n()

const { data, isPending, isError } = useTransformerComponentStatusQuery(transformerId)

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => {
    if (!data.value?.latestConditionStatuses) {
        return []
    }

    return data.value.latestConditionStatuses
})

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: props.exportLayout ? 'fitColumns' : 'fitDataTable',
        height: '100%',
        pagination: false,
        reactiveData: true,
        data: newTableRowData,
        columnDefaults: {
            vertAlign: 'middle',
        },
        columns: [
            {
                title: t('transformers.conditionStatus.content.latestReports.table.date'),
                field: 'date',
                formatter: (cell: CellComponent) => {
                    return format(new Date(cell.getValue()), DATE_FORMAT)
                },
            },
            {
                title: t('transformers.conditionStatus.content.latestReports.table.reportType'),
                field: 'reportType',
                formatter: (cell: CellComponent) => {
                    const record = AnalysisTypes.find((e) => e.value === cell.getValue())
                    return record ? t(record.labelKey) : '-'
                },
            },
            {
                title: t('transformers.conditionStatus.content.latestReports.table.reportId'),
                field: 'reportId',
            },
            {
                title: t('transformers.conditionStatus.content.latestReports.table.status'),
                field: 'status',
                formatter: (cell: CellComponent) => {
                    const record = StatusTypes.find((e) => e.value === cell.getValue())

                    if (!record) {
                        return '-'
                    }

                    cell.getElement().style.backgroundColor = record.color
                    cell.getElement().style.color = 'white'
                    cell.getElement().style.fontWeight = '500'

                    return t(record.labelKey)
                },
            },
            {
                title: t('transformers.conditionStatus.content.latestReports.table.nextReportDate'),
                field: 'nextReportDate',
                formatter: (cell: CellComponent) => {
                    return format(new Date(cell.getValue()), DATE_FORMAT)
                },
            },
        ],
        initialSort: [{ column: 'date', dir: 'desc' }],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.latest-reports {
    margin: -($spacing-s);
}
</style>

<style lang="scss">
.latest-reports-link-cell {
    color: $pbl-primary;
    cursor: pointer;
}
</style>
