import { defineStore } from 'pinia'
import { ref } from 'vue'

type ConditionStatusExportParameters = {
    startDate?: string
    endDate?: string
    gasInOilParameterGroup?: string | number
    commentsLength?: number
    recommendationsLength?: number
}

export const useExportStateStore = defineStore('exportStateStore', () => {
    const conditionStatusExportParameters = ref<ConditionStatusExportParameters>({})

    const resetConditionStatusExportParameters = () => {
        conditionStatusExportParameters.value = {}
    }

    return {
        conditionStatusExportParameters,
        resetConditionStatusExportParameters,
    }
})
