<template>
    <div class="content-panel">
        <div
            v-if="headline"
            :class="[
                {
                    'content-panel__header--collapsible': collapsible,
                },
                'content-panel__header',
            ]"
            @click="onCollapseToggle"
        >
            <div class="content-panel__header-title">
                <template v-if="collapsible">
                    <ChevronRight
                        v-if="collapsed"
                        class="content-panel__header-icon"
                    />
                    <ChevronDown
                        v-else
                        class="content-panel__header-icon"
                    />
                </template>
                <span
                    slot="headline"
                    class="headline"
                >
                    {{ headline }}
                </span>
            </div>
            <div class="content-panel__header-context">
                <slot name="context" />
            </div>
        </div>
        <div
            v-show="!collapsed && !hideContent"
            class="content-panel__content"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
import { ChevronRight, ChevronDown } from 'lucide-vue-next'

const collapsed = defineModel<boolean>('collapsed', { default: false })

const props = defineProps<{
    headline?: string
    collapsible?: boolean
    hideContent?: boolean
}>()

const { headline, collapsible } = toRefs(props)

const onCollapseToggle = () => {
    if (!collapsible.value) {
        return
    }

    collapsed.value = !collapsed.value
}
</script>

<style scoped lang="scss">
.content-panel {
    width: 100%;

    display: flex;
    flex-direction: column;

    box-shadow: $pbl-box-shadow;
    background-color: $pbl-foreground;
    border-radius: 4px;

    color: $pbl-text;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 $spacing-s;
        height: 2.5rem;

        border-radius: 4px 4px 0 0;
        border-bottom: 1px solid $pbl-divider;

        &-title {
            display: flex;
            align-items: center;

            gap: $spacing-2xs;
        }

        &-icon {
            height: 20px;
            width: 20px;

            margin-top: -1px;
        }

        &--collapsible {
            cursor: pointer;
            user-select: none;

            &:hover {
                color: $pbl-primary;
            }
        }
    }

    &__content {
        max-height: 100%;
        padding: $spacing-s;
    }

    .headline {
        font-weight: 500;
        font-size: 0.875em;
    }
}
</style>
