<template>
    <div
        v-if="hierarchyData"
        class="selector"
    >
        <FormSingleSelectField
            v-model="issueType"
            :label="t('transformers.portfolioOverview.flaggedTransformers.labels.issueType')"
            :options="issueTypeOptions"
        />
        <FormSingleSelectField
            v-model="groupSid"
            :label="t('transformers.portfolioOverview.flaggedTransformers.labels.group')"
            :options="groupOptions"
        />
        <FormMultiselectField
            :key="JSON.stringify(plantSids)"
            v-model="plantSids"
            input-id="selectedPlant"
            :label="t('transformers.portfolioOverview.flaggedTransformers.labels.plant')"
            :options="plantOptions"
        />
    </div>
</template>

<script setup lang="ts">
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import FormMultiselectField from '@/components/form-inputs/form-multiselect-field/FormMultiselectField.vue'
import { computed, nextTick, watch } from 'vue'
import { IssueTypes } from '@/configuration/issues/issue-types'
import { useTransformersHierarchyQuery } from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { useI18n } from 'vue-i18n'
import { SelectOptions } from '@/components/form-inputs/common/select-option'

const ALL_ITEMS_VALUE = -1

const issueType = defineModel<number | undefined>('issueType')
const groupSid = defineModel<number | undefined>('groupSid')
const plantSids = defineModel<number[]>('plantSids')

const { t } = useI18n()

const { data: hierarchyData } = useTransformersHierarchyQuery()

const issueTypeOptions = computed(() =>
    IssueTypes.map((e) => ({
        label: t(e.labelKey),
        value: e.value,
    })),
)

const groupOptions = computed(() => {
    if (!hierarchyData.value?.plantGroups) {
        return []
    }

    const hierarchyOptions = hierarchyData.value.plantGroups.map((e) => ({
        label: e.name ?? '-',
        value: e.sid ?? -1,
    }))

    const allGroupsOption = {
        label: t('transformers.portfolioOverview.flaggedTransformers.options.allGroups'),
        value: ALL_ITEMS_VALUE,
    }

    return [allGroupsOption, ...hierarchyOptions]
})

const plantOptions = computed(() => {
    if (!hierarchyData.value?.plantGroups) {
        return []
    }

    const options: SelectOptions<number> = []

    if (groupSid.value === ALL_ITEMS_VALUE) {
        hierarchyData.value.plantGroups.forEach((plantGroup) => {
            options.push(
                ...(plantGroup.plants?.map((e) => ({
                    label: e.name ?? '-',
                    value: e.sid ?? -1,
                })) ?? []),
            )
        })
    } else {
        const groupEntry = hierarchyData.value.plantGroups.find((e) => e.sid === groupSid.value)

        const groupPlants =
            groupEntry?.plants?.map((e) => ({
                label: e.name ?? '-',
                value: e.sid ?? -1,
            })) ?? []

        options.push(...groupPlants)
    }

    const allPlantsOption = {
        label: t('transformers.portfolioOverview.flaggedTransformers.options.allPlants'),
        value: ALL_ITEMS_VALUE,
    }

    return [allPlantsOption, ...options]
})

watch([groupSid, plantSids], ([newGroupSid, newPlantSids]) => {
    if (!newGroupSid || !newPlantSids) {
        return
    }

    if (newPlantSids.length !== 1 && newPlantSids.includes(ALL_ITEMS_VALUE)) {
        nextTick(() => {
            plantSids.value = [ALL_ITEMS_VALUE]
        })

        return
    }

    if (newGroupSid === ALL_ITEMS_VALUE) {
        return
    }

    const currentGroupPlantSids =
        hierarchyData.value?.plantGroups?.find((e) => e.sid === newGroupSid)?.plants?.map((e) => e.sid ?? -1) ?? []

    const filteredPlantSids = newPlantSids.filter((e) => e !== ALL_ITEMS_VALUE)

    if (filteredPlantSids.some((e) => !currentGroupPlantSids.includes(e))) {
        nextTick(() => {
            plantSids.value = []
        })
    }
})
</script>

<style scoped lang="scss">
.selector {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    column-gap: $spacing-s;
}
</style>
