<template>
    <VeeFormInputField
        name="englishName"
        :label="t('admin.recommendationTypes.recommendationModal.labels.englishName')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
        :maxlength="MAXLENGTH"
        class="full-width"
    />
    <VeeFormInputField
        name="germanName"
        :label="t('admin.recommendationTypes.recommendationModal.labels.germanName')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
        :maxlength="MAXLENGTH"
        class="full-width"
    />
</template>

<script setup lang="ts">
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const MAXLENGTH = 150
</script>

<style scoped lang="scss">
.full-width {
    grid-column: span 3 / span 3;
}
</style>
