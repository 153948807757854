import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusEditRecommendationTypeModalActions = {
    openEditRecommendation: (id: number) => void
}

const conditionStatusEditRecommendationTypeModalActionsInjectionKey: InjectionKey<ConditionStatusEditRecommendationTypeModalActions> =
    Symbol('conditionStatusEditRecommendationTypeModalActionsInjectionKey')

export const useConditionStatusEditRecommendationTypeModal = () => {
    const actions = inject(conditionStatusEditRecommendationTypeModalActionsInjectionKey)

    if (!actions) {
        throw new Error('Invalid use of ConditionStatusEditRecommendationTypeModal')
    }

    return actions
}

export const provideConditionStatusEditRecommendationTypeModal = (
    actions: ConditionStatusEditRecommendationTypeModalActions,
) => provide(conditionStatusEditRecommendationTypeModalActionsInjectionKey, actions)
