import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusAddRecommendationModalActions = {
    openAddRecommendation: () => void
}

const conditionStatusAddRecommendationModalActionsInjectionKey: InjectionKey<ConditionStatusAddRecommendationModalActions> =
    Symbol('conditionStatusAddRecommendationModalActionsInjectionKey')

export const useConditionStatusAddRecommendationModal = () => {
    return inject(conditionStatusAddRecommendationModalActionsInjectionKey)
}

export const provideConditionStatusAddRecommendationModal = (actions: ConditionStatusAddRecommendationModalActions) =>
    provide(conditionStatusAddRecommendationModalActionsInjectionKey, actions)
