import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'

const commonObjectFields = {
    recommendationTypeId: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
    recommendationFreeText: zod.string().default(''),
    sapNotificationNumber: zod.string(),
    ptRiskId: zod.string(),
    pspNumber: zod.string(),
    sapNotificationUrl: zod.string().url('formInputs.validation.invalidUrl').or(zod.literal('')),
    ptRiskUrl: zod.string().url('formInputs.validation.invalidUrl').or(zod.literal('')),
    pspNumberUrl: zod.string().url('formInputs.validation.invalidUrl').or(zod.literal('')),
}

export const addRecommendationValidationSchema = toTypedSchema(
    zod
        .object({
            ...commonObjectFields,
            comment: zod.string(),
        })
        .superRefine((value, ctx) => {
            if (value.recommendationTypeId === OTHER_RECOMMENDATION_TYPE_ID && !value.recommendationFreeText) {
                ctx.addIssue({
                    code: zod.ZodIssueCode.custom,
                    path: ['recommendationFreeText'],
                    message: 'formInputs.validation.requiredText',
                })
            }
        }),
)

export const editRecommendationValidationSchema = toTypedSchema(
    zod.object({
        ...commonObjectFields,
        recommendationId: zod.number(),
        comments: zod.array(
            zod
                .object({
                    id: zod.number().optional(),
                    text: zod.string(),
                    date: zod.string().optional(),
                    kid: zod.string().optional(),
                })
                .superRefine((value, ctx) => {
                    if (value.id && !value.text) {
                        ctx.addIssue({
                            code: zod.ZodIssueCode.custom,
                            path: ['text'],
                            message: 'formInputs.validation.requiredText',
                        })
                    }
                }),
        ),
    }),
)
