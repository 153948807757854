export const SystemStatusDescriptions = [
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mmab.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mmab.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mofn.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mofn.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mauf.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mauf.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.miar.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.miar.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mmdr.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mmdr.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.lovm.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.lovm.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.amer.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.amer.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mrst.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.mrst.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.pzur.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.pzur.description',
    },
    {
        label: 'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.ofma.title',
        description:
            'transformers.conditionStatus.content.recommendationModal.systemStatusDescription.legend.ofma.description',
    },
] as const
