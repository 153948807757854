<template>
    <ConditionStatusOverrideModal>
        <ConditionStatusCommentModal>
            <SystemStatusDescriptionModal>
                <ConditionStatusViewRecommendationModal>
                    <ConditionStatusEditRecommendationModal>
                        <ConditionStatusAddRecommendationModal>
                            <QueryLoaderGuard
                                :is-pending="isPending"
                                :is-error="isError"
                            >
                                <div class="condition-status-content">
                                    <div class="condition-status-content__master-data-latest-grid">
                                        <MasterDataPanel :transformer-id="transformerId" />
                                        <ConditionStatusLatestReportsSection
                                            class="condition-status-content__master-data-latest-grid__latest-reports"
                                        />
                                    </div>
                                    <ConditionStatusRecommendationSection />
                                    <template v-if="!isSectionHidden">
                                        <ConditionStatusAnalysesSection />
                                        <ConditionStatusCommentsSection />
                                        <ConditionStatusOilHistoricalTrendsSection />
                                    </template>
                                    <ConditionStatusVisualInspectionsSection />
                                </div>
                            </QueryLoaderGuard>
                        </ConditionStatusAddRecommendationModal>
                    </ConditionStatusEditRecommendationModal>
                </ConditionStatusViewRecommendationModal>
            </SystemStatusDescriptionModal>
        </ConditionStatusCommentModal>
    </ConditionStatusOverrideModal>
</template>

<script setup lang="ts">
import ConditionStatusLatestReportsSection from '@/pages/transformers/condition-status/sections/latest-reports/ConditionStatusLatestReportsSection.vue'
import ConditionStatusAnalysesSection from '@/pages/transformers/condition-status/sections/analyses/ConditionStatusAnalysesSection.vue'
import ConditionStatusOverrideModal from '@/pages/transformers/condition-status/modals/override/ConditionStatusOverrideModal.vue'
import ConditionStatusOilHistoricalTrendsSection from '@/pages/transformers/condition-status/sections/oil-historical-trends/ConditionStatusOilHistoricalTrendsSection.vue'
import ConditionStatusVisualInspectionsSection from '@/pages/transformers/condition-status/sections/visual-inspections/ConditionStatusVisualInspectionsSection.vue'
import ConditionStatusCommentModal from '@/pages/transformers/condition-status/modals/comment/ConditionStatusCommentModal.vue'
import ConditionStatusCommentsSection from '@/pages/transformers/condition-status/sections/comments/ConditionStatusCommentsSection.vue'
import { provideTransformerId } from '@/composables/useTransformerId'
import { computed, toRefs, watch } from 'vue'
import ConditionStatusRecommendationSection from '@/pages/transformers/condition-status/sections/recommendations/ConditionStatusRecommendationSection.vue'
import ConditionStatusAddRecommendationModal from '@/pages/transformers/condition-status/modals/recommendation/add-recommendation/ConditionStatusAddRecommendationModal.vue'
import ConditionStatusEditRecommendationModal from '@/pages/transformers/condition-status/modals/recommendation/edit-recommendation/ConditionStatusEditRecommendationModal.vue'
import ConditionStatusViewRecommendationModal from '@/pages/transformers/condition-status/modals/recommendation/view-recommendation/ConditionStatusViewRecommendationModal.vue'
import SystemStatusDescriptionModal from '@/pages/transformers/condition-status/modals/recommendation/system-status-description/SystemStatusDescriptionModal.vue'
import MasterDataPanel from '@/components/master-data/MasterDataPanel.vue'
import { useExportStateStore } from '@/store/exportStateStore'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { useRoute, useRouter } from 'vue-router'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { CONSTRUCTION_TYPE } from '@/utils/master-data-utils'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'

const route = useRoute()
const router = useRouter()

const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const exportStateStore = useExportStateStore()

const props = defineProps<{
    transformerId: string
}>()

const { transformerId } = toRefs(props)

const { data, isPending, isError } = useTransformerDetailsQuery(transformerId)

const isSectionHidden = computed(() => {
    return data.value?.transformerTypeEnumSid === CONSTRUCTION_TYPE.TROCKEN
})

const processScrollState = () => {
    conditionStatusCollapseStore.resetCollapsedState()

    if (!route.query.focused || isNaN(Number(route.query.focused))) {
        return
    }

    const focusedState = Number(route.query.focused)
    conditionStatusCollapseStore.setCollapseScrollState(focusedState)

    router.replace({
        query: {},
    })
}

provideTransformerId(computed(() => props.transformerId))

watch(
    () => props.transformerId,
    () => {
        exportStateStore.resetConditionStatusExportParameters()
        processScrollState()
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.condition-status-content {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;

    &__master-data-latest-grid {
        display: flex;
        gap: $spacing-m;

        &__latest-reports {
            flex: 1;
        }
    }
}
</style>
