/* tslint:disable */
/* eslint-disable */
/**
 * ADAM API 1.0
 * ADAM API
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base'

/**
 *
 * @export
 * @interface ActivityTrackingListDto
 */
export interface ActivityTrackingListDto {
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    componentSid?: string | null
    /**
     *
     * @type {number}
     * @memberof ActivityTrackingListDto
     */
    action?: number
    /**
     *
     * @type {number}
     * @memberof ActivityTrackingListDto
     */
    previousStatus?: number | null
    /**
     *
     * @type {number}
     * @memberof ActivityTrackingListDto
     */
    newStatus?: number | null
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    timestamp?: string
    /**
     *
     * @type {number}
     * @memberof ActivityTrackingListDto
     */
    componentType?: number
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    plantGroupName?: string | null
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    plantName?: string | null
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    componentPageLink?: string | null
    /**
     *
     * @type {string}
     * @memberof ActivityTrackingListDto
     */
    componentName?: string | null
}
/**
 *
 * @export
 * @interface AddBookmarkRequest
 */
export interface AddBookmarkRequest {
    /**
     * The sid of the component to which the bookmark is added.
     * @type {string}
     * @memberof AddBookmarkRequest
     */
    componentSid?: string | null
    /**
     * The component type.
     * @type {number}
     * @memberof AddBookmarkRequest
     */
    componentType?: number
}
/**
 *
 * @export
 * @interface AddBookmarkResponse
 */
export interface AddBookmarkResponse {
    /**
     * The id of the bookmark added.
     * @type {number}
     * @memberof AddBookmarkResponse
     */
    bookmarkId?: number
}
/**
 *
 * @export
 * @interface AddBookmarkResponseOkResponse
 */
export interface AddBookmarkResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof AddBookmarkResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof AddBookmarkResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof AddBookmarkResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {AddBookmarkResponse}
     * @memberof AddBookmarkResponseOkResponse
     */
    result?: AddBookmarkResponse
}
/**
 *
 * @export
 * @interface AddCommentResponse
 */
export interface AddCommentResponse {
    /**
     * The id of the comment added.
     * @type {number}
     * @memberof AddCommentResponse
     */
    commentId?: number
}
/**
 *
 * @export
 * @interface AddCommentResponseOkResponse
 */
export interface AddCommentResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof AddCommentResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof AddCommentResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof AddCommentResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {AddCommentResponse}
     * @memberof AddCommentResponseOkResponse
     */
    result?: AddCommentResponse
}
/**
 *
 * @export
 * @interface AddRecommendationResponse
 */
export interface AddRecommendationResponse {
    /**
     * The id of the recommendation added.
     * @type {number}
     * @memberof AddRecommendationResponse
     */
    recommendationId?: number
}
/**
 *
 * @export
 * @interface AddRecommendationResponseOkResponse
 */
export interface AddRecommendationResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof AddRecommendationResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof AddRecommendationResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof AddRecommendationResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {AddRecommendationResponse}
     * @memberof AddRecommendationResponseOkResponse
     */
    result?: AddRecommendationResponse
}
/**
 *
 * @export
 * @interface AddRecommendationTypeRequest
 */
export interface AddRecommendationTypeRequest {
    /**
     *
     * @type {string}
     * @memberof AddRecommendationTypeRequest
     */
    name: string 
    /**
     *
     * @type {string}
     * @memberof AddRecommendationTypeRequest
     */
    germanName: string
}
/**
 *
 * @export
 * @interface AddRecommendationTypeResponse
 */
export interface AddRecommendationTypeResponse {
    /**
     *
     * @type {number}
     * @memberof AddRecommendationTypeResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface AddRecommendationTypeResponseOkResponse
 */
export interface AddRecommendationTypeResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof AddRecommendationTypeResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof AddRecommendationTypeResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof AddRecommendationTypeResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {AddRecommendationTypeResponse}
     * @memberof AddRecommendationTypeResponseOkResponse
     */
    result?: AddRecommendationTypeResponse
}
/**
 *
 * @export
 * @interface AnalysisDto
 */
export interface AnalysisDto {
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    samplingDate?: string
    /**
     *
     * @type {number}
     * @memberof AnalysisDto
     */
    status?: number
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    samplingPoint?: string | null
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    comment?: string | null
    /**
     *
     * @type {number}
     * @memberof AnalysisDto
     */
    temperature?: number | null
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    sampler?: string | null
    /**
     *
     * @type {boolean}
     * @memberof AnalysisDto
     */
    isExcluded?: boolean
    /**
     *
     * @type {number}
     * @memberof AnalysisDto
     */
    analysisType?: number
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    analyticalNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof AnalysisDto
     */
    note?: string | null
    /**
     *
     * @type {Array<ParameterReportDto>}
     * @memberof AnalysisDto
     */
    parameters?: Array<ParameterReportDto> | null
    /**
     *
     * @type {Array<CommentDto>}
     * @memberof AnalysisDto
     */
    comments?: Array<CommentDto> | null
}
/**
 *
 * @export
 * @interface AssetDto
 */
export interface AssetDto {
    /**
     *
     * @type {number}
     * @memberof AssetDto
     */
    sid?: number
    /**
     *
     * @type {string}
     * @memberof AssetDto
     */
    name?: string | null
}
/**
 *
 * @export
 * @interface BadRequestResponse
 */
export interface BadRequestResponse {
    /**
     *
     * @type {number}
     * @memberof BadRequestResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof BadRequestResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof BadRequestResponse
     */
    message?: string | null
    /**
     *
     * @type {Array<string>}
     * @memberof BadRequestResponse
     */
    errors?: Array<string> | null
}
/**
 *
 * @export
 * @interface BookmarkDto
 */
export interface BookmarkDto {
    /**
     *
     * @type {number}
     * @memberof BookmarkDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof BookmarkDto
     */
    hierarchy?: number
    /**
     *
     * @type {string}
     * @memberof BookmarkDto
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof BookmarkDto
     */
    componentName?: string | null
    /**
     *
     * @type {number}
     * @memberof BookmarkDto
     */
    componentType?: number
}
/**
 *
 * @export
 * @interface BookmarkUpdateDto
 */
export interface BookmarkUpdateDto {
    /**
     *
     * @type {number}
     * @memberof BookmarkUpdateDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof BookmarkUpdateDto
     */
    hierarchy?: number
}
/**
 *
 * @export
 * @interface CommentAddDto
 */
export interface CommentAddDto {
    /**
     *
     * @type {string}
     * @memberof CommentAddDto
     */
    reportNumber: string
    /**
     *
     * @type {number}
     * @memberof CommentAddDto
     */
    analysisType?: number | null
    /**
     *
     * @type {number}
     * @memberof CommentAddDto
     */
    parameterId?: number | null
    /**
     *
     * @type {string}
     * @memberof CommentAddDto
     */
    text: string
}
/**
 *
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     *
     * @type {number}
     * @memberof CommentDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof CommentDto
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof CommentDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof CommentDto
     */
    reportNumber?: string | null
    /**
     *
     * @type {number}
     * @memberof CommentDto
     */
    analysisType?: number | null
    /**
     *
     * @type {number}
     * @memberof CommentDto
     */
    parameterId?: number | null
    /**
     *
     * @type {string}
     * @memberof CommentDto
     */
    text?: string | null
    /**
     *
     * @type {string}
     * @memberof CommentDto
     */
    kid?: string | null
    /**
     *
     * @type {boolean}
     * @memberof CommentDto
     */
    isOverride?: boolean
    /**
     *
     * @type {number}
     * @memberof CommentDto
     */
    previousStatus?: number | null
    /**
     *
     * @type {number}
     * @memberof CommentDto
     */
    newStatus?: number | null
}
/**
 *
 * @export
 * @interface CommentEditDto
 */
export interface CommentEditDto {
    /**
     *
     * @type {string}
     * @memberof CommentEditDto
     */
    text?: string | null
}
/**
 *
 * @export
 * @interface CommentParameterDto
 */
export interface CommentParameterDto {
    /**
     *
     * @type {string}
     * @memberof CommentParameterDto
     */
    text?: string | null
    /**
     *
     * @type {string}
     * @memberof CommentParameterDto
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof CommentParameterDto
     */
    kid?: string | null
}
/**
 *
 * @export
 * @interface ConditionStatusTooltipDto
 */
export interface ConditionStatusTooltipDto {
    /**
     *
     * @type {string}
     * @memberof ConditionStatusTooltipDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof ConditionStatusTooltipDto
     */
    transformerName?: string | null
}
/**
 *
 * @export
 * @interface DeleteBookmarkResponse
 */
export interface DeleteBookmarkResponse {
    /**
     * Id of the deleted entity.
     * @type {number}
     * @memberof DeleteBookmarkResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface DeleteBookmarkResponseOkResponse
 */
export interface DeleteBookmarkResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof DeleteBookmarkResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof DeleteBookmarkResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof DeleteBookmarkResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {DeleteBookmarkResponse}
     * @memberof DeleteBookmarkResponseOkResponse
     */
    result?: DeleteBookmarkResponse
}
/**
 *
 * @export
 * @interface DeleteCommentResponse
 */
export interface DeleteCommentResponse {
    /**
     * Id of the deleted entity.
     * @type {number}
     * @memberof DeleteCommentResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface DeleteCommentResponseOkResponse
 */
export interface DeleteCommentResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof DeleteCommentResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof DeleteCommentResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof DeleteCommentResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {DeleteCommentResponse}
     * @memberof DeleteCommentResponseOkResponse
     */
    result?: DeleteCommentResponse
}
/**
 *
 * @export
 * @interface EditCommentResponse
 */
export interface EditCommentResponse {
    /**
     * Id of the edited comment.
     * @type {number}
     * @memberof EditCommentResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface EditCommentResponseOkResponse
 */
export interface EditCommentResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof EditCommentResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof EditCommentResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof EditCommentResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {EditCommentResponse}
     * @memberof EditCommentResponseOkResponse
     */
    result?: EditCommentResponse
}
/**
 *
 * @export
 * @interface EditRecommendationResponse
 */
export interface EditRecommendationResponse {
    /**
     * The id of the updated recommentdation.
     * @type {number}
     * @memberof EditRecommendationResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface EditRecommendationResponseOkResponse
 */
export interface EditRecommendationResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof EditRecommendationResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof EditRecommendationResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof EditRecommendationResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {EditRecommendationResponse}
     * @memberof EditRecommendationResponseOkResponse
     */
    result?: EditRecommendationResponse
}
/**
 *
 * @export
 * @interface EditRecommendationTypeResponse
 */
export interface EditRecommendationTypeResponse {
    /**
     *
     * @type {number}
     * @memberof EditRecommendationTypeResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface EditRecommendationTypeResponseOkResponse
 */
export interface EditRecommendationTypeResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof EditRecommendationTypeResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof EditRecommendationTypeResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof EditRecommendationTypeResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {EditRecommendationTypeResponse}
     * @memberof EditRecommendationTypeResponseOkResponse
     */
    result?: EditRecommendationTypeResponse
}
/**
 *
 * @export
 * @interface EditThresholdResponse
 */
export interface EditThresholdResponse {
    /**
     * The id of the new threshold.
     * @type {number}
     * @memberof EditThresholdResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface EditThresholdResponseOkResponse
 */
export interface EditThresholdResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof EditThresholdResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof EditThresholdResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof EditThresholdResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {EditThresholdResponse}
     * @memberof EditThresholdResponseOkResponse
     */
    result?: EditThresholdResponse
}
/**
 *
 * @export
 * @interface FlaggedTransformerDto
 */
export interface FlaggedTransformerDto {
    /**
     *
     * @type {string}
     * @memberof FlaggedTransformerDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof FlaggedTransformerDto
     */
    transformerName?: string | null
}
/**
 *
 * @export
 * @interface GetConditionStatusByAgeResponse
 */
export interface GetConditionStatusByAgeResponse {
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusByAgeResponse
     */
    years?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusByAgeResponse
     */
    numberOfNormal?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusByAgeResponse
     */
    numberOfInferior?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusByAgeResponse
     */
    numberOfCritical?: Array<number> | null
    /**
     *
     * @type {Array<{ [key: string]: Array<ConditionStatusTooltipDto>; }>}
     * @memberof GetConditionStatusByAgeResponse
     */
    tooltipData?: Array<{ [key: string]: Array<ConditionStatusTooltipDto> }> | null
}
/**
 *
 * @export
 * @interface GetConditionStatusByAgeResponseOkResponse
 */
export interface GetConditionStatusByAgeResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetConditionStatusByAgeResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusByAgeResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusByAgeResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetConditionStatusByAgeResponse}
     * @memberof GetConditionStatusByAgeResponseOkResponse
     */
    result?: GetConditionStatusByAgeResponse
}
/**
 *
 * @export
 * @interface GetConditionStatusByTransformerResponse
 */
export interface GetConditionStatusByTransformerResponse {
    /**
     *
     * @type {number}
     * @memberof GetConditionStatusByTransformerResponse
     */
    generalStatus?: number
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusByTransformerResponse
     */
    lastSyncedDate?: string | null
    /**
     *
     * @type {Array<LatestConditionStatusDto>}
     * @memberof GetConditionStatusByTransformerResponse
     */
    latestConditionStatuses?: Array<LatestConditionStatusDto> | null
}
/**
 *
 * @export
 * @interface GetConditionStatusByTransformerResponseOkResponse
 */
export interface GetConditionStatusByTransformerResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetConditionStatusByTransformerResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusByTransformerResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusByTransformerResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetConditionStatusByTransformerResponse}
     * @memberof GetConditionStatusByTransformerResponseOkResponse
     */
    result?: GetConditionStatusByTransformerResponse
}
/**
 *
 * @export
 * @interface GetConditionStatusYearlyResponse
 */
export interface GetConditionStatusYearlyResponse {
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusYearlyResponse
     */
    years?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusYearlyResponse
     */
    numberOfNormal?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusYearlyResponse
     */
    numberOfInferior?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetConditionStatusYearlyResponse
     */
    numberOfCritical?: Array<number> | null
    /**
     *
     * @type {Array<{ [key: string]: Array<ConditionStatusTooltipDto>; }>}
     * @memberof GetConditionStatusYearlyResponse
     */
    tooltipData?: Array<{ [key: string]: Array<ConditionStatusTooltipDto> }> | null
}
/**
 *
 * @export
 * @interface GetConditionStatusYearlyResponseOkResponse
 */
export interface GetConditionStatusYearlyResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetConditionStatusYearlyResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusYearlyResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetConditionStatusYearlyResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetConditionStatusYearlyResponse}
     * @memberof GetConditionStatusYearlyResponseOkResponse
     */
    result?: GetConditionStatusYearlyResponse
}
/**
 *
 * @export
 * @interface GetConstantsResponse
 */
export interface GetConstantsResponse {
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    statusTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    analysisTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    unitOfMeasureTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    thresholdOperatorTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    thresholdLastOperatorTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    thresholdCriteriaTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    componentTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    reportTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    languageTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    exportTypes?: { [key: string]: string } | null
    /**
     *
     * @type {{ [key: string]: string; }}
     * @memberof GetConstantsResponse
     */
    transformerAgeTypes?: { [key: string]: string } | null
    /**
     *
     * @type {Array<RecommendationTypesDto>}
     * @memberof GetConstantsResponse
     */
    recommendationTypes?: Array<RecommendationTypesDto> | null
    /**
     *
     * @type {Array<ParameterTypeDto>}
     * @memberof GetConstantsResponse
     */
    parameters?: Array<ParameterTypeDto> | null
    /**
     *
     * @type {Array<VisualInspectionParameterDto>}
     * @memberof GetConstantsResponse
     */
    visualInspectionParameters?: Array<VisualInspectionParameterDto> | null
    /**
     *
     * @type {Array<ParameterGroupDto>}
     * @memberof GetConstantsResponse
     */
    gasInOilParameterGroups?: Array<ParameterGroupDto> | null
}
/**
 *
 * @export
 * @interface GetConstantsResponseOkResponse
 */
export interface GetConstantsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetConstantsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetConstantsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetConstantsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetConstantsResponse}
     * @memberof GetConstantsResponseOkResponse
     */
    result?: GetConstantsResponse
}
/**
 *
 * @export
 * @interface GetMasterDataPlantsResponse
 */
export interface GetMasterDataPlantsResponse {
    /**
     *
     * @type {Array<PlantGroupDto>}
     * @memberof GetMasterDataPlantsResponse
     */
    plantGroups?: Array<PlantGroupDto> | null
}
/**
 *
 * @export
 * @interface GetMasterDataPlantsResponseOkResponse
 */
export interface GetMasterDataPlantsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetMasterDataPlantsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataPlantsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataPlantsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetMasterDataPlantsResponse}
     * @memberof GetMasterDataPlantsResponseOkResponse
     */
    result?: GetMasterDataPlantsResponse
}
/**
 *
 * @export
 * @interface GetMasterDataTransformerDetailsResponse
 */
export interface GetMasterDataTransformerDetailsResponse {
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    transformerName?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    plantGroupSid?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    plantGroupName?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    plantNameShort?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    plantSid?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    plantName?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    storagePlantSid?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    storagePlantName?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    technicalLocation?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    manufacturer?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    manufacturerEnumSid?: number | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    transformerType?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    transformerTypeEnumSid?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    constructionYear?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    nominalPower?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    primaryVoltageOs?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    secondaryVoltageOs?: number | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    coolingSystem?: string | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    coolingSystemEnumSid?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    oilWeight?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    totalWeight?: number | null
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    age?: number | null
    /**
     *
     * @type {boolean}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    isReserve?: boolean
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponse
     */
    decommissioningDate?: string | null
}
/**
 *
 * @export
 * @interface GetMasterDataTransformerDetailsResponseOkResponse
 */
export interface GetMasterDataTransformerDetailsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformerDetailsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformerDetailsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetMasterDataTransformerDetailsResponse}
     * @memberof GetMasterDataTransformerDetailsResponseOkResponse
     */
    result?: GetMasterDataTransformerDetailsResponse
}
/**
 *
 * @export
 * @interface GetMasterDataTransformersResponse
 */
export interface GetMasterDataTransformersResponse {
    /**
     *
     * @type {Array<PlantGroupWithTransformerHierarchyDto>}
     * @memberof GetMasterDataTransformersResponse
     */
    plantGroups?: Array<PlantGroupWithTransformerHierarchyDto> | null
}
/**
 *
 * @export
 * @interface GetMasterDataTransformersResponseOkResponse
 */
export interface GetMasterDataTransformersResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetMasterDataTransformersResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformersResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetMasterDataTransformersResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetMasterDataTransformersResponse}
     * @memberof GetMasterDataTransformersResponseOkResponse
     */
    result?: GetMasterDataTransformersResponse
}
/**
 *
 * @export
 * @interface GetOilHistoricalTrendsResponse
 */
export interface GetOilHistoricalTrendsResponse {
    /**
     *
     * @type {boolean}
     * @memberof GetOilHistoricalTrendsResponse
     */
    hasThresholds?: boolean
    /**
     *
     * @type {Array<{ [key: string]: any; }>}
     * @memberof GetOilHistoricalTrendsResponse
     */
    dataPoints?: Array<{ [key: string]: any }> | null
    /**
     *
     * @type {Array<{ [key: string]: number; }>}
     * @memberof GetOilHistoricalTrendsResponse
     */
    statuses?: Array<{ [key: string]: number }> | null
    /**
     *
     * @type {Array<{ [key: string]: number; }>}
     * @memberof GetOilHistoricalTrendsResponse
     */
    unitsOfMeasure?: Array<{ [key: string]: number }> | null
    /**
     *
     * @type {{ [key: string]: boolean; }}
     * @memberof GetOilHistoricalTrendsResponse
     */
    legend?: { [key: string]: boolean } | null
}
/**
 *
 * @export
 * @interface GetOilHistoricalTrendsResponseOkResponse
 */
export interface GetOilHistoricalTrendsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetOilHistoricalTrendsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetOilHistoricalTrendsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetOilHistoricalTrendsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetOilHistoricalTrendsResponse}
     * @memberof GetOilHistoricalTrendsResponseOkResponse
     */
    result?: GetOilHistoricalTrendsResponse
}
/**
 *
 * @export
 * @interface GetPlantGroupStatusSummaryResponse
 */
export interface GetPlantGroupStatusSummaryResponse {
    /**
     *
     * @type {Array<string>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    plantGroups?: Array<string> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    plantGroupSids?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    numberOfNormalTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    numberOfInferiorTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    numberOfCriticalTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    numberOfNotAssessedTransformers?: Array<number> | null
    /**
     *
     * @type {Array<{ [key: string]: string; }>}
     * @memberof GetPlantGroupStatusSummaryResponse
     */
    tooltipData?: Array<{ [key: string]: string }> | null
}
/**
 *
 * @export
 * @interface GetPlantGroupStatusSummaryResponseOkResponse
 */
export interface GetPlantGroupStatusSummaryResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetPlantGroupStatusSummaryResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetPlantGroupStatusSummaryResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetPlantGroupStatusSummaryResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetPlantGroupStatusSummaryResponse}
     * @memberof GetPlantGroupStatusSummaryResponseOkResponse
     */
    result?: GetPlantGroupStatusSummaryResponse
}
/**
 *
 * @export
 * @interface GetPlantStatusSummaryResponse
 */
export interface GetPlantStatusSummaryResponse {
    /**
     *
     * @type {Array<string>}
     * @memberof GetPlantStatusSummaryResponse
     */
    plants?: Array<string> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantStatusSummaryResponse
     */
    numberOfNormalTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantStatusSummaryResponse
     */
    numberOfInferiorTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantStatusSummaryResponse
     */
    numberOfCriticalTransformers?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof GetPlantStatusSummaryResponse
     */
    numberOfNotAssessedTransformers?: Array<number> | null
    /**
     *
     * @type {Array<{ [key: string]: Array<PlantOverviewToolTipDto>; }>}
     * @memberof GetPlantStatusSummaryResponse
     */
    tooltipData?: Array<{ [key: string]: Array<PlantOverviewToolTipDto> }> | null
}
/**
 *
 * @export
 * @interface GetPlantStatusSummaryResponseOkResponse
 */
export interface GetPlantStatusSummaryResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetPlantStatusSummaryResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetPlantStatusSummaryResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetPlantStatusSummaryResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetPlantStatusSummaryResponse}
     * @memberof GetPlantStatusSummaryResponseOkResponse
     */
    result?: GetPlantStatusSummaryResponse
}
/**
 *
 * @export
 * @interface GetRecommendationResponse
 */
export interface GetRecommendationResponse {
    /**
     *
     * @type {number}
     * @memberof GetRecommendationResponse
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof GetRecommendationResponse
     */
    recommendationTypeId?: number
    /**
     *
     * @type {Array<RecommendationCommentDto>}
     * @memberof GetRecommendationResponse
     */
    comments?: Array<RecommendationCommentDto> | null
    /**
     *
     * @type {RecommendationSapNotificationDto}
     * @memberof GetRecommendationResponse
     */
    sapNotifcation?: RecommendationSapNotificationDto
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    powerPlant?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    sapNotificationNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    pspNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    ptRiskId?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    sapNotificationUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    ptRiskUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    pspNumberUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    freeText?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponse
     */
    kid?: string | null
}
/**
 *
 * @export
 * @interface GetRecommendationResponseOkResponse
 */
export interface GetRecommendationResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetRecommendationResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetRecommendationResponse}
     * @memberof GetRecommendationResponseOkResponse
     */
    result?: GetRecommendationResponse
}
/**
 *
 * @export
 * @interface GetRecommendationTypeResponse
 */
export interface GetRecommendationTypeResponse {
    /**
     *
     * @type {number}
     * @memberof GetRecommendationTypeResponse
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof GetRecommendationTypeResponse
     */
    englishName?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationTypeResponse
     */
    germanName?: string | null
}
/**
 *
 * @export
 * @interface GetRecommendationTypeResponseOkResponse
 */
export interface GetRecommendationTypeResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetRecommendationTypeResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetRecommendationTypeResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetRecommendationTypeResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetRecommendationTypeResponse}
     * @memberof GetRecommendationTypeResponseOkResponse
     */
    result?: GetRecommendationTypeResponse
}
/**
 *
 * @export
 * @interface GetReportResponse
 */
export interface GetReportResponse {
    /**
     *
     * @type {string}
     * @memberof GetReportResponse
     */
    reportId?: string | null
    /**
     *
     * @type {number}
     * @memberof GetReportResponse
     */
    status?: number
    /**
     *
     * @type {number}
     * @memberof GetReportResponse
     */
    reportType?: number
    /**
     *
     * @type {boolean}
     * @memberof GetReportResponse
     */
    isExcluded?: boolean
    /**
     *
     * @type {boolean}
     * @memberof GetReportResponse
     */
    canBeExcluded?: boolean
    /**
     *
     * @type {Array<AnalysisDto>}
     * @memberof GetReportResponse
     */
    data?: Array<AnalysisDto> | null
}
/**
 *
 * @export
 * @interface GetReportResponseOkResponse
 */
export interface GetReportResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetReportResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetReportResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetReportResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetReportResponse}
     * @memberof GetReportResponseOkResponse
     */
    result?: GetReportResponse
}
/**
 *
 * @export
 * @interface GetStatusSummaryResponse
 */
export interface GetStatusSummaryResponse {
    /**
     *
     * @type {{ [key: string]: number; }}
     * @memberof GetStatusSummaryResponse
     */
    statusSummary?: { [key: string]: number } | null
}
/**
 *
 * @export
 * @interface GetStatusSummaryResponseOkResponse
 */
export interface GetStatusSummaryResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetStatusSummaryResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetStatusSummaryResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetStatusSummaryResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetStatusSummaryResponse}
     * @memberof GetStatusSummaryResponseOkResponse
     */
    result?: GetStatusSummaryResponse
}
/**
 *
 * @export
 * @interface GetThresholdResponse
 */
export interface GetThresholdResponse {
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    normalValue?: number
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    normalOperatorType?: number
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    inferiorOperatorType?: number
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criticalValue?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criticalOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criticalLastValue?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criticalLastOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    inferiorLastValue?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    inferiorLastOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    parameterId?: number
    /**
     *
     * @type {string}
     * @memberof GetThresholdResponse
     */
    criteriaDescription?: string | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criteriaFieldType?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criteriaOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponse
     */
    criteriaValue?: number | null
}
/**
 *
 * @export
 * @interface GetThresholdResponseOkResponse
 */
export interface GetThresholdResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof GetThresholdResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof GetThresholdResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof GetThresholdResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {GetThresholdResponse}
     * @memberof GetThresholdResponseOkResponse
     */
    result?: GetThresholdResponse
}
/**
 *
 * @export
 * @interface HttpStatusResponse
 */
export interface HttpStatusResponse {
    /**
     *
     * @type {number}
     * @memberof HttpStatusResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof HttpStatusResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof HttpStatusResponse
     */
    message?: string | null
    /**
     *
     * @type {Array<string>}
     * @memberof HttpStatusResponse
     */
    errors?: Array<string> | null
}
/**
 *
 * @export
 * @interface InternalServerErrorResponse
 */
export interface InternalServerErrorResponse {
    /**
     *
     * @type {number}
     * @memberof InternalServerErrorResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof InternalServerErrorResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof InternalServerErrorResponse
     */
    message?: string | null
    /**
     *
     * @type {string}
     * @memberof InternalServerErrorResponse
     */
    details?: string | null
}
/**
 *
 * @export
 * @interface LatestConditionStatusDto
 */
export interface LatestConditionStatusDto {
    /**
     *
     * @type {string}
     * @memberof LatestConditionStatusDto
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof LatestConditionStatusDto
     */
    date?: string
    /**
     *
     * @type {number}
     * @memberof LatestConditionStatusDto
     */
    reportType?: number
    /**
     *
     * @type {string}
     * @memberof LatestConditionStatusDto
     */
    reportId?: string | null
    /**
     *
     * @type {number}
     * @memberof LatestConditionStatusDto
     */
    status?: number
    /**
     *
     * @type {string}
     * @memberof LatestConditionStatusDto
     */
    nextReportDate?: string
    /**
     *
     * @type {boolean}
     * @memberof LatestConditionStatusDto
     */
    isNextDateDue?: boolean
}
/**
 *
 * @export
 * @interface LimitDto
 */
export interface LimitDto {
    /**
     *
     * @type {number}
     * @memberof LimitDto
     */
    parameterId?: number
    /**
     *
     * @type {number}
     * @memberof LimitDto
     */
    limit?: number
}
/**
 *
 * @export
 * @interface ListBookmarksResponse
 */
export interface ListBookmarksResponse {
    /**
     *
     * @type {Array<BookmarkDto>}
     * @memberof ListBookmarksResponse
     */
    bookmarks?: Array<BookmarkDto> | null
}
/**
 *
 * @export
 * @interface ListBookmarksResponseOkResponse
 */
export interface ListBookmarksResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListBookmarksResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListBookmarksResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListBookmarksResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListBookmarksResponse}
     * @memberof ListBookmarksResponseOkResponse
     */
    result?: ListBookmarksResponse
}
/**
 *
 * @export
 * @interface ListCommentsResponse
 */
export interface ListCommentsResponse {
    /**
     *
     * @type {Array<CommentDto>}
     * @memberof ListCommentsResponse
     */
    comments?: Array<CommentDto> | null
    /**
     *
     * @type {number}
     * @memberof ListCommentsResponse
     */
    total?: number
}
/**
 *
 * @export
 * @interface ListCommentsResponseOkResponse
 */
export interface ListCommentsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListCommentsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListCommentsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListCommentsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListCommentsResponse}
     * @memberof ListCommentsResponseOkResponse
     */
    result?: ListCommentsResponse
}
/**
 *
 * @export
 * @interface ListComponentRecommendationsResponse
 */
export interface ListComponentRecommendationsResponse {
    /**
     *
     * @type {Array<RecommendationDto>}
     * @memberof ListComponentRecommendationsResponse
     */
    items?: Array<RecommendationDto> | null
    /**
     *
     * @type {number}
     * @memberof ListComponentRecommendationsResponse
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface ListComponentRecommendationsResponseOkResponse
 */
export interface ListComponentRecommendationsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListComponentRecommendationsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListComponentRecommendationsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListComponentRecommendationsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListComponentRecommendationsResponse}
     * @memberof ListComponentRecommendationsResponseOkResponse
     */
    result?: ListComponentRecommendationsResponse
}
/**
 *
 * @export
 * @interface ListFlaggedTransformersResponse
 */
export interface ListFlaggedTransformersResponse {
    /**
     *
     * @type {Array<FlaggedTransformerDto>}
     * @memberof ListFlaggedTransformersResponse
     */
    items?: Array<FlaggedTransformerDto> | null
    /**
     *
     * @type {number}
     * @memberof ListFlaggedTransformersResponse
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface ListFlaggedTransformersResponseOkResponse
 */
export interface ListFlaggedTransformersResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListFlaggedTransformersResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListFlaggedTransformersResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListFlaggedTransformersResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListFlaggedTransformersResponse}
     * @memberof ListFlaggedTransformersResponseOkResponse
     */
    result?: ListFlaggedTransformersResponse
}
/**
 *
 * @export
 * @interface ListOilAnalysisReportsByTransformerResponse
 */
export interface ListOilAnalysisReportsByTransformerResponse {
    /**
     *
     * @type {number}
     * @memberof ListOilAnalysisReportsByTransformerResponse
     */
    status?: number
    /**
     *
     * @type {Array<ReportDataDto>}
     * @memberof ListOilAnalysisReportsByTransformerResponse
     */
    reports?: Array<ReportDataDto> | null
    /**
     *
     * @type {Array<LimitDto>}
     * @memberof ListOilAnalysisReportsByTransformerResponse
     */
    limits?: Array<LimitDto> | null
}
/**
 *
 * @export
 * @interface ListOilAnalysisReportsByTransformerResponseOkResponse
 */
export interface ListOilAnalysisReportsByTransformerResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListOilAnalysisReportsByTransformerResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListOilAnalysisReportsByTransformerResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListOilAnalysisReportsByTransformerResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListOilAnalysisReportsByTransformerResponse}
     * @memberof ListOilAnalysisReportsByTransformerResponseOkResponse
     */
    result?: ListOilAnalysisReportsByTransformerResponse
}
/**
 *
 * @export
 * @interface ListPortfolioEventsResponse
 */
export interface ListPortfolioEventsResponse {
    /**
     *
     * @type {Array<ActivityTrackingListDto>}
     * @memberof ListPortfolioEventsResponse
     */
    items?: Array<ActivityTrackingListDto> | null
    /**
     *
     * @type {number}
     * @memberof ListPortfolioEventsResponse
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface ListPortfolioEventsResponseOkResponse
 */
export interface ListPortfolioEventsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListPortfolioEventsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListPortfolioEventsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListPortfolioEventsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListPortfolioEventsResponse}
     * @memberof ListPortfolioEventsResponseOkResponse
     */
    result?: ListPortfolioEventsResponse
}
/**
 *
 * @export
 * @interface ListRecommendationTypesResponse
 */
export interface ListRecommendationTypesResponse {
    /**
     *
     * @type {RecommendationTypesDtoPaginatedResponseDto}
     * @memberof ListRecommendationTypesResponse
     */
    recommendationTypes?: RecommendationTypesDtoPaginatedResponseDto
}
/**
 *
 * @export
 * @interface ListRecommendationTypesResponseOkResponse
 */
export interface ListRecommendationTypesResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListRecommendationTypesResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListRecommendationTypesResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListRecommendationTypesResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListRecommendationTypesResponse}
     * @memberof ListRecommendationTypesResponseOkResponse
     */
    result?: ListRecommendationTypesResponse
}
/**
 *
 * @export
 * @interface ListRecommendationsByComponentTypeResponse
 */
export interface ListRecommendationsByComponentTypeResponse {
    /**
     *
     * @type {Array<RecommendationComponentListDto>}
     * @memberof ListRecommendationsByComponentTypeResponse
     */
    items?: Array<RecommendationComponentListDto> | null
    /**
     *
     * @type {number}
     * @memberof ListRecommendationsByComponentTypeResponse
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface ListRecommendationsByComponentTypeResponseOkResponse
 */
export interface ListRecommendationsByComponentTypeResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListRecommendationsByComponentTypeResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListRecommendationsByComponentTypeResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListRecommendationsByComponentTypeResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListRecommendationsByComponentTypeResponse}
     * @memberof ListRecommendationsByComponentTypeResponseOkResponse
     */
    result?: ListRecommendationsByComponentTypeResponse
}
/**
 *
 * @export
 * @interface ListReportNumbersResponse
 */
export interface ListReportNumbersResponse {
    /**
     *
     * @type {Array<ReportNumberDto>}
     * @memberof ListReportNumbersResponse
     */
    reportNumbers?: Array<ReportNumberDto> | null
    /**
     *
     * @type {number}
     * @memberof ListReportNumbersResponse
     */
    total?: number
}
/**
 *
 * @export
 * @interface ListReportNumbersResponseOkResponse
 */
export interface ListReportNumbersResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListReportNumbersResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListReportNumbersResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListReportNumbersResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListReportNumbersResponse}
     * @memberof ListReportNumbersResponseOkResponse
     */
    result?: ListReportNumbersResponse
}
/**
 *
 * @export
 * @interface ListReportsResponse
 */
export interface ListReportsResponse {
    /**
     *
     * @type {number}
     * @memberof ListReportsResponse
     */
    total?: number
    /**
     *
     * @type {Array<ReportDto>}
     * @memberof ListReportsResponse
     */
    reports?: Array<ReportDto> | null
}
/**
 *
 * @export
 * @interface ListReportsResponseOkResponse
 */
export interface ListReportsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListReportsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListReportsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListReportsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListReportsResponse}
     * @memberof ListReportsResponseOkResponse
     */
    result?: ListReportsResponse
}
/**
 *
 * @export
 * @interface ListThresholdsResponse
 */
export interface ListThresholdsResponse {
    /**
     *
     * @type {Array<ThresholdsAnalysisDto>}
     * @memberof ListThresholdsResponse
     */
    thresholdsAnalysis?: Array<ThresholdsAnalysisDto> | null
}
/**
 *
 * @export
 * @interface ListThresholdsResponseOkResponse
 */
export interface ListThresholdsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListThresholdsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListThresholdsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListThresholdsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListThresholdsResponse}
     * @memberof ListThresholdsResponseOkResponse
     */
    result?: ListThresholdsResponse
}
/**
 *
 * @export
 * @interface ListVisualInspectionsResponse
 */
export interface ListVisualInspectionsResponse {
    /**
     *
     * @type {Array<VisualInspectionDataDto>}
     * @memberof ListVisualInspectionsResponse
     */
    items?: Array<VisualInspectionDataDto> | null
    /**
     *
     * @type {number}
     * @memberof ListVisualInspectionsResponse
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface ListVisualInspectionsResponseOkResponse
 */
export interface ListVisualInspectionsResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof ListVisualInspectionsResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof ListVisualInspectionsResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof ListVisualInspectionsResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {ListVisualInspectionsResponse}
     * @memberof ListVisualInspectionsResponseOkResponse
     */
    result?: ListVisualInspectionsResponse
}
/**
 *
 * @export
 * @interface NotFoundResponse
 */
export interface NotFoundResponse {
    /**
     *
     * @type {number}
     * @memberof NotFoundResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof NotFoundResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof NotFoundResponse
     */
    message?: string | null
}
/**
 *
 * @export
 * @interface OverrideCommentDto
 */
export interface OverrideCommentDto {
    /**
     *
     * @type {string}
     * @memberof OverrideCommentDto
     */
    text?: string | null
    /**
     *
     * @type {string}
     * @memberof OverrideCommentDto
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof OverrideCommentDto
     */
    kid?: string | null
    /**
     *
     * @type {number}
     * @memberof OverrideCommentDto
     */
    previousStatus?: number
    /**
     *
     * @type {number}
     * @memberof OverrideCommentDto
     */
    newStatus?: number
}
/**
 *
 * @export
 * @interface OverrideParameterStatusResponse
 */
export interface OverrideParameterStatusResponse {
    /**
     * The id of the updated parameter value.
     * @type {number}
     * @memberof OverrideParameterStatusResponse
     */
    id?: number
}
/**
 *
 * @export
 * @interface OverrideParameterStatusResponseOkResponse
 */
export interface OverrideParameterStatusResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof OverrideParameterStatusResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof OverrideParameterStatusResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof OverrideParameterStatusResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {OverrideParameterStatusResponse}
     * @memberof OverrideParameterStatusResponseOkResponse
     */
    result?: OverrideParameterStatusResponse
}
/**
 *
 * @export
 * @interface OverrideStatusDto
 */
export interface OverrideStatusDto {
    /**
     *
     * @type {number}
     * @memberof OverrideStatusDto
     */
    newStatus?: number
    /**
     *
     * @type {string}
     * @memberof OverrideStatusDto
     */
    comment?: string | null
    /**
     *
     * @type {string}
     * @memberof OverrideStatusDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {number}
     * @memberof OverrideStatusDto
     */
    analysisType?: number
}
/**
 *
 * @export
 * @interface ParameterDto
 */
export interface ParameterDto {
    /**
     *
     * @type {number}
     * @memberof ParameterDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof ParameterDto
     */
    parameterId?: number
    /**
     *
     * @type {number}
     * @memberof ParameterDto
     */
    status?: number
    /**
     *
     * @type {CommentParameterDto}
     * @memberof ParameterDto
     */
    latestComment?: CommentParameterDto
    /**
     *
     * @type {string}
     * @memberof ParameterDto
     */
    comment?: string | null
    /**
     *
     * @type {number}
     * @memberof ParameterDto
     */
    numberValue?: number | null
    /**
     *
     * @type {string}
     * @memberof ParameterDto
     */
    stringValue?: string | null
    /**
     *
     * @type {OverrideCommentDto}
     * @memberof ParameterDto
     */
    overrideComment?: OverrideCommentDto
}
/**
 *
 * @export
 * @interface ParameterGroupDto
 */
export interface ParameterGroupDto {
    /**
     *
     * @type {string}
     * @memberof ParameterGroupDto
     */
    name?: string | null
    /**
     *
     * @type {boolean}
     * @memberof ParameterGroupDto
     */
    mustIncludeTemperature?: boolean
    /**
     *
     * @type {number}
     * @memberof ParameterGroupDto
     */
    analysisType?: number
    /**
     *
     * @type {Array<number>}
     * @memberof ParameterGroupDto
     */
    parameterIds?: Array<number> | null
    /**
     *
     * @type {Array<number>}
     * @memberof ParameterGroupDto
     */
    denominatorParameterIds?: Array<number> | null
}
/**
 *
 * @export
 * @interface ParameterReportDto
 */
export interface ParameterReportDto {
    /**
     *
     * @type {number}
     * @memberof ParameterReportDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof ParameterReportDto
     */
    parameterId?: number
    /**
     *
     * @type {number}
     * @memberof ParameterReportDto
     */
    status?: number
    /**
     *
     * @type {CommentParameterDto}
     * @memberof ParameterReportDto
     */
    latestComment?: CommentParameterDto
    /**
     *
     * @type {string}
     * @memberof ParameterReportDto
     */
    comment?: string | null
    /**
     *
     * @type {number}
     * @memberof ParameterReportDto
     */
    numberValue?: number | null
    /**
     *
     * @type {string}
     * @memberof ParameterReportDto
     */
    stringValue?: string | null
    /**
     *
     * @type {OverrideCommentDto}
     * @memberof ParameterReportDto
     */
    overrideComment?: OverrideCommentDto
    /**
     *
     * @type {number}
     * @memberof ParameterReportDto
     */
    limit?: number | null
    /**
     *
     * @type {Array<CommentDto>}
     * @memberof ParameterReportDto
     */
    comments?: Array<CommentDto> | null
}
/**
 *
 * @export
 * @interface ParameterTypeDto
 */
export interface ParameterTypeDto {
    /**
     *
     * @type {number}
     * @memberof ParameterTypeDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof ParameterTypeDto
     */
    analysisType?: number
    /**
     *
     * @type {string}
     * @memberof ParameterTypeDto
     */
    englishName?: string | null
    /**
     *
     * @type {string}
     * @memberof ParameterTypeDto
     */
    germanName?: string | null
    /**
     *
     * @type {number}
     * @memberof ParameterTypeDto
     */
    unitOfMeasureType?: number
    /**
     *
     * @type {string}
     * @memberof ParameterTypeDto
     */
    shortName?: string | null
    /**
     *
     * @type {boolean}
     * @memberof ParameterTypeDto
     */
    canBeOverwritten?: boolean
    /**
     *
     * @type {number}
     * @memberof ParameterTypeDto
     */
    hierarchyOrderId?: number | null
    /**
     *
     * @type {boolean}
     * @memberof ParameterTypeDto
     */
    isDisplayShortName?: boolean
    /**
     *
     * @type {string}
     * @memberof ParameterTypeDto
     */
    englishAutomaticNotice?: string | null
    /**
     *
     * @type {string}
     * @memberof ParameterTypeDto
     */
    germanAutomaticNotice?: string | null
}
/**
 *
 * @export
 * @interface PingGetResponse
 */
export interface PingGetResponse {
    /**
     *
     * @type {number}
     * @memberof PingGetResponse
     */
    timestamp?: number
}
/**
 *
 * @export
 * @interface PingGetResponseOkResponse
 */
export interface PingGetResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof PingGetResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof PingGetResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof PingGetResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {PingGetResponse}
     * @memberof PingGetResponseOkResponse
     */
    result?: PingGetResponse
}
/**
 *
 * @export
 * @interface PlantGroupDto
 */
export interface PlantGroupDto {
    /**
     *
     * @type {number}
     * @memberof PlantGroupDto
     */
    sid?: number
    /**
     *
     * @type {string}
     * @memberof PlantGroupDto
     */
    name?: string | null
    /**
     *
     * @type {Array<AssetDto>}
     * @memberof PlantGroupDto
     */
    plants?: Array<AssetDto> | null
}
/**
 *
 * @export
 * @interface PlantGroupWithTransformerHierarchyDto
 */
export interface PlantGroupWithTransformerHierarchyDto {
    /**
     *
     * @type {number}
     * @memberof PlantGroupWithTransformerHierarchyDto
     */
    sid?: number
    /**
     *
     * @type {string}
     * @memberof PlantGroupWithTransformerHierarchyDto
     */
    name?: string | null
    /**
     *
     * @type {Array<PlantWithTransformersDto>}
     * @memberof PlantGroupWithTransformerHierarchyDto
     */
    plants?: Array<PlantWithTransformersDto> | null
}
/**
 *
 * @export
 * @interface PlantOverviewToolTipDto
 */
export interface PlantOverviewToolTipDto {
    /**
     *
     * @type {string}
     * @memberof PlantOverviewToolTipDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof PlantOverviewToolTipDto
     */
    transformerName?: string | null
    /**
     *
     * @type {string}
     * @memberof PlantOverviewToolTipDto
     */
    transformerComponentStatusLink?: string | null
}
/**
 *
 * @export
 * @interface PlantWithTransformersDto
 */
export interface PlantWithTransformersDto {
    /**
     *
     * @type {number}
     * @memberof PlantWithTransformersDto
     */
    sid?: number
    /**
     *
     * @type {string}
     * @memberof PlantWithTransformersDto
     */
    name?: string | null
    /**
     *
     * @type {Array<TransformerIdDto>}
     * @memberof PlantWithTransformersDto
     */
    transformers?: Array<TransformerIdDto> | null
}
/**
 *
 * @export
 * @interface RecommendationAddDto
 */
export interface RecommendationAddDto {
    /**
     *
     * @type {Array<string>}
     * @memberof RecommendationAddDto
     */
    comments?: Array<string> | null
    /**
     *
     * @type {number}
     * @memberof RecommendationAddDto
     */
    recommendationTypeId?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    freeText?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    sapNotificationNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    ptRiskId?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    pspNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    sapNotificationUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    ptRiskUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationAddDto
     */
    pspNumberUrl?: string | null
}
/**
 *
 * @export
 * @interface RecommendationCommentDto
 */
export interface RecommendationCommentDto {
    /**
     *
     * @type {number}
     * @memberof RecommendationCommentDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationCommentDto
     */
    text?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationCommentDto
     */
    kid?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationCommentDto
     */
    date?: string
}
/**
 *
 * @export
 * @interface RecommendationCommentEditDto
 */
export interface RecommendationCommentEditDto {
    /**
     *
     * @type {number}
     * @memberof RecommendationCommentEditDto
     */
    id?: number | null
    /**
     *
     * @type {string}
     * @memberof RecommendationCommentEditDto
     */
    text?: string | null
}
/**
 *
 * @export
 * @interface RecommendationComponentListDto
 */
export interface RecommendationComponentListDto {
    /**
     *
     * @type {number}
     * @memberof RecommendationComponentListDto
     */
    recommendationId?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    plantGroupName?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    plantName?: string | null
    /**
     *
     * @type {number}
     * @memberof RecommendationComponentListDto
     */
    componentType?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    componentName?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    componentLink?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    date?: string
    /**
     *
     * @type {RecommendationCommentDto}
     * @memberof RecommendationComponentListDto
     */
    recommendationComment?: RecommendationCommentDto
    /**
     *
     * @type {number}
     * @memberof RecommendationComponentListDto
     */
    recommendationTypeId?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    sapNotificationNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    sapNotificationUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    ptRiskId?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    ptRiskUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    pspNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    pspNumberUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    freeText?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationComponentListDto
     */
    createdByKid?: string | null
}
/**
 *
 * @export
 * @interface RecommendationDto
 */
export interface RecommendationDto {
    /**
     *
     * @type {number}
     * @memberof RecommendationDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    date?: string
    /**
     *
     * @type {number}
     * @memberof RecommendationDto
     */
    recommendationTypeId?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    freeText?: string | null
    /**
     *
     * @type {RecommendationCommentDto}
     * @memberof RecommendationDto
     */
    comment?: RecommendationCommentDto
    /**
     *
     * @type {RecommendationSapNotificationDto}
     * @memberof RecommendationDto
     */
    sapNotifcation?: RecommendationSapNotificationDto
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    sapNotificationNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    pspNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    ptRiskId?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    sapNotificationUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    ptRiskUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    pspNumberUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationDto
     */
    kid?: string | null
}
/**
 *
 * @export
 * @interface RecommendationEditDto
 */
export interface RecommendationEditDto {
    /**
     *
     * @type {Array<RecommendationCommentEditDto>}
     * @memberof RecommendationEditDto
     */
    comments?: Array<RecommendationCommentEditDto> | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    ptRiskId?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    pspNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    sapNotificationNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    sapNotificationUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    ptRiskUrl?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationEditDto
     */
    pspNumberUrl?: string | null
}
/**
 *
 * @export
 * @interface RecommendationSapNotificationDto
 */
export interface RecommendationSapNotificationDto {
    /**
     *
     * @type {string}
     * @memberof RecommendationSapNotificationDto
     */
    notificationId?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationSapNotificationDto
     */
    notificationDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationSapNotificationDto
     */
    author?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationSapNotificationDto
     */
    creationDate?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationSapNotificationDto
     */
    systemStatusTextDe?: string | null
}
/**
 *
 * @export
 * @interface RecommendationTypeRequestDto
 */
export interface RecommendationTypeRequestDto {
    /**
     *
     * @type {string}
     * @memberof RecommendationTypeRequestDto
     */
    name?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationTypeRequestDto
     */
    germanName?: string | null
}
/**
 *
 * @export
 * @interface RecommendationTypesDto
 */
export interface RecommendationTypesDto {
    /**
     *
     * @type {number}
     * @memberof RecommendationTypesDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof RecommendationTypesDto
     */
    englishName?: string | null
    /**
     *
     * @type {string}
     * @memberof RecommendationTypesDto
     */
    germanName?: string | null
}
/**
 *
 * @export
 * @interface RecommendationTypesDtoPaginatedResponseDto
 */
export interface RecommendationTypesDtoPaginatedResponseDto {
    /**
     *
     * @type {Array<RecommendationTypesDto>}
     * @memberof RecommendationTypesDtoPaginatedResponseDto
     */
    items?: Array<RecommendationTypesDto> | null
    /**
     *
     * @type {number}
     * @memberof RecommendationTypesDtoPaginatedResponseDto
     */
    totalCount?: number
}
/**
 *
 * @export
 * @interface RegisteredServicesResponse
 */
export interface RegisteredServicesResponse {
    /**
     *
     * @type {Array<ServiceDto>}
     * @memberof RegisteredServicesResponse
     */
    items?: Array<ServiceDto> | null
}
/**
 *
 * @export
 * @interface RegisteredServicesResponseOkResponse
 */
export interface RegisteredServicesResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof RegisteredServicesResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof RegisteredServicesResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof RegisteredServicesResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {RegisteredServicesResponse}
     * @memberof RegisteredServicesResponseOkResponse
     */
    result?: RegisteredServicesResponse
}
/**
 *
 * @export
 * @interface ReportDataDto
 */
export interface ReportDataDto {
    /**
     *
     * @type {string}
     * @memberof ReportDataDto
     */
    analyticalNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportDataDto
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof ReportDataDto
     */
    samplingPoint?: string | null
    /**
     *
     * @type {number}
     * @memberof ReportDataDto
     */
    temperature?: number | null
    /**
     *
     * @type {number}
     * @memberof ReportDataDto
     */
    status?: number
    /**
     *
     * @type {Array<ParameterDto>}
     * @memberof ReportDataDto
     */
    parameters?: Array<ParameterDto> | null
}
/**
 *
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    groupName?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    plantName?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    componentSid?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    componentName?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    date?: string
    /**
     *
     * @type {number}
     * @memberof ReportDto
     */
    reportType?: number
    /**
     *
     * @type {number}
     * @memberof ReportDto
     */
    status?: number
    /**
     *
     * @type {string}
     * @memberof ReportDto
     */
    reportNumber?: string | null
    /**
     *
     * @type {boolean}
     * @memberof ReportDto
     */
    isExcluded?: boolean
    /**
     *
     * @type {boolean}
     * @memberof ReportDto
     */
    canBeExcluded?: boolean
    /**
     *
     * @type {number}
     * @memberof ReportDto
     */
    componentType?: number
}
/**
 *
 * @export
 * @interface ReportNumberDto
 */
export interface ReportNumberDto {
    /**
     *
     * @type {string}
     * @memberof ReportNumberDto
     */
    id?: string | null
    /**
     *
     * @type {string}
     * @memberof ReportNumberDto
     */
    date?: string
}
/**
 *
 * @export
 * @interface ServiceDto
 */
export interface ServiceDto {
    /**
     *
     * @type {string}
     * @memberof ServiceDto
     */
    serviceType?: string | null
    /**
     *
     * @type {string}
     * @memberof ServiceDto
     */
    implementationType?: string | null
    /**
     *
     * @type {ServiceLifetime}
     * @memberof ServiceDto
     */
    lifetime?: ServiceLifetime
}

/**
 *   0 = Singleton  1 = Scoped  2 = Transient
 * @export
 * @enum {string}
 */

export const ServiceLifetime = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
} as const

export type ServiceLifetime = (typeof ServiceLifetime)[keyof typeof ServiceLifetime]

/**
 *
 * @export
 * @interface SetIsExcludedResponse
 */
export interface SetIsExcludedResponse {
    /**
     *
     * @type {boolean}
     * @memberof SetIsExcludedResponse
     */
    isExcluded?: boolean
}
/**
 *
 * @export
 * @interface SetIsExcludedResponseOkResponse
 */
export interface SetIsExcludedResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof SetIsExcludedResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof SetIsExcludedResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof SetIsExcludedResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {SetIsExcludedResponse}
     * @memberof SetIsExcludedResponseOkResponse
     */
    result?: SetIsExcludedResponse
}
/**
 *   1 = Plant  2 = ReportType  3 = Status  4 = Excluded
 * @export
 * @enum {string}
 */

export const SortProjectType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
} as const

export type SortProjectType = (typeof SortProjectType)[keyof typeof SortProjectType]

/**
 *
 * @export
 * @interface SyncDataResponse
 */
export interface SyncDataResponse {
    /**
     *
     * @type {boolean}
     * @memberof SyncDataResponse
     */
    importWasSuccessful?: boolean
}
/**
 *
 * @export
 * @interface SyncDataResponseOkResponse
 */
export interface SyncDataResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof SyncDataResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof SyncDataResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof SyncDataResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {SyncDataResponse}
     * @memberof SyncDataResponseOkResponse
     */
    result?: SyncDataResponse
}
/**
 *
 * @export
 * @interface ThresholdEditDto
 */
export interface ThresholdEditDto {
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    normalValue?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    normalOperatorType?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    inferiorOperatorType?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    criticalValue?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    criticalOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    criticalLastValue?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    criticalLastOperatorType?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    inferiorLastValue?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdEditDto
     */
    inferiorLastOperatorType?: number | null
}
/**
 *
 * @export
 * @interface ThresholdListDto
 */
export interface ThresholdListDto {
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    parameterId?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    criteriaFieldType?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    criteriaOperatorType?: number | null
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    criteriaDescription?: string | null
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    criteriaValue?: number | null
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    limit?: number
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    normalValue?: string | null
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    inferiorValue?: string | null
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    criticalValue?: string | null
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    validFrom?: string
    /**
     *
     * @type {string}
     * @memberof ThresholdListDto
     */
    validTo?: string
    /**
     *
     * @type {number}
     * @memberof ThresholdListDto
     */
    analysisType?: number | null
}
/**
 *
 * @export
 * @interface ThresholdsAnalysisDto
 */
export interface ThresholdsAnalysisDto {
    /**
     *
     * @type {number}
     * @memberof ThresholdsAnalysisDto
     */
    analysisType?: number
    /**
     *
     * @type {number}
     * @memberof ThresholdsAnalysisDto
     */
    cycleYears?: number
    /**
     *
     * @type {Array<ThresholdListDto>}
     * @memberof ThresholdsAnalysisDto
     */
    thresholds?: Array<ThresholdListDto> | null
}
/**
 *
 * @export
 * @interface TooManyRequestsResponse
 */
export interface TooManyRequestsResponse {
    /**
     *
     * @type {number}
     * @memberof TooManyRequestsResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof TooManyRequestsResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof TooManyRequestsResponse
     */
    message?: string | null
}
/**
 *
 * @export
 * @interface TransformerIdDto
 */
export interface TransformerIdDto {
    /**
     *
     * @type {string}
     * @memberof TransformerIdDto
     */
    transformerSid?: string | null
    /**
     *
     * @type {string}
     * @memberof TransformerIdDto
     */
    functionalLocation?: string | null
    /**
     *
     * @type {number}
     * @memberof TransformerIdDto
     */
    bookmarkId?: number | null
}
/**
 *
 * @export
 * @interface UpdateBookmarksRequest
 */
export interface UpdateBookmarksRequest {
    /**
     * The updated data.
     * @type {Array<BookmarkUpdateDto>}
     * @memberof UpdateBookmarksRequest
     */
    bookmarks?: Array<BookmarkUpdateDto> | null
}
/**
 *
 * @export
 * @interface UpdateBookmarksResponse
 */
export interface UpdateBookmarksResponse {
    /**
     * The updated data.
     * @type {Array<BookmarkDto>}
     * @memberof UpdateBookmarksResponse
     */
    updatedData?: Array<BookmarkDto> | null
}
/**
 *
 * @export
 * @interface UpdateBookmarksResponseOkResponse
 */
export interface UpdateBookmarksResponseOkResponse {
    /**
     *
     * @type {number}
     * @memberof UpdateBookmarksResponseOkResponse
     */
    statusCode?: number
    /**
     *
     * @type {string}
     * @memberof UpdateBookmarksResponseOkResponse
     */
    statusDescription?: string | null
    /**
     *
     * @type {string}
     * @memberof UpdateBookmarksResponseOkResponse
     */
    message?: string | null
    /**
     *
     * @type {UpdateBookmarksResponse}
     * @memberof UpdateBookmarksResponseOkResponse
     */
    result?: UpdateBookmarksResponse
}
/**
 *
 * @export
 * @interface VisualInspectionDataDto
 */
export interface VisualInspectionDataDto {
    /**
     *
     * @type {number}
     * @memberof VisualInspectionDataDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof VisualInspectionDataDto
     */
    analyticalNumber?: string | null
    /**
     *
     * @type {string}
     * @memberof VisualInspectionDataDto
     */
    reportLink?: string | null
    /**
     *
     * @type {string}
     * @memberof VisualInspectionDataDto
     */
    date?: string
    /**
     *
     * @type {string}
     * @memberof VisualInspectionDataDto
     */
    inspectionComment?: string | null
    /**
     *
     * @type {Array<VisualInspectionParameterValueDto>}
     * @memberof VisualInspectionDataDto
     */
    inspectionParameterValues?: Array<VisualInspectionParameterValueDto> | null
}
/**
 *
 * @export
 * @interface VisualInspectionParameterDto
 */
export interface VisualInspectionParameterDto {
    /**
     *
     * @type {number}
     * @memberof VisualInspectionParameterDto
     */
    id?: number
    /**
     *
     * @type {string}
     * @memberof VisualInspectionParameterDto
     */
    name?: string | null
    /**
     *
     * @type {number}
     * @memberof VisualInspectionParameterDto
     */
    hierarchyOrderId?: number | null
}
/**
 *
 * @export
 * @interface VisualInspectionParameterValueDto
 */
export interface VisualInspectionParameterValueDto {
    /**
     *
     * @type {number}
     * @memberof VisualInspectionParameterValueDto
     */
    id?: number
    /**
     *
     * @type {number}
     * @memberof VisualInspectionParameterValueDto
     */
    parameterId?: number
    /**
     *
     * @type {number}
     * @memberof VisualInspectionParameterValueDto
     */
    status?: number
    /**
     *
     * @type {CommentParameterDto}
     * @memberof VisualInspectionParameterValueDto
     */
    latestComment?: CommentParameterDto
    /**
     *
     * @type {string}
     * @memberof VisualInspectionParameterValueDto
     */
    comment?: string | null
}

/**
 * BookmarksApi - axios parameter creator
 * @export
 */
export const BookmarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a bookmark for a transformer.
         * @summary Add a bookmark for a transformer. (Auth policies: CanViewTransformerData)
         * @param {AddBookmarkRequest} [addBookmarkRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBookmark: async (
            addBookmarkRequest?: AddBookmarkRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookmarks`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                addBookmarkRequest,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Bulk update bookmarks by id.
         * @summary Bulk update bookmarks by id. (Auth policies: CanViewTransformerData)
         * @param {UpdateBookmarksRequest} [updateBookmarksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateBookmarks: async (
            updateBookmarksRequest?: UpdateBookmarksRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookmarks`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                updateBookmarksRequest,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Deletes a bookmark by id.
         * @summary Deletes a bookmark by id. (Auth policies: CanViewTransformerData)
         * @param {number} bookmarkId The id of the bookmark to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookmark: async (bookmarkId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookmarkId' is not null or undefined
            assertParamExists('deleteBookmark', 'bookmarkId', bookmarkId)
            const localVarPath = `/api/v1/bookmarks/{bookmarkId}`.replace(
                `{${'bookmarkId'}}`,
                encodeURIComponent(String(bookmarkId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List bookmarks.
         * @summary List bookmarks. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookmarks: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/bookmarks`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * BookmarksApi - functional programming interface
 * @export
 */
export const BookmarksApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BookmarksApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a bookmark for a transformer.
         * @summary Add a bookmark for a transformer. (Auth policies: CanViewTransformerData)
         * @param {AddBookmarkRequest} [addBookmarkRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBookmark(
            addBookmarkRequest?: AddBookmarkRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBookmarkResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addBookmark(addBookmarkRequest, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['BookmarksApi.addBookmark']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Bulk update bookmarks by id.
         * @summary Bulk update bookmarks by id. (Auth policies: CanViewTransformerData)
         * @param {UpdateBookmarksRequest} [updateBookmarksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateBookmarks(
            updateBookmarksRequest?: UpdateBookmarksRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBookmarksResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateBookmarks(
                updateBookmarksRequest,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['BookmarksApi.bulkUpdateBookmarks']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Deletes a bookmark by id.
         * @summary Deletes a bookmark by id. (Auth policies: CanViewTransformerData)
         * @param {number} bookmarkId The id of the bookmark to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookmark(
            bookmarkId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteBookmarkResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBookmark(bookmarkId, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['BookmarksApi.deleteBookmark']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List bookmarks.
         * @summary List bookmarks. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBookmarks(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListBookmarksResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBookmarks(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['BookmarksApi.listBookmarks']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * BookmarksApi - factory interface
 * @export
 */
export const BookmarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookmarksApiFp(configuration)
    return {
        /**
         * Add a bookmark for a transformer.
         * @summary Add a bookmark for a transformer. (Auth policies: CanViewTransformerData)
         * @param {AddBookmarkRequest} [addBookmarkRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBookmark(
            addBookmarkRequest?: AddBookmarkRequest,
            options?: any,
        ): AxiosPromise<AddBookmarkResponseOkResponse> {
            return localVarFp.addBookmark(addBookmarkRequest, options).then((request) => request(axios, basePath))
        },
        /**
         * Bulk update bookmarks by id.
         * @summary Bulk update bookmarks by id. (Auth policies: CanViewTransformerData)
         * @param {UpdateBookmarksRequest} [updateBookmarksRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateBookmarks(
            updateBookmarksRequest?: UpdateBookmarksRequest,
            options?: any,
        ): AxiosPromise<UpdateBookmarksResponseOkResponse> {
            return localVarFp
                .bulkUpdateBookmarks(updateBookmarksRequest, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Deletes a bookmark by id.
         * @summary Deletes a bookmark by id. (Auth policies: CanViewTransformerData)
         * @param {number} bookmarkId The id of the bookmark to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookmark(bookmarkId: number, options?: any): AxiosPromise<DeleteBookmarkResponseOkResponse> {
            return localVarFp.deleteBookmark(bookmarkId, options).then((request) => request(axios, basePath))
        },
        /**
         * List bookmarks.
         * @summary List bookmarks. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBookmarks(request?: object, options?: any): AxiosPromise<ListBookmarksResponseOkResponse> {
            return localVarFp.listBookmarks(request, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * BookmarksApi - interface
 * @export
 * @interface BookmarksApi
 */
export interface BookmarksApiInterface {
    /**
     * Add a bookmark for a transformer.
     * @summary Add a bookmark for a transformer. (Auth policies: CanViewTransformerData)
     * @param {AddBookmarkRequest} [addBookmarkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApiInterface
     */
    addBookmark(
        addBookmarkRequest?: AddBookmarkRequest,
        options?: AxiosRequestConfig,
    ): AxiosPromise<AddBookmarkResponseOkResponse>

    /**
     * Bulk update bookmarks by id.
     * @summary Bulk update bookmarks by id. (Auth policies: CanViewTransformerData)
     * @param {UpdateBookmarksRequest} [updateBookmarksRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApiInterface
     */
    bulkUpdateBookmarks(
        updateBookmarksRequest?: UpdateBookmarksRequest,
        options?: AxiosRequestConfig,
    ): AxiosPromise<UpdateBookmarksResponseOkResponse>

    /**
     * Deletes a bookmark by id.
     * @summary Deletes a bookmark by id. (Auth policies: CanViewTransformerData)
     * @param {number} bookmarkId The id of the bookmark to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApiInterface
     */
    deleteBookmark(bookmarkId: number, options?: AxiosRequestConfig): AxiosPromise<DeleteBookmarkResponseOkResponse>

    /**
     * List bookmarks.
     * @summary List bookmarks. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApiInterface
     */
    listBookmarks(request?: object, options?: AxiosRequestConfig): AxiosPromise<ListBookmarksResponseOkResponse>
}

/**
 * BookmarksApi - object-oriented interface
 * @export
 * @class BookmarksApi
 * @extends {BaseAPI}
 */
export class BookmarksApi extends BaseAPI implements BookmarksApiInterface {
    /**
     * Add a bookmark for a transformer.
     * @summary Add a bookmark for a transformer. (Auth policies: CanViewTransformerData)
     * @param {AddBookmarkRequest} [addBookmarkRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public addBookmark(addBookmarkRequest?: AddBookmarkRequest, options?: AxiosRequestConfig) {
        return BookmarksApiFp(this.configuration)
            .addBookmark(addBookmarkRequest, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Bulk update bookmarks by id.
     * @summary Bulk update bookmarks by id. (Auth policies: CanViewTransformerData)
     * @param {UpdateBookmarksRequest} [updateBookmarksRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public bulkUpdateBookmarks(updateBookmarksRequest?: UpdateBookmarksRequest, options?: AxiosRequestConfig) {
        return BookmarksApiFp(this.configuration)
            .bulkUpdateBookmarks(updateBookmarksRequest, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Deletes a bookmark by id.
     * @summary Deletes a bookmark by id. (Auth policies: CanViewTransformerData)
     * @param {number} bookmarkId The id of the bookmark to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public deleteBookmark(bookmarkId: number, options?: AxiosRequestConfig) {
        return BookmarksApiFp(this.configuration)
            .deleteBookmark(bookmarkId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List bookmarks.
     * @summary List bookmarks. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookmarksApi
     */
    public listBookmarks(request?: object, options?: AxiosRequestConfig) {
        return BookmarksApiFp(this.configuration)
            .listBookmarks(request, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a comment to a transformer.
         * @summary Add a comment to a transformer. (Auth policies: CanEditTransformerComments)
         * @param {string} transformerSid The sid of the transformer to which the comment is added.
         * @param {CommentAddDto} [commentAddDto] The data needed for the new comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment: async (
            transformerSid: string,
            commentAddDto?: CommentAddDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('addComment', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/comments/{transformerSid}`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(commentAddDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Deletes a comment by id.
         * @summary Deletes a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{commentId}`.replace(
                `{${'commentId'}}`,
                encodeURIComponent(String(commentId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Updates a comment by id.
         * @summary Updates a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be updated.
         * @param {CommentEditDto} [commentEditDto] The updated text.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editComment: async (
            commentId: number,
            commentEditDto?: CommentEditDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('editComment', 'commentId', commentId)
            const localVarPath = `/api/v1/comments/{commentId}`.replace(
                `{${'commentId'}}`,
                encodeURIComponent(String(commentId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(commentEditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List comments by transformer sid.
         * @summary List comments by transformer sid. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComments: async (
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('listComments', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/comments/{transformerSid}`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List report numbers by term search.
         * @summary List report numbers by term search. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportNumbers: async (
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('listReportNumbers', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/comments/{transformerSid}/reports`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a comment to a transformer.
         * @summary Add a comment to a transformer. (Auth policies: CanEditTransformerComments)
         * @param {string} transformerSid The sid of the transformer to which the comment is added.
         * @param {CommentAddDto} [commentAddDto] The data needed for the new comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addComment(
            transformerSid: string,
            commentAddDto?: CommentAddDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddCommentResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addComment(transformerSid, commentAddDto, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['CommentsApi.addComment']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Deletes a comment by id.
         * @summary Deletes a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(
            commentId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteCommentResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['CommentsApi.deleteComment']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Updates a comment by id.
         * @summary Updates a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be updated.
         * @param {CommentEditDto} [commentEditDto] The updated text.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editComment(
            commentId: number,
            commentEditDto?: CommentEditDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditCommentResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editComment(commentId, commentEditDto, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['CommentsApi.editComment']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List comments by transformer sid.
         * @summary List comments by transformer sid. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComments(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListCommentsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComments(
                transformerSid,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['CommentsApi.listComments']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List report numbers by term search.
         * @summary List report numbers by term search. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReportNumbers(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReportNumbersResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReportNumbers(
                transformerSid,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['CommentsApi.listReportNumbers']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentsApiFp(configuration)
    return {
        /**
         * Add a comment to a transformer.
         * @summary Add a comment to a transformer. (Auth policies: CanEditTransformerComments)
         * @param {string} transformerSid The sid of the transformer to which the comment is added.
         * @param {CommentAddDto} [commentAddDto] The data needed for the new comment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addComment(
            transformerSid: string,
            commentAddDto?: CommentAddDto,
            options?: any,
        ): AxiosPromise<AddCommentResponseOkResponse> {
            return localVarFp
                .addComment(transformerSid, commentAddDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Deletes a comment by id.
         * @summary Deletes a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: number, options?: any): AxiosPromise<DeleteCommentResponseOkResponse> {
            return localVarFp.deleteComment(commentId, options).then((request) => request(axios, basePath))
        },
        /**
         * Updates a comment by id.
         * @summary Updates a comment by id. (Auth policies: CanEditTransformerComments)
         * @param {number} commentId The id of the comment to be updated.
         * @param {CommentEditDto} [commentEditDto] The updated text.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editComment(
            commentId: number,
            commentEditDto?: CommentEditDto,
            options?: any,
        ): AxiosPromise<EditCommentResponseOkResponse> {
            return localVarFp
                .editComment(commentId, commentEditDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * List comments by transformer sid.
         * @summary List comments by transformer sid. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComments(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListCommentsResponseOkResponse> {
            return localVarFp
                .listComments(transformerSid, page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * List report numbers by term search.
         * @summary List report numbers by term search. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReportNumbers(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListReportNumbersResponseOkResponse> {
            return localVarFp
                .listReportNumbers(transformerSid, page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * CommentsApi - interface
 * @export
 * @interface CommentsApi
 */
export interface CommentsApiInterface {
    /**
     * Add a comment to a transformer.
     * @summary Add a comment to a transformer. (Auth policies: CanEditTransformerComments)
     * @param {string} transformerSid The sid of the transformer to which the comment is added.
     * @param {CommentAddDto} [commentAddDto] The data needed for the new comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApiInterface
     */
    addComment(
        transformerSid: string,
        commentAddDto?: CommentAddDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<AddCommentResponseOkResponse>

    /**
     * Deletes a comment by id.
     * @summary Deletes a comment by id. (Auth policies: CanEditTransformerComments)
     * @param {number} commentId The id of the comment to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApiInterface
     */
    deleteComment(commentId: number, options?: AxiosRequestConfig): AxiosPromise<DeleteCommentResponseOkResponse>

    /**
     * Updates a comment by id.
     * @summary Updates a comment by id. (Auth policies: CanEditTransformerComments)
     * @param {number} commentId The id of the comment to be updated.
     * @param {CommentEditDto} [commentEditDto] The updated text.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApiInterface
     */
    editComment(
        commentId: number,
        commentEditDto?: CommentEditDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<EditCommentResponseOkResponse>

    /**
     * List comments by transformer sid.
     * @summary List comments by transformer sid. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApiInterface
     */
    listComments(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListCommentsResponseOkResponse>

    /**
     * List report numbers by term search.
     * @summary List report numbers by term search. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApiInterface
     */
    listReportNumbers(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListReportNumbersResponseOkResponse>
}

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI implements CommentsApiInterface {
    /**
     * Add a comment to a transformer.
     * @summary Add a comment to a transformer. (Auth policies: CanEditTransformerComments)
     * @param {string} transformerSid The sid of the transformer to which the comment is added.
     * @param {CommentAddDto} [commentAddDto] The data needed for the new comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public addComment(transformerSid: string, commentAddDto?: CommentAddDto, options?: AxiosRequestConfig) {
        return CommentsApiFp(this.configuration)
            .addComment(transformerSid, commentAddDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Deletes a comment by id.
     * @summary Deletes a comment by id. (Auth policies: CanEditTransformerComments)
     * @param {number} commentId The id of the comment to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public deleteComment(commentId: number, options?: AxiosRequestConfig) {
        return CommentsApiFp(this.configuration)
            .deleteComment(commentId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Updates a comment by id.
     * @summary Updates a comment by id. (Auth policies: CanEditTransformerComments)
     * @param {number} commentId The id of the comment to be updated.
     * @param {CommentEditDto} [commentEditDto] The updated text.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public editComment(commentId: number, commentEditDto?: CommentEditDto, options?: AxiosRequestConfig) {
        return CommentsApiFp(this.configuration)
            .editComment(commentId, commentEditDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List comments by transformer sid.
     * @summary List comments by transformer sid. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public listComments(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return CommentsApiFp(this.configuration)
            .listComments(transformerSid, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List report numbers by term search.
     * @summary List report numbers by term search. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentsApi
     */
    public listReportNumbers(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return CommentsApiFp(this.configuration)
            .listReportNumbers(transformerSid, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * ComponentStatusApi - axios parameter creator
 * @export
 */
export const ComponentStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get condition status by transformer.
         * @summary Get condition status by transformer. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusByTransformer: async (
            transformerSid: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('getConditionStatusByTransformer', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/component-status/{transformerSid}`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get historical trends of a parameter for a transformer in a given timeframe.
         * @summary Get historical trends of a parameter. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [languageType]
         * @param {boolean} [mustIncludeTemperature]
         * @param {Array<number>} [parameterIds]
         * @param {Array<number>} [denominatorParameterIds]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOilHistoricalTrends: async (
            transformerSid: string,
            languageType?: number,
            mustIncludeTemperature?: boolean,
            parameterIds?: Array<number>,
            denominatorParameterIds?: Array<number>,
            startDate?: string,
            endDate?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('getOilHistoricalTrends', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/component-status/{transformerSid}/historical-trends`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (languageType !== undefined) {
                localVarQueryParameter['LanguageType'] = languageType
            }

            if (mustIncludeTemperature !== undefined) {
                localVarQueryParameter['MustIncludeTemperature'] = mustIncludeTemperature
            }

            if (parameterIds) {
                localVarQueryParameter['ParameterIds'] = parameterIds
            }

            if (denominatorParameterIds) {
                localVarQueryParameter['DenominatorParameterIds'] = denominatorParameterIds
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] =
                    (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] =
                    (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List oil analysis reports by transformer id and analysis type.
         * @summary List oil analysis reports by transformer id and analysis type. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [analysisType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOilAnalysisReportsByTransformer: async (
            transformerSid: string,
            analysisType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('listOilAnalysisReportsByTransformer', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/component-status/{transformerSid}/reports`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (analysisType !== undefined) {
                localVarQueryParameter['analysisType'] = analysisType
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List visual inspections by transformer id.
         * @summary List visual inspections by transformer id. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisualInspectionsByTransformerSid: async (
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('listVisualInspectionsByTransformerSid', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/component-status/{transformerSid}/visual-inspections`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Override status of a parameter.
         * @summary Override status of a parameter. (Auth policies: CanOverrideTransformerConditionStatus)
         * @param {number} parameterId The id of the parameter that is being overriden.
         * @param {OverrideStatusDto} [overrideStatusDto] The data needed for the override.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overrideParameterStatusById: async (
            parameterId: number,
            overrideStatusDto?: OverrideStatusDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'parameterId' is not null or undefined
            assertParamExists('overrideParameterStatusById', 'parameterId', parameterId)
            const localVarPath = `/api/v1/component-status/{parameterId}`.replace(
                `{${'parameterId'}}`,
                encodeURIComponent(String(parameterId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                overrideStatusDto,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * ComponentStatusApi - functional programming interface
 * @export
 */
export const ComponentStatusApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Get condition status by transformer.
         * @summary Get condition status by transformer. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConditionStatusByTransformer(
            transformerSid: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<GetConditionStatusByTransformerResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConditionStatusByTransformer(
                transformerSid,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['ComponentStatusApi.getConditionStatusByTransformer']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get historical trends of a parameter for a transformer in a given timeframe.
         * @summary Get historical trends of a parameter. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [languageType]
         * @param {boolean} [mustIncludeTemperature]
         * @param {Array<number>} [parameterIds]
         * @param {Array<number>} [denominatorParameterIds]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOilHistoricalTrends(
            transformerSid: string,
            languageType?: number,
            mustIncludeTemperature?: boolean,
            parameterIds?: Array<number>,
            denominatorParameterIds?: Array<number>,
            startDate?: string,
            endDate?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOilHistoricalTrendsResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOilHistoricalTrends(
                transformerSid,
                languageType,
                mustIncludeTemperature,
                parameterIds,
                denominatorParameterIds,
                startDate,
                endDate,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ComponentStatusApi.getOilHistoricalTrends']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List oil analysis reports by transformer id and analysis type.
         * @summary List oil analysis reports by transformer id and analysis type. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [analysisType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOilAnalysisReportsByTransformer(
            transformerSid: string,
            analysisType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ListOilAnalysisReportsByTransformerResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listOilAnalysisReportsByTransformer(
                transformerSid,
                analysisType,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['ComponentStatusApi.listOilAnalysisReportsByTransformer']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List visual inspections by transformer id.
         * @summary List visual inspections by transformer id. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listVisualInspectionsByTransformerSid(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListVisualInspectionsResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listVisualInspectionsByTransformerSid(
                transformerSid,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['ComponentStatusApi.listVisualInspectionsByTransformerSid']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Override status of a parameter.
         * @summary Override status of a parameter. (Auth policies: CanOverrideTransformerConditionStatus)
         * @param {number} parameterId The id of the parameter that is being overriden.
         * @param {OverrideStatusDto} [overrideStatusDto] The data needed for the override.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async overrideParameterStatusById(
            parameterId: number,
            overrideStatusDto?: OverrideStatusDto,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverrideParameterStatusResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.overrideParameterStatusById(
                parameterId,
                overrideStatusDto,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ComponentStatusApi.overrideParameterStatusById']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * ComponentStatusApi - factory interface
 * @export
 */
export const ComponentStatusApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ComponentStatusApiFp(configuration)
    return {
        /**
         * Get condition status by transformer.
         * @summary Get condition status by transformer. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConditionStatusByTransformer(
            transformerSid: string,
            options?: any,
        ): AxiosPromise<GetConditionStatusByTransformerResponseOkResponse> {
            return localVarFp
                .getConditionStatusByTransformer(transformerSid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get historical trends of a parameter for a transformer in a given timeframe.
         * @summary Get historical trends of a parameter. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [languageType]
         * @param {boolean} [mustIncludeTemperature]
         * @param {Array<number>} [parameterIds]
         * @param {Array<number>} [denominatorParameterIds]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOilHistoricalTrends(
            transformerSid: string,
            languageType?: number,
            mustIncludeTemperature?: boolean,
            parameterIds?: Array<number>,
            denominatorParameterIds?: Array<number>,
            startDate?: string,
            endDate?: string,
            options?: any,
        ): AxiosPromise<GetOilHistoricalTrendsResponseOkResponse> {
            return localVarFp
                .getOilHistoricalTrends(
                    transformerSid,
                    languageType,
                    mustIncludeTemperature,
                    parameterIds,
                    denominatorParameterIds,
                    startDate,
                    endDate,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * List oil analysis reports by transformer id and analysis type.
         * @summary List oil analysis reports by transformer id and analysis type. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [analysisType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOilAnalysisReportsByTransformer(
            transformerSid: string,
            analysisType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListOilAnalysisReportsByTransformerResponseOkResponse> {
            return localVarFp
                .listOilAnalysisReportsByTransformer(
                    transformerSid,
                    analysisType,
                    page,
                    size,
                    term,
                    termToLower,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * List visual inspections by transformer id.
         * @summary List visual inspections by transformer id. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listVisualInspectionsByTransformerSid(
            transformerSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListVisualInspectionsResponseOkResponse> {
            return localVarFp
                .listVisualInspectionsByTransformerSid(transformerSid, page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Override status of a parameter.
         * @summary Override status of a parameter. (Auth policies: CanOverrideTransformerConditionStatus)
         * @param {number} parameterId The id of the parameter that is being overriden.
         * @param {OverrideStatusDto} [overrideStatusDto] The data needed for the override.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        overrideParameterStatusById(
            parameterId: number,
            overrideStatusDto?: OverrideStatusDto,
            options?: any,
        ): AxiosPromise<OverrideParameterStatusResponseOkResponse> {
            return localVarFp
                .overrideParameterStatusById(parameterId, overrideStatusDto, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * ComponentStatusApi - interface
 * @export
 * @interface ComponentStatusApi
 */
export interface ComponentStatusApiInterface {
    /**
     * Get condition status by transformer.
     * @summary Get condition status by transformer. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApiInterface
     */
    getConditionStatusByTransformer(
        transformerSid: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetConditionStatusByTransformerResponseOkResponse>

    /**
     * Get historical trends of a parameter for a transformer in a given timeframe.
     * @summary Get historical trends of a parameter. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [languageType]
     * @param {boolean} [mustIncludeTemperature]
     * @param {Array<number>} [parameterIds]
     * @param {Array<number>} [denominatorParameterIds]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApiInterface
     */
    getOilHistoricalTrends(
        transformerSid: string,
        languageType?: number,
        mustIncludeTemperature?: boolean,
        parameterIds?: Array<number>,
        denominatorParameterIds?: Array<number>,
        startDate?: string,
        endDate?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetOilHistoricalTrendsResponseOkResponse>

    /**
     * List oil analysis reports by transformer id and analysis type.
     * @summary List oil analysis reports by transformer id and analysis type. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [analysisType]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApiInterface
     */
    listOilAnalysisReportsByTransformer(
        transformerSid: string,
        analysisType?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListOilAnalysisReportsByTransformerResponseOkResponse>

    /**
     * List visual inspections by transformer id.
     * @summary List visual inspections by transformer id. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApiInterface
     */
    listVisualInspectionsByTransformerSid(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListVisualInspectionsResponseOkResponse>

    /**
     * Override status of a parameter.
     * @summary Override status of a parameter. (Auth policies: CanOverrideTransformerConditionStatus)
     * @param {number} parameterId The id of the parameter that is being overriden.
     * @param {OverrideStatusDto} [overrideStatusDto] The data needed for the override.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApiInterface
     */
    overrideParameterStatusById(
        parameterId: number,
        overrideStatusDto?: OverrideStatusDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<OverrideParameterStatusResponseOkResponse>
}

/**
 * ComponentStatusApi - object-oriented interface
 * @export
 * @class ComponentStatusApi
 * @extends {BaseAPI}
 */
export class ComponentStatusApi extends BaseAPI implements ComponentStatusApiInterface {
    /**
     * Get condition status by transformer.
     * @summary Get condition status by transformer. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApi
     */
    public getConditionStatusByTransformer(transformerSid: string, options?: AxiosRequestConfig) {
        return ComponentStatusApiFp(this.configuration)
            .getConditionStatusByTransformer(transformerSid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get historical trends of a parameter for a transformer in a given timeframe.
     * @summary Get historical trends of a parameter. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [languageType]
     * @param {boolean} [mustIncludeTemperature]
     * @param {Array<number>} [parameterIds]
     * @param {Array<number>} [denominatorParameterIds]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApi
     */
    public getOilHistoricalTrends(
        transformerSid: string,
        languageType?: number,
        mustIncludeTemperature?: boolean,
        parameterIds?: Array<number>,
        denominatorParameterIds?: Array<number>,
        startDate?: string,
        endDate?: string,
        options?: AxiosRequestConfig,
    ) {
        return ComponentStatusApiFp(this.configuration)
            .getOilHistoricalTrends(
                transformerSid,
                languageType,
                mustIncludeTemperature,
                parameterIds,
                denominatorParameterIds,
                startDate,
                endDate,
                options,
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List oil analysis reports by transformer id and analysis type.
     * @summary List oil analysis reports by transformer id and analysis type. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [analysisType]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApi
     */
    public listOilAnalysisReportsByTransformer(
        transformerSid: string,
        analysisType?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return ComponentStatusApiFp(this.configuration)
            .listOilAnalysisReportsByTransformer(transformerSid, analysisType, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List visual inspections by transformer id.
     * @summary List visual inspections by transformer id. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApi
     */
    public listVisualInspectionsByTransformerSid(
        transformerSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return ComponentStatusApiFp(this.configuration)
            .listVisualInspectionsByTransformerSid(transformerSid, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Override status of a parameter.
     * @summary Override status of a parameter. (Auth policies: CanOverrideTransformerConditionStatus)
     * @param {number} parameterId The id of the parameter that is being overriden.
     * @param {OverrideStatusDto} [overrideStatusDto] The data needed for the override.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentStatusApi
     */
    public overrideParameterStatusById(
        parameterId: number,
        overrideStatusDto?: OverrideStatusDto,
        options?: AxiosRequestConfig,
    ) {
        return ComponentStatusApiFp(this.configuration)
            .overrideParameterStatusById(parameterId, overrideStatusDto, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * List portfolio events.
         * @summary List portfolio events. (Auth policies: CanViewTransformerData)
         * @param {number} componentType
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [plantGroupSids]
         * @param {Array<number>} [notificationTypes]
         * @param {number} [pastDays]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents: async (
            componentType: number,
            plantSids?: Array<number>,
            plantGroupSids?: Array<number>,
            notificationTypes?: Array<number>,
            pastDays?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'componentType' is not null or undefined
            assertParamExists('listEvents', 'componentType', componentType)
            const localVarPath = `/api/v1/dashboard`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (componentType !== undefined) {
                localVarQueryParameter['ComponentType'] = componentType
            }

            if (plantSids) {
                localVarQueryParameter['PlantSids'] = plantSids
            }

            if (plantGroupSids) {
                localVarQueryParameter['PlantGroupSids'] = plantGroupSids
            }

            if (notificationTypes) {
                localVarQueryParameter['NotificationTypes'] = notificationTypes
            }

            if (pastDays !== undefined) {
                localVarQueryParameter['PastDays'] = pastDays
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * List portfolio events.
         * @summary List portfolio events. (Auth policies: CanViewTransformerData)
         * @param {number} componentType
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [plantGroupSids]
         * @param {Array<number>} [notificationTypes]
         * @param {number} [pastDays]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listEvents(
            componentType: number,
            plantSids?: Array<number>,
            plantGroupSids?: Array<number>,
            notificationTypes?: Array<number>,
            pastDays?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPortfolioEventsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listEvents(
                componentType,
                plantSids,
                plantGroupSids,
                notificationTypes,
                pastDays,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['DashboardApi.listEvents']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * List portfolio events.
         * @summary List portfolio events. (Auth policies: CanViewTransformerData)
         * @param {number} componentType
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [plantGroupSids]
         * @param {Array<number>} [notificationTypes]
         * @param {number} [pastDays]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listEvents(
            componentType: number,
            plantSids?: Array<number>,
            plantGroupSids?: Array<number>,
            notificationTypes?: Array<number>,
            pastDays?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListPortfolioEventsResponseOkResponse> {
            return localVarFp
                .listEvents(
                    componentType,
                    plantSids,
                    plantGroupSids,
                    notificationTypes,
                    pastDays,
                    page,
                    size,
                    term,
                    termToLower,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * DashboardApi - interface
 * @export
 * @interface DashboardApi
 */
export interface DashboardApiInterface {
    /**
     * List portfolio events.
     * @summary List portfolio events. (Auth policies: CanViewTransformerData)
     * @param {number} componentType
     * @param {Array<number>} [plantSids]
     * @param {Array<number>} [plantGroupSids]
     * @param {Array<number>} [notificationTypes]
     * @param {number} [pastDays]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApiInterface
     */
    listEvents(
        componentType: number,
        plantSids?: Array<number>,
        plantGroupSids?: Array<number>,
        notificationTypes?: Array<number>,
        pastDays?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListPortfolioEventsResponseOkResponse>
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI implements DashboardApiInterface {
    /**
     * List portfolio events.
     * @summary List portfolio events. (Auth policies: CanViewTransformerData)
     * @param {number} componentType
     * @param {Array<number>} [plantSids]
     * @param {Array<number>} [plantGroupSids]
     * @param {Array<number>} [notificationTypes]
     * @param {number} [pastDays]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public listEvents(
        componentType: number,
        plantSids?: Array<number>,
        plantGroupSids?: Array<number>,
        notificationTypes?: Array<number>,
        pastDays?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return DashboardApiFp(this.configuration)
            .listEvents(
                componentType,
                plantSids,
                plantGroupSids,
                notificationTypes,
                pastDays,
                page,
                size,
                term,
                termToLower,
                options,
            )
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * DataImportApi - axios parameter creator
 * @export
 */
export const DataImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the data from snowflake and imports it in the sql database.
         * @summary Gets the data from snowflake and imports it in the sql database. (Auth)
         * @param {Array<string>} [componentSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncData: async (componentSids?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/data-sync`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (componentSids) {
                localVarQueryParameter['ComponentSids'] = componentSids
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * DataImportApi - functional programming interface
 * @export
 */
export const DataImportApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DataImportApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the data from snowflake and imports it in the sql database.
         * @summary Gets the data from snowflake and imports it in the sql database. (Auth)
         * @param {Array<string>} [componentSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncData(
            componentSids?: Array<string>,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncDataResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncData(componentSids, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['DataImportApi.syncData']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * DataImportApi - factory interface
 * @export
 */
export const DataImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataImportApiFp(configuration)
    return {
        /**
         * Gets the data from snowflake and imports it in the sql database.
         * @summary Gets the data from snowflake and imports it in the sql database. (Auth)
         * @param {Array<string>} [componentSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncData(componentSids?: Array<string>, options?: any): AxiosPromise<SyncDataResponseOkResponse> {
            return localVarFp.syncData(componentSids, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * DataImportApi - interface
 * @export
 * @interface DataImportApi
 */
export interface DataImportApiInterface {
    /**
     * Gets the data from snowflake and imports it in the sql database.
     * @summary Gets the data from snowflake and imports it in the sql database. (Auth)
     * @param {Array<string>} [componentSids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApiInterface
     */
    syncData(componentSids?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<SyncDataResponseOkResponse>
}

/**
 * DataImportApi - object-oriented interface
 * @export
 * @class DataImportApi
 * @extends {BaseAPI}
 */
export class DataImportApi extends BaseAPI implements DataImportApiInterface {
    /**
     * Gets the data from snowflake and imports it in the sql database.
     * @summary Gets the data from snowflake and imports it in the sql database. (Auth)
     * @param {Array<string>} [componentSids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataImportApi
     */
    public syncData(componentSids?: Array<string>, options?: AxiosRequestConfig) {
        return DataImportApiFp(this.configuration)
            .syncData(componentSids, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get constants.
         * @summary Get constants. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstants: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/constants`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * Get constants.
         * @summary Get constants. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConstants(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConstantsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConstants(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['GeneralApi.getConstants']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * Get constants.
         * @summary Get constants. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConstants(request?: object, options?: any): AxiosPromise<GetConstantsResponseOkResponse> {
            return localVarFp.getConstants(request, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * GeneralApi - interface
 * @export
 * @interface GeneralApi
 */
export interface GeneralApiInterface {
    /**
     * Get constants.
     * @summary Get constants. (Auth)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApiInterface
     */
    getConstants(request?: object, options?: AxiosRequestConfig): AxiosPromise<GetConstantsResponseOkResponse>
}

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI implements GeneralApiInterface {
    /**
     * Get constants.
     * @summary Get constants. (Auth)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public getConstants(request?: object, options?: AxiosRequestConfig) {
        return GeneralApiFp(this.configuration)
            .getConstants(request, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * HistoricalDataApi - axios parameter creator
 * @export
 */
export const HistoricalDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get home index
         * @summary Get home index (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/historical-data`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * HistoricalDataApi - functional programming interface
 * @export
 */
export const HistoricalDataApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoricalDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Get home index
         * @summary Get home index (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async merge(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.merge(options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['HistoricalDataApi.merge']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * HistoricalDataApi - factory interface
 * @export
 */
export const HistoricalDataApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = HistoricalDataApiFp(configuration)
    return {
        /**
         * Get home index
         * @summary Get home index (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        merge(options?: any): AxiosPromise<void> {
            return localVarFp.merge(options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * HistoricalDataApi - interface
 * @export
 * @interface HistoricalDataApi
 */
export interface HistoricalDataApiInterface {
    /**
     * Get home index
     * @summary Get home index (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataApiInterface
     */
    merge(options?: AxiosRequestConfig): AxiosPromise<void>
}

/**
 * HistoricalDataApi - object-oriented interface
 * @export
 * @class HistoricalDataApi
 * @extends {BaseAPI}
 */
export class HistoricalDataApi extends BaseAPI implements HistoricalDataApiInterface {
    /**
     * Get home index
     * @summary Get home index (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoricalDataApi
     */
    public merge(options?: AxiosRequestConfig) {
        return HistoricalDataApiFp(this.configuration)
            .merge(options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * MaintenancesApi - axios parameter creator
 * @export
 */
export const MaintenancesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get registered services
         * @summary Get registered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredServices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/maintenance/registered-services`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * MaintenancesApi - functional programming interface
 * @export
 */
export const MaintenancesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenancesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get registered services
         * @summary Get registered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredServices(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisteredServicesResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredServices(options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['MaintenancesApi.getRegisteredServices']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * MaintenancesApi - factory interface
 * @export
 */
export const MaintenancesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = MaintenancesApiFp(configuration)
    return {
        /**
         * Get registered services
         * @summary Get registered services
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredServices(options?: any): AxiosPromise<RegisteredServicesResponseOkResponse> {
            return localVarFp.getRegisteredServices(options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * MaintenancesApi - interface
 * @export
 * @interface MaintenancesApi
 */
export interface MaintenancesApiInterface {
    /**
     * Get registered services
     * @summary Get registered services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancesApiInterface
     */
    getRegisteredServices(options?: AxiosRequestConfig): AxiosPromise<RegisteredServicesResponseOkResponse>
}

/**
 * MaintenancesApi - object-oriented interface
 * @export
 * @class MaintenancesApi
 * @extends {BaseAPI}
 */
export class MaintenancesApi extends BaseAPI implements MaintenancesApiInterface {
    /**
     * Get registered services
     * @summary Get registered services
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenancesApi
     */
    public getRegisteredServices(options?: AxiosRequestConfig) {
        return MaintenancesApiFp(this.configuration)
            .getRegisteredServices(options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * MasterDataApi - axios parameter creator
 * @export
 */
export const MasterDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Lists master data plants based on user\'s asset permissions.
         * @summary Lists master data plants based on user\'s asset permissions. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlants: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/master-data/plants`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get transformer master data.
         * @summary Get transformer master data. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerDetails: async (
            transformerSid: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'transformerSid' is not null or undefined
            assertParamExists('getTransformerDetails', 'transformerSid', transformerSid)
            const localVarPath = `/api/v1/master-data/transformers/{transformerSid}`.replace(
                `{${'transformerSid'}}`,
                encodeURIComponent(String(transformerSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get master data transformers based on user\'s asset permissions.
         * @summary Get master data transformers based on user\'s asset permissions. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformers: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/master-data/transformers`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * MasterDataApi - functional programming interface
 * @export
 */
export const MasterDataApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MasterDataApiAxiosParamCreator(configuration)
    return {
        /**
         * Lists master data plants based on user\'s asset permissions.
         * @summary Lists master data plants based on user\'s asset permissions. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlants(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMasterDataPlantsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlants(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['MasterDataApi.getPlants']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get transformer master data.
         * @summary Get transformer master data. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransformerDetails(
            transformerSid: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<GetMasterDataTransformerDetailsResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransformerDetails(transformerSid, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['MasterDataApi.getTransformerDetails']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get master data transformers based on user\'s asset permissions.
         * @summary Get master data transformers based on user\'s asset permissions. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransformers(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMasterDataTransformersResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransformers(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['MasterDataApi.getTransformers']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * MasterDataApi - factory interface
 * @export
 */
export const MasterDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MasterDataApiFp(configuration)
    return {
        /**
         * Lists master data plants based on user\'s asset permissions.
         * @summary Lists master data plants based on user\'s asset permissions. (Auth)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlants(request?: object, options?: any): AxiosPromise<GetMasterDataPlantsResponseOkResponse> {
            return localVarFp.getPlants(request, options).then((request) => request(axios, basePath))
        },
        /**
         * Get transformer master data.
         * @summary Get transformer master data. (Auth policies: CanViewTransformerData)
         * @param {string} transformerSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerDetails(
            transformerSid: string,
            options?: any,
        ): AxiosPromise<GetMasterDataTransformerDetailsResponseOkResponse> {
            return localVarFp.getTransformerDetails(transformerSid, options).then((request) => request(axios, basePath))
        },
        /**
         * Get master data transformers based on user\'s asset permissions.
         * @summary Get master data transformers based on user\'s asset permissions. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformers(request?: object, options?: any): AxiosPromise<GetMasterDataTransformersResponseOkResponse> {
            return localVarFp.getTransformers(request, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * MasterDataApi - interface
 * @export
 * @interface MasterDataApi
 */
export interface MasterDataApiInterface {
    /**
     * Lists master data plants based on user\'s asset permissions.
     * @summary Lists master data plants based on user\'s asset permissions. (Auth)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    getPlants(request?: object, options?: AxiosRequestConfig): AxiosPromise<GetMasterDataPlantsResponseOkResponse>

    /**
     * Get transformer master data.
     * @summary Get transformer master data. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    getTransformerDetails(
        transformerSid: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetMasterDataTransformerDetailsResponseOkResponse>

    /**
     * Get master data transformers based on user\'s asset permissions.
     * @summary Get master data transformers based on user\'s asset permissions. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApiInterface
     */
    getTransformers(
        request?: object,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetMasterDataTransformersResponseOkResponse>
}

/**
 * MasterDataApi - object-oriented interface
 * @export
 * @class MasterDataApi
 * @extends {BaseAPI}
 */
export class MasterDataApi extends BaseAPI implements MasterDataApiInterface {
    /**
     * Lists master data plants based on user\'s asset permissions.
     * @summary Lists master data plants based on user\'s asset permissions. (Auth)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public getPlants(request?: object, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration)
            .getPlants(request, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get transformer master data.
     * @summary Get transformer master data. (Auth policies: CanViewTransformerData)
     * @param {string} transformerSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public getTransformerDetails(transformerSid: string, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration)
            .getTransformerDetails(transformerSid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get master data transformers based on user\'s asset permissions.
     * @summary Get master data transformers based on user\'s asset permissions. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MasterDataApi
     */
    public getTransformers(request?: object, options?: AxiosRequestConfig) {
        return MasterDataApiFp(this.configuration)
            .getTransformers(request, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get ping
         * @summary Get ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ping`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * Get ping
         * @summary Get ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingGetResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['PingApi.ping']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * Get ping
         * @summary Get ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<PingGetResponseOkResponse> {
            return localVarFp.ping(options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * PingApi - interface
 * @export
 * @interface PingApi
 */
export interface PingApiInterface {
    /**
     * Get ping
     * @summary Get ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApiInterface
     */
    ping(options?: AxiosRequestConfig): AxiosPromise<PingGetResponseOkResponse>
}

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI implements PingApiInterface {
    /**
     * Get ping
     * @summary Get ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public ping(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration)
            .ping(options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * PortfolioOverviewApi - axios parameter creator
 * @export
 */
export const PortfolioOverviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get portfolio status summary at a plant group level.
         * @summary Get portfolio status summary at a plant group level. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantGroupPortfolioSummary: async (
            request?: object,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portfolio-overview/transformer/summary/plant-group`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get portfolio status summary at a plant level.
         * @summary Get portfolio status summary at a plant level. (Auth policies: CanViewTransformerData)
         * @param {number} plantGroupSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantPortfolioSummary: async (
            plantGroupSid: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'plantGroupSid' is not null or undefined
            assertParamExists('getPlantPortfolioSummary', 'plantGroupSid', plantGroupSid)
            const localVarPath = `/api/v1/portfolio-overview/transformer/summary/plant/{plantGroupSid}`.replace(
                `{${'plantGroupSid'}}`,
                encodeURIComponent(String(plantGroupSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get portfolio status summary.
         * @summary Get portfolio status summary. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerPortfolioSummary: async (
            request?: object,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portfolio-overview/transformer/summary`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List flagged transformers by plant sids and issue type.
         * @summary List flagged transformers by plant sids and issue type. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {number} [issueType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFlaggedTransformers: async (
            plantSids?: Array<number>,
            issueType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portfolio-overview/transformer/flagged`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (plantSids) {
                localVarQueryParameter['plantSids'] = plantSids
            }

            if (issueType !== undefined) {
                localVarQueryParameter['issueType'] = issueType
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * PortfolioOverviewApi - functional programming interface
 * @export
 */
export const PortfolioOverviewApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioOverviewApiAxiosParamCreator(configuration)
    return {
        /**
         * Get portfolio status summary at a plant group level.
         * @summary Get portfolio status summary at a plant group level. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlantGroupPortfolioSummary(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlantGroupStatusSummaryResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlantGroupPortfolioSummary(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['PortfolioOverviewApi.getPlantGroupPortfolioSummary']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get portfolio status summary at a plant level.
         * @summary Get portfolio status summary at a plant level. (Auth policies: CanViewTransformerData)
         * @param {number} plantGroupSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPlantPortfolioSummary(
            plantGroupSid: number,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPlantStatusSummaryResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPlantPortfolioSummary(plantGroupSid, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['PortfolioOverviewApi.getPlantPortfolioSummary']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get portfolio status summary.
         * @summary Get portfolio status summary. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransformerPortfolioSummary(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStatusSummaryResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransformerPortfolioSummary(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['PortfolioOverviewApi.getTransformerPortfolioSummary']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List flagged transformers by plant sids and issue type.
         * @summary List flagged transformers by plant sids and issue type. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {number} [issueType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFlaggedTransformers(
            plantSids?: Array<number>,
            issueType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListFlaggedTransformersResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFlaggedTransformers(
                plantSids,
                issueType,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['PortfolioOverviewApi.listFlaggedTransformers']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * PortfolioOverviewApi - factory interface
 * @export
 */
export const PortfolioOverviewApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = PortfolioOverviewApiFp(configuration)
    return {
        /**
         * Get portfolio status summary at a plant group level.
         * @summary Get portfolio status summary at a plant group level. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantGroupPortfolioSummary(
            request?: object,
            options?: any,
        ): AxiosPromise<GetPlantGroupStatusSummaryResponseOkResponse> {
            return localVarFp
                .getPlantGroupPortfolioSummary(request, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get portfolio status summary at a plant level.
         * @summary Get portfolio status summary at a plant level. (Auth policies: CanViewTransformerData)
         * @param {number} plantGroupSid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlantPortfolioSummary(
            plantGroupSid: number,
            options?: any,
        ): AxiosPromise<GetPlantStatusSummaryResponseOkResponse> {
            return localVarFp
                .getPlantPortfolioSummary(plantGroupSid, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get portfolio status summary.
         * @summary Get portfolio status summary. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerPortfolioSummary(
            request?: object,
            options?: any,
        ): AxiosPromise<GetStatusSummaryResponseOkResponse> {
            return localVarFp
                .getTransformerPortfolioSummary(request, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * List flagged transformers by plant sids and issue type.
         * @summary List flagged transformers by plant sids and issue type. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {number} [issueType]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFlaggedTransformers(
            plantSids?: Array<number>,
            issueType?: number,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListFlaggedTransformersResponseOkResponse> {
            return localVarFp
                .listFlaggedTransformers(plantSids, issueType, page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * PortfolioOverviewApi - interface
 * @export
 * @interface PortfolioOverviewApi
 */
export interface PortfolioOverviewApiInterface {
    /**
     * Get portfolio status summary at a plant group level.
     * @summary Get portfolio status summary at a plant group level. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApiInterface
     */
    getPlantGroupPortfolioSummary(
        request?: object,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetPlantGroupStatusSummaryResponseOkResponse>

    /**
     * Get portfolio status summary at a plant level.
     * @summary Get portfolio status summary at a plant level. (Auth policies: CanViewTransformerData)
     * @param {number} plantGroupSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApiInterface
     */
    getPlantPortfolioSummary(
        plantGroupSid: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetPlantStatusSummaryResponseOkResponse>

    /**
     * Get portfolio status summary.
     * @summary Get portfolio status summary. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApiInterface
     */
    getTransformerPortfolioSummary(
        request?: object,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetStatusSummaryResponseOkResponse>

    /**
     * List flagged transformers by plant sids and issue type.
     * @summary List flagged transformers by plant sids and issue type. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {number} [issueType]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApiInterface
     */
    listFlaggedTransformers(
        plantSids?: Array<number>,
        issueType?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListFlaggedTransformersResponseOkResponse>
}

/**
 * PortfolioOverviewApi - object-oriented interface
 * @export
 * @class PortfolioOverviewApi
 * @extends {BaseAPI}
 */
export class PortfolioOverviewApi extends BaseAPI implements PortfolioOverviewApiInterface {
    /**
     * Get portfolio status summary at a plant group level.
     * @summary Get portfolio status summary at a plant group level. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApi
     */
    public getPlantGroupPortfolioSummary(request?: object, options?: AxiosRequestConfig) {
        return PortfolioOverviewApiFp(this.configuration)
            .getPlantGroupPortfolioSummary(request, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get portfolio status summary at a plant level.
     * @summary Get portfolio status summary at a plant level. (Auth policies: CanViewTransformerData)
     * @param {number} plantGroupSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApi
     */
    public getPlantPortfolioSummary(plantGroupSid: number, options?: AxiosRequestConfig) {
        return PortfolioOverviewApiFp(this.configuration)
            .getPlantPortfolioSummary(plantGroupSid, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get portfolio status summary.
     * @summary Get portfolio status summary. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApi
     */
    public getTransformerPortfolioSummary(request?: object, options?: AxiosRequestConfig) {
        return PortfolioOverviewApiFp(this.configuration)
            .getTransformerPortfolioSummary(request, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List flagged transformers by plant sids and issue type.
     * @summary List flagged transformers by plant sids and issue type. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {number} [issueType]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioOverviewApi
     */
    public listFlaggedTransformers(
        plantSids?: Array<number>,
        issueType?: number,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return PortfolioOverviewApiFp(this.configuration)
            .listFlaggedTransformers(plantSids, issueType, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * PortfolioTrendsApi - axios parameter creator
 * @export
 */
export const PortfolioTrendsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get status number of the transformers by age.
         * @summary Get status number of the transformers by age. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {string} [endDate]
         * @param {number} [transformerAgeType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerStatusesByAge: async (
            plantSids?: Array<number>,
            endDate?: string,
            transformerAgeType?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portfolio-trends/transformer/condition-status-by-age`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (plantSids) {
                localVarQueryParameter['PlantSids'] = plantSids
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] =
                    (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate
            }

            if (transformerAgeType !== undefined) {
                localVarQueryParameter['TransformerAgeType'] = transformerAgeType
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get the yearly status number of the transformers.
         * @summary Get the yearly status number of the transformers. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerYearlyStatuses: async (
            plantSids?: Array<number>,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/portfolio-trends/transformer/condition-status-yearly`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (plantSids) {
                localVarQueryParameter['PlantSids'] = plantSids
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * PortfolioTrendsApi - functional programming interface
 * @export
 */
export const PortfolioTrendsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PortfolioTrendsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get status number of the transformers by age.
         * @summary Get status number of the transformers by age. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {string} [endDate]
         * @param {number} [transformerAgeType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransformerStatusesByAge(
            plantSids?: Array<number>,
            endDate?: string,
            transformerAgeType?: number,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConditionStatusByAgeResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransformerStatusesByAge(
                plantSids,
                endDate,
                transformerAgeType,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['PortfolioTrendsApi.getTransformerStatusesByAge']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get the yearly status number of the transformers.
         * @summary Get the yearly status number of the transformers. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransformerYearlyStatuses(
            plantSids?: Array<number>,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConditionStatusYearlyResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransformerYearlyStatuses(plantSids, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['PortfolioTrendsApi.getTransformerYearlyStatuses']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * PortfolioTrendsApi - factory interface
 * @export
 */
export const PortfolioTrendsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = PortfolioTrendsApiFp(configuration)
    return {
        /**
         * Get status number of the transformers by age.
         * @summary Get status number of the transformers by age. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {string} [endDate]
         * @param {number} [transformerAgeType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerStatusesByAge(
            plantSids?: Array<number>,
            endDate?: string,
            transformerAgeType?: number,
            options?: any,
        ): AxiosPromise<GetConditionStatusByAgeResponseOkResponse> {
            return localVarFp
                .getTransformerStatusesByAge(plantSids, endDate, transformerAgeType, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get the yearly status number of the transformers.
         * @summary Get the yearly status number of the transformers. (Auth policies: CanViewTransformerData)
         * @param {Array<number>} [plantSids]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransformerYearlyStatuses(
            plantSids?: Array<number>,
            options?: any,
        ): AxiosPromise<GetConditionStatusYearlyResponseOkResponse> {
            return localVarFp
                .getTransformerYearlyStatuses(plantSids, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * PortfolioTrendsApi - interface
 * @export
 * @interface PortfolioTrendsApi
 */
export interface PortfolioTrendsApiInterface {
    /**
     * Get status number of the transformers by age.
     * @summary Get status number of the transformers by age. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {string} [endDate]
     * @param {number} [transformerAgeType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTrendsApiInterface
     */
    getTransformerStatusesByAge(
        plantSids?: Array<number>,
        endDate?: string,
        transformerAgeType?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetConditionStatusByAgeResponseOkResponse>

    /**
     * Get the yearly status number of the transformers.
     * @summary Get the yearly status number of the transformers. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTrendsApiInterface
     */
    getTransformerYearlyStatuses(
        plantSids?: Array<number>,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetConditionStatusYearlyResponseOkResponse>
}

/**
 * PortfolioTrendsApi - object-oriented interface
 * @export
 * @class PortfolioTrendsApi
 * @extends {BaseAPI}
 */
export class PortfolioTrendsApi extends BaseAPI implements PortfolioTrendsApiInterface {
    /**
     * Get status number of the transformers by age.
     * @summary Get status number of the transformers by age. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {string} [endDate]
     * @param {number} [transformerAgeType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTrendsApi
     */
    public getTransformerStatusesByAge(
        plantSids?: Array<number>,
        endDate?: string,
        transformerAgeType?: number,
        options?: AxiosRequestConfig,
    ) {
        return PortfolioTrendsApiFp(this.configuration)
            .getTransformerStatusesByAge(plantSids, endDate, transformerAgeType, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get the yearly status number of the transformers.
     * @summary Get the yearly status number of the transformers. (Auth policies: CanViewTransformerData)
     * @param {Array<number>} [plantSids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortfolioTrendsApi
     */
    public getTransformerYearlyStatuses(plantSids?: Array<number>, options?: AxiosRequestConfig) {
        return PortfolioTrendsApiFp(this.configuration)
            .getTransformerYearlyStatuses(plantSids, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * RecommendationTypesApi - axios parameter creator
 * @export
 */
export const RecommendationTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new recommendation type.
         * @summary Add a new recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {AddRecommendationTypeRequest} [addRecommendationTypeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecommendationType: async (
            addRecommendationTypeRequest?: AddRecommendationTypeRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recommendation-types`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                addRecommendationTypeRequest,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Edits a recommendation type.
         * @summary Edits a recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} recommendationTypeId
         * @param {RecommendationTypeRequestDto} [recommendationTypeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRecommendationType: async (
            recommendationTypeId: number,
            recommendationTypeRequestDto?: RecommendationTypeRequestDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'recommendationTypeId' is not null or undefined
            assertParamExists('editRecommendationType', 'recommendationTypeId', recommendationTypeId)
            const localVarPath = `/api/v1/recommendation-types/{recommendationTypeId}`.replace(
                `{${'recommendationTypeId'}}`,
                encodeURIComponent(String(recommendationTypeId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                recommendationTypeRequestDto,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get recommendation type details by id.
         * @summary Get recommendation type details by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationType: async (
            recommendationTypeId: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'recommendationTypeId' is not null or undefined
            assertParamExists('getRecommendationType', 'recommendationTypeId', recommendationTypeId)
            const localVarPath = `/api/v1/recommendation-types/{recommendationTypeId}`.replace(
                `{${'recommendationTypeId'}}`,
                encodeURIComponent(String(recommendationTypeId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List recommendation types.
         * @summary List recommendation types. (Auth policies: CanViewRecommendations)
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendationTypes: async (
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/recommendation-types`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * RecommendationTypesApi - functional programming interface
 * @export
 */
export const RecommendationTypesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RecommendationTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new recommendation type.
         * @summary Add a new recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {AddRecommendationTypeRequest} [addRecommendationTypeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecommendationType(
            addRecommendationTypeRequest?: AddRecommendationTypeRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddRecommendationTypeResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecommendationType(
                addRecommendationTypeRequest,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationTypesApi.addRecommendationType']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Edits a recommendation type.
         * @summary Edits a recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} recommendationTypeId
         * @param {RecommendationTypeRequestDto} [recommendationTypeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editRecommendationType(
            recommendationTypeId: number,
            recommendationTypeRequestDto?: RecommendationTypeRequestDto,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditRecommendationTypeResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editRecommendationType(
                recommendationTypeId,
                recommendationTypeRequestDto,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationTypesApi.editRecommendationType']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get recommendation type details by id.
         * @summary Get recommendation type details by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendationType(
            recommendationTypeId: number,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecommendationTypeResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendationType(
                recommendationTypeId,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationTypesApi.getRecommendationType']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List recommendation types.
         * @summary List recommendation types. (Auth policies: CanViewRecommendations)
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecommendationTypes(
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRecommendationTypesResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecommendationTypes(
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationTypesApi.listRecommendationTypes']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * RecommendationTypesApi - factory interface
 * @export
 */
export const RecommendationTypesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = RecommendationTypesApiFp(configuration)
    return {
        /**
         * Add a new recommendation type.
         * @summary Add a new recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {AddRecommendationTypeRequest} [addRecommendationTypeRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecommendationType(
            addRecommendationTypeRequest?: AddRecommendationTypeRequest,
            options?: any,
        ): AxiosPromise<AddRecommendationTypeResponseOkResponse> {
            return localVarFp
                .addRecommendationType(addRecommendationTypeRequest, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Edits a recommendation type.
         * @summary Edits a recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} recommendationTypeId
         * @param {RecommendationTypeRequestDto} [recommendationTypeRequestDto]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRecommendationType(
            recommendationTypeId: number,
            recommendationTypeRequestDto?: RecommendationTypeRequestDto,
            options?: any,
        ): AxiosPromise<EditRecommendationTypeResponseOkResponse> {
            return localVarFp
                .editRecommendationType(recommendationTypeId, recommendationTypeRequestDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get recommendation type details by id.
         * @summary Get recommendation type details by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationTypeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendationType(
            recommendationTypeId: number,
            options?: any,
        ): AxiosPromise<GetRecommendationTypeResponseOkResponse> {
            return localVarFp
                .getRecommendationType(recommendationTypeId, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * List recommendation types.
         * @summary List recommendation types. (Auth policies: CanViewRecommendations)
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendationTypes(
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListRecommendationTypesResponseOkResponse> {
            return localVarFp
                .listRecommendationTypes(page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * RecommendationTypesApi - interface
 * @export
 * @interface RecommendationTypesApi
 */
export interface RecommendationTypesApiInterface {
    /**
     * Add a new recommendation type.
     * @summary Add a new recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {AddRecommendationTypeRequest} [addRecommendationTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApiInterface
     */
    addRecommendationType(
        addRecommendationTypeRequest?: AddRecommendationTypeRequest,
        options?: AxiosRequestConfig,
    ): AxiosPromise<AddRecommendationTypeResponseOkResponse>

    /**
     * Edits a recommendation type.
     * @summary Edits a recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} recommendationTypeId
     * @param {RecommendationTypeRequestDto} [recommendationTypeRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApiInterface
     */
    editRecommendationType(
        recommendationTypeId: number,
        recommendationTypeRequestDto?: RecommendationTypeRequestDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<EditRecommendationTypeResponseOkResponse>

    /**
     * Get recommendation type details by id.
     * @summary Get recommendation type details by id. (Auth policies: CanViewRecommendations)
     * @param {number} recommendationTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApiInterface
     */
    getRecommendationType(
        recommendationTypeId: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetRecommendationTypeResponseOkResponse>

    /**
     * List recommendation types.
     * @summary List recommendation types. (Auth policies: CanViewRecommendations)
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApiInterface
     */
    listRecommendationTypes(
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListRecommendationTypesResponseOkResponse>
}

/**
 * RecommendationTypesApi - object-oriented interface
 * @export
 * @class RecommendationTypesApi
 * @extends {BaseAPI}
 */
export class RecommendationTypesApi extends BaseAPI implements RecommendationTypesApiInterface {
    /**
     * Add a new recommendation type.
     * @summary Add a new recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {AddRecommendationTypeRequest} [addRecommendationTypeRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApi
     */
    public addRecommendationType(
        addRecommendationTypeRequest?: AddRecommendationTypeRequest,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationTypesApiFp(this.configuration)
            .addRecommendationType(addRecommendationTypeRequest, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Edits a recommendation type.
     * @summary Edits a recommendation type. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} recommendationTypeId
     * @param {RecommendationTypeRequestDto} [recommendationTypeRequestDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApi
     */
    public editRecommendationType(
        recommendationTypeId: number,
        recommendationTypeRequestDto?: RecommendationTypeRequestDto,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationTypesApiFp(this.configuration)
            .editRecommendationType(recommendationTypeId, recommendationTypeRequestDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get recommendation type details by id.
     * @summary Get recommendation type details by id. (Auth policies: CanViewRecommendations)
     * @param {number} recommendationTypeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApi
     */
    public getRecommendationType(recommendationTypeId: number, options?: AxiosRequestConfig) {
        return RecommendationTypesApiFp(this.configuration)
            .getRecommendationType(recommendationTypeId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List recommendation types.
     * @summary List recommendation types. (Auth policies: CanViewRecommendations)
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationTypesApi
     */
    public listRecommendationTypes(
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationTypesApiFp(this.configuration)
            .listRecommendationTypes(page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * RecommendationsApi - axios parameter creator
 * @export
 */
export const RecommendationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a recommendation to a component.
         * @summary Add a recommendation to a component. (Auth policies: CanEditTransformerRecommendations)
         * @param {string} componentSid The sid of the transformer to which the recommendation is added.
         * @param {RecommendationAddDto} [recommendationAddDto] The data needed for the new recommendation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecommendation: async (
            componentSid: string,
            recommendationAddDto?: RecommendationAddDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'componentSid' is not null or undefined
            assertParamExists('addRecommendation', 'componentSid', componentSid)
            const localVarPath = `/api/v1/recommendations/{componentSid}`.replace(
                `{${'componentSid'}}`,
                encodeURIComponent(String(componentSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                recommendationAddDto,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Updates a recommendation by id.
         * @summary Updates a recommendation by id. (Auth policies: CanEditTransformerRecommendations)
         * @param {number} recommendationId The id of the comment to be updated.
         * @param {RecommendationEditDto} [recommendationEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRecommendation: async (
            recommendationId: number,
            recommendationEditDto?: RecommendationEditDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'recommendationId' is not null or undefined
            assertParamExists('editRecommendation', 'recommendationId', recommendationId)
            const localVarPath = `/api/v1/recommendations/{recommendationId}`.replace(
                `{${'recommendationId'}}`,
                encodeURIComponent(String(recommendationId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(
                recommendationEditDto,
                localVarRequestOptions,
                configuration,
            )

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get recommendation by id.
         * @summary Get recommendation by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendation: async (recommendationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'recommendationId' is not null or undefined
            assertParamExists('getRecommendation', 'recommendationId', recommendationId)
            const localVarPath = `/api/v1/recommendations/{recommendationId}`.replace(
                `{${'recommendationId'}}`,
                encodeURIComponent(String(recommendationId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List recommendations by component sid.
         * @summary List recommendations by component sid. (Auth policies: CanViewRecommendations)
         * @param {string} componentSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComponentRecommendations: async (
            componentSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'componentSid' is not null or undefined
            assertParamExists('listComponentRecommendations', 'componentSid', componentSid)
            const localVarPath = `/api/v1/recommendations/{componentSid}/components`.replace(
                `{${'componentSid'}}`,
                encodeURIComponent(String(componentSid)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List recommendations for all requested plants and component types.
         * @summary List recommendations for all requested plants and component types. (Auth policies: CanViewRecommendations)
         * @param {Array<number>} plantSids
         * @param {Array<number>} componentTypes
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendations: async (
            plantSids: Array<number>,
            componentTypes: Array<number>,
            startDate?: string,
            endDate?: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'plantSids' is not null or undefined
            assertParamExists('listRecommendations', 'plantSids', plantSids)
            // verify required parameter 'componentTypes' is not null or undefined
            assertParamExists('listRecommendations', 'componentTypes', componentTypes)
            const localVarPath = `/api/v1/recommendations/component-types`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (plantSids) {
                localVarQueryParameter['PlantSids'] = plantSids
            }

            if (componentTypes) {
                localVarQueryParameter['ComponentTypes'] = componentTypes
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] =
                    (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] =
                    (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * RecommendationsApi - functional programming interface
 * @export
 */
export const RecommendationsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = RecommendationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a recommendation to a component.
         * @summary Add a recommendation to a component. (Auth policies: CanEditTransformerRecommendations)
         * @param {string} componentSid The sid of the transformer to which the recommendation is added.
         * @param {RecommendationAddDto} [recommendationAddDto] The data needed for the new recommendation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRecommendation(
            componentSid: string,
            recommendationAddDto?: RecommendationAddDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddRecommendationResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRecommendation(
                componentSid,
                recommendationAddDto,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationsApi.addRecommendation']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Updates a recommendation by id.
         * @summary Updates a recommendation by id. (Auth policies: CanEditTransformerRecommendations)
         * @param {number} recommendationId The id of the comment to be updated.
         * @param {RecommendationEditDto} [recommendationEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editRecommendation(
            recommendationId: number,
            recommendationEditDto?: RecommendationEditDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditRecommendationResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editRecommendation(
                recommendationId,
                recommendationEditDto,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationsApi.editRecommendation']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get recommendation by id.
         * @summary Get recommendation by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecommendation(
            recommendationId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecommendationResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecommendation(recommendationId, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationsApi.getRecommendation']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List recommendations by component sid.
         * @summary List recommendations by component sid. (Auth policies: CanViewRecommendations)
         * @param {string} componentSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComponentRecommendations(
            componentSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListComponentRecommendationsResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listComponentRecommendations(
                componentSid,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath =
                operationServerMap['RecommendationsApi.listComponentRecommendations']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List recommendations for all requested plants and component types.
         * @summary List recommendations for all requested plants and component types. (Auth policies: CanViewRecommendations)
         * @param {Array<number>} plantSids
         * @param {Array<number>} componentTypes
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRecommendations(
            plantSids: Array<number>,
            componentTypes: Array<number>,
            startDate?: string,
            endDate?: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ListRecommendationsByComponentTypeResponseOkResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRecommendations(
                plantSids,
                componentTypes,
                startDate,
                endDate,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['RecommendationsApi.listRecommendations']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * RecommendationsApi - factory interface
 * @export
 */
export const RecommendationsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = RecommendationsApiFp(configuration)
    return {
        /**
         * Add a recommendation to a component.
         * @summary Add a recommendation to a component. (Auth policies: CanEditTransformerRecommendations)
         * @param {string} componentSid The sid of the transformer to which the recommendation is added.
         * @param {RecommendationAddDto} [recommendationAddDto] The data needed for the new recommendation.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecommendation(
            componentSid: string,
            recommendationAddDto?: RecommendationAddDto,
            options?: any,
        ): AxiosPromise<AddRecommendationResponseOkResponse> {
            return localVarFp
                .addRecommendation(componentSid, recommendationAddDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Updates a recommendation by id.
         * @summary Updates a recommendation by id. (Auth policies: CanEditTransformerRecommendations)
         * @param {number} recommendationId The id of the comment to be updated.
         * @param {RecommendationEditDto} [recommendationEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editRecommendation(
            recommendationId: number,
            recommendationEditDto?: RecommendationEditDto,
            options?: any,
        ): AxiosPromise<EditRecommendationResponseOkResponse> {
            return localVarFp
                .editRecommendation(recommendationId, recommendationEditDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get recommendation by id.
         * @summary Get recommendation by id. (Auth policies: CanViewRecommendations)
         * @param {number} recommendationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecommendation(recommendationId: number, options?: any): AxiosPromise<GetRecommendationResponseOkResponse> {
            return localVarFp.getRecommendation(recommendationId, options).then((request) => request(axios, basePath))
        },
        /**
         * List recommendations by component sid.
         * @summary List recommendations by component sid. (Auth policies: CanViewRecommendations)
         * @param {string} componentSid
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComponentRecommendations(
            componentSid: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListComponentRecommendationsResponseOkResponse> {
            return localVarFp
                .listComponentRecommendations(componentSid, page, size, term, termToLower, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * List recommendations for all requested plants and component types.
         * @summary List recommendations for all requested plants and component types. (Auth policies: CanViewRecommendations)
         * @param {Array<number>} plantSids
         * @param {Array<number>} componentTypes
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRecommendations(
            plantSids: Array<number>,
            componentTypes: Array<number>,
            startDate?: string,
            endDate?: string,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListRecommendationsByComponentTypeResponseOkResponse> {
            return localVarFp
                .listRecommendations(
                    plantSids,
                    componentTypes,
                    startDate,
                    endDate,
                    page,
                    size,
                    term,
                    termToLower,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * RecommendationsApi - interface
 * @export
 * @interface RecommendationsApi
 */
export interface RecommendationsApiInterface {
    /**
     * Add a recommendation to a component.
     * @summary Add a recommendation to a component. (Auth policies: CanEditTransformerRecommendations)
     * @param {string} componentSid The sid of the transformer to which the recommendation is added.
     * @param {RecommendationAddDto} [recommendationAddDto] The data needed for the new recommendation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApiInterface
     */
    addRecommendation(
        componentSid: string,
        recommendationAddDto?: RecommendationAddDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<AddRecommendationResponseOkResponse>

    /**
     * Updates a recommendation by id.
     * @summary Updates a recommendation by id. (Auth policies: CanEditTransformerRecommendations)
     * @param {number} recommendationId The id of the comment to be updated.
     * @param {RecommendationEditDto} [recommendationEditDto] The updated data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApiInterface
     */
    editRecommendation(
        recommendationId: number,
        recommendationEditDto?: RecommendationEditDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<EditRecommendationResponseOkResponse>

    /**
     * Get recommendation by id.
     * @summary Get recommendation by id. (Auth policies: CanViewRecommendations)
     * @param {number} recommendationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApiInterface
     */
    getRecommendation(
        recommendationId: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetRecommendationResponseOkResponse>

    /**
     * List recommendations by component sid.
     * @summary List recommendations by component sid. (Auth policies: CanViewRecommendations)
     * @param {string} componentSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApiInterface
     */
    listComponentRecommendations(
        componentSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListComponentRecommendationsResponseOkResponse>

    /**
     * List recommendations for all requested plants and component types.
     * @summary List recommendations for all requested plants and component types. (Auth policies: CanViewRecommendations)
     * @param {Array<number>} plantSids
     * @param {Array<number>} componentTypes
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApiInterface
     */
    listRecommendations(
        plantSids: Array<number>,
        componentTypes: Array<number>,
        startDate?: string,
        endDate?: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListRecommendationsByComponentTypeResponseOkResponse>
}

/**
 * RecommendationsApi - object-oriented interface
 * @export
 * @class RecommendationsApi
 * @extends {BaseAPI}
 */
export class RecommendationsApi extends BaseAPI implements RecommendationsApiInterface {
    /**
     * Add a recommendation to a component.
     * @summary Add a recommendation to a component. (Auth policies: CanEditTransformerRecommendations)
     * @param {string} componentSid The sid of the transformer to which the recommendation is added.
     * @param {RecommendationAddDto} [recommendationAddDto] The data needed for the new recommendation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public addRecommendation(
        componentSid: string,
        recommendationAddDto?: RecommendationAddDto,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationsApiFp(this.configuration)
            .addRecommendation(componentSid, recommendationAddDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Updates a recommendation by id.
     * @summary Updates a recommendation by id. (Auth policies: CanEditTransformerRecommendations)
     * @param {number} recommendationId The id of the comment to be updated.
     * @param {RecommendationEditDto} [recommendationEditDto] The updated data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public editRecommendation(
        recommendationId: number,
        recommendationEditDto?: RecommendationEditDto,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationsApiFp(this.configuration)
            .editRecommendation(recommendationId, recommendationEditDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get recommendation by id.
     * @summary Get recommendation by id. (Auth policies: CanViewRecommendations)
     * @param {number} recommendationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public getRecommendation(recommendationId: number, options?: AxiosRequestConfig) {
        return RecommendationsApiFp(this.configuration)
            .getRecommendation(recommendationId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List recommendations by component sid.
     * @summary List recommendations by component sid. (Auth policies: CanViewRecommendations)
     * @param {string} componentSid
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public listComponentRecommendations(
        componentSid: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationsApiFp(this.configuration)
            .listComponentRecommendations(componentSid, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List recommendations for all requested plants and component types.
     * @summary List recommendations for all requested plants and component types. (Auth policies: CanViewRecommendations)
     * @param {Array<number>} plantSids
     * @param {Array<number>} componentTypes
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecommendationsApi
     */
    public listRecommendations(
        plantSids: Array<number>,
        componentTypes: Array<number>,
        startDate?: string,
        endDate?: string,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return RecommendationsApiFp(this.configuration)
            .listRecommendations(plantSids, componentTypes, startDate, endDate, page, size, term, termToLower, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Exports an excel according to the filters.
         * @summary Exports an excel according to the filters. (Auth policies: CanExportReports)
         * @param {number} exportType
         * @param {Array<number>} [groupSids]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [componentTypes]
         * @param {Array<string>} [componentSids]
         * @param {number} [languageType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReport: async (
            exportType: number,
            groupSids?: Array<number>,
            startDate?: string,
            endDate?: string,
            plantSids?: Array<number>,
            componentTypes?: Array<number>,
            componentSids?: Array<string>,
            languageType?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'exportType' is not null or undefined
            assertParamExists('exportReport', 'exportType', exportType)
            const localVarPath = `/api/v1/reports/{exportType}/export`.replace(
                `{${'exportType'}}`,
                encodeURIComponent(String(exportType)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (groupSids) {
                localVarQueryParameter['groupSids'] = groupSids
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] =
                    (startDate as any) instanceof Date ? (startDate as any).toISOString() : startDate
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] =
                    (endDate as any) instanceof Date ? (endDate as any).toISOString() : endDate
            }

            if (plantSids) {
                localVarQueryParameter['plantSids'] = plantSids
            }

            if (componentTypes) {
                localVarQueryParameter['componentTypes'] = componentTypes
            }

            if (componentSids) {
                localVarQueryParameter['componentSids'] = componentSids
            }

            if (languageType !== undefined) {
                localVarQueryParameter['languageType'] = languageType
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get report details by id.
         * @summary Get report details by id. (Auth policies: CanViewReports)
         * @param {string} reportId
         * @param {number} [reportType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport: async (
            reportId: string,
            reportType?: number,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReport', 'reportId', reportId)
            const localVarPath = `/api/v1/reports/{reportId}`.replace(
                `{${'reportId'}}`,
                encodeURIComponent(String(reportId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List reports by plant sids.
         * @summary List reports by plant sids. (Auth policies: CanViewReports)
         * @param {Array<number>} componentTypes
         * @param {Array<number>} [plantSids]
         * @param {number} [reportType]
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {SortProjectType} [sortingSortProjectType]   1 &#x3D; Plant  2 &#x3D; ReportType  3 &#x3D; Status  4 &#x3D; Excluded
         * @param {boolean} [sortingSortAscending]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (
            componentTypes: Array<number>,
            plantSids?: Array<number>,
            reportType?: number,
            fromDate?: string,
            toDate?: string,
            sortingSortProjectType?: SortProjectType,
            sortingSortAscending?: boolean,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'componentTypes' is not null or undefined
            assertParamExists('listReports', 'componentTypes', componentTypes)
            const localVarPath = `/api/v1/reports`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (plantSids) {
                localVarQueryParameter['plantSids'] = plantSids
            }

            if (reportType !== undefined) {
                localVarQueryParameter['reportType'] = reportType
            }

            if (componentTypes) {
                localVarQueryParameter['componentTypes'] = componentTypes
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] =
                    (fromDate as any) instanceof Date ? (fromDate as any).toISOString() : fromDate
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] =
                    (toDate as any) instanceof Date ? (toDate as any).toISOString() : toDate
            }

            if (sortingSortProjectType !== undefined) {
                localVarQueryParameter['sorting.SortProjectType'] = sortingSortProjectType
            }

            if (sortingSortAscending !== undefined) {
                localVarQueryParameter['sorting.SortAscending'] = sortingSortAscending
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size
            }

            if (term !== undefined) {
                localVarQueryParameter['Term'] = term
            }

            if (termToLower !== undefined) {
                localVarQueryParameter['TermToLower'] = termToLower
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Sets the exclusion flag on a report.
         * @summary Sets the exclusion flag on a report. (Auth policies: CanExcludeReports)
         * @param {string} analyticalNumber
         * @param {boolean} [isExcluded]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIsExcludeFlag: async (
            analyticalNumber: string,
            isExcluded?: boolean,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'analyticalNumber' is not null or undefined
            assertParamExists('setIsExcludeFlag', 'analyticalNumber', analyticalNumber)
            const localVarPath = `/api/v1/reports/{analyticalNumber}/exclusion`.replace(
                `{${'analyticalNumber'}}`,
                encodeURIComponent(String(analyticalNumber)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (isExcluded !== undefined) {
                localVarQueryParameter['IsExcluded'] = isExcluded
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * Exports an excel according to the filters.
         * @summary Exports an excel according to the filters. (Auth policies: CanExportReports)
         * @param {number} exportType
         * @param {Array<number>} [groupSids]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [componentTypes]
         * @param {Array<string>} [componentSids]
         * @param {number} [languageType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportReport(
            exportType: number,
            groupSids?: Array<number>,
            startDate?: string,
            endDate?: string,
            plantSids?: Array<number>,
            componentTypes?: Array<number>,
            componentSids?: Array<string>,
            languageType?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportReport(
                exportType,
                groupSids,
                startDate,
                endDate,
                plantSids,
                componentTypes,
                componentSids,
                languageType,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ReportsApi.exportReport']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get report details by id.
         * @summary Get report details by id. (Auth policies: CanViewReports)
         * @param {string} reportId
         * @param {number} [reportType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReport(
            reportId: string,
            reportType?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReportResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReport(reportId, reportType, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ReportsApi.getReport']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List reports by plant sids.
         * @summary List reports by plant sids. (Auth policies: CanViewReports)
         * @param {Array<number>} componentTypes
         * @param {Array<number>} [plantSids]
         * @param {number} [reportType]
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {SortProjectType} [sortingSortProjectType]   1 &#x3D; Plant  2 &#x3D; ReportType  3 &#x3D; Status  4 &#x3D; Excluded
         * @param {boolean} [sortingSortAscending]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(
            componentTypes: Array<number>,
            plantSids?: Array<number>,
            reportType?: number,
            fromDate?: string,
            toDate?: string,
            sortingSortProjectType?: SortProjectType,
            sortingSortAscending?: boolean,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReportsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(
                componentTypes,
                plantSids,
                reportType,
                fromDate,
                toDate,
                sortingSortProjectType,
                sortingSortAscending,
                page,
                size,
                term,
                termToLower,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ReportsApi.listReports']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Sets the exclusion flag on a report.
         * @summary Sets the exclusion flag on a report. (Auth policies: CanExcludeReports)
         * @param {string} analyticalNumber
         * @param {boolean} [isExcluded]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setIsExcludeFlag(
            analyticalNumber: string,
            isExcluded?: boolean,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetIsExcludedResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setIsExcludeFlag(
                analyticalNumber,
                isExcluded,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ReportsApi.setIsExcludeFlag']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * Exports an excel according to the filters.
         * @summary Exports an excel according to the filters. (Auth policies: CanExportReports)
         * @param {number} exportType
         * @param {Array<number>} [groupSids]
         * @param {string} [startDate]
         * @param {string} [endDate]
         * @param {Array<number>} [plantSids]
         * @param {Array<number>} [componentTypes]
         * @param {Array<string>} [componentSids]
         * @param {number} [languageType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportReport(
            exportType: number,
            groupSids?: Array<number>,
            startDate?: string,
            endDate?: string,
            plantSids?: Array<number>,
            componentTypes?: Array<number>,
            componentSids?: Array<string>,
            languageType?: number,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .exportReport(
                    exportType,
                    groupSids,
                    startDate,
                    endDate,
                    plantSids,
                    componentTypes,
                    componentSids,
                    languageType,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * Get report details by id.
         * @summary Get report details by id. (Auth policies: CanViewReports)
         * @param {string} reportId
         * @param {number} [reportType]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReport(reportId: string, reportType?: number, options?: any): AxiosPromise<GetReportResponseOkResponse> {
            return localVarFp.getReport(reportId, reportType, options).then((request) => request(axios, basePath))
        },
        /**
         * List reports by plant sids.
         * @summary List reports by plant sids. (Auth policies: CanViewReports)
         * @param {Array<number>} componentTypes
         * @param {Array<number>} [plantSids]
         * @param {number} [reportType]
         * @param {string} [fromDate]
         * @param {string} [toDate]
         * @param {SortProjectType} [sortingSortProjectType]   1 &#x3D; Plant  2 &#x3D; ReportType  3 &#x3D; Status  4 &#x3D; Excluded
         * @param {boolean} [sortingSortAscending]
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [term]
         * @param {string} [termToLower]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(
            componentTypes: Array<number>,
            plantSids?: Array<number>,
            reportType?: number,
            fromDate?: string,
            toDate?: string,
            sortingSortProjectType?: SortProjectType,
            sortingSortAscending?: boolean,
            page?: number,
            size?: number,
            term?: string,
            termToLower?: string,
            options?: any,
        ): AxiosPromise<ListReportsResponseOkResponse> {
            return localVarFp
                .listReports(
                    componentTypes,
                    plantSids,
                    reportType,
                    fromDate,
                    toDate,
                    sortingSortProjectType,
                    sortingSortAscending,
                    page,
                    size,
                    term,
                    termToLower,
                    options,
                )
                .then((request) => request(axios, basePath))
        },
        /**
         * Sets the exclusion flag on a report.
         * @summary Sets the exclusion flag on a report. (Auth policies: CanExcludeReports)
         * @param {string} analyticalNumber
         * @param {boolean} [isExcluded]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setIsExcludeFlag(
            analyticalNumber: string,
            isExcluded?: boolean,
            options?: any,
        ): AxiosPromise<SetIsExcludedResponseOkResponse> {
            return localVarFp
                .setIsExcludeFlag(analyticalNumber, isExcluded, options)
                .then((request) => request(axios, basePath))
        },
    }
}

/**
 * ReportsApi - interface
 * @export
 * @interface ReportsApi
 */
export interface ReportsApiInterface {
    /**
     * Exports an excel according to the filters.
     * @summary Exports an excel according to the filters. (Auth policies: CanExportReports)
     * @param {number} exportType
     * @param {Array<number>} [groupSids]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [plantSids]
     * @param {Array<number>} [componentTypes]
     * @param {Array<string>} [componentSids]
     * @param {number} [languageType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    exportReport(
        exportType: number,
        groupSids?: Array<number>,
        startDate?: string,
        endDate?: string,
        plantSids?: Array<number>,
        componentTypes?: Array<number>,
        componentSids?: Array<string>,
        languageType?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<void>

    /**
     * Get report details by id.
     * @summary Get report details by id. (Auth policies: CanViewReports)
     * @param {string} reportId
     * @param {number} [reportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    getReport(
        reportId: string,
        reportType?: number,
        options?: AxiosRequestConfig,
    ): AxiosPromise<GetReportResponseOkResponse>

    /**
     * List reports by plant sids.
     * @summary List reports by plant sids. (Auth policies: CanViewReports)
     * @param {Array<number>} componentTypes
     * @param {Array<number>} [plantSids]
     * @param {number} [reportType]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {SortProjectType} [sortingSortProjectType]   1 &#x3D; Plant  2 &#x3D; ReportType  3 &#x3D; Status  4 &#x3D; Excluded
     * @param {boolean} [sortingSortAscending]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    listReports(
        componentTypes: Array<number>,
        plantSids?: Array<number>,
        reportType?: number,
        fromDate?: string,
        toDate?: string,
        sortingSortProjectType?: SortProjectType,
        sortingSortAscending?: boolean,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ListReportsResponseOkResponse>

    /**
     * Sets the exclusion flag on a report.
     * @summary Sets the exclusion flag on a report. (Auth policies: CanExcludeReports)
     * @param {string} analyticalNumber
     * @param {boolean} [isExcluded]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApiInterface
     */
    setIsExcludeFlag(
        analyticalNumber: string,
        isExcluded?: boolean,
        options?: AxiosRequestConfig,
    ): AxiosPromise<SetIsExcludedResponseOkResponse>
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI implements ReportsApiInterface {
    /**
     * Exports an excel according to the filters.
     * @summary Exports an excel according to the filters. (Auth policies: CanExportReports)
     * @param {number} exportType
     * @param {Array<number>} [groupSids]
     * @param {string} [startDate]
     * @param {string} [endDate]
     * @param {Array<number>} [plantSids]
     * @param {Array<number>} [componentTypes]
     * @param {Array<string>} [componentSids]
     * @param {number} [languageType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public exportReport(
        exportType: number,
        groupSids?: Array<number>,
        startDate?: string,
        endDate?: string,
        plantSids?: Array<number>,
        componentTypes?: Array<number>,
        componentSids?: Array<string>,
        languageType?: number,
        options?: AxiosRequestConfig,
    ) {
        return ReportsApiFp(this.configuration)
            .exportReport(
                exportType,
                groupSids,
                startDate,
                endDate,
                plantSids,
                componentTypes,
                componentSids,
                languageType,
                options,
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get report details by id.
     * @summary Get report details by id. (Auth policies: CanViewReports)
     * @param {string} reportId
     * @param {number} [reportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReport(reportId: string, reportType?: number, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration)
            .getReport(reportId, reportType, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List reports by plant sids.
     * @summary List reports by plant sids. (Auth policies: CanViewReports)
     * @param {Array<number>} componentTypes
     * @param {Array<number>} [plantSids]
     * @param {number} [reportType]
     * @param {string} [fromDate]
     * @param {string} [toDate]
     * @param {SortProjectType} [sortingSortProjectType]   1 &#x3D; Plant  2 &#x3D; ReportType  3 &#x3D; Status  4 &#x3D; Excluded
     * @param {boolean} [sortingSortAscending]
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [term]
     * @param {string} [termToLower]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReports(
        componentTypes: Array<number>,
        plantSids?: Array<number>,
        reportType?: number,
        fromDate?: string,
        toDate?: string,
        sortingSortProjectType?: SortProjectType,
        sortingSortAscending?: boolean,
        page?: number,
        size?: number,
        term?: string,
        termToLower?: string,
        options?: AxiosRequestConfig,
    ) {
        return ReportsApiFp(this.configuration)
            .listReports(
                componentTypes,
                plantSids,
                reportType,
                fromDate,
                toDate,
                sortingSortProjectType,
                sortingSortAscending,
                page,
                size,
                term,
                termToLower,
                options,
            )
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Sets the exclusion flag on a report.
     * @summary Sets the exclusion flag on a report. (Auth policies: CanExcludeReports)
     * @param {string} analyticalNumber
     * @param {boolean} [isExcluded]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public setIsExcludeFlag(analyticalNumber: string, isExcluded?: boolean, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration)
            .setIsExcludeFlag(analyticalNumber, isExcluded, options)
            .then((request) => request(this.axios, this.basePath))
    }
}

/**
 * ThresholdsApi - axios parameter creator
 * @export
 */
export const ThresholdsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Updates a threshold by id.
         * @summary Updates a threshold by id. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} thresholdId The id of the threshold to be updated.
         * @param {ThresholdEditDto} [thresholdEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editThreshold: async (
            thresholdId: number,
            thresholdEditDto?: ThresholdEditDto,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'thresholdId' is not null or undefined
            assertParamExists('editThreshold', 'thresholdId', thresholdId)
            const localVarPath = `/api/v1/thresholds/{thresholdId}`.replace(
                `{${'thresholdId'}}`,
                encodeURIComponent(String(thresholdId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            localVarHeaderParameter['Content-Type'] = 'application/json'

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }
            localVarRequestOptions.data = serializeDataIfNeeded(thresholdEditDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Export thresholds.
         * @summary Export thresholds. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} languageType
         * @param {string} timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportThresholds: async (
            languageType: number,
            timezone: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'languageType' is not null or undefined
            assertParamExists('exportThresholds', 'languageType', languageType)
            // verify required parameter 'timezone' is not null or undefined
            assertParamExists('exportThresholds', 'timezone', timezone)
            const localVarPath = `/api/v1/thresholds/{languageType}/export/{timezone}`
                .replace(`{${'languageType'}}`, encodeURIComponent(String(languageType)))
                .replace(`{${'timezone'}}`, encodeURIComponent(String(timezone)))
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * Get threshold details by id.
         * @summary Get threshold details by id. (Auth policies: CanViewTransformerData)
         * @param {number} thresholdId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdById: async (thresholdId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'thresholdId' is not null or undefined
            assertParamExists('getThresholdById', 'thresholdId', thresholdId)
            const localVarPath = `/api/v1/thresholds/{thresholdId}`.replace(
                `{${'thresholdId'}}`,
                encodeURIComponent(String(thresholdId)),
            )
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
        /**
         * List thresholds.
         * @summary List thresholds. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listThresholds: async (request?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/thresholds`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
            let baseOptions
            if (configuration) {
                baseOptions = configuration.baseOptions
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
            const localVarHeaderParameter = {} as any
            const localVarQueryParameter = {} as any

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration)

            if (request !== undefined) {
                for (const [key, value] of Object.entries(request)) {
                    localVarQueryParameter[key] = value
                }
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter)
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            }

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            }
        },
    }
}

/**
 * ThresholdsApi - functional programming interface
 * @export
 */
export const ThresholdsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ThresholdsApiAxiosParamCreator(configuration)
    return {
        /**
         * Updates a threshold by id.
         * @summary Updates a threshold by id. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} thresholdId The id of the threshold to be updated.
         * @param {ThresholdEditDto} [thresholdEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editThreshold(
            thresholdId: number,
            thresholdEditDto?: ThresholdEditDto,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EditThresholdResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editThreshold(
                thresholdId,
                thresholdEditDto,
                options,
            )
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ThresholdsApi.editThreshold']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Export thresholds.
         * @summary Export thresholds. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} languageType
         * @param {string} timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportThresholds(
            languageType: number,
            timezone: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportThresholds(languageType, timezone, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ThresholdsApi.exportThresholds']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * Get threshold details by id.
         * @summary Get threshold details by id. (Auth policies: CanViewTransformerData)
         * @param {number} thresholdId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThresholdById(
            thresholdId: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetThresholdResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThresholdById(thresholdId, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ThresholdsApi.getThresholdById']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
        /**
         * List thresholds.
         * @summary List thresholds. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listThresholds(
            request?: object,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListThresholdsResponseOkResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listThresholds(request, options)
            const index = configuration?.serverIndex ?? 0
            const operationBasePath = operationServerMap['ThresholdsApi.listThresholds']?.[index]?.url
            return (axios, basePath) =>
                createRequestFunction(
                    localVarAxiosArgs,
                    globalAxios,
                    BASE_PATH,
                    configuration,
                )(axios, operationBasePath || basePath)
        },
    }
}

/**
 * ThresholdsApi - factory interface
 * @export
 */
export const ThresholdsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThresholdsApiFp(configuration)
    return {
        /**
         * Updates a threshold by id.
         * @summary Updates a threshold by id. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} thresholdId The id of the threshold to be updated.
         * @param {ThresholdEditDto} [thresholdEditDto] The updated data.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editThreshold(
            thresholdId: number,
            thresholdEditDto?: ThresholdEditDto,
            options?: any,
        ): AxiosPromise<EditThresholdResponseOkResponse> {
            return localVarFp
                .editThreshold(thresholdId, thresholdEditDto, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Export thresholds.
         * @summary Export thresholds. (Auth policies: CanEditThresholdsAndRecommendations)
         * @param {number} languageType
         * @param {string} timezone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportThresholds(languageType: number, timezone: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .exportThresholds(languageType, timezone, options)
                .then((request) => request(axios, basePath))
        },
        /**
         * Get threshold details by id.
         * @summary Get threshold details by id. (Auth policies: CanViewTransformerData)
         * @param {number} thresholdId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdById(thresholdId: number, options?: any): AxiosPromise<GetThresholdResponseOkResponse> {
            return localVarFp.getThresholdById(thresholdId, options).then((request) => request(axios, basePath))
        },
        /**
         * List thresholds.
         * @summary List thresholds. (Auth policies: CanViewTransformerData)
         * @param {object} [request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listThresholds(request?: object, options?: any): AxiosPromise<ListThresholdsResponseOkResponse> {
            return localVarFp.listThresholds(request, options).then((request) => request(axios, basePath))
        },
    }
}

/**
 * ThresholdsApi - interface
 * @export
 * @interface ThresholdsApi
 */
export interface ThresholdsApiInterface {
    /**
     * Updates a threshold by id.
     * @summary Updates a threshold by id. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} thresholdId The id of the threshold to be updated.
     * @param {ThresholdEditDto} [thresholdEditDto] The updated data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApiInterface
     */
    editThreshold(
        thresholdId: number,
        thresholdEditDto?: ThresholdEditDto,
        options?: AxiosRequestConfig,
    ): AxiosPromise<EditThresholdResponseOkResponse>

    /**
     * Export thresholds.
     * @summary Export thresholds. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} languageType
     * @param {string} timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApiInterface
     */
    exportThresholds(languageType: number, timezone: string, options?: AxiosRequestConfig): AxiosPromise<void>

    /**
     * Get threshold details by id.
     * @summary Get threshold details by id. (Auth policies: CanViewTransformerData)
     * @param {number} thresholdId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApiInterface
     */
    getThresholdById(thresholdId: number, options?: AxiosRequestConfig): AxiosPromise<GetThresholdResponseOkResponse>

    /**
     * List thresholds.
     * @summary List thresholds. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApiInterface
     */
    listThresholds(request?: object, options?: AxiosRequestConfig): AxiosPromise<ListThresholdsResponseOkResponse>
}

/**
 * ThresholdsApi - object-oriented interface
 * @export
 * @class ThresholdsApi
 * @extends {BaseAPI}
 */
export class ThresholdsApi extends BaseAPI implements ThresholdsApiInterface {
    /**
     * Updates a threshold by id.
     * @summary Updates a threshold by id. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} thresholdId The id of the threshold to be updated.
     * @param {ThresholdEditDto} [thresholdEditDto] The updated data.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApi
     */
    public editThreshold(thresholdId: number, thresholdEditDto?: ThresholdEditDto, options?: AxiosRequestConfig) {
        return ThresholdsApiFp(this.configuration)
            .editThreshold(thresholdId, thresholdEditDto, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Export thresholds.
     * @summary Export thresholds. (Auth policies: CanEditThresholdsAndRecommendations)
     * @param {number} languageType
     * @param {string} timezone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApi
     */
    public exportThresholds(languageType: number, timezone: string, options?: AxiosRequestConfig) {
        return ThresholdsApiFp(this.configuration)
            .exportThresholds(languageType, timezone, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * Get threshold details by id.
     * @summary Get threshold details by id. (Auth policies: CanViewTransformerData)
     * @param {number} thresholdId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApi
     */
    public getThresholdById(thresholdId: number, options?: AxiosRequestConfig) {
        return ThresholdsApiFp(this.configuration)
            .getThresholdById(thresholdId, options)
            .then((request) => request(this.axios, this.basePath))
    }

    /**
     * List thresholds.
     * @summary List thresholds. (Auth policies: CanViewTransformerData)
     * @param {object} [request]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThresholdsApi
     */
    public listThresholds(request?: object, options?: AxiosRequestConfig) {
        return ThresholdsApiFp(this.configuration)
            .listThresholds(request, options)
            .then((request) => request(this.axios, this.basePath))
    }
}
