<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('admin.recommendationTypes.recommendationModal.title.add')"
            click-shield
            class="recommendation-dialog"
        >
            <div class="recommendation-dialog__modal">
                <RecommendationTypeModalStaticFormFields />
            </div>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('admin.recommendationTypes.recommendationModal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isMutating"
                :loading="isMutating"
                @click="onSubmit"
            >
                {{ t('admin.recommendationTypes.recommendationModal.actions.save') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { computed, ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { useForm } from 'vee-validate'
import { addRecommendationTypeValidationSchema } from '@/pages/admin/recommendation-types/modal/ConditionStatusRecommendationTypeModal.form'
import { provideConditionStatusAddRecommendationTypeModal } from '@/pages/admin/recommendation-types/modal/add-recommendation/useConditionStatusAddRecommendationTypeModal'
import { useRecommendationsAddMutation } from '@/api/mutations/admin/recommendation-types/useRecommendationTypesAddMutation'
import { AxiosError } from 'axios'
import { AddRecommendationTypeRequest } from '@/api/generated/HCA'
import RecommendationTypeModalStaticFormFields from '@/pages/admin/recommendation-types/modal/RecommendationTypeModalStaticFormFields.vue'

const { t } = useI18n()
const toasts = usePebbleToasts()

const dialogRef = ref<PebbleDialog | null>(null)

const { mutateAsync: mutateAddAsync, isPending: isAddPending } = useRecommendationsAddMutation()

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: addRecommendationTypeValidationSchema,
    initialValues: {
        englishName: '',
        germanName: '',
    },
})

const isMutating = computed(() => isAddPending.value)

const openAddRecommendation = () => {
    resetForm()

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    try {
        const data: AddRecommendationTypeRequest = {
            name: values.englishName,
            germanName: values.germanName,
        }

        await mutateAddAsync({
            data,
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

provideConditionStatusAddRecommendationTypeModal({
    openAddRecommendation,
})
</script>

<style scoped lang="scss">
.recommendation-dialog {
    --width: 70vw;
    &__modal {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacing-s $spacing-m;
    }
}
</style>
