import { ReportParams } from '@/types/reports'
import { inject, InjectionKey, provide, ref, Ref } from 'vue'

const defaultParams: ReportParams = {
    plantGroupSid: [],
    plantSid: [],
    componentType: [],
    reportType: 0,
}

export const ReportParamsInjectionKey: InjectionKey<Ref<ReportParams>> = Symbol('ReportParamsInjectionKey')

export const useReportParams = () => inject(ReportParamsInjectionKey) ?? ref(defaultParams)

export const provideReportParams = (reportParams: Ref<ReportParams>) => provide(ReportParamsInjectionKey, reportParams)
