import { UserAccessManagementHttpClient, type Authenticator } from '@coode/fe-sdk'
import { COMMON_SCOPES, coodeSdkConfig } from '@/authentication/coodeSdkConfig'

/**
 * Returns a configured client for User Access Management
 * using current use-case Coode SDK configuration.
 *
 * @constant
 * @param authenticator Configured MSAL instance for current use-case
 * @returns {UserAccessManagementHttpClient}
 */
export const useUamClient = (authenticator: Authenticator): UserAccessManagementHttpClient => {
    const { environment, auth } = coodeSdkConfig

    const defaultGetTokenCallback = async (scope: string | undefined) => await authenticator.getToken(scope ?? '')
    const getTokenCallback = auth?.getTokenCallback ?? defaultGetTokenCallback

    return new UserAccessManagementHttpClient(environment, getTokenCallback, auth?.requestFailedCallback, {
        url: import.meta.env.VITE_USER_ACCESS_MANAGEMENT_API_URL,
        scope: COMMON_SCOPES.USER_ACCESS_MANAGEMENT,
    })
}
