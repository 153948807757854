import { defineStore } from 'pinia'
import { TranslationService } from '@/api/services/TranslationService'
import { i18n } from '@/utils/i18n'
import { composeLocaleObject } from '@/utils/i18n-utils'

export const useMainStore = defineStore('mainStore', {
    state: () => ({
        isAuthenticationReady: false,
        isTranslationReady: false,
    }),
    getters: {
        isAppReady: (state) => state.isAuthenticationReady && state.isTranslationReady,
    },
    actions: {
        setAuthenticationAsReady(): void {
            this.isAuthenticationReady = true
            this.initializeTranslation().then()
        },
        async initializeTranslation(): Promise<void> {
            const translationService = new TranslationService()

            const localeResponse = await translationService.getLocales()

            const phraseResponses = await Promise.allSettled(
                localeResponse.locales.map((e) =>
                    translationService.getPhrases(e.value).then((data) => ({ locale: e.value, data })),
                ),
            )

            phraseResponses.forEach((response) => {
                if (response.status !== 'fulfilled') {
                    return
                }

                const localeObject = composeLocaleObject(response.value.data.phrases ?? [])
                i18n.global.setLocaleMessage(response.value.locale, localeObject)
            })

            this.isTranslationReady = true
        },
    },
})
