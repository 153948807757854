<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.comments.headline')"
        :is-pending="isPending"
        :is-error="isError"
        :hide-content="commentsData.length === 0"
    >
        <template
            v-if="commentsData.length === 0"
            #context
        >
            <ColorBadge
                :color="StatusTypes[ConditionStatus.None].color"
                :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                small
            />
        </template>
        <div
            v-if="commentsData.length !== 0"
            class="comments-section"
        >
            <template
                v-for="(comment, index) in commentsData"
                :key="comment.id"
            >
                <div class="comments-section__item-horizontal-list">
                    <div class="comments-section__item">
                        <div class="comments-section__item-title">
                            {{ t('transformers.conditionStatus.content.comments.table.headers.date') }}
                        </div>
                        <div class="comments-section__item-value">
                            {{ comment.date }}
                        </div>
                    </div>
                    <div class="comments-section__item">
                        <div class="comments-section__item-title">
                            {{ t('transformers.conditionStatus.content.comments.table.headers.reportNumber') }}
                        </div>
                        <div class="comments-section__item-value">
                            {{ comment.reportNumber }}
                        </div>
                    </div>
                    <div class="comments-section__item">
                        <div class="comments-section__item-title">
                            {{ t('transformers.conditionStatus.content.comments.table.headers.analysisType') }}
                        </div>
                        <div class="comments-section__item-value">
                            {{ comment.type }}
                        </div>
                    </div>
                    <div class="comments-section__item comments-section__item--wide">
                        <div class="comments-section__item-title">
                            {{ t('transformers.conditionStatus.content.comments.table.headers.parameterId') }}
                        </div>
                        <div class="comments-section__item-value">
                            {{ comment.measurement }}
                        </div>
                    </div>
                </div>
                <div class="comments-section__item">
                    <div class="comments-section__item-title">
                        {{ t('transformers.conditionStatus.content.comments.table.headers.text') }}
                    </div>
                    <div
                        v-if="comment.comment.title"
                        class="comments-section__item-value comments-section__item-value--important"
                    >
                        {{ comment.comment.title }}
                    </div>
                    <div class="comments-section__item-value">
                        {{ comment.comment.text }}
                    </div>
                </div>
                <div
                    v-if="index !== commentsData.length - 1"
                    class="comments-section__separator"
                />
            </template>
        </div>
        <EmptyStatePanelContent v-else />
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useTransformerId } from '@/composables/useTransformerId'
import { useTransformerCommentsQuery } from '@/api/queries/transformers/useTransformerCommentsQuery'
import { useI18n } from 'vue-i18n'
import { computed, toRefs } from 'vue'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import ColorBadge from '@/components/badge/ColorBadge.vue'

type NormalizedCommentData = {
    id: number
    date: string
    reportNumber: string
    type: string
    measurement: string
    comment: {
        title?: string
        text: string
    }
    userKid: string
}

const props = defineProps<{
    preloadSize?: number
}>()

const { preloadSize } = toRefs(props)

const { t, locale } = useI18n()
const transformerId = useTransformerId()

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useTransformerCommentsQuery(transformerId, preloadSize)

const commentsData = computed<NormalizedCommentData[]>(() => {
    if (!data.value?.pages || !constantsData.value) {
        return []
    }

    const loadedComments = data.value.pages.flatMap((e) => e?.comments ?? [])

    const normalizedComments: NormalizedCommentData[] = []

    loadedComments.forEach((comment) => {
        const commentDate = format(new Date(comment.date ?? new Date()), DATE_FORMAT)

        const analysisType = AnalysisTypes.find((e) => e.value === comment.analysisType)
        const measurementType = constantsData.value?.parameters?.find((e) => e.id === comment.parameterId)

        const title = t('transformers.conditionStatus.content.oilAnalyses.overrideComment.title', {
            from: t(StatusTypes.find((e) => e.value === comment.previousStatus)?.labelKey ?? ''),
            to: t(StatusTypes.find((e) => e.value === comment.newStatus)?.labelKey ?? ''),
        })

        normalizedComments.push({
            id: comment.id ?? -1,
            date: commentDate,
            reportNumber: comment.reportNumber ?? '-',
            type: analysisType ? t(analysisType.labelKey) : '-',
            measurement: measurementType ? chooseLangString(locale, measurementType) : '-',
            comment: {
                title: comment.isOverride ? title : undefined,
                text: comment.text ?? '-',
            },
            userKid: comment.kid ?? '-',
        })
    })

    return normalizedComments
})
</script>

<style scoped lang="scss">
.comments-section {
    display: flex;
    flex-direction: column;

    gap: $spacing-xs;

    &__item {
        break-inside: avoid;

        &--wide {
            grid-column: span 2 / span 2;
        }

        &-title {
            font-weight: 500;
            font-size: $default-font-size;
        }

        &-value {
            font-size: $default-font-size;

            &--important {
                font-weight: 500;
                margin-top: $spacing-3xs;
            }
        }

        &-meta {
            font-size: $default-font-size;
            color: $pbl-text-muted;
        }
    }

    &__item-horizontal-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(6, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__separator {
        height: 1px;
        width: 100%;
        background-color: $pbl-foreground-muted;
    }
}
</style>
