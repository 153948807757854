<template>
    <div class="notification-filters">
        <FormMultiselectField
            v-model="notificationTypes"
            :label="t('dashboard.notifications.filters.labels.notificationType')"
            :options="notificationTypeOptions"
            input-id="notificationTypes"
            class="notification-filters__leading-content-field"
        />
        <FormSingleSelectField
            v-model="pastDays"
            :label="t('dashboard.notifications.filters.labels.pastDays')"
            :options="pastDaysOptions"
            input-id="pastDays"
        />
        <div class="notification-filters__trailing-content">
            <FormSingleSelectField
                v-model="componentType"
                :label="t('dashboard.notifications.filters.labels.componentType')"
                :options="componentTypeOptions"
                input-id="componentType"
                class="notification-filters__trailing-content-field"
            />
            <HorizontalButton
                :text="t('recommendations.selector.actions.reset')"
                icon-name="arrow-counterclockwise"
                class="notification-filters__trailing-content-button"
                @click="onSelectionReset"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { ComponentType, ComponentTypes } from '@/configuration/component/component-types'
import { useI18n } from 'vue-i18n'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { ActionTypes } from '@/configuration/actions/action-type'
import FormMultiselectField from '@/components/form-inputs/form-multiselect-field/FormMultiselectField.vue'
import HorizontalButton from '@/components/buttons/HorizontalButton.vue'
import { DEFAULT_PAST_DAYS_VALUE, PAST_DAYS_VALUES } from '@/configuration/notifications-filter/notifications-filter'

const { t } = useI18n()

const componentType = defineModel<number>('componentType')
const notificationTypes = defineModel<number[]>('notificationTypes')
const pastDays = defineModel<number>('pastDays')

const notificationTypeOptions = computed<SelectOptions<number>>(() =>
    ActionTypes.map((e) => ({
        label: t(e.shortLabelKey) ?? '-',
        value: e.value ?? 0,
    })),
)

const componentTypeOptions = computed<SelectOptions<number>>(() =>
    ComponentTypes.map((e) => ({
        label: t(e.labelKey) ?? '-',
        value: e.value ?? 0,
    })),
)

const pastDaysOptions = computed<SelectOptions<number>>(() =>
    PAST_DAYS_VALUES.map((days) => ({
        label: t('dashboard.notifications.filters.lastDays', { days }),
        value: days,
    })),
)

const onSelectionReset = () => {
    componentType.value = ComponentType.Transformer
    notificationTypes.value = []
    pastDays.value = DEFAULT_PAST_DAYS_VALUE
}
</script>

<style scoped lang="scss">
.notification-filters {
    display: grid;
    grid-template-columns: minmax(0, 1fr) repeat(2, minmax(0, 1fr));
    grid-gap: $spacing-s;

    padding: $spacing-s $spacing-s;
    box-shadow: $pbl-box-shadow;

    &__trailing-content {
        display: flex;
        gap: $spacing-s;

        &-field {
            flex: 1;
        }

        &-button {
            align-self: start;

            margin-top: 36px;
        }
    }
}
</style>
