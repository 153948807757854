import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { RecommendationEditDto } from '@/api/generated/HCA'

export const useRecommendationEditMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { id: number; componentSid: string; data: RecommendationEditDto }) =>
            hcaClientFactory.recommendationsApi.editRecommendation(payload.id, payload.data),
        onSuccess: async (response, payload) => {
            await queryClient.invalidateQueries({
                queryKey: ['recommendations', payload.componentSid],
            })

            await queryClient.invalidateQueries({
                queryKey: ['recommendation', payload.id],
            })
        },
    })
}
