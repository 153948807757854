<template>
    <div class="recommendations-header">
        <span class="recommendations-header__title">
            {{ t('recommendations.title') }}
        </span>
        <RecommendationsComponentSelector
            :model-value="recommendationParams"
            @update:model-value="onTransformerValueUpdate"
        />
    </div>
</template>
<script setup lang="ts">
import RecommendationsComponentSelector from '@/pages/recommendations/RecommendationsComponentSelector.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { RecommendationParams } from '@/types/recommendations'
import { RECOMMENDATIONS_PARAMS_KEY } from '@/utils/recommendations-utils'

defineProps<{
    recommendationParams: RecommendationParams
}>()

const { t } = useI18n()
const router = useRouter()

const onTransformerValueUpdate = (params: RecommendationParams) => {
    localStorage.setItem(RECOMMENDATIONS_PARAMS_KEY, JSON.stringify(params))
    router.replace({
        name: 'RecommendationsRoot',
        query: params,
    })
}
</script>

<style scoped lang="scss">
.recommendations-header {
    @include spacing-page;

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    background-color: white;
    border-bottom: 1px solid $pbl-border-muted;

    &__title {
        font-size: 1.25rem;
        font-weight: 500;
    }
}
</style>
