<template>
    <div class="recommendations-content">
        <SystemStatusDescriptionModal>
            <ConditionStatusViewRecommendationModal>
                <RecommendationsTableSection />
            </ConditionStatusViewRecommendationModal>
        </SystemStatusDescriptionModal>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { RecommendationParams } from '@/types/recommendations'
import RecommendationsTableSection from '@/pages/recommendations/sections/RecommendationsTableSection.vue'
import { provideRecommendationParams } from '@/composables/useRecommendationParams'
import ConditionStatusViewRecommendationModal from '@/pages/transformers/condition-status/modals/recommendation/view-recommendation/ConditionStatusViewRecommendationModal.vue'
import SystemStatusDescriptionModal from '@/pages/transformers/condition-status/modals/recommendation/system-status-description/SystemStatusDescriptionModal.vue'

const props = defineProps<{
    recommendationParams: RecommendationParams
}>()

provideRecommendationParams(computed(() => props.recommendationParams))
</script>

<style scoped lang="scss">
.recommendations-content {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;
}
</style>
