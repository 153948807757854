<template>
    <div class="condition-status-oil-historical-trends-selector">
        <div class="condition-status-oil-historical-trends-selector__selector">
            <div class="condition-status-oil-historical-trends-selector__selector__field">
                <FormSingleSelectField
                    :model-value="gasInOilParameterGroup"
                    :label="
                        t('transformers.conditionStatus.content.oilHistoricalTrends.selector.labels.measurementPoint')
                    "
                    :placeholder-text="
                        t(
                            'transformers.conditionStatus.content.oilHistoricalTrends.selector.placeholders.measurementPoint',
                        )
                    "
                    :options="gasInOilParameterGroupsOptions"
                    class="component-selector__field"
                    @update:model-value="onUpdateGasInOilParameterGroup"
                />
            </div>
            <div class="condition-status-oil-historical-trends-selector__selector__field">
                <FormInputField
                    :model-value="startDate"
                    :label="t('transformers.conditionStatus.content.oilHistoricalTrends.selector.labels.startDate')"
                    type="month"
                    @update:model-value="onUpdateStartDate"
                />
            </div>
            <div class="condition-status-oil-historical-trends-selector__selector__field">
                <FormInputField
                    :model-value="endDate"
                    :label="t('transformers.conditionStatus.content.oilHistoricalTrends.selector.labels.endDate')"
                    type="month"
                    :invalid="!isTimeframeValid"
                    :error-message="
                        t('transformers.conditionStatus.content.oilHistoricalTrends.selector.errors.invalidTimeFrame')
                    "
                    @update:model-value="onUpdateEndDate"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, toRefs, watch } from 'vue'
import { compareAsc } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { OilHistoricalTrendsSelector } from '@/pages/transformers/condition-status/sections/oil-historical-trends/oil-historical-trends-types'

const { t } = useI18n()

const props = defineProps<{
    gasInOilParameterGroup?: string | number
    startDate?: string
    endDate?: string
}>()

const emit = defineEmits<{
    'update:selector': [value: OilHistoricalTrendsSelector]
}>()

const { gasInOilParameterGroup, startDate, endDate } = toRefs(props)

const isTimeframeValid = computed(() => {
    if (!startDate.value || !endDate.value) {
        return false
    }

    return compareAsc(new Date(startDate.value), new Date(endDate.value)) === -1
})

const { data: constants } = useConstantsQuery()

const gasInOilParameterGroups = computed(() => constants.value?.gasInOilParameterGroups ?? [])
const gasInOilParameterGroupsOptions = computed<SelectOptions<string>>(
    () =>
        gasInOilParameterGroups.value.map((group) => ({
            label:
                t(
                    `transformers.conditionStatus.content.oilHistoricalTrends.selector.measurementPointOptions.${group.name}`,
                ) ?? '-',
            value: group.name ?? '',
        })) ?? [],
)

const onUpdateGasInOilParameterGroup = (value: string | number | undefined) => {
    emit('update:selector', {
        gasInOilParameterGroup: value,
        startDate: startDate.value,
        endDate: endDate.value,
    })
}

const onUpdateStartDate = (value: string | undefined) => {
    emit('update:selector', {
        gasInOilParameterGroup: gasInOilParameterGroup.value,
        startDate: value,
        endDate: endDate.value,
    })
}

const onUpdateEndDate = (value: string | undefined) => {
    emit('update:selector', {
        gasInOilParameterGroup: gasInOilParameterGroup.value,
        startDate: startDate.value,
        endDate: value,
    })
}

watch(
    () => gasInOilParameterGroups.value,
    (newGasInOilParameterGroups) => {
        if (!newGasInOilParameterGroups[0]?.name) {
            return
        }

        onUpdateGasInOilParameterGroup(newGasInOilParameterGroups[0].name)
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.condition-status-oil-historical-trends-selector {
    &__selector {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1em;

        &__field {
            min-width: 300px;
        }
    }
}
</style>
