import { ActionType } from '@/configuration/actions/action-type'

export enum ConditionStatusScrollState {
    Default = 1,
    OilAnalysis,
    Comments,
    Recommendations,
    VisualInspection,
}

export const CONDITION_STATUS_SCROLL_STATE_ACTION_TYPE_MAP: Record<ActionType, ConditionStatusScrollState> = {
    [ActionType.ReportSubmitted]: ConditionStatusScrollState.OilAnalysis,
    [ActionType.ReportInvalidated]: ConditionStatusScrollState.OilAnalysis,
    [ActionType.UndoReportInvalidation]: ConditionStatusScrollState.OilAnalysis,
    [ActionType.CommentSubmitted]: ConditionStatusScrollState.Comments,
    [ActionType.CommentEdited]: ConditionStatusScrollState.Comments,
    [ActionType.CommentDeleted]: ConditionStatusScrollState.Comments,
    [ActionType.ConditionStatusManuallyOverridden]: ConditionStatusScrollState.OilAnalysis,
    [ActionType.ThresholdEdited]: ConditionStatusScrollState.OilAnalysis,
    [ActionType.RecommendationEdited]: ConditionStatusScrollState.Recommendations,
    [ActionType.RecommendationSubmitted]: ConditionStatusScrollState.Recommendations,
    [ActionType.VisualInspectionReportSubmitted]: ConditionStatusScrollState.VisualInspection,
    [ActionType.ComponentConditionStatusCritical]: ConditionStatusScrollState.Default,
    [ActionType.ChangeInComponentConditionStatus]: ConditionStatusScrollState.Default,
    [ActionType.LastSynced]: ConditionStatusScrollState.Default,
} as const
