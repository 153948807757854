import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { ThresholdEditDto } from '@/api/generated/HCA'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { waitFor } from '@/utils/timer-utils'

export const useEditThresholdMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { id: number; data: ThresholdEditDto }) =>
            hcaClientFactory.thresholdsApi.editThreshold(payload.id, payload.data),
        onSuccess: async (data, payload) => {
            // Due to BE handling of Validation dates, if the data is requested within a second of editing, the entry will not be retrieved.
            await waitFor(1000)

            await queryClient.invalidateQueries({ queryKey: ['thresholds', 'list'] })
            await queryClient.invalidateQueries({ queryKey: ['thresholds', payload.id] })
        },
    })
}
