<template>
    <ViewStatusAssessmentCriteriaModal>
        <div class="portfolio-overview-page">
            <div class="portfolio-overview-page__content">
                <div class="portfolio-overview-page__content__master-data-latest-grid">
                    <div class="portfolio-overview-page__content__master-data-latest-grid__status-summary">
                        <PortfolioOverviewStatusSummary />
                    </div>
                    <PortfolioOverviewFlaggedTransformers />
                </div>
                <div class="portfolio-overview-page__content__master-data-latest-grid">
                    <div class="portfolio-overview-page__content__master-data-latest-grid__chart-summary">
                        <PortfolioOverviewGroupSummary @click:group="onClickGroup" />
                    </div>
                    <div class="portfolio-overview-page__content__master-data-latest-grid__chart-summary">
                        <PortfolioOverviewPlantStatusSummary
                            :plant-group-sid-param="plantGroupSid"
                            @reset:group="onResetGroup"
                        />
                    </div>
                </div>
            </div>
        </div>
    </ViewStatusAssessmentCriteriaModal>
</template>

<script setup lang="ts">
import PortfolioOverviewStatusSummary from '@/pages/transformers/portfolio-overview/PortfolioOverviewStatusSummary.vue'
import PortfolioOverviewGroupSummary from '@/pages/transformers/portfolio-overview/PortfolioOverviewGroupSummary.vue'
import PortfolioOverviewPlantStatusSummary from '@/pages/transformers/portfolio-overview/plant-status-summary/PortfolioOverviewPlantStatusSummary.vue'
import { ref } from 'vue'
import ViewStatusAssessmentCriteriaModal from '@/pages/admin/thresholds/modals/status-assessment-criteria/ViewStatusAssessmentCriteriaModal.vue'
import PortfolioOverviewFlaggedTransformers from '@/pages/transformers/portfolio-overview/PortfolioOverviewFlaggedTransformers.vue'

const plantGroupSid = ref<number | undefined>(undefined)

const onClickGroup = (value: number) => {
    plantGroupSid.value = value
}

const onResetGroup = () => {
    plantGroupSid.value = undefined
}
</script>

<style scoped lang="scss">
.portfolio-overview-page {
    @include spacing-page;

    overflow-y: scroll;
    max-height: calc(100vh - $navbar-height * 2);

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    &__title {
        font-size: 1.25rem;
        font-weight: 500;
    }

    &__content {
        display: flex;
        flex-direction: column;

        gap: $spacing-m;

        &__master-data-latest-grid {
            display: flex;
            gap: $spacing-m;

            &__status-summary {
                width: 25%;
            }

            &__chart-summary {
                width: 50%;
            }
        }
    }
}
</style>
