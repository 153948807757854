<template>
    <FormInputField
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.transformerId')"
        :model-value="transformerId"
        disabled
    />
    <VeeFormSingleSelectField
        name="recommendationTypeId"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.recommendationTypeId')"
        :placeholder="t('formInputs.common.placeholders.selectOption')"
        :disabled="recommendationTypeDisabled"
        :options="recommendationTypeOptions"
        class="full-width"
    />
    <VeeFormInputField
        v-if="isRecommendationFreeTextDisplayed"
        name="recommendationFreeText"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.recommendationFreeText')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
        :disabled="recommendationTypeDisabled"
        class="full-width"
    />
    <VeeFormInputField
        name="sapNotificationNumber"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.sapNotificationNumber')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
    />
    <VeeFormInputField
        name="pspNumber"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.pspNumber')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
    />
    <VeeFormInputField
        name="ptRiskId"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.ptRiskId')"
        :placeholder="t('formInputs.common.placeholders.enterValue')"
    />
    <VeeFormInputField
        v-show="sapNotificationNumber"
        name="sapNotificationUrl"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.sapNotificationUrl')"
        :placeholder="t('formInputs.common.placeholders.enterUrl')"
        class="full-width"
    />
    <VeeFormInputField
        v-show="pspNumber"
        name="pspNumberUrl"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.pspNumberUrl')"
        :placeholder="t('formInputs.common.placeholders.enterUrl')"
        class="full-width"
    />
    <VeeFormInputField
        v-show="ptRiskId"
        name="ptRiskUrl"
        :label="t('transformers.conditionStatus.content.recommendationModal.labels.ptRiskUrl')"
        :placeholder="t('formInputs.common.placeholders.enterUrl')"
        class="full-width"
    />
</template>

<script setup lang="ts">
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import VeeFormSingleSelectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormSingleSelectField.vue'
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { computed, watch } from 'vue'
import { chooseLangString } from '@/utils/i18n-utils'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { useI18n } from 'vue-i18n'
import { useTransformerId } from '@/composables/useTransformerId'
import { useFieldValue, useSetFormValues } from 'vee-validate'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'

defineProps<{
    recommendationTypeDisabled?: boolean
}>()

const transformerId = useTransformerId()
const { t, locale } = useI18n()
const { data: constantsData } = useConstantsQuery()

const setFormValues = useSetFormValues()

const recommendationTypeId = useFieldValue<number | undefined>(() => 'recommendationTypeId')
const sapNotificationNumber = useFieldValue<string | undefined>(() => 'sapNotificationNumber')
const pspNumber = useFieldValue<string | undefined>(() => 'pspNumber')
const ptRiskId = useFieldValue<string | undefined>(() => 'ptRiskId')

const recommendationTypeOptions = computed(
    () =>
        constantsData.value?.recommendationTypes?.map((e) => ({
            label: chooseLangString(locale, e),
            value: e.id ?? 0,
        })) ?? [],
)

const isRecommendationFreeTextDisplayed = computed(() => recommendationTypeId.value === OTHER_RECOMMENDATION_TYPE_ID)

watch(
    [sapNotificationNumber, pspNumber, ptRiskId, isRecommendationFreeTextDisplayed],
    ([newSapNotificationNumber, newPspNumber, newPtRiskId, newIsRecommendationFreeTextDisplayed]) => {
        if (!newSapNotificationNumber) {
            setFormValues(
                {
                    sapNotificationUrl: '',
                },
                false,
            )
        }

        if (!newPspNumber) {
            setFormValues(
                {
                    pspNumberUrl: '',
                },
                false,
            )
        }

        if (!newPtRiskId) {
            setFormValues(
                {
                    ptRiskUrl: '',
                },
                false,
            )
        }

        if (!newIsRecommendationFreeTextDisplayed) {
            setFormValues(
                {
                    recommendationFreeText: '',
                },
                false,
            )
        }
    },
)
</script>

<style scoped lang="scss">
.full-width {
    grid-column: span 3 / span 3;
}
</style>
