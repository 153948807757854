import { computed } from 'vue'
import { useUserStore } from '@/store/userStore'
import { Permission } from '@/configuration/permissions/permission'

export const usePermissions = () => {
    const userStore = useUserStore()

    const userPermissions = computed(() => ({
        canViewTransformerData: userStore.hasPermission(Permission.CanViewTransformerData),
        canViewReports: userStore.hasPermission(Permission.CanViewReports),
        canViewRecommendations: userStore.hasPermission(Permission.CanViewRecommendations),
        canExportExports: userStore.hasPermission(Permission.CanExportReports),
        canEditTransformerRecommendations: userStore.hasPermission(Permission.CanEditTransformerRecommendations),
        canEditTransformerComments: userStore.hasPermission(Permission.CanEditTransformerComments),
        canOverrideTransformerConditionStatus: userStore.hasPermission(
            Permission.CanOverrideTransformerConditionStatus,
        ),
        canExcludeReports: userStore.hasPermission(Permission.CanExcludeReports),
        canEditThresholdsAndRecommendations: userStore.hasPermission(Permission.CanEditThresholdsAndRecommendations),
    }))

    return {
        userPermissions,
    }
}
