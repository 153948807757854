<template>
    <div
        :class="[
            {
                'header--dev': isDevelopmentEnvironment,
                'header--uat': isStagingEnvironment,
                'header--prd': isProductionEnvironment,
            },
            'header',
        ]"
    >
        <div class="header__right-content">
            <div>
                <pbl-icon
                    class="header__icon"
                    name="list"
                    @click="onAppDrawerIconClick"
                />
                <GlobalAppDrawer
                    :open="isAppDrawerOpen"
                    @close="onAppDrawerClose"
                />
            </div>
            <div
                :class="[
                    {
                        'header__vertical-separator--dev': isDevelopmentEnvironment,
                        'header__vertical-separator--uat': isStagingEnvironment,
                        'header__vertical-separator--prd': isProductionEnvironment,
                    },
                    'header__vertical-separator',
                ]"
            />
            <img
                class="header__brand-logo"
                src="/coode-logo.png"
                alt="COODE logo"
            />
            <div
                :class="[
                    {
                        'header__vertical-separator--dev': isDevelopmentEnvironment,
                        'header__vertical-separator--uat': isStagingEnvironment,
                        'header__vertical-separator--prd': isProductionEnvironment,
                    },
                    'header__vertical-separator',
                ]"
            />
            <h3 class="header__app-name">
                {{ t('header.appTitle') }}
            </h3>
        </div>
        <div class="header__left-content">
            <div
                v-click-outside="onLanguageClickOutside"
                class="header__icon-wrapper"
            >
                <pbl-icon
                    :class="[
                        'header__icon',
                        {
                            'header__icon--active': isLanguageOpen,
                        },
                    ]"
                    name="translate"
                    @click="onLanguageIconClick"
                />
                <HeaderLanguage
                    :open="isLanguageOpen"
                    @close="onLanguageClickOutside"
                />
            </div>
            <div
                v-click-outside="closeNotifications"
                class="header__icon-wrapper"
            >
                <pbl-icon
                    :class="[
                        'header__icon',
                        {
                            'header__icon--active': isNotificationsOpen,
                        },
                    ]"
                    name="bell"
                    @click="onNotificationsIconClick"
                />
                <div
                    v-show="metaDataStore.totalNotificationsCount !== 0 && !isNotificationsOpen"
                    class="header__icon-badge"
                    @click="onNotificationsIconClick"
                >
                    {{ metaDataStore.totalNotificationsCount }}
                </div>

                <HeaderNotifications
                    :open="isNotificationsOpen"
                    @close="closeNotifications"
                />
            </div>
            <div
                v-click-outside="onUserProfileClickOutside"
                class="header__icon-wrapper"
            >
                <pbl-icon
                    :class="[
                        'header__icon',
                        {
                            'header__icon--active': isUserProfileOpen,
                        },
                    ]"
                    name="person-circle"
                    @click="onUserProfileIconClick"
                />
                <HeaderUserProfile :open="isUserProfileOpen" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import HeaderUserProfile from '@/components/header/user-profile/HeaderUserProfile.vue'
import HeaderLanguage from '@/components/header/language/HeaderLanguage.vue'
import GlobalAppDrawer from '@/components/header/app-drawer/GlobalAppDrawer.vue'
import HeaderNotifications from '@/components/header/notifications/HeaderNotifications.vue'
import { useI18n } from 'vue-i18n'
import { useMetaDataStore } from '@/store/metaDataStore'
import { isDevelopmentEnvironment, isProductionEnvironment, isStagingEnvironment } from '@/utils/environment'

const { t } = useI18n()
const metaDataStore = useMetaDataStore()

const isAppDrawerOpen = ref(false)
const isNotificationsOpen = ref(false)
const isUserProfileOpen = ref(false)
const isLanguageOpen = ref(false)

const onAppDrawerIconClick = () => {
    isAppDrawerOpen.value = !isAppDrawerOpen.value
}

const onAppDrawerClose = () => {
    isAppDrawerOpen.value = false
}

const onLanguageIconClick = () => {
    isLanguageOpen.value = !isLanguageOpen.value
}

const onLanguageClickOutside = () => {
    if (!isLanguageOpen.value) {
        return
    }

    isLanguageOpen.value = false
}

const onNotificationsIconClick = () => {
    isNotificationsOpen.value = !isNotificationsOpen.value
}

const closeNotifications = () => {
    if (!isNotificationsOpen.value) {
        return
    }

    isNotificationsOpen.value = false
}

const onUserProfileIconClick = () => {
    isUserProfileOpen.value = !isUserProfileOpen.value
}

const onUserProfileClickOutside = () => {
    if (!isUserProfileOpen.value) {
        return
    }

    isUserProfileOpen.value = false
}
</script>

<style scoped lang="scss">
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: $navbar-height;
    padding: $spacing-2xs $spacing-s;

    color: $pbl-foreground;
    background-color: $pbl-primary;

    user-select: none;

    &--dev {
        background-color: $coode-header-dev;
    }

    &--uat {
        background-color: $coode-header-uat;
    }

    &--prd {
        background-color: $coode-header-prd;
    }

    &__vertical-separator {
        width: 2px;
        height: calc($navbar-height - $spacing-m);

        &--dev {
            background-color: adjust-color($coode-header-dev, $lightness: -5%);
        }

        &--uat {
            background-color: adjust-color($coode-header-uat, $lightness: -5%);
        }

        &--prd {
            background-color: adjust-color($coode-header-prd, $lightness: -5%);
        }
    }

    &__right-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-s;
    }

    &__brand-logo {
        height: calc($navbar-height - $spacing-s);
    }

    &__app-name {
        margin: 0;
        font-weight: 400;
    }

    &__left-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-s;
    }

    &__icon-wrapper {
        position: relative;
    }

    &__icon-badge {
        cursor: pointer;
        position: absolute;
        z-index: 10;

        top: -8px;
        right: 0;

        transform: translateX(35%);

        background-color: $pbl-invalid;

        font-size: 0.8em;
        padding: 2px 4px;

        border-radius: 8px;
    }

    &__icon {
        position: relative;

        cursor: pointer;
        --size: #{$spacing-m};

        &--active {
            color: rgba(255, 255, 255, 0.5);
        }
    }
}
</style>
