<template>
    <div class="reports-content-export">
        <pbl-button
            :loading="isPending"
            :disabled="isPending || isError"
            @click="openExport"
        >
            {{ t('reports.export.create') }}
        </pbl-button>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'
import { useReportsExportModal } from '@/pages/reports/export/useReportsExportModal'

const { t } = useI18n()
const { isPending, isError } = usePlantsHierarchyQuery()
const { openExport } = useReportsExportModal()
</script>

<style scoped lang="scss">
.reports-content-export {
    display: flex;
}
</style>
