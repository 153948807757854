<template>
    <ContentPanel
        v-model:collapsed="collapsed"
        :headline="headline"
        :collapsible="collapsible"
        :hide-content="hideContent"
    >
        <QueryLoaderGuard
            :is-pending="isPending"
            :is-error="isError"
            :error-message="errorMessage"
        >
            <slot />
        </QueryLoaderGuard>
        <template #context>
            <slot name="context" />
        </template>
    </ContentPanel>
</template>

<script setup lang="ts">
import ContentPanel from '@/components/panel/ContentPanel.vue'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'

const collapsed = defineModel<boolean>('collapsed', { default: false })

defineProps<{
    headline?: string
    collapsible?: boolean
    hideContent?: boolean

    isPending?: boolean
    isError?: boolean
    errorMessage?: string
}>()
</script>
