<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.visualInspections.status.label')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="visualInspectionData.length !== 0"
            class="visual-inspection-section"
        >
            <template
                v-for="(visualInspection, index) in visualInspectionData"
                :key="visualInspection.id"
            >
                <div class="visual-inspection-section__report">
                    <div class="visual-inspection-section__item-horizontal-list">
                        <div class="visual-inspection-section__item">
                            <div class="visual-inspection-section__item-title">
                                {{ t('transformers.conditionStatus.content.recommendations.table.headers.date') }}
                            </div>
                            <div class="visual-inspection-section__item-value">
                                {{ visualInspection.date }}
                            </div>
                        </div>
                    </div>
                    <template
                        v-for="(parameter, parameterIndex) in visualInspection.parameters"
                        :key="parameter.id"
                    >
                        <div class="visual-inspection-section__item-inspections-list">
                            <div class="visual-inspection-section__item">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.measurement') }}
                                </div>
                                <div class="visual-inspection-section__item-value">
                                    {{ parameter.measurement }}
                                </div>
                            </div>
                            <div class="visual-inspection-section__item">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.status') }}
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Normal"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.normal') }}
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Critical"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.critical') }}
                                    <AlertCircle
                                        class="visual-inspection-section__item-icon visual-inspection-section__item-icon--critical"
                                    />
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Inferior"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.inferior') }}
                                    <AlertTriangle
                                        class="visual-inspection-section__item-icon visual-inspection-section__item-icon--inferior"
                                    />
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.None"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.none') }}
                                </div>
                            </div>
                            <div class="visual-inspection-section__item visual-inspection-section__item--wide">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.comment') }}
                                </div>
                                <div class="visual-inspection-section__item-value">
                                    {{ parameter.comment }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="parameterIndex !== visualInspection.parameters.length - 1"
                            class="visual-inspection-section__separator--muted visual-inspection-section__separator"
                        />
                    </template>
                    <div
                        v-if="index !== visualInspectionData.length - 1"
                        class="visual-inspection-section__separator"
                    />
                </div>
            </template>
        </div>
        <EmptyStatePanelContent v-else />
        <template #context>
            <ColorBadge
                :color="reportStatus.color"
                :text="t(reportStatus.labelKey)"
                small
            />
        </template>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useI18n } from 'vue-i18n'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { computed, toRefs } from 'vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { AlertTriangle, AlertCircle } from 'lucide-vue-next'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { AnalysisDto } from '@/api/generated/HCA'

type NormalizedVisualInspectionParameter = {
    id: number
    measurement: string
    status: ConditionStatus
    comment: string
}

type NormalizedVisualInspectionData = {
    id: string
    date: string
    parameters: NormalizedVisualInspectionParameter[]
}

const props = defineProps<{
    data: AnalysisDto
}>()

const { data } = toRefs(props)

const { t } = useI18n()

const { data: constantsData, isPending, isError } = useConstantsQuery()

const reportStatus = computed(() => {
    const status = data.value.status ?? ConditionStatus.None
    return StatusTypes[status ?? ConditionStatus.None]
})

const visualInspectionData = computed<NormalizedVisualInspectionData[]>(() => {
    if (!constantsData.value) {
        return []
    }

    const normalizedVisualInspections: NormalizedVisualInspectionData[] = []
    const visualInspectionParameters: NormalizedVisualInspectionParameter[] = []

    data.value.parameters?.forEach((parameter) => {
        const parameterEntry = constantsData.value?.visualInspectionParameters?.find(
            (e) => e.id === parameter.parameterId,
        )

        if (!parameterEntry) {
            return
        }

        visualInspectionParameters.push({
            id: parameter.id ?? -1,
            measurement: t(
                `transformers.conditionStatus.content.visualInspections.table.measurementPoints.${parameterEntry.name}`,
            ),
            status: StatusTypes.find((e) => e.value === parameter.status)?.value ?? ConditionStatus.None,
            comment: parameter.comment ?? '-',
        })
    })

    normalizedVisualInspections.push({
        id: data.value.analyticalNumber ?? '-',
        date: format(new Date(data.value.samplingDate ?? new Date()), DATE_FORMAT),
        parameters: visualInspectionParameters,
    })

    return normalizedVisualInspections
})
</script>

<style scoped lang="scss">
.visual-inspection-section {
    display: flex;
    flex-direction: column;

    gap: $spacing-xs;

    &__report {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;
    }

    &__item {
        break-inside: avoid;

        &-title {
            font-weight: 500;
            font-size: $default-font-size;
        }

        &-value {
            display: flex;
            align-items: center;

            gap: $spacing-2xs;

            font-size: $default-font-size;
        }

        &-meta {
            font-size: $default-font-size;
            color: $pbl-text-muted;
        }

        &-icon {
            height: 20px;
            width: 20px;

            &--critical {
                color: $status-critical;
            }

            &--inferior {
                color: $status-inferior;
            }
        }

        &--wide {
            grid-column: span 3 / span 3;
        }
    }

    &__item-horizontal-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__item-inspections-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__separator {
        height: 1px;
        width: 100%;
        background-color: $pbl-border-muted;

        margin: $spacing-s 0;

        &--muted {
            margin: 0;

            background-color: $pbl-foreground-muted;
        }
    }
}
</style>
