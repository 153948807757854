<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('transformers.conditionStatus.content.recommendationModal.systemStatusDescription.title')"
            click-shield
        >
            <div class="system-status-description-modal">
                <div class="system-status-description-modal__labels">
                    <div
                        v-for="(systemStatusDescription, index) in SystemStatusDescriptions"
                        :key="index"
                    >
                        {{ t(systemStatusDescription.label) }}
                    </div>
                </div>
                <div class="system-status-description-modal__values">
                    <div
                        v-for="(systemStatusDescription, index) in SystemStatusDescriptions"
                        :key="index"
                    >
                        {{ t(systemStatusDescription.description) }}
                    </div>
                </div>
            </div>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { provideSystemStatusDescriptionModal } from '@/pages/transformers/condition-status/modals/recommendation/system-status-description/useSystemStatusDescriptionModal'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { SystemStatusDescriptions } from '@/configuration/system-status-description/system-status-descriptions'

const { t } = useI18n()

const dialogRef = ref<PebbleDialog | null>(null)

const openSystemStatusDescription = () => {
    dialogRef.value?.show()
}

provideSystemStatusDescriptionModal({
    openSystemStatusDescription,
})
</script>

<style scoped lang="scss">
.system-status-description-modal {
    display: flex;
    gap: $spacing-m;

    &__labels {
        font-weight: 500;
    }
}
</style>
