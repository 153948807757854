<template>
    <QueryLoaderGuard
        :is-pending="isLoading"
        :is-error="isError"
    >
        <div class="condition-status-oil-historical-trends-chart">
            <div
                v-if="!showChart"
                class="condition-status-oil-historical-trends-chart__empty-state"
            >
                <pbl-icon name="check2-square" />
                <span>{{ t('transformers.conditionStatus.content.oilHistoricalTrends.chart.emptyState') }}</span>
            </div>
            <template v-else-if="showChart">
                <div
                    v-if="!dimensions.length"
                    class="condition-status-oil-historical-trends-chart__empty-state"
                >
                    <pbl-icon name="x-circle" />
                    <span>{{ t('transformers.conditionStatus.content.oilHistoricalTrends.chart.noData') }}</span>
                </div>
                <v-chart
                    v-else
                    ref="chartRef"
                    class="condition-status-oil-historical-trends-chart__chart"
                    :option="option"
                    autoresize
                />
            </template>
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, nextTick, watch } from 'vue'
import { use, color } from 'echarts/core'
import { EChartsOption } from 'echarts'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import {
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    TitleComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { useTransformerComponentHistoricalQuery } from '@/api/queries/transformers/useTransformerComponentHistoricalQuery'
import { compareAsc, format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { ECHARTS_COLOR_PALETTE } from '@/utils/echarts-utils'
import { useTransformerId } from '@/composables/useTransformerId'
import { StatusTypes } from '@/configuration/status/condition-status'
import { DATE_FORMAT } from '@/utils/date-utils'
import { CHART_ICON } from '@/utils/chart-utils'
import { LanguageType } from '@/configuration/language/language-types'

use([
    TitleComponent,
    LineChart,
    CanvasRenderer,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
])

const { t, locale } = useI18n()

const transformerId = useTransformerId()

interface SelectOptionsExtradata {
    parameterIds?: Array<number>
    denominatorParameterIds?: Array<number>
    mustIncludeTemperature: boolean
}

const props = defineProps<{
    gasInOilParameterGroup?: string | number
    startDate?: string
    endDate?: string
    hideToolbox?: boolean
}>()

const { gasInOilParameterGroup, startDate, endDate, hideToolbox } = toRefs(props)

const chartRef = ref<InstanceType<typeof VChart> | null>(null)
const showTitle = ref(false)

const emit = defineEmits<{
    'update:hasData': [value: boolean]
}>()

const isTimeframeValid = computed(() => {
    if (!startDate.value || !endDate.value) {
        return false
    }

    return compareAsc(new Date(startDate.value), new Date(endDate.value)) === -1
})

const showChart = computed(() => {
    return isTimeframeValid.value && !!gasInOilParameterGroup.value
})

const { data: constants } = useConstantsQuery()

const extraData = computed((): SelectOptionsExtradata => {
    const defaultExtraData: SelectOptionsExtradata = {
        parameterIds: [],
        denominatorParameterIds: [],
        mustIncludeTemperature: false,
    }

    const foundItem = constants.value?.gasInOilParameterGroups?.find(
        (group) => group.name === gasInOilParameterGroup.value,
    )

    if (!foundItem) {
        return defaultExtraData
    }

    return {
        parameterIds: foundItem.parameterIds ?? [],
        denominatorParameterIds: foundItem.denominatorParameterIds ?? [],
        mustIncludeTemperature: foundItem.mustIncludeTemperature ?? false,
    }
})

const parameterIds = computed(() => extraData.value.parameterIds ?? [])
const denominatorParameterIds = computed(() => extraData.value.denominatorParameterIds ?? [])
const mustIncludeTemperature = computed(() => extraData.value.mustIncludeTemperature)

const languageType = computed(
    () => (LanguageType[locale.value as unknown as LanguageType] as unknown as number) ?? LanguageType['en-GB'],
)

const { data, isLoading, isError } = useTransformerComponentHistoricalQuery(
    transformerId,
    languageType,
    mustIncludeTemperature,
    parameterIds,
    denominatorParameterIds,
    startDate,
    endDate,
    showChart,
)

const dimensions = computed(() => {
    if (!data.value?.dataPoints?.length) {
        return []
    }

    const keys = Object.keys(data.value?.dataPoints[0])
    keys.shift()
    return keys
})

const hasThresholds = computed(() => {
    return !!data.value?.hasThresholds
})

const statuses = computed(() => {
    return data.value?.statuses ?? []
})

const unitsOfMeasure = computed(() => {
    return data.value?.unitsOfMeasure ?? []
})

const legend = computed(() => {
    return data.value?.legend ?? {}
})

const gasInOilParameterGroupName = computed(() => {
    const foundKey = constants.value?.gasInOilParameterGroups?.find(
        (group) => group.name === gasInOilParameterGroup.value,
    )?.name

    return foundKey
        ? t(`transformers.conditionStatus.content.oilHistoricalTrends.selector.measurementPointOptions.${foundKey}`)
        : ''
})

const saveAsImageTitle = computed(
    () =>
        `${t('transformers.conditionStatus.content.oilHistoricalTrends.title')} - ${gasInOilParameterGroupName.value}`,
)

const option = computed(() => {
    const options: EChartsOption = {
        title: {
            show: showTitle.value,
            text: saveAsImageTitle.value,
            top: 5,
            left: '40%',
            textStyle: {
                fontSize: 16,
                fontWeight: 500,
            },
        },
        grid: {
            top: 120,
            left: 5,
            right: 50,
            bottom: 5,
            containLabel: true,
        },
        textStyle: {
            fontFamily: 'Roboto',
            color: '#adadad',
        },
        legend: {
            selected: legend.value,
            top: 30,
            width: '90%',
            textStyle: {
                fontWeight: 'bold',
            },
        },
        toolbox: {
            show: !hideToolbox.value,
            orient: 'vertical',
            itemSize: 16,
            itemGap: 14,
            showTitle: true,
            feature: {
                dataZoom: {
                    icon: {
                        zoom: CHART_ICON.ZOOM,
                        back: CHART_ICON.BACK,
                    },
                    title: {
                        zoom: t('chart.action.zoom'),
                        back: t('chart.action.back'),
                    },
                },
                mySaveAsImage: {
                    title: t('chart.action.saveAsImage'),
                    icon: CHART_ICON.SAVE,
                    onclick: async () => {
                        showTitle.value = true
                        await nextTick()

                        const dataURL = chartRef.value?.chart?.getDataURL({
                            type: 'png',
                            pixelRatio: 2,
                            backgroundColor: '#fff',
                            excludeComponents: ['toolbox'],
                        })

                        if (!dataURL) {
                            await nextTick()
                            showTitle.value = false

                            return
                        }

                        const link = document.createElement('a')
                        link.href = dataURL
                        link.download = saveAsImageTitle.value
                        link.click()
                        link.remove()

                        await nextTick()
                        showTitle.value = false
                    },
                },
            },
            iconStyle: {
                color: '#333',
                borderColor: 'none',
            },
            emphasis: {
                iconStyle: {
                    color: '#0178dc',
                    borderColor: 'none',
                },
            },
        },
        tooltip: {
            trigger: 'axis',
            formatter: (args) => {
                if (!Array.isArray(args)) {
                    return ''
                }

                const axisTimestamp = (args[0].value as Record<string, unknown>).timestamp as string
                const axisValueLabel = format(new Date(axisTimestamp), DATE_FORMAT)

                const wrapperElement = document.createElement('div')
                wrapperElement.classList.add('wrapper')
                document.body.appendChild(wrapperElement)

                const axisLabelElement = document.createElement('div')
                axisLabelElement.classList.add('wrapper__axis-label')
                axisLabelElement.textContent = axisValueLabel
                wrapperElement.appendChild(axisLabelElement)

                args.forEach((element) => {
                    const seriesName = element.seriesName ?? ''
                    const status = StatusTypes[statuses.value?.[element.dataIndex]?.[seriesName]]
                    const unitOfMeasure =
                        constants.value?.unitOfMeasureTypes?.[
                            unitsOfMeasure.value?.[element.dataIndex]?.[seriesName]
                        ] ?? ''

                    const contentItemElement = document.createElement('div')
                    contentItemElement.classList.add('wrapper__content-item')
                    wrapperElement.appendChild(contentItemElement)

                    const colorCircleElement = document.createElement('span')
                    colorCircleElement.classList.add('wrapper__content-item__color-circle')
                    colorCircleElement.style.backgroundColor = element.color?.toString() ?? '#333'
                    contentItemElement.appendChild(colorCircleElement)

                    const seriesNameElement = document.createElement('span')
                    seriesNameElement.classList.add('wrapper__content-item__series-name')
                    seriesNameElement.textContent = seriesName
                    contentItemElement.appendChild(seriesNameElement)

                    const floatRightContainerElement = document.createElement('div')
                    floatRightContainerElement.classList.add('wrapper__content-item__float-right-container')
                    contentItemElement.appendChild(floatRightContainerElement)

                    const valueElement = document.createElement('span')
                    valueElement.classList.add('wrapper__content-item__float-right-container__value')
                    valueElement.textContent = `${(element.value as Record<string, unknown>)[seriesName] ?? '-'}`
                    floatRightContainerElement.appendChild(valueElement)

                    const unitOfMeasureElement = document.createElement('span')
                    unitOfMeasureElement.classList.add('wrapper__content-item__float-right-container__unit-of-measure')
                    unitOfMeasureElement.textContent = unitOfMeasure
                    floatRightContainerElement.appendChild(unitOfMeasureElement)

                    const statusElement = document.createElement('span')
                    statusElement.classList.add('wrapper__content-item__float-right-container__status-label')
                    if (status) {
                        statusElement.textContent = t(status.labelKey)
                        statusElement.style.backgroundColor = status.color
                    }
                    floatRightContainerElement.appendChild(statusElement)
                })

                return wrapperElement
            },
        },
        dataset: {
            source: data.value?.dataPoints ?? [],
            dimensions: ['timestamp', ...dimensions.value],
        },
        xAxis: {
            type: 'time',
        },
        yAxis: {
            scale: true,
        },
        series: [],
    }

    dimensions.value.forEach((dimension, index) => {
        const series = options.series

        if (!Array.isArray(series)) {
            return
        }

        const randomColor = color.random()

        let lineStyleType: 'solid' | 'dashed' = 'solid'
        let lineColor: string = ECHARTS_COLOR_PALETTE[index] ?? randomColor

        if (hasThresholds.value) {
            const isValueLine = index % 2 === 0

            lineStyleType = isValueLine ? 'solid' : 'dashed'
            lineColor = isValueLine
                ? ECHARTS_COLOR_PALETTE[index / 2] ?? randomColor
                : (series[index - 1]?.color as string) ?? randomColor
        }

        series.push({
            name: dimension,
            type: 'line',
            lineStyle: {
                type: lineStyleType,
            },
            encode: {
                x: 'timestamp',
                y: dimension,
            },
            color: lineColor,
            label: {
                show: true,
                position: 'top',
            },
            symbolSize: 8,
        })
    })

    return options
})

watch([data, isLoading, isError], ([newDataPoints]) => {
    emit('update:hasData', (newDataPoints?.dataPoints?.length ?? -1) > 0)
})
</script>

<style scoped lang="scss">
.condition-status-oil-historical-trends-chart {
    &__selector {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1em;

        &__field {
            min-width: 300px;
        }
    }

    &__empty-state {
        height: 400px;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }

        span {
            font-size: 1rem;
        }
    }

    &__chart {
        width: 100%;
        height: 400px;
    }
}
</style>

<style lang="scss">
.wrapper {
    margin: 0;
    line-height: 1;

    &__axis-label {
        font-size: $default-font-size;
        color: #666;
        font-weight: 900;
    }

    &__content-item {
        margin: 10px 0 0;
        line-height: 1;

        &__color-circle {
            display: inline-block;
            margin-right: 4px;
            border-radius: 10px;
            width: 10px;
            height: 10px;
        }

        &__series-name {
            font-size: $default-font-size;
            color: #666;
            font-weight: 400;
            margin-left: 2px;
        }

        &__float-right-container {
            float: right;
            margin-left: 20px;
            display: flex;

            &__value,
            &__unit-of-measure {
                font-size: $default-font-size;
                color: #666;
                font-weight: 900;
            }

            &__unit-of-measure {
                margin-left: 4px;
            }

            &__status-label {
                margin-left: 20px;
                min-width: 50px;
                padding: 4px 6px;
                color: #ffffff;
                font-size: 10px;
                font-weight: 400;
                border-radius: 2px;
                text-align: center;
            }
        }
    }
}
</style>
