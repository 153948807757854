<template>
    <FormTextareaField
        v-model="value"
        :label="label"
        :invalid="!meta.valid && meta.validated"
        :error-message="errorMessage ? t(errorMessage) : undefined"
    />
</template>

<script setup lang="ts">
import FormTextareaField from '@/components/form-inputs/form-textarea-field/FormTextareaField.vue'
import { useI18n } from 'vue-i18n'
import { useField } from 'vee-validate'

const props = defineProps<{
    name: string
    label: string
}>()

const { t } = useI18n()
const { value, errorMessage, meta } = useField<string>(() => props.name)
</script>
