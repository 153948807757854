<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('transformers.conditionStatus.content.recommendationModal.title.add')"
            click-shield
            class="recommendation-dialog"
        >
            <div class="recommendation-modal">
                <RecommendationModalStaticFormFields />
                <div class="recommendation-modal__horizontal-separator recommendation-modal__field--full-width" />
                <VeeFormInputField
                    name="comment"
                    :label="t('transformers.conditionStatus.content.recommendationModal.labels.comment')"
                    :placeholder="t('formInputs.common.placeholders.enterValue')"
                    class="recommendation-modal__field--full-width"
                />
            </div>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('transformers.conditionStatus.content.recommendationModal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isMutating"
                :loading="isMutating"
                @click="onSubmit"
            >
                {{ t('transformers.conditionStatus.content.recommendationModal.actions.save') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { computed, ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { useForm } from 'vee-validate'
import { addRecommendationValidationSchema } from '@/pages/transformers/condition-status/modals/recommendation/ConditionStatusRecommendationModal.form'
import { provideConditionStatusAddRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/add-recommendation/useConditionStatusAddRecommendationModal'
import { useTransformerId } from '@/composables/useTransformerId'
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import { useRecommendationsAddMutation } from '@/api/mutations/recommendations/useRecommendationsAddMutation'
import { AxiosError } from 'axios'
import { RecommendationAddDto } from '@/api/generated/HCA'
import RecommendationModalStaticFormFields from '@/pages/transformers/condition-status/modals/recommendation/RecommendationModalStaticFormFields.vue'

const { t } = useI18n()
const toasts = usePebbleToasts()
const transformerId = useTransformerId()

const dialogRef = ref<PebbleDialog | null>(null)

const { mutateAsync: mutateAddAsync, isPending: isAddPending } = useRecommendationsAddMutation()

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: addRecommendationValidationSchema,
    initialValues: {
        recommendationFreeText: '',
        sapNotificationNumber: '',
        ptRiskId: '',
        pspNumber: '',
        sapNotificationUrl: '',
        ptRiskUrl: '',
        pspNumberUrl: '',
        comment: '',
    },
})

const isMutating = computed(() => isAddPending.value)

const openAddRecommendation = () => {
    resetForm()

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    try {
        const data: RecommendationAddDto = {
            recommendationTypeId: values.recommendationTypeId,
            freeText: values.recommendationFreeText,
            sapNotificationNumber: values.sapNotificationNumber,
            pspNumber: values.pspNumber,
            ptRiskId: values.ptRiskId,
            sapNotificationUrl: values.sapNotificationUrl,
            ptRiskUrl: values.ptRiskUrl,
            pspNumberUrl: values.pspNumberUrl,
            comments: values.comment ? [values.comment] : undefined,
        }

        await mutateAddAsync({
            componentSid: transformerId.value,
            data,
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

provideConditionStatusAddRecommendationModal({
    openAddRecommendation,
})
</script>

<style scoped lang="scss">
.recommendation-dialog {
    --width: 70vw;
}

.recommendation-modal {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $spacing-s $spacing-m;

    &__field {
        &--full-width {
            grid-column: span 3 / span 3;
        }

        &--two-thirds {
            grid-column: span 2 / span 2;
        }
    }

    &__horizontal-separator {
        height: 1px;
        width: 100%;

        background-color: $pbl-border;
    }
}
</style>
