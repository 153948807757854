<template>
    <Transition>
        <div
            v-if="open"
            class="notifications"
        >
            <div class="notifications__pointer" />
            <div class="notifications__content">
                <div class="notifications__content-header">
                    <h3 class="notifications__title">
                        {{ t('header.notifications.title') }}
                    </h3>
                    <template v-if="metaDataStore.notifications.length !== 0">
                        <button
                            v-if="!isClearingNotifications"
                            class="notifications__clear-button"
                            @click="onClearAllClick"
                        >
                            <pbl-icon name="trash3" />
                            {{ t('header.notifications.clearAll') }}
                        </button>
                        <pbl-spinner v-else />
                    </template>
                </div>
                <div
                    v-if="metaDataStore.notifications.length !== 0"
                    class="notifications__content-notifications"
                >
                    <NotificationItem
                        v-for="notification in metaDataStore.notifications"
                        :key="notification.id"
                        :notification="notification"
                    />
                </div>
                <div
                    v-else
                    class="notifications__content-empty-notifications"
                >
                    {{ t('header.notifications.noNotifications') }}
                </div>
                <div class="notifications__content-actions">
                    <button
                        class="notifications__action-button notifications__action-button--blue"
                        @click="onViewMoreOnAppStoreClick"
                    >
                        {{ t('header.notifications.viewMoreInAppStore') }}
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useMetaDataStore } from '@/store/metaDataStore'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import NotificationItem from '@/components/header/notifications/NotificationItem.vue'
import { ref } from 'vue'

defineProps<{
    open: boolean
}>()

const emit = defineEmits<{
    close: []
}>()

const { t } = useI18n()
const toasts = usePebbleToasts()
const metaDataStore = useMetaDataStore()

const isClearingNotifications = ref(false)

const onClearAllClick = async () => {
    isClearingNotifications.value = true

    try {
        await metaDataStore.clearAllNotifications()
    } catch {
        toasts?.error({
            message: t('header.notifications.errors.failedToClearAll'),
        })
    } finally {
        isClearingNotifications.value = false
    }
}

const onViewMoreOnAppStoreClick = () => {
    emit('close')
    window.open(import.meta.env.VITE_APP_STORE_URL + 'notifications-overview')
}
</script>

<style scoped lang="scss">
.notifications {
    position: absolute;
    top: calc(100% + 7px);
    right: -7px;

    color: $pbl-text;
    background-color: $pbl-foreground;
    border-radius: 3px;
    box-shadow: rgba(4, 7, 22, 0.15) 0 5.625px 11.25px -1.25px;

    white-space: nowrap;

    z-index: 1;

    min-width: 300px;

    &__pointer {
        position: relative;
        z-index: 20;
        top: -5px;
        right: 14px;

        margin-left: auto;

        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $pbl-foreground;
    }

    &__content {
        display: flex;
        flex-direction: column;

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: $spacing-2xs $spacing-xs $spacing-s;
        }

        &-notifications {
            max-height: 60vh;

            overflow-y: auto;
            border-top: 1px solid $pbl-border;
            border-bottom: 1px solid adjust-color($pbl-primary, $lightness: -10%);
        }

        &-empty-notifications {
            padding: $spacing-l;
            border-top: 1px solid $pbl-border-muted;
            text-align: center;
        }

        &-actions {
            display: flex;
            flex-direction: column;
        }
    }

    &__clear-button {
        cursor: pointer;
        display: flex;
        gap: $spacing-3xs;

        margin-left: $spacing-3xl;
        border: 0;
        background-color: transparent;
        color: $pbl-invalid;

        &:hover:not(&:active) {
            color: adjust-color($pbl-invalid, $lightness: -10%);
        }
    }

    &__action-button {
        cursor: pointer;
        padding: $spacing-xs 0;

        border: 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &--blue {
            color: white;
            background-color: $pbl-primary;

            &:hover:not(&:active) {
                background-color: adjust-color($pbl-primary, $lightness: -10%);
            }
        }
    }

    &__title {
        margin: 0;
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.3s cubic-bezier(0.42, 0.97, 0.52, 1.49);
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
