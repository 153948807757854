type Environment = 'development' | 'dev' | 'uat' | 'prd'

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const currentEnvironment: Environment = import.meta.env.MODE

export const isLocalEnvironment = currentEnvironment === 'development'
export const isDevelopmentEnvironment = currentEnvironment === 'development' || currentEnvironment === 'dev'
export const isStagingEnvironment = currentEnvironment === 'uat'
export const isProductionEnvironment = currentEnvironment === 'prd'
