<template>
    <div class="coming-soon-panel">
        <Construction class="coming-soon-panel__icon" />
        <span>
            {{ t('common.comingSoon') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Construction } from 'lucide-vue-next'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.coming-soon-panel {
    padding: $spacing-l 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $spacing-s;

    color: $pbl-warning;

    span {
        font-weight: 500;
        font-size: 1.2rem;
    }

    &__icon {
        height: 2.5rem;
        width: 2.5rem;
    }
}
</style>
