import { inject, InjectionKey, provide } from 'vue'

export type SystemStatusDescriptionModalActions = {
    openSystemStatusDescription: () => void
}

const systemStatusDescriptionModalActionsInjectionKey: InjectionKey<SystemStatusDescriptionModalActions> = Symbol(
    'systemStatusDescriptionModalActionsInjectionKey',
)

export const useSystemStatusDescriptionModal = () => {
    return inject(systemStatusDescriptionModalActionsInjectionKey)
}

export const provideSystemStatusDescriptionModal = (actions: SystemStatusDescriptionModalActions) =>
    provide(systemStatusDescriptionModalActionsInjectionKey, actions)
