import { Directive } from 'vue'

export const clickOutside: Directive = {
    beforeMount: (el, binding) => {
        el.clickOutsideEvent = (event: Event) => {
            if (el !== event.target && !el.contains(event.target)) {
                binding.value()
            }
        }

        document.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted: (el) => {
        document.removeEventListener('click', el.clickOutsideEvent)
    },
}
