<template>
    <ContentPanel :headline="panelHeadline">
        <div class="thresholds-table">
            <div ref="tableRef" />
        </div>
    </ContentPanel>
</template>

<script setup lang="ts">
import { ThresholdsAnalysisDto } from '@/api/generated/HCA'
import { computed, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { createElement, Pencil } from 'lucide'
import { useThresholdEditModal } from '@/pages/admin/thresholds/modals/threshold/useThresholdEditModal'

type ThresholdsTableRow = {
    id: number
    measurementName: string
    limit: string
    normal: string
    inferior: string
    critical: string
    validFrom: string
}

const props = defineProps<{
    data: ThresholdsAnalysisDto
    hideActions?: boolean
}>()

const { data } = toRefs(props)

const { t, locale } = useI18n()
const thresholdEditModal = useThresholdEditModal()

const { data: constantsData } = useConstantsQuery()

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const panelHeadline = computed(() => {
    if (!data.value.analysisType) {
        return '-'
    }

    let panelTitle = t(AnalysisTypes.find((e) => e.value === data.value.analysisType)?.labelKey ?? '-')

    if (data.value.cycleYears) {
        panelTitle += ` ${t('admin.thresholds.oilAnalyses.tables.everyYears', { years: data.value.cycleYears })}`
    }

    return panelTitle
})

const tableRowData = computed<ThresholdsTableRow[]>(() => {
    if (!constantsData.value) {
        return []
    }

    const tableRows: ThresholdsTableRow[] = []

    data.value.thresholds?.forEach((e) => {
        const parameterEntry = constantsData.value?.parameters?.find((parameter) => parameter.id === e.parameterId)

        if (!parameterEntry) {
            return
        }

        tableRows.push({
            id: e.id ?? -1,
            measurementName: `${chooseLangString(locale, parameterEntry)} ${e.criteriaDescription}`,
            limit: e.limit?.toString() ?? '-',
            normal: e.normalValue ?? '-',
            inferior: e.inferiorValue ?? '-',
            critical: e.criticalValue ?? '-',
            validFrom: format(new Date(e.validFrom ?? new Date()), DATE_TIME_FORMAT),
        })
    })

    return tableRows
})

const onEditClick = (parameterId: number) => {
    thresholdEditModal?.openThreshold({ id: parameterId })
}

watch([tableRef, tableRowData, locale, props], ([newTableRef, newData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: 'fitColumns',
        height: '100%',
        pagination: false,
        reactiveData: true,
        data: newData,
        renderVertical: 'basic',
        columnDefaults: {
            vertAlign: 'middle',
        },
        columns: [
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.measurementName'),
                field: 'measurementName',
            },
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.limit'),
                field: 'limit',
                width: 125,
            },
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.normal'),
                field: 'normal',
            },
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.inferior'),
                field: 'inferior',
            },
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.critical'),
                field: 'critical',
            },
            {
                title: t('admin.thresholds.oilAnalyses.tables.headers.validFrom'),
                field: 'validFrom',
            },
            ...(props.hideActions
                ? []
                : [
                    {
                        title: t('admin.thresholds.oilAnalyses.tables.headers.actions'),
                        field: 'id',
                        width: 100,
                        formatter: (cell: CellComponent) => {
                            const parameterId = cell.getValue()

                            const cellDiv = document.createElement('div')
                            cellDiv.classList.add('thresholds-table-cell')

                            const editIcon = createElement(Pencil)
                            editIcon.addEventListener('click', () => onEditClick(parameterId))
                            editIcon.classList.add('thresholds-table-cell__icon')
                            cellDiv.appendChild(editIcon)

                            return cellDiv
                        },
                    },
                ]),
        ],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.thresholds-table {
    margin: -($spacing-s);
}
</style>

<style lang="scss">
.thresholds-table-cell {
    display: flex;
    align-items: center;

    &__icon {
        height: 16px;
        width: 16px;

        cursor: pointer;
        color: $pbl-primary;
    }
}
</style>
