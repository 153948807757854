import { inject, InjectionKey, provide } from 'vue'
import { CommentDto } from '@/api/generated/HCA'

export type ConditionStatusOpenCommentPayload = {
    comment: CommentDto
}

export type ConditionStatusCommentModalActions = {
    openComment: (payload?: ConditionStatusOpenCommentPayload) => void
}

export const ConditionStatusCommentModalInjectionKey: InjectionKey<ConditionStatusCommentModalActions> = Symbol(
    'ConditionStatusCommentModalInjectionKey',
)

export const useConditionStatusCommentModal = () => {
    return inject(ConditionStatusCommentModalInjectionKey)
}

export const provideConditionStatusCommentModal = (actions: ConditionStatusCommentModalActions) =>
    provide(ConditionStatusCommentModalInjectionKey, actions)
