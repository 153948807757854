<template>
    <ContentPanel :headline="t('reports.detailsPage.visualInspection.title')">
        <div
            v-if="visualInspectionData"
            class="visual-inspection-table"
        >
            <div ref="tableRef" />
        </div>
        <EmptyStatePanelContent v-else />
    </ContentPanel>
</template>

<script setup lang="ts">
import { AnalysisDto } from '@/api/generated/HCA'
import { computed, ref, toRefs, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { PebbleTable } from '@pebble/tables'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { CellComponent, ColumnDefinition, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { AlertCircle, AlertTriangle, createElement, IconNode } from 'lucide'

type TableRowData = {
    measurementName: string
    status: number
    comment: string
}

const props = defineProps<{
    reportNumber: string
    visualInspectionData: AnalysisDto
}>()

const { reportNumber, visualInspectionData } = toRefs(props)

const { t } = useI18n()
const { data: constantsData } = useConstantsQuery()

const statusIcons: Record<number, { iconNode: IconNode; color: string }> = {
    [ConditionStatus.Inferior]: {
        iconNode: AlertTriangle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Inferior)?.color ?? '',
    },
    [ConditionStatus.Critical]: {
        iconNode: AlertCircle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Critical)?.color ?? '',
    },
}

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const columns = computed<ColumnDefinition[]>(() => {
    if (!visualInspectionData.value) {
        return []
    }

    return [
        {
            title: t('transformers.conditionStatus.content.visualInspections.table.reportNumber'),
            columns: [
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.measurement'),
                    field: 'measurementName',
                    headerSort: false,
                },
            ],
        },
        {
            title: '',
            titleFormatter: () => {
                const date = format(new Date(new Date()), DATE_FORMAT)
                const analyticalNumber = reportNumber.value ?? ''

                const headerCell = document.createElement('div')
                headerCell.className = 'visual-inspection-table-header-cell'

                const linkSpan = document.createElement('span')
                linkSpan.textContent = analyticalNumber
                headerCell.appendChild(linkSpan)

                const dateSpan = document.createElement('span')
                dateSpan.textContent = date
                dateSpan.classList.add('visual-inspection-table-header-cell__date')
                headerCell.appendChild(dateSpan)

                return headerCell
            },
            columns: [
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.status'),
                    field: 'status',
                    headerSort: false,
                    formatter: (cell: CellComponent) => {
                        const status = cell.getValue() ?? ConditionStatus.None
                        const displayedText = t(StatusTypes[status].labelKey) ?? '-'

                        const statusIconData = statusIcons[status]

                        const cellDiv = document.createElement('div')
                        cellDiv.className = 'visual-inspections-table-content-cell'

                        const textSpan = document.createElement('span')
                        textSpan.textContent = displayedText
                        cellDiv.appendChild(textSpan)

                        if (statusIconData) {
                            const statusIcon = createElement(statusIconData.iconNode)
                            statusIcon.classList.add('visual-inspections-table-icon')
                            statusIcon.setAttribute('stroke', statusIconData.color)
                            statusIcon.setAttribute('stroke-width', '3px')
                            cellDiv.appendChild(statusIcon)
                        }

                        return cellDiv
                    },
                },
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.comment'),
                    field: 'comment',
                    headerSort: false,
                    formatter: 'textarea',
                },
            ],
        },
    ]
})

const tableData = computed(() => {
    if (!visualInspectionData.value || !constantsData.value) {
        return []
    }

    const rowData: TableRowData[] = []

    visualInspectionData.value.parameters?.forEach((parameter) => {
        const parameterData = constantsData.value?.visualInspectionParameters?.find(
            (e) => e.id === parameter.parameterId,
        )

        rowData.push({
            measurementName: t(
                `transformers.conditionStatus.content.visualInspections.table.measurementPoints.${parameterData?.name}`,
            ),
            status: parameter.status ?? 0,
            comment: parameter.comment ?? '-',
        })
    })

    return rowData
})

watch([tableRef, tableData], ([newTableRef, newTableData]) => {
    if (!newTableRef) {
        return
    }

    if (newTableData.length === 0) {
        return
    }

    const tabulatorOptions: Options = {
        layoutColumnsOnNewData: true,
        reactiveData: true,
        pagination: false,
        layout: 'fitDataStretch',
        height: '100%',
        headerSort: false,
        columnHeaderVertAlign: 'bottom',
        columnDefaults: {
            vertAlign: 'middle',
        },
        data: newTableData,
        columns: columns.value,
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.visual-inspection-table {
    margin: -($spacing-s);
}
</style>

<style lang="scss">
.visual-inspection-table-header-cell {
    display: flex;
    gap: 4px;

    &__link {
        color: $pbl-primary;
        cursor: pointer;
    }

    &__date {
        font-weight: normal;
    }
}

.visual-inspections-table-limit-cell:not(.tabulator-col) {
    background-color: #0078dc26 !important;
}

.visual-inspections-table {
    .tabulator-row-even {
        .tabulator-frozen:not(.visual-inspections-table-limit-cell) {
            background-color: #fcfcfc !important;
        }
    }
}

.visual-inspections-table-comment-cell {
    text-overflow: ellipsis;
    display: inline;
}

.visual-inspections-table-content-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
}

.visual-inspections-table-icon {
    width: 1.1rem;
    height: 1.1rem;
}

.visual-inspections-table-tooltip {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    background-color: $pbl-text !important;
    color: $pbl-foreground;

    max-width: 300px;

    &__title {
        font-weight: 500;
    }

    &__content {
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    &__details {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
