<template>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="visual-inspection">
            <div v-if="data && data.items?.length !== 0">
                <div class="visual-inspection__header">
                    <span class="visual-inspection__header__title">
                        {{ t('transformers.conditionStatus.content.visualInspections.status.label') }}
                    </span>
                    <ColorBadge
                        :color="reportStatus.color"
                        :text="t(reportStatus.labelKey)"
                    />
                </div>
                <div class="visual-inspection__table-section">
                    <div
                        ref="tableRef"
                        class="visual-inspection__table-section__table"
                    />
                </div>
            </div>
            <EmptyStatePanelContent v-else />
        </div>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { useTransformerVisualInspectionQuery } from '@/api/queries/transformers/useTransformerVisualInspectionsQuery'
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { useI18n } from 'vue-i18n'
import { CellComponent, ColumnDefinition, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { VisualInspectionParameterValueDto } from '@/api/generated/HCA'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { useTransformerId } from '@/composables/useTransformerId'
import { AlertCircle, AlertTriangle, createElement, IconNode } from 'lucide'
import { useTransformerComponentStatusQuery } from '@/api/queries/transformers/useTransformerComponentStatusQuery'
import { AnalysisType } from '@/configuration/analysis/analysis-types'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { useRouter } from 'vue-router'
import { ReportType } from '@/configuration/report/report-types'

type RowData = Record<string, string | number | VisualInspectionParameterValueDto>

const transformerId = useTransformerId()

const { t } = useI18n()
const router = useRouter()

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useTransformerVisualInspectionQuery(transformerId)
const { data: statusData } = useTransformerComponentStatusQuery(transformerId)

const reportStatus = computed(() => {
    const status =
        statusData.value?.latestConditionStatuses?.find((e) => e.reportType === AnalysisType.VisualInspection)
            ?.status ?? ConditionStatus.None
    return StatusTypes[status ?? ConditionStatus.None]
})

const statusIcons: Record<number, { iconNode: IconNode; color: string }> = {
    [ConditionStatus.Inferior]: {
        iconNode: AlertTriangle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Inferior)?.color ?? '',
    },
    [ConditionStatus.Critical]: {
        iconNode: AlertCircle,
        color: StatusTypes.find((e) => e.value === ConditionStatus.Critical)?.color ?? '',
    },
}

const openReport = (event: Event) => {
    const analyticalNumber = (event.target as HTMLElement)?.innerText ?? ''

    router.push({
        name: 'Reports_View_Condition',
        params: {
            reportNumber: analyticalNumber,
            reportType: ReportType.VisualInspection,
        },
    })
}

// @ts-expect-error ColumnDefinition doesn't properly type the Tooltip return type.
const columns = computed<ColumnDefinition[]>(() => {
    if (!data.value) {
        return []
    }

    return [
        {
            title: t('transformers.conditionStatus.content.visualInspections.table.reportNumber'),
            columns: [
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.measurement'),
                    field: 'parameterName',
                    headerSort: false,
                },
            ],
            frozen: true,
        },
        ...(data.value?.items?.map((report, index) => ({
            title: `${report.analyticalNumber} ${format(new Date(report.date ?? new Date()), DATE_FORMAT)}`,
            titleFormatter: () => {
                const date = format(new Date(report.date ?? new Date()), DATE_FORMAT)
                const analyticalNumber = report.analyticalNumber ?? ''

                const headerCell = document.createElement('div')
                headerCell.className = 'visual-inspection-table-header-cell'

                const linkSpan = document.createElement('span')
                linkSpan.textContent = analyticalNumber
                linkSpan.classList.add('visual-inspection-table-header-cell__link')
                linkSpan.addEventListener('click', openReport)
                headerCell.appendChild(linkSpan)

                const dateSpan = document.createElement('span')
                dateSpan.textContent = `(${date})`
                dateSpan.classList.add('visual-inspection-table-header-cell__date')
                headerCell.appendChild(dateSpan)

                return headerCell
            },
            headerTooltip: () => {
                const tooltipDiv = document.createElement('div')
                tooltipDiv.classList.add('visual-inspections-table-tooltip')

                const contentDiv = document.createElement('div')
                contentDiv.classList.add('visual-inspections-table-tooltip__content')
                contentDiv.textContent = t(
                    'transformers.conditionStatus.content.visualInspections.table.reportNumberTooltip',
                )
                tooltipDiv.appendChild(contentDiv)

                return tooltipDiv
            },
            columns: [
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.status'),
                    field: report.analyticalNumber ?? 'N/A',
                    headerSort: false,
                    minWidth: 150,
                    formatter: (cell: CellComponent) => {
                        const value: VisualInspectionParameterValueDto = cell.getValue()
                        const status = value?.status ?? ConditionStatus.None
                        const displayedText = t(StatusTypes[status].labelKey) ?? '-'

                        const statusIconData = statusIcons[status]

                        const cellDiv = document.createElement('div')
                        cellDiv.className = 'visual-inspections-table-content-cell'

                        const textSpan = document.createElement('span')
                        textSpan.textContent = displayedText
                        cellDiv.appendChild(textSpan)

                        if (statusIconData) {
                            const statusIcon = createElement(statusIconData.iconNode)
                            statusIcon.classList.add('visual-inspections-table-icon')
                            statusIcon.setAttribute('stroke', statusIconData.color)
                            statusIcon.setAttribute('stroke-width', '3px')
                            cellDiv.appendChild(statusIcon)
                        }

                        return cellDiv
                    },
                },
                {
                    title: t('transformers.conditionStatus.content.visualInspections.table.comment'),
                    field: report.analyticalNumber ?? 'N/A',
                    headerSort: false,
                    minWidth: 150,
                    maxWidth: 300,
                    formatter: (cell: CellComponent) => {
                        const value: VisualInspectionParameterValueDto = cell.getValue()
                        const displayedText = value?.comment ?? '-'

                        const cellDiv = document.createElement('div')
                        cellDiv.className = 'visual-inspections-table-comment-cell'

                        const textSpan = document.createElement('span')
                        textSpan.textContent = displayedText
                        cellDiv.appendChild(textSpan)

                        return cellDiv
                    },
                    tooltip: (event: UIEvent, cell: CellComponent) => {
                        const value: VisualInspectionParameterValueDto = cell.getValue()
                        const commentText = value?.comment

                        if (!commentText) {
                            return ''
                        }

                        const tooltipDiv = document.createElement('div')
                        tooltipDiv.classList.add('visual-inspections-table-tooltip')

                        const contentDiv = document.createElement('div')
                        contentDiv.classList.add('visual-inspections-table-tooltip__content')
                        contentDiv.textContent = commentText
                        tooltipDiv.appendChild(contentDiv)

                        return tooltipDiv
                    },
                },
            ],
            frozen: index === 0,
        })) ?? []),
    ]
})

const tableData = computed(() => {
    if (!data.value || !constantsData.value) {
        return []
    }

    const availableParameters = new Set<number>()

    data.value.items?.forEach((report) => {
        report.inspectionParameterValues?.forEach((parameter) => {
            if (parameter.parameterId) {
                availableParameters.add(parameter.parameterId)
            }
        })
    })

    const rowData: RowData[] = []

    availableParameters.forEach((availableParameter) => {
        const constantsRecord = constantsData.value?.visualInspectionParameters?.find(
            (e) => e.id === availableParameter,
        )

        if (!constantsRecord) {
            return
        }

        rowData.push({
            parameterId: availableParameter,
            parameterName: t(
                `transformers.conditionStatus.content.visualInspections.table.measurementPoints.${constantsRecord.name}`,
            ),
        })
    })

    data.value.items?.forEach((report) => {
        const columnId = report.analyticalNumber ?? 'N/A'

        report.inspectionParameterValues?.forEach((parameter) => {
            const rowRecord = rowData.find((e) => e.parameterId === parameter.parameterId)

            if (rowRecord) {
                rowRecord[columnId] = parameter
            }
        })
    })

    return rowData
})

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

watch([tableRef, columns], ([newTableRef, newColumns]) => {
    if (!newTableRef) {
        return
    }

    if (newColumns.length === 0) {
        return
    }

    const tabulatorOptions: Options = {
        layoutColumnsOnNewData: true,
        reactiveData: true,
        pagination: false,
        layout: 'fitDataTable',
        height: '100%',
        headerSort: false,
        columnHeaderVertAlign: 'bottom',
        columns: newColumns,
        data: tableData.value,
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})
</script>

<style scoped lang="scss">
.visual-inspection {
    &__header {
        display: flex;
        align-items: center;
        gap: 1em;

        &__title {
            font-weight: 500;
        }
    }

    &__table-section {
        margin-top: $spacing-s;

        &__table {
            border: 1px solid $pbl-border-muted;
        }
    }
}
</style>

<style lang="scss">
.visual-inspection-table-header-cell {
    display: flex;
    gap: 4px;

    &__link {
        color: $pbl-primary;
        cursor: pointer;
    }

    &__date {
        color: $pbl-text-muted;
        font-weight: normal;
    }
}

.visual-inspections-table-limit-cell:not(.tabulator-col) {
    background-color: #0078dc26 !important;
}

.visual-inspections-table {
    .tabulator-row-even {
        .tabulator-frozen:not(.visual-inspections-table-limit-cell) {
            background-color: #fcfcfc !important;
        }
    }
}

.visual-inspections-table-comment-cell {
    text-overflow: ellipsis;
    display: inline;
}

.visual-inspections-table-content-cell {
    display: flex;
    align-items: center;
    gap: $spacing-2xs;
}

.visual-inspections-table-icon {
    width: 1.1rem;
    height: 1.1rem;
}

.visual-inspections-table-tooltip {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    background-color: $pbl-text !important;
    color: $pbl-foreground;

    max-width: 300px;

    &__title {
        font-weight: 500;
    }

    &__content {
        white-space: pre-wrap;
        text-overflow: ellipsis;
    }

    &__details {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
