<template>
    <ExportWrapper :file-name="fileName">
        <QueryLoaderGuard
            :is-pending="isPending"
            :is-error="isError"
        >
            <template v-if="transformerId">
                <ExportViewReportTitleSection
                    :report-number="reportNumber"
                    :report-type="reportTypeAsNumber"
                    :transformer-id="transformerId"
                />
                <div class="master-data-wrapper">
                    <MasterDataPanel :transformer-id="transformerId" />
                </div>
                <div
                    v-if="reportTypeAsNumber === ReportType.OilAnalysis"
                    class="oil-analysis-wrapper"
                >
                    <ViewReportOilAnalysisSection
                        :report-number="reportNumber"
                        :report-type="reportTypeAsNumber"
                        export-layout
                    />
                </div>
                <div
                    v-if="reportTypeAsNumber === ReportType.VisualInspection && data?.data?.[0]"
                    class="section-wrapper"
                >
                    <ExportViewReportVisualInspectionSection :data="data.data[0]" />
                </div>
                <ExportFooterSection />
            </template>
        </QueryLoaderGuard>
    </ExportWrapper>
</template>

<script setup lang="ts">
import ExportWrapper from '@/pages/exports/ExportWrapper.vue'
import ExportViewReportTitleSection from '@/pages/exports/reports/view-report/sections/ExportViewReportTitleSection.vue'
import MasterDataPanel from '@/components/master-data/MasterDataPanel.vue'
import { computed, toRefs } from 'vue'
import { ReportType, ReportTypes } from '@/configuration/report/report-types'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import ViewReportOilAnalysisSection from '@/pages/reports/view-report-page/sections/ViewReportOilAnalysisSection.vue'
import ExportViewReportVisualInspectionSection from '@/pages/exports/reports/view-report/sections/ExportViewReportVisualInspectionSection.vue'
import ExportFooterSection from '@/pages/exports/common/ExportFooterSection.vue'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    reportNumber: string
    reportType: string
}>()

const { reportNumber, reportType } = toRefs(props)

const { t } = useI18n()

const reportTypeAsNumber = computed(() => {
    const parsedNumber = parseInt(reportType.value)

    return isNaN(parsedNumber) ? ReportType.OilAnalysis : parsedNumber
})

const { data, isPending, isError } = useReportGetQuery(reportNumber, reportTypeAsNumber)

const transformerId = computed(() => {
    if (!data.value) {
        return undefined
    }

    const firstAnalysisWithComponentSid = data.value?.data?.find((e) => !!e.componentSid)
    return firstAnalysisWithComponentSid?.componentSid ?? undefined
})

const { data: transformerDetailsData } = useTransformerDetailsQuery(transformerId)

const fileName = computed(() => {
    if (!transformerDetailsData.value) {
        return undefined
    }

    const plantGroupName = transformerDetailsData.value.plantGroupName
    const plantName = transformerDetailsData.value.plantName
    const transformerSid = transformerDetailsData.value.transformerSid
    const reportType = t(ReportTypes.find((e) => e.value === reportTypeAsNumber.value)?.labelKey ?? '-')
        .split(' ')
        .join('')
    const currentDate = format(new Date(), DATE_FORMAT)

    return `HCA_${plantGroupName}_${plantName}_${transformerSid}_${reportType}_${reportNumber.value}_${currentDate}`
})
</script>

<style scoped lang="scss">
:deep(.content-panel) {
    border: 1px solid $pbl-divider;
    box-shadow: unset;
}

.master-data-wrapper {
    padding: $spacing-xs $spacing-s;
    break-inside: avoid;
}

.section-wrapper {
    padding: $spacing-xs $spacing-s;
}

.oil-analysis-wrapper {
    padding: $spacing-xs $spacing-s;
}
</style>
