import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'

const commonObjectFields = {
    englishName: zod.string(),
    germanName: zod.string(),
}

export const addRecommendationTypeValidationSchema = toTypedSchema(
    zod.object({
        ...commonObjectFields,
    }),
)

export const editRecommendationTypeValidationSchema = toTypedSchema(
    zod.object({
        ...commonObjectFields,
        recommendationId: zod.number(),
    }),
)
