<template>
    <Teleport to="body">
        <pbl-dialog
            ref="pblDialogRef"
            click-shield
            :headline="options.headline"
        >
            <!-- Disable ESLint warnings for non-Vue slot attribute -->
            <!-- eslint-disable -->
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="onCancelClick"
            >
                {{ options.cancelButtonText }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="isLoading"
                :loading="isLoading"
                @click="onConfirmClick"
            >
                {{ options.confirmButtonText }}
            </pbl-button>
            <!-- eslint-enable -->
            {{ options.content }}
        </pbl-dialog>
    </Teleport>
    <slot />
</template>

<script setup lang="ts">
import { provide, reactive, ref } from 'vue'
import {
    GlobalConfirmationModalInjectionKey,
    GlobalConfirmationModalOptions,
} from '@/composables/useGlobalConfirmationModal'
import { useI18n } from 'vue-i18n'
import { PebbleDialog } from '@pebble/core'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'

const { t } = useI18n()
const toasts = usePebbleToasts()

const pblDialogRef = ref<PebbleDialog | null>()

const isLoading = ref(false)

const options: GlobalConfirmationModalOptions = reactive({
    headline: '',
})

const handleModalRequest = (requestOptions: GlobalConfirmationModalOptions) => {
    options.headline = requestOptions.headline
    options.content = requestOptions.content ?? ''
    options.confirmButtonText = requestOptions.confirmButtonText ?? t('globalConfirmation.defaultConfirm')
    options.cancelButtonText = requestOptions.cancelButtonText ?? t('globalConfirmation.defaultCancel')
    options.onConfirm = requestOptions.onConfirm

    isLoading.value = false

    pblDialogRef.value?.show()
}

provide(GlobalConfirmationModalInjectionKey, handleModalRequest)

const onConfirmClick = async () => {
    if (!options.onConfirm) {
        pblDialogRef.value?.hide()
        return
    }

    isLoading.value = true

    try {
        await options.onConfirm()
    } catch (err) {
        toasts?.error({
            message: t('common.errors.unknown'),
            duration: 3000,
        })
    } finally {
        isLoading.value = false
        pblDialogRef.value?.hide()
    }
}

const onCancelClick = () => {
    pblDialogRef.value?.hide()
}
</script>
