<template>
    <div class="list-item">
        <div class="list-item__text">
            <div class="list-item__text-title">{{ notificationTitle }}</div>
            <div class="list-item__text-subtitle">{{ notificationDate }}</div>
        </div>
        <div
            class="list-item__action"
            @click="onNotificationActionClick"
        >
            <CornerDownRight />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ActivityTrackingListDto } from '@/api/generated/HCA'
import { computed } from 'vue'
import { TransformerFlatDto } from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { StatusTypes } from '@/configuration/status/condition-status'
import { CornerDownRight } from 'lucide-vue-next'
import { useRouter } from 'vue-router'
import { CONDITION_STATUS_SCROLL_STATE_ACTION_TYPE_MAP } from '@/configuration/internal-redirect-states/condition-status-scroll-state'
import { ActionType } from '@/configuration/actions/action-type'

const props = defineProps<{
    notification: ActivityTrackingListDto
    flattenedHierarchy: TransformerFlatDto[]
}>()

const { t } = useI18n()
const router = useRouter()

const notificationAction = computed(() => {
    if (!props.notification.action) {
        return '-'
    }

    const previousStatus = StatusTypes.find((e) => e.value === props.notification.previousStatus)
    const newStatus = StatusTypes.find((e) => e.value === props.notification.newStatus)

    return t(`dashboard.notifications.actions.${props.notification.action}`, {
        previousStatus: previousStatus ? t(previousStatus.labelKey) : '-',
        newStatus: newStatus ? t(newStatus.labelKey) : '-',
    })
})

const notificationTitle = computed(() => {
    const transformerRecord = props.flattenedHierarchy.find((e) => e.transformer.id === props.notification.componentSid)

    if (!transformerRecord) {
        return `${notificationAction.value}`
    }

    return t('dashboard.notifications.item', {
        plantGroup: transformerRecord.plantGroup.name,
        plant: transformerRecord.plant.name,
        componentSid: props.notification.componentName,
        action: notificationAction.value,
    })
})

const notificationDate = computed(() => format(new Date(props.notification.timestamp ?? new Date()), DATE_TIME_FORMAT))

const onNotificationActionClick = () => {
    (props.notification.action as ActionType) != ActionType.ThresholdEdited ? router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: props.notification.componentSid,
        },
        query: {
            focused: props.notification.action
                ? CONDITION_STATUS_SCROLL_STATE_ACTION_TYPE_MAP[props.notification.action as ActionType]
                : undefined,
        },
    })
        :
        router.push({
            name: 'Admin_Thresholds'
        })
}
</script>

<style scoped lang="scss">
.list-item {
    margin-right: $spacing-2xs;
    background-color: $pbl-foreground-muted;
    border-radius: 4px;

    display: flex;
    gap: $spacing-xs;
    align-items: center;
    justify-content: space-between;

    padding: $spacing-xs $spacing-s;

    &__text {
        display: flex;
        flex-direction: column;
        gap: $spacing-3xs;

        &-title {
            font-weight: 500;
        }

        &-subtitle {
            color: $pbl-text-muted;
        }
    }

    &__action {
        cursor: pointer;

        color: $pbl-primary;
    }
}
</style>
