<template>
    <FormInputField
        v-model="value"
        :label="label"
        :placeholder="placeholder"
        :type="type"
        :disabled="disabled"
        :invalid="!meta.valid && meta.validated"
        :error-message="errorMessage ? t(errorMessage) : undefined"
        :maxlength="maxlength"
        :has-clear-action="hasClearAction"
    />
</template>

<script setup lang="ts">
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    name: string

    label: string
    disabled?: boolean
    placeholder?: string
    type?: string
    maxlength?: number
    hasClearAction?: boolean
}>()

const { t } = useI18n()
const { value, errorMessage, meta } = useField<string>(() => props.name)
</script>
