import { useTransformerReportsQuery } from '@/api/queries/transformers/useTransformerReportsQuery'
import { computed, MaybeRefOrGetter, Ref } from 'vue'
import { AnalysisType, AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { ListOilAnalysisReportsByTransformerResponse } from '@/api/generated/HCA'
import { useI18n } from 'vue-i18n'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import { DATE_FORMAT } from '@/utils/date-utils'
import { format } from 'date-fns'
import { useTransformerReportNumbersQuery } from '@/api/queries/transformers/useTransformerReportNumbersQuery'

export const useConditionStatusCommentSelectionHierarchy = (transformerId: MaybeRefOrGetter<string>) => {
    const { t, locale } = useI18n()

    const { data: constantsData } = useConstantsQuery()
    const { data: insulatingOilData } = useTransformerReportsQuery(transformerId, AnalysisType.InsulatingOil)
    const { data: furansData } = useTransformerReportsQuery(transformerId, AnalysisType.Furans)
    const { data: gasInOilData } = useTransformerReportsQuery(transformerId, AnalysisType.GasInOil)
    const { data: reportNumbersData } = useTransformerReportNumbersQuery(transformerId)

    const extractMeasurementsFromReportData = (
        reportNumber: string,
        data?: ListOilAnalysisReportsByTransformerResponse,
    ): SelectOptions<number> => {
        const parameters = data?.reports?.find((e) => e.analyticalNumber === reportNumber)?.parameters
        const constantsParameters = constantsData.value?.parameters

        if (!parameters || !constantsParameters) {
            return []
        }

        const parameterIds = parameters.map((e) => e.parameterId).filter((e) => !!e) as number[]

        return parameterIds
            .map((parameterId) => {
                const parameter = constantsParameters.find((e) => e.id === parameterId)

                if (!parameter) {
                    return undefined
                }

                return {
                    label: chooseLangString(locale, parameter),
                    value: parameter.id ?? 0,
                }
            })
            .filter((e) => !!e) as SelectOptions<number>
    }

    const isReportNumberInData = (
        reportNumber: string,
        data?: ListOilAnalysisReportsByTransformerResponse,
    ): boolean => {
        return !!data?.reports?.some((e) => e.analyticalNumber === reportNumber)
    }

    const reportNumberOptions = computed<SelectOptions>(
        () =>
            reportNumbersData.value?.reportNumbers?.map((e) => {
                const id = e.id ?? '-'

                return {
                    label: e.date ? `${id} (${format(e.date, DATE_FORMAT)})` : id,
                    value: id,
                }
            }) ?? [],
    )

    const createComputedAnalysisTypeOptions = (reportNumber: Ref<string>) => {
        return computed<SelectOptions<number>>(() => {
            const options: SelectOptions<number> = []

            if (isReportNumberInData(reportNumber.value, insulatingOilData.value)) {
                options.push({
                    label: t(AnalysisTypes.find((e) => e.value === AnalysisType.InsulatingOil)?.labelKey ?? ''),
                    value: AnalysisType.InsulatingOil,
                })
            }

            if (isReportNumberInData(reportNumber.value, furansData.value)) {
                options.push({
                    label: t(AnalysisTypes.find((e) => e.value === AnalysisType.Furans)?.labelKey ?? ''),
                    value: AnalysisType.Furans,
                })
            }

            if (isReportNumberInData(reportNumber.value, gasInOilData.value)) {
                options.push({
                    label: t(AnalysisTypes.find((e) => e.value === AnalysisType.GasInOil)?.labelKey ?? ''),
                    value: AnalysisType.GasInOil,
                })
            }

            return options
        })
    }

    const createComputedMeasurementOptions = (reportNumber: Ref<string>, analysisType: Ref<number | undefined>) => {
        return computed(() => {
            switch (analysisType.value) {
            case AnalysisType.GasInOil:
                return extractMeasurementsFromReportData(reportNumber.value, gasInOilData.value)
            case AnalysisType.Furans:
                return extractMeasurementsFromReportData(reportNumber.value, furansData.value)
            case AnalysisType.InsulatingOil:
                return extractMeasurementsFromReportData(reportNumber.value, insulatingOilData.value)
            default:
                return []
            }
        })
    }

    return {
        reportNumberOptions,
        createComputedAnalysisTypeOptions,
        createComputedMeasurementOptions,
    }
}
