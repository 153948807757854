import { MaybeRefOrGetter, toValue } from 'vue'
import { AnalysisType } from '@/configuration/analysis/analysis-types'
import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformerReportsQuery = (
    transformerId: MaybeRefOrGetter<string>,
    analysisType: MaybeRefOrGetter<AnalysisType>,
) => {
    return useQuery({
        queryKey: ['transformers', transformerId, 'reports', toValue(analysisType)],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.componentStatusApi.listOilAnalysisReportsByTransformer(
                toValue(transformerId),
                toValue(analysisType),
                0,
                0,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
