import { useQuery } from '@tanstack/vue-query'
import { computed, MaybeRef, toValue } from 'vue'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticatedAnd } from '@/utils/query-utils'

export const useGetThresholdQuery = (thresholdId: MaybeRef<number | undefined>) => {
    return useQuery({
        queryKey: ['thresholds', thresholdId],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.thresholdsApi.getThresholdById(toValue(thresholdId) ?? 0, { signal })

            return result
        },
        enabled: whenAuthenticatedAnd(computed(() => !!toValue(thresholdId))),
        staleTime: Infinity,
    })
}
