import { inject, InjectionKey } from 'vue'

export type GlobalConfirmationModalOptions = {
    headline: string
    content?: string
    cancelButtonText?: string
    confirmButtonText?: string
    onConfirm?: () => Promise<unknown>
}

export type GlobalConfirmationModalFn = (options: GlobalConfirmationModalOptions) => void

export const GlobalConfirmationModalInjectionKey: InjectionKey<GlobalConfirmationModalFn> =
    Symbol('globalConfirmationHandler')

export const useGlobalConfirmationModal = () => {
    return (
        inject(GlobalConfirmationModalInjectionKey) ??
        (() => {
            console.log('Global Confirmation Modal trigger with no value injected.')
        })
    )
}
