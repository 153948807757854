import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { UpdateBookmarksRequest } from '@/api/generated/HCA'

export const useBookmarksUpdateMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: UpdateBookmarksRequest) =>
            hcaClientFactory.bookmarksApi.bulkUpdateBookmarks(payload),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['bookmarks'] })
            await queryClient.invalidateQueries({ queryKey: ['transformersHierarchy'] })
        },
    })
}
