<template>
    <div class="comment">
        <div class="comment__field-line">
            <VeeFormInputField
                :name="createAccessorForProperty('text')"
                :disabled="!isEditable"
                label=""
                class="comment__field-line__field"
            />
            <pbl-button
                v-if="isDeletable"
                variant="primary"
                class="comment__field-line__button"
                @click="onDeleteClick"
            >
                <Trash2
                    slot="icon"
                    :size="20"
                />
            </pbl-button>
        </div>
        <div
            v-if="isMetadataAvailable"
            class="comment__meta"
        >
            {{ `${formattedDate} | KID ${commentKid}` }}
        </div>
    </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate'
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import { computed } from 'vue'
import { format } from 'date-fns'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { Trash2 } from 'lucide-vue-next'
import { useUserStore } from '@/store/userStore'

const props = defineProps<{
    index: number
}>()

const emit = defineEmits<{
    delete: []
}>()

const userStore = useUserStore()

const createAccessorForProperty = (property: string) => `comments.${props.index}.${property}`

const { value: commentId } = useField<number | undefined>(() => createAccessorForProperty('id'))
const { value: commentKid } = useField<string | undefined>(() => createAccessorForProperty('kid'))
const { value: commentDate } = useField<string | undefined>(() => createAccessorForProperty('date'))

const isDeletable = computed(() => !commentId.value)
const isEditable = computed(() => !commentKid.value || commentKid.value === userStore.userData?.kid)
const isMetadataAvailable = computed(() => !!commentKid.value && !!commentDate.value)

const formattedDate = computed(() => {
    if (!commentDate.value) {
        return '-'
    }

    return format(new Date(commentDate.value), DATE_TIME_FORMAT)
})

const onDeleteClick = () => {
    if (!isDeletable.value) {
        return
    }

    emit('delete')
}
</script>

<style scoped lang="scss">
.comment {
    display: flex;
    flex-direction: column;

    &__field-line {
        display: flex;
        align-items: center;
        justify-content: center;

        gap: $spacing-xs;

        &__field {
            flex-grow: 1;
        }

        &__button {
            margin-top: 2px;
        }
    }

    &__meta {
        color: $pbl-text-muted;
        font-size: 0.9em;

        margin-left: 3px;
    }
}
</style>
