<template>
    <ContentPanel>
        <div class="notification-section">
            <div class="notification-section__title">
                {{ t('dashboard.notifications.title') }}
                <Info
                    v-tooltip="t('dashboard.notifications.tooltip')"
                    class="notification-section__title-icon"
                />
            </div>
            <NotificationFilters
                v-model:component-type="componentType"
                v-model:notification-types="notificationTypes"
                v-model:past-days="pastDays"
            />
            <QueryLoaderGuard
                :is-pending="isPending"
                :is-error="isError"
            >
                <div class="notification-section__list">
                    <template v-if="data?.items && data.items.length !== 0">
                        <template
                            v-for="(item, index) in data.items"
                            :key="index"
                        >
                            <NotificationsListItem
                                v-if="item"
                                :notification="item"
                                :flattened-hierarchy="flattenedHierarchy"
                            />
                        </template>
                    </template>
                    <EmptyStatePanelContent v-else />
                </div>
            </QueryLoaderGuard>
        </div>
    </ContentPanel>
</template>

<script setup lang="ts">
import { useDashboardNotificationsQuery } from '@/api/queries/dashboard/useDashboardNotificationsQuery'
import NotificationsListItem from '@/pages/dashboard/sections/notifications/NotificationsListItem.vue'
import {
    flattenTransformersHierarchy,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import NotificationFilters from '@/pages/dashboard/sections/notifications/NotificationFilters.vue'
import { ComponentType } from '@/configuration/component/component-types'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { DEFAULT_PAST_DAYS_VALUE } from '@/configuration/notifications-filter/notifications-filter'
import { Info } from 'lucide-vue-next'

const { t } = useI18n()

const {
    data: hierarchyData,
    isPending: isHierarchyPending,
    isError: isHierarchyError,
} = useTransformersHierarchyQuery()

const componentType = ref<number>(ComponentType.Transformer)
const notificationTypes = ref<number[]>([])
const pastDays = ref(DEFAULT_PAST_DAYS_VALUE)

const {
    data,
    isPending: isNotificationsPending,
    isError: isNotificationsError,
} = useDashboardNotificationsQuery(componentType, notificationTypes, pastDays)

const isPending = computed(() => isHierarchyPending.value || isNotificationsPending.value)
const isError = computed(() => isHierarchyError.value || isNotificationsError.value)

const flattenedHierarchy = computed(() => flattenTransformersHierarchy(hierarchyData.value?.plantGroups ?? []))
</script>

<style scoped lang="scss">
.notification-section {
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    &__title {
        font-weight: 500;

        display: flex;
        align-items: center;
        gap: $spacing-2xs;

        &-icon {
            height: 16px;
            width: 16px;
            margin-bottom: 1px;

            color: $pbl-text-muted;

            &:hover {
                color: $pbl-primary;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: $spacing-s;

        overflow-y: auto;
    }
}
</style>
