<template>
    <div class="reports-header">
        <div class="reports-header__bar">
            <span class="reports-header__bar__title">
                {{ t('reports.title') }}
            </span>
            <ReportsExportModal>
                <ReportsExportSection />
            </ReportsExportModal>
        </div>
        <ReportsComponentSelector
            :model-value="reportParams"
            @update:model-value="onTransformerValueUpdate"
        />
    </div>
</template>
<script setup lang="ts">
import ReportsComponentSelector from '@/pages/reports/view-all-reports-page/ReportsComponentSelector.vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ReportParams } from '@/types/reports'
import { REPORTS_PARAMS_KEY } from '@/utils/reports-utils'
import ReportsExportSection from '@/pages/reports/view-all-reports-page/sections/ReportsExportSection.vue'
import ReportsExportModal from '@/pages/reports/export/ReportsExportModal.vue'

defineProps<{
    reportParams: ReportParams
}>()

const { t } = useI18n()
const router = useRouter()

const onTransformerValueUpdate = (params: ReportParams) => {
    localStorage.setItem(REPORTS_PARAMS_KEY, JSON.stringify(params))
    router.replace({
        name: 'Reports_ViewAll',
        query: params,
    })
}
</script>

<style scoped lang="scss">
.reports-header {
    @include spacing-page;

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    background-color: white;
    border-bottom: 1px solid $pbl-border-muted;

    &__bar {
        display: flex;
        align-items: center;
        gap: 48px;

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
        }
    }
}
</style>
