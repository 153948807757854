<template>
    <LoadingPanel
        :headline="t('transformers.portfolioOverview.portfolioStatusSummary.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="data"
            class="portfolio-status-summary"
        >
            <div class="portfolio-status-summary__items">
                <PortfolioOverviewStatusItem
                    v-for="(item, index) in statusSummary"
                    :key="index"
                    :color="item.color"
                    :label="item.label"
                    :value="item.value"
                />
            </div>
            <span class="portfolio-status-summary__legend">
                {{ t('transformers.portfolioOverview.portfolioStatusSummary.numberOfTransformers') }}
            </span>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { usePortfolioStatusSummaryQuery } from '@/api/queries/transformers/portfolio-overview/usePortfolioStatusSummaryQuery'
import PortfolioOverviewStatusItem from '@/pages/transformers/portfolio-overview/PortfolioOverviewStatusItem.vue'
import { computed } from 'vue'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'

const { t } = useI18n()
const { data, isPending, isError } = usePortfolioStatusSummaryQuery()

const statusSummary = computed(() => {
    if (!data.value?.statusSummary) {
        return []
    }

    return Object.entries(data.value.statusSummary)
        .map(([key, value]) => {
            const statusType = StatusTypes.find((e) => e.value === parseInt(key))

            return {
                color: statusType ? statusType.color : '#000',
                label: statusType ? t(statusType.labelKey) : '-',
                type: statusType?.value ?? 0,
                value,
            }
        })
        .toSorted((a, b) => (b.type === ConditionStatus.None ? -1 : a.type - b.type))
})
</script>

<style scoped lang="scss">
.portfolio-status-summary {
    display: flex;
    flex-direction: column;
    gap: 1em;

    &__items {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 10px;
    }

    &__legend {
        font-size: $default-font-size;
        color: $pbl-text-muted;
    }
}
</style>
