import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'
import { PlantGroupDto } from '@/api/generated/HCA'

type PlantFlatDto = {
    plant: {
        sid: number
        name: string
    }
    plantGroup: {
        sid: number
        name: string
    }
}

export const usePlantsHierarchyQuery = () => {
    return useQuery({
        queryKey: ['plantsHierarchy'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.masterDataApi.getPlants(undefined, { signal })
            return result
        },
        enabled: whenAuthenticated,
        staleTime: Infinity,
    })
}

export const flattenPlantsHierarchy = (hierarchyStructure: PlantGroupDto[]): PlantFlatDto[] => {
    const flattenedPlants: PlantFlatDto[] = []

    hierarchyStructure.forEach((plantGroup) => {
        const plantGroupData = {
            sid: plantGroup.sid ?? 0,
            name: plantGroup.name ?? '-',
        }

        plantGroup.plants?.forEach((plant) => {
            flattenedPlants.push({
                plant: {
                    sid: plant.sid ?? 0,
                    name: plant.name ?? '-',
                },
                plantGroup: plantGroupData,
            })
        })
    })

    return flattenedPlants
}
