<template>
    <FormFieldWrapper :label="label">
        <pbl-textarea
            :value="modelValue"
            :invalid="invalid"
            @input="onPblInput"
        >
            <span
                v-if="invalid"
                slot="subline"
            >
                {{ errorMessage }}
            </span>
        </pbl-textarea>
    </FormFieldWrapper>
</template>

<script setup lang="ts">
import FormFieldWrapper from '@/components/form-inputs/form-field-wrapper/FormFieldWrapper.vue'

defineProps<{
    modelValue: string
    label?: string

    invalid?: boolean
    errorMessage?: string
}>()

const emit = defineEmits<{
    'update:modelValue': [value: string]
}>()

const onPblInput = (event: InputEvent) => {
    const value = (event.target as HTMLInputElement)?.value ?? ''

    emit('update:modelValue', value)
}
</script>
