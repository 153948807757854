import * as zod from 'zod'
import { toTypedSchema } from '@vee-validate/zod'

const optionalNumberString = zod
    .string()
    .refine((val) => val === '' || !isNaN(Number(val)), 'formInputs.common.placeholders.enterNumber')

export const thresholdEditValidationSchema = toTypedSchema(
    zod.object({
        normalValue: optionalNumberString,
        normalOperatorType: zod.number().optional(),
        inferiorOperatorType: zod.number().optional(),
        criticalValue: optionalNumberString,
        criticalOperatorType: zod.number().optional(),
        criticalLastValue: optionalNumberString,
        criticalLastOperatorType: zod.number().optional(),
        inferiorLastValue: optionalNumberString,
        inferiorLastOperatorType: zod.number().optional(),
    }),
)
