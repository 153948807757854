<template>
    <ContentPanel :headline="t('transformers.portfolioOverview.flaggedTransformers.headline')">
        <div class="flagged-transformers">
            <FlaggedTransformersSelector
                v-model:issue-type="selectedIssue"
                v-model:group-sid="selectedGroup"
                v-model:plant-sids="selectedPlants"
            />
            <AwaitSelectionPanelContent v-if="!selectedIssue || selectedPlants.length === 0" />
            <QueryLoaderGuard
                v-else
                :is-pending="isFlaggedPending"
                :is-error="isFlaggedError"
            >
                <div
                    v-if="data?.items && data.items?.length > 0"
                    class="flagged-transformers__transformers"
                >
                    <div class="flagged-transformers__transformers__list">
                        <FlaggedTransformerItem
                            v-for="(item, index) in flaggedTransformersList"
                            :key="index"
                            v-tooltip="item.transformerName"
                            :transformer="getShortTransformerName(item)"
                        />
                    </div>
                    <pbl-button
                        v-if="showToggleButton"
                        compact
                        class="flagged-transformers__transformers__show-all-button"
                        @click="toggleShowAll"
                    >
                        {{
                            isShowAll
                                ? t('transformers.portfolioOverview.flaggedTransformers.toggle.showLess')
                                : t('transformers.portfolioOverview.flaggedTransformers.toggle.showMore', {
                                      count: labelCount,
                                  })
                        }}
                    </pbl-button>
                </div>
                <EmptyStatePanelContent v-else />
            </QueryLoaderGuard>
        </div>
    </ContentPanel>
</template>

<script setup lang="ts">
import { usePortfolioFlaggedTransformers } from '@/api/queries/transformers/portfolio-overview/usePortfolioFlaggedTransformers'
import { computed, ref, watch } from 'vue'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { IssueTypes } from '@/configuration/issues/issue-types'
import { useI18n } from 'vue-i18n'
import AwaitSelectionPanelContent from '@/components/states/AwaitSelectionPanelContent.vue'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import FlaggedTransformerItem from '@/pages/transformers/portfolio-overview/flagged-transformers/FlaggedTransformerItem.vue'
import FlaggedTransformersSelector from '@/pages/transformers/portfolio-overview/flagged-transformers/FlaggedTransformersSelector.vue'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import {
    flattenTransformersHierarchy,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { dedupeArray } from '@/utils/array-utils'
import { FLAGGED_TRANSFORMERS_KEY } from '@/utils/flagged-transformers-utils'
import { FlaggedTransformerDto } from '@/api/generated/HCA'

const ALL_ITEMS_VALUE = -1
const DEFAULT_MAX_SHOWN_ITEMS = 5
const TRANSFORMER_NAME_MAX_LENGTH = 25

const isShowAll = ref(false)

const { t } = useI18n()

const { data: hierarchyData } = useTransformersHierarchyQuery()
const flattenedHierarchy = computed(() => flattenTransformersHierarchy(hierarchyData.value?.plantGroups ?? []))

const storedParamsRaw = localStorage.getItem(FLAGGED_TRANSFORMERS_KEY)
const storedParams = storedParamsRaw && JSON.parse(storedParamsRaw)

const selectedIssue = ref<number | undefined>(storedParams?.issueType ?? IssueTypes[0].value)
const selectedGroup = ref<number | undefined>(storedParams?.groupSid ?? ALL_ITEMS_VALUE)
const selectedPlants = ref<number[]>(storedParams?.plantSids ?? [ALL_ITEMS_VALUE])

const plantSids = computed(() => {
    if (!selectedPlants.value.includes(ALL_ITEMS_VALUE)) {
        return selectedPlants.value
    }

    const applicablePlantSids: number[] = []

    if (selectedGroup.value === ALL_ITEMS_VALUE) {
        applicablePlantSids.push(...flattenedHierarchy.value.map((e) => e.plant.sid))
    } else {
        applicablePlantSids.push(
            ...flattenedHierarchy.value.filter((e) => e.plantGroup.sid === selectedGroup.value).map((e) => e.plant.sid),
        )
    }

    return dedupeArray(applicablePlantSids)
})

const {
    data,
    isPending: isFlaggedPending,
    isError: isFlaggedError,
} = usePortfolioFlaggedTransformers(selectedIssue, plantSids)

watch(
    [selectedIssue, selectedGroup, selectedPlants],
    ([newSelectedIssue, newSelectedGroup, newSelectedPlants]) => {
        const storedPayload = {
            issueType: newSelectedIssue,
            groupSid: newSelectedGroup,
            plantSids: newSelectedPlants,
        }

        localStorage.setItem(FLAGGED_TRANSFORMERS_KEY, JSON.stringify(storedPayload))
    },
    { immediate: true },
)

const labelCount = computed(() => {
    if (!data.value?.totalCount || !showToggleButton.value) {
        return
    }

    return data.value.totalCount - DEFAULT_MAX_SHOWN_ITEMS
})

const showToggleButton = computed(() => {
    return data.value?.totalCount && data.value.totalCount > DEFAULT_MAX_SHOWN_ITEMS
})

const flaggedTransformersList = computed(() => {
    if (!data.value?.items) {
        return []
    }

    return isShowAll.value ? data.value.items : data.value.items.slice(0, DEFAULT_MAX_SHOWN_ITEMS)
})

const toggleShowAll = () => {
    isShowAll.value = !isShowAll.value
}

const getShortTransformerName = (item: FlaggedTransformerDto) => {
    const name = item.transformerName ?? ''
    const shortName =
        name.length > TRANSFORMER_NAME_MAX_LENGTH ? name.substring(0, TRANSFORMER_NAME_MAX_LENGTH) + '...' : name

    return {
        ...item,
        transformerName: shortName,
    }
}
</script>

<style scoped lang="scss">
.flagged-transformers {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    &__transformers {
        display: flex;
        flex-direction: column;

        &__list {
            display: flex;
            flex-wrap: wrap;
            gap: $spacing-xs;
        }

        &__show-all-button {
            width: max-content;
            padding-top: $spacing-s;
        }
    }
}
</style>
