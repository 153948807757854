import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusAddRecommendationTypeModalActions = {
    openAddRecommendation: () => void
}

const conditionStatusAddRecommendationTypeModalActionsInjectionKey: InjectionKey<ConditionStatusAddRecommendationTypeModalActions> =
    Symbol('conditionStatusAddRecommendationTypeModalActionsInjectionKey')

export const useConditionStatusAddRecommendationTypeModal = () => {
    const actions = inject(conditionStatusAddRecommendationTypeModalActionsInjectionKey)

    if (!actions) {
        throw new Error('Invalid use of ConditionStatusAddRecommendationTypeModal')
    }

    return actions
}

export const provideConditionStatusAddRecommendationTypeModal = (
    actions: ConditionStatusAddRecommendationTypeModalActions,
) => provide(conditionStatusAddRecommendationTypeModalActionsInjectionKey, actions)
