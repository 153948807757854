import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformerDataSyncMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { componentSids: string[] }) =>
            hcaClientFactory.dataImportApi.syncData(payload.componentSids),
        onSuccess: async (response, payload) => {
            await queryClient.invalidateQueries({
                queryKey: ['transformers', payload.componentSids[0], 'componentStatus'],
            })
        },
    })
}
