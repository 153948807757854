import { BaseService } from '@/api/services/BaseService'
import { useAuthStore } from '@/store/authStore'
import { COMMON_SCOPES } from '@/authentication/coodeSdkConfig'
import { ApiResponse } from '@/api/models/ApiResponse'
import { GetLocalesResponse } from '@/api/models/translation/GetLocalesResponse'
import { GetPhrasesResponse } from '@/api/models/translation/GetPhrasesResponse'

export class TranslationService extends BaseService {
    constructor() {
        super({
            baseURL: import.meta.env.VITE_TRANSLATION_SERVICE_API_URL,
            accessTokenProvider: () => useAuthStore().getTokenForScope(COMMON_SCOPES.TRANSLATION_SERVICE),
        })
    }

    async getLocales(): Promise<GetLocalesResponse> {
        const response = await this.get<ApiResponse<GetLocalesResponse>>({
            url: `locales/${import.meta.env.VITE_TRANSLATION_PROJECT_ID}`,
        })

        return response.data.result
    }

    async getPhrases(localeCode: string): Promise<GetPhrasesResponse> {
        const response = await this.get<ApiResponse<GetPhrasesResponse>>({
            url: `phrases/${import.meta.env.VITE_TRANSLATION_PROJECT_ID}/${localeCode}`,
        })

        return response.data.result
    }
}
