<template>
    <div class="comments">
        <div class="comments__header">
            <span class="comments__header-title">
                {{ t('transformers.conditionStatus.content.recommendationModal.comments.title') }}
            </span>
            <pbl-button
                compact
                @click="onAddComment"
            >
                <pbl-icon
                    slot="icon"
                    name="plus"
                ></pbl-icon>
                {{ t('transformers.conditionStatus.content.recommendationModal.comments.addComment') }}
            </pbl-button>
        </div>
        <template
            v-for="(field, index) in fields"
            :key="field.key"
        >
            <EditRecommendationModalCommentItem
                :index="index"
                @delete="onDeleteComment(index)"
            />
        </template>
        <span
            v-if="fields.length === 0"
            class="comments__empty"
        >
            {{ t('transformers.conditionStatus.content.recommendationModal.empty.noComments') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { useFieldArray } from 'vee-validate'
import EditRecommendationModalCommentItem from '@/pages/transformers/condition-status/modals/recommendation/edit-recommendation/EditRecommendationModalCommentItem.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { fields, prepend, remove } = useFieldArray(() => 'comments')

const onAddComment = () => {
    prepend({
        text: '',
    })
}

const onDeleteComment = (index: number) => {
    remove(index)
}
</script>

<style scoped lang="scss">
.comments {
    grid-column: span 3 / span 3;

    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            font-weight: 500;
        }
    }

    &__empty {
        margin-top: $spacing-2xs;
        color: $pbl-text-muted;
    }
}
</style>
