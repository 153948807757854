export enum AnalysisType {
    GasInOil = 1,
    Furans = 2,
    InsulatingOil = 3,
    VisualInspection = 4,
}

export const AnalysisTypes = [
    {
        labelKey: 'common.analysisTypes.gasInOil',
        value: AnalysisType.GasInOil,
    },
    {
        labelKey: 'common.analysisTypes.furans',
        value: AnalysisType.Furans,
    },
    {
        labelKey: 'common.analysisTypes.insulatingOil',
        value: AnalysisType.InsulatingOil,
    },
    {
        labelKey: 'common.analysisTypes.visualInspection',
        value: AnalysisType.VisualInspection,
    },
] as const
