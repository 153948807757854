import { toValue, MaybeRef } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useRecommendationsListQuery = (
    plantSids: MaybeRef<number[]>,
    componentTypes: MaybeRef<number[]>,
    startDate: MaybeRef<string | undefined>,
    endDate: MaybeRef<string | undefined>,
) => {
    return useQuery({
        queryKey: ['recommendations', plantSids, componentTypes, startDate, endDate, 'list-recommendations'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.recommendationsApi.listRecommendations(
                toValue(plantSids),
                toValue(componentTypes),
                toValue(startDate),
                toValue(endDate),
                0,
                0,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
