<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('transformers.conditionStatus.content.overrideModal.title')"
            click-shield
            class="override-dialog"
        >
            <div class="override-modal">
                <div class="override-modal__detail-line">
                    <FormInputField
                        :model-value="contextPayload?.analyticalNumber ?? ''"
                        :label="t('transformers.conditionStatus.content.overrideModal.labels.reportNumber')"
                        disabled
                    />
                    <FormInputField
                        :model-value="analysisTypeString"
                        :label="t('transformers.conditionStatus.content.overrideModal.labels.analysisType')"
                        disabled
                    />
                    <FormInputField
                        :model-value="contextPayload?.parameterName ?? ''"
                        :label="t('transformers.conditionStatus.content.overrideModal.labels.measurement')"
                        disabled
                    />
                </div>
                <div class="override-modal__detail-line">
                    <VeeFormSingleSelectField
                        name="status"
                        :label="t('transformers.conditionStatus.content.overrideModal.labels.status')"
                        :options="statusOptions"
                    />
                </div>
                <div class="override-modal__textarea-line">
                    <VeeTextareaField
                        name="comment"
                        :label="t('transformers.conditionStatus.content.overrideModal.labels.comment')"
                    />
                </div>
            </div>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('transformers.conditionStatus.content.overrideModal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isMutating"
                :loading="isMutating"
                @click="onSubmit"
            >
                {{ t('transformers.conditionStatus.content.overrideModal.actions.confirm') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import {
    ConditionStatusOpenPayload,
    provideConditionStatusOverrideModal,
} from '@/pages/transformers/condition-status/modals/override/useConditionStatusOverrideModal'
import { useI18n } from 'vue-i18n'
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { useForm } from 'vee-validate'
import { statusOverrideValidationSchema } from '@/pages/transformers/condition-status/modals/override/ConditionStatusOverrideModal.form'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { useTransformerId } from '@/composables/useTransformerId'
import { useTransformersStatusAssessmentOverrideMutation } from '@/api/mutations/transformers/useTransformersStatusAssessmentOverrideMutation'
import { AxiosError } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { AnalysisTypes } from '@/configuration/analysis/analysis-types'
import VeeFormSingleSelectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormSingleSelectField.vue'
import VeeTextareaField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeTextareaField.vue'

const { t } = useI18n()
const toasts = usePebbleToasts()

const transformerId = useTransformerId()

const dialogRef = ref<PebbleDialog | null>(null)
const contextPayload = ref<ConditionStatusOpenPayload | undefined>()

const { mutateAsync, isPending: isMutating } = useTransformersStatusAssessmentOverrideMutation()

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: statusOverrideValidationSchema,
    initialValues: {
        comment: '',
        status: contextPayload.value?.status,
    },
})

const statusOptions = computed<SelectOptions<number>>(() =>
    StatusTypes.filter((e) => e.value !== ConditionStatus.None).map((e) => ({
        label: t(e.labelKey),
        value: e.value,
    })),
)

const analysisTypeString = computed(() =>
    t(AnalysisTypes.find((e) => e.value === contextPayload.value?.analysisType)?.labelKey ?? '-'),
)

const openOverride = (payload: ConditionStatusOpenPayload) => {
    contextPayload.value = payload

    resetForm({
        values: {
            comment: '',
            status: payload.status,
        },
    })

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    if (!contextPayload.value) {
        return
    }

    try {
        await mutateAsync({
            parameterId: contextPayload.value.parameterId,
            statusDto: {
                newStatus: values.status,
                comment: values.comment,
                analysisType: contextPayload.value.analysisType,
                transformerSid: transformerId.value,
            },
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

provideConditionStatusOverrideModal({
    openOverride,
})
</script>

<style scoped lang="scss">
.override-modal {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;

    &__detail-line {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: $spacing-m;
    }
}

.override-dialog {
    --width: 50vw;
}
</style>
