import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'
import { PlantGroupWithTransformerHierarchyDto } from '@/api/generated/HCA'
import { SelectOptions } from '@/components/form-inputs/common/select-option'

export type TransformerFlatDto = {
    transformer: {
        id: string
        functionalLocation?: string | null
        plantSid: number
        plantGroupSid: number
        bookmarkId?: number | null
    }
    plant: {
        sid: number
        name: string
        plantGroupSid: number
    }
    plantGroup: {
        sid: number
        name: string
    }
}

export const useTransformersHierarchyQuery = () => {
    return useQuery({
        queryKey: ['transformersHierarchy'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.masterDataApi.getTransformers(undefined, { signal })
            return result
        },
        enabled: whenAuthenticated,
        staleTime: Infinity,
    })
}

export const flattenTransformersHierarchy = (
    hierarchyStructure: PlantGroupWithTransformerHierarchyDto[],
): TransformerFlatDto[] => {
    const flattenedTransformers: TransformerFlatDto[] = []

    hierarchyStructure.forEach((plantGroup) => {
        const plantGroupData = {
            sid: plantGroup.sid ?? 0,
            name: plantGroup.name ?? '-',
        }

        plantGroup.plants?.forEach((plant) => {
            const plantData = {
                sid: plant.sid ?? 0,
                name: plant.name ?? '-',
                plantGroupSid: plantGroup.sid ?? 0,
            }

            plant.transformers?.forEach((transformer) => {
                flattenedTransformers.push({
                    transformer: {
                        id: transformer.transformerSid ?? '',
                        functionalLocation: transformer.functionalLocation,
                        plantGroupSid: plantGroup.sid ?? 0,
                        plantSid: plant.sid ?? 0,
                        bookmarkId: transformer.bookmarkId,
                    },
                    plantGroup: plantGroupData,
                    plant: plantData,
                })
            })
        })
    })

    return flattenedTransformers
}

export const transformerOptions = (hierarchyStructure: TransformerFlatDto[]): SelectOptions => {
    return hierarchyStructure.map((e) => ({
        label:
            !e.transformer.functionalLocation || e.transformer.id === e.transformer.functionalLocation
                ? `${e.transformer.id} / -`
                : `${e.transformer.id} / ${e.transformer.functionalLocation}`,
        value: e.transformer.id,
    }))
}
