<template>
    <LoadingPanel
        :is-pending="isPending"
        :is-error="isError"
    >
        <div class="bookmarks-section">
            <div class="bookmarks-section__heading">
                <div class="bookmarks-section__title">
                    {{ t('dashboard.bookmarks.title') }}
                    <pbl-spinner v-if="isDragDisabled" />
                </div>
            </div>
            <div class="bookmarks-section__list-wrapper">
                <div class="bookmarks-section__subtitle">
                    {{ t('navigation.sidebar.transformers') }}
                </div>
                <Draggable
                    v-if="draftBookmarks.length > 0"
                    v-model="draftBookmarks"
                    :handle="dragHandle"
                    item-key="id"
                    class="bookmarks-section__list"
                    @update="onBookmarkOrderCommit"
                >
                    <template #item="{ element }">
                        <BookmarkListItem
                            :bookmark="element"
                            :disabled="isDragDisabled"
                            @click:bookmark="onBookmarkClick(element.componentSid ?? '')"
                            @click:delete="onBookmarkDelete(element.id ?? 0)"
                        />
                    </template>
                </Draggable>
                <div
                    v-else
                    class="bookmarks-section__empty-status"
                >
                    <div class="bookmarks-section__empty-status__title">
                        {{ t('dashboard.bookmarks.emptyList.title') }}
                    </div>
                    <div class="bookmarks-section__empty-status__description">
                        <i18n-t keypath="dashboard.bookmarks.emptyList.description">
                            <div class="bookmarks-section__empty-status__description__button">
                                <HorizontalButton
                                    :text="t('transformers.conditionStatus.selector.actions.favourite')"
                                    icon-name="star-fill"
                                    variant="secondary"
                                />
                            </div>
                        </i18n-t>
                    </div>
                </div>
            </div>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useBookmarksListQuery } from '@/api/queries/bookmarks/useBookmarksListQuery'
import Draggable from 'vuedraggable'
import { computed, ref, watch } from 'vue'
import BookmarkListItem from '@/pages/dashboard/sections/bookmarks/BookmarkListItem.vue'
import HorizontalButton from '@/components/buttons/HorizontalButton.vue'
import { BookmarkDto } from '@/api/generated/HCA'
import { useTransformersHierarchyQuery } from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useBookmarksDeleteMutation } from '@/api/mutations/bookmarks/useBookmarksDeleteMutation'
import { AxiosError } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { toRawDeep } from '@/utils/vue-utils'
import { useBookmarksUpdateMutation } from '@/api/mutations/bookmarks/useBookmarksUpdateMutation'

const { t } = useI18n()
const router = useRouter()
const toasts = usePebbleToasts()

const { isPending: isHierarchyPending, isError: isHierarchyError } = useTransformersHierarchyQuery()
const { data, isPending: isBookmarksPending, isError: isBookmarksError } = useBookmarksListQuery()

const { mutateAsync: mutateDeleteAsync, isPending: isDeletePending } = useBookmarksDeleteMutation()
const { mutateAsync: mutateUpdateAsync, isPending: isUpdatePending } = useBookmarksUpdateMutation()

const isPending = computed(() => isBookmarksPending.value || isHierarchyPending.value)
const isError = computed(() => isBookmarksError.value || isHierarchyError.value)

const isDragDisabled = computed(() => isDeletePending.value || isUpdatePending.value)
const dragHandle = computed(() => (isDragDisabled.value ? '[data-handle=false]' : '[data-handle=true]'))

const draftBookmarks = ref<BookmarkDto[]>([])

const onBookmarkClick = (transformerId: string) => {
    router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId,
        },
    })
}

const onBookmarkDelete = async (bookmarkId: number) => {
    if (isDragDisabled.value) {
        return
    }

    const removedEntry = draftBookmarks.value.find((e) => e.id === bookmarkId)

    if (!removedEntry) {
        return
    }

    try {
        draftBookmarks.value = draftBookmarks.value.filter((e) => e.id !== removedEntry.id)

        await mutateDeleteAsync({
            bookmarkId: removedEntry.id ?? 0,
        })
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }

        draftBookmarks.value.push(removedEntry)
    }
}

const onBookmarkOrderCommit = async () => {
    const oldBookmarks = structuredClone(toRawDeep(draftBookmarks.value))
    const bookmarks = structuredClone(oldBookmarks)

    for (let i = 0; i < bookmarks.length; i++) {
        bookmarks[i].hierarchy = i + 1
    }

    draftBookmarks.value = bookmarks

    try {
        await mutateUpdateAsync({
            bookmarks: bookmarks.map((e) => ({
                id: e.id ?? 0,
                hierarchy: e.hierarchy ?? 0,
            })),
        })
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }

        draftBookmarks.value = oldBookmarks
    }
}

watch(
    data,
    (newData) => {
        const savedBookmarks = newData?.bookmarks ?? []

        draftBookmarks.value = savedBookmarks.toSorted((a, b) => (a.hierarchy ?? 0) - (b.hierarchy ?? 0))
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.bookmarks-section {
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    &__heading {
        display: flex;
        flex-direction: column;
        gap: $spacing-2xs;
    }

    &__title {
        font-weight: 500;

        display: flex;
        align-items: center;
        justify-content: space-between;

        & > pbl-spinner {
            --size: 20px;
        }
    }

    &__list-wrapper {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: $spacing-2xs;
    }

    &__empty-status {
        padding: $spacing-s;
        background-color: #f5f5f5;
        border-radius: 4px;
        color: $pbl-text-muted;

        display: flex;
        flex-direction: column;
        gap: $spacing-xs;

        &__title {
            font-weight: 500;
        }

        &__description {
            &__button {
                width: fit-content;
                display: inline-block;
                vertical-align: bottom;
            }
        }
    }
}
</style>
