<template>
    <div class="reports-content">
        <ReportsTableSection />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ReportsTableSection from '@/pages/reports/view-all-reports-page/sections/ReportsTableSection.vue'
import { provideReportParams } from '@/composables/useReportParams'
import { ReportParams } from '@/types/reports'

const props = defineProps<{
    reportParams: ReportParams
}>()

provideReportParams(computed(() => props.reportParams))
</script>

<style scoped lang="scss">
.reports-content {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;

    &__export {
        display: flex;
    }
}
</style>
