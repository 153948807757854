<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="
                commentId
                    ? t('transformers.conditionStatus.content.commentModal.titles.edit')
                    : t('transformers.conditionStatus.content.commentModal.titles.add')
            "
            click-shield
            class="comment-dialog"
        >
            <div class="comment-modal">
                <div class="comment-modal__selector">
                    <VeeFormSingleSelectField
                        name="reportNumber"
                        :label="t('transformers.conditionStatus.content.commentModal.labels.reportNumber')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="reportNumberOptions"
                        :disabled="reportNumberOptions.length === 0 || !!commentId"
                    />
                    <VeeFormSingleSelectField
                        name="analysisType"
                        :label="t('transformers.conditionStatus.content.commentModal.labels.analysisType')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="analysisTypeOptions"
                        :disabled="analysisTypeOptions.length === 0 || !!commentId"
                    />
                    <VeeFormSingleSelectField
                        name="measurement"
                        :label="t('transformers.conditionStatus.content.commentModal.labels.measurement')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="measurementOptions"
                        :disabled="measurementOptions.length === 0 || !!commentId"
                    />
                </div>
                <div class="comment-modal__textarea">
                    <VeeTextareaField
                        name="text"
                        :label="t('transformers.conditionStatus.content.commentModal.labels.text')"
                        :placeholder="t('formInputs.common.placeholders.enterValue')"
                    />
                </div>
            </div>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('transformers.conditionStatus.content.commentModal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isMutating"
                :loading="isMutating"
                @click="onSubmit"
            >
                {{ t('transformers.conditionStatus.content.commentModal.actions.confirm') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { useTransformerId } from '@/composables/useTransformerId'
import {
    ConditionStatusOpenCommentPayload,
    provideConditionStatusCommentModal,
} from '@/pages/transformers/condition-status/modals/comment/useConditionStatusCommentModal'
import { computed, ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { useField, useForm } from 'vee-validate'
import { commentValidationSchema } from '@/pages/transformers/condition-status/modals/comment/ConditionStatusCommentModal.form'
import { useConditionStatusCommentSelectionHierarchy } from '@/pages/transformers/condition-status/modals/comment/useConditionStatusCommentSelectionHierarchy'
import { useTransformersCommentsAddMutation } from '@/api/mutations/transformers/comments/useTransformersCommentsAddMutation'
import { AxiosError } from 'axios'
import { useTransformersCommentsEditMutation } from '@/api/mutations/transformers/comments/useTransformersCommentsEditMutation'
import VeeTextareaField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeTextareaField.vue'
import VeeFormSingleSelectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormSingleSelectField.vue'

const { t } = useI18n()
const toasts = usePebbleToasts()

const transformerId = useTransformerId()

const dialogRef = ref<PebbleDialog | null>(null)

const { reportNumberOptions, createComputedAnalysisTypeOptions, createComputedMeasurementOptions } =
    useConditionStatusCommentSelectionHierarchy(transformerId)

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: commentValidationSchema,
    initialValues: {
        commentId: undefined,
        reportNumber: undefined,
        analysisType: undefined,
        measurement: undefined,
        text: '',
    },
})

const { value: commentId } = useField<number | undefined>(() => 'commentId')
const { value: reportNumberValue } = useField<string>(() => 'reportNumber')
const { value: analysisTypeValue } = useField<number | undefined>(() => 'analysisType')

const analysisTypeOptions = createComputedAnalysisTypeOptions(reportNumberValue)
const measurementOptions = createComputedMeasurementOptions(reportNumberValue, analysisTypeValue)

const { mutateAsync: mutateAddAsync, isPending: isAddPending } = useTransformersCommentsAddMutation()
const { mutateAsync: mutateEditAsync, isPending: isEditPending } = useTransformersCommentsEditMutation()

const isMutating = computed(() => isAddPending.value || isEditPending.value)

const openComment = (payload?: ConditionStatusOpenCommentPayload) => {
    resetForm({
        values: {
            commentId: payload?.comment?.id,
            reportNumber: payload?.comment?.reportNumber ?? undefined,
            analysisType: payload?.comment?.analysisType ?? undefined,
            measurement: payload?.comment?.parameterId ?? undefined,
            text: payload?.comment?.text ?? '',
        },
    })

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    const commentData = {
        reportNumber: values.reportNumber,
        analysisType: values.analysisType,
        parameterId: values.measurement,
        text: values.text,
    }

    try {
        if (values.commentId) {
            await mutateEditAsync({
                transformerId: transformerId.value,
                commentId: values.commentId,
                commentData,
            })
        } else {
            await mutateAddAsync({
                transformerSid: transformerId.value,
                commentData,
            })
        }

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

provideConditionStatusCommentModal({
    openComment,
})
</script>

<style scoped lang="scss">
.comment-dialog {
    --width: 70vw;
}

.comment-modal {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;

    &__selector {
        display: flex;
        gap: $spacing-m;

        & > * {
            flex-grow: 1;
        }
    }
}
</style>
