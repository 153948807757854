<template>
    <div class="sidebar-navigation">
        <template
            v-for="entry in navigationStructure"
            :key="entry.titleKey"
        >
            <SidebarNavigationItem
                :entry="entry"
                @click="onItemClick(entry)"
            />
            <div class="sidebar-navigation__separator" />
        </template>
    </div>
</template>

<script setup lang="ts">
import { NavigationEntry, NavigationStructure } from '@/components/navigation/common-navigation'
import SidebarNavigationItem from '@/components/navigation/sidebar/SidebarNavigationItem.vue'
import { useRouter } from 'vue-router'

defineProps<{
    navigationStructure: NavigationStructure
}>()

const router = useRouter()

const onItemClick = async (entry: NavigationEntry) => {
    if (entry.destinationRouteName === router.currentRoute.value.name) {
        return
    }

    await router.push({ name: entry.destinationRouteName })
}
</script>

<style scoped lang="scss">
.sidebar-navigation {
    min-height: 100%;

    background-color: $pbl-foreground;
    border-right: 1px solid $pbl-border-muted;

    &__separator {
        width: 100%;
        height: 1px;

        background-color: $pbl-border-muted;
    }
}
</style>
