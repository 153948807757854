<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('transformers.conditionStatus.content.recommendationModal.title.edit')"
            click-shield
            class="recommendation-dialog"
        >
            <QueryLoaderGuard
                :is-pending="isPending"
                :is-error="isError"
            >
                <div class="recommendation-modal">
                    <RecommendationModalStaticFormFields recommendation-type-disabled />
                    <div class="recommendation-modal__horizontal-separator recommendation-modal__field--full-width" />
                    <EditRecommendationModalComments />
                </div>
                <div
                    slot="footer"
                    class="recommendation-modal__footer"
                >
                    <div class="recommendation-modal__footer__meta">
                        <span v-if="isMetadataAvailable">
                            {{ t('transformers.conditionStatus.content.recommendationModal.metadata.lastUpdated') }}:
                            {{ `${formattedDate} | KID ${lastUpdatedKid}` }}
                        </span>
                    </div>
                    <div class="recommendation-modal__footer__actions">
                        <pbl-button
                            slot="footer"
                            variant="secondary"
                            @click="closeModal"
                        >
                            {{ t('transformers.conditionStatus.content.recommendationModal.actions.cancel') }}
                        </pbl-button>
                        <pbl-button
                            slot="footer"
                            :disabled="!meta.dirty || isEditPending"
                            :loading="isEditPending"
                            @click="onSubmit"
                        >
                            {{ t('transformers.conditionStatus.content.recommendationModal.actions.save') }}
                        </pbl-button>
                    </div>
                </div>
            </QueryLoaderGuard>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { provideConditionStatusEditRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/edit-recommendation/useConditionStatusEditRecommendationModal'
import { useI18n } from 'vue-i18n'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { computed, ref, watch } from 'vue'
import { PebbleDialog } from '@pebble/core'
import RecommendationModalStaticFormFields from '@/pages/transformers/condition-status/modals/recommendation/RecommendationModalStaticFormFields.vue'
import { useField, useForm } from 'vee-validate'
import { editRecommendationValidationSchema } from '@/pages/transformers/condition-status/modals/recommendation/ConditionStatusRecommendationModal.form'
import { useRecommendationQuery } from '@/api/queries/recommendations/useRecommendationQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import EditRecommendationModalComments from '@/pages/transformers/condition-status/modals/recommendation/edit-recommendation/EditRecommendationModalComments.vue'
import { useRecommendationEditMutation } from '@/api/mutations/recommendations/useRecommendationEditMutation'
import { RecommendationEditDto } from '@/api/generated/HCA'
import { AxiosError } from 'axios'
import { useTransformerId } from '@/composables/useTransformerId'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { format } from 'date-fns'

const { t } = useI18n()
const toasts = usePebbleToasts()
const transformerId = useTransformerId()

const dialogRef = ref<PebbleDialog | null>(null)

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: editRecommendationValidationSchema,
})

const { value: recommendationId } = useField<number>(() => 'recommendationId')

const { data, isPending, isError } = useRecommendationQuery(recommendationId)
const { mutateAsync: mutateEditAsync, isPending: isEditPending } = useRecommendationEditMutation()

const openEditRecommendation = (id: number) => {
    resetForm({
        values: {
            recommendationId: id,
        },
    })

    populateForm()

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    try {
        const data: RecommendationEditDto = {
            sapNotificationNumber: values.sapNotificationNumber,
            pspNumber: values.pspNumber,
            ptRiskId: values.ptRiskId,
            sapNotificationUrl: values.sapNotificationUrl,
            ptRiskUrl: values.ptRiskUrl,
            pspNumberUrl: values.pspNumberUrl,
            comments: values.comments.map((e) => ({
                id: e.id,
                text: e.text,
            })),
        }

        await mutateEditAsync({
            id: values.recommendationId,
            componentSid: transformerId.value,
            data,
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

const populateForm = () => {
    const commentData = data.value

    if (!commentData || commentData.id !== recommendationId.value) {
        return
    }

    resetForm({
        values: {
            recommendationId: commentData.id ?? 0,
            recommendationTypeId: commentData.recommendationTypeId,
            recommendationFreeText: commentData.freeText ?? '',
            sapNotificationNumber: commentData.sapNotificationNumber ?? '',
            ptRiskId: commentData.ptRiskId ?? '',
            pspNumber: commentData.pspNumber ?? '',
            sapNotificationUrl: commentData.sapNotificationUrl ?? '',
            pspNumberUrl: commentData.pspNumberUrl ?? '',
            ptRiskUrl: commentData.ptRiskUrl ?? '',
            comments:
                commentData.comments
                    ?.filter((e) => !!e.text)
                    ?.map((e) => ({
                        id: e.id ?? undefined,
                        text: e.text ?? '',
                        date: e.date ?? undefined,
                        kid: e.kid ?? undefined,
                    })) ?? [],
        },
    })
}

const isMetadataAvailable = computed(() => !!data.value?.date && !!data.value.kid)

const lastUpdatedKid = computed(() => data.value?.kid ?? '-')

const formattedDate = computed(() => {
    if (!data.value?.date) {
        return '-'
    }

    return format(new Date(data.value.date), DATE_TIME_FORMAT)
})

watch(data, (newData) => {
    if (!newData) {
        return
    }

    populateForm()
})

provideConditionStatusEditRecommendationModal({
    openEditRecommendation,
})
</script>

<style scoped lang="scss">
.recommendation-dialog {
    --width: 70vw;
}

.recommendation-modal {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $spacing-s $spacing-m;

    &__field {
        &--full-width {
            grid-column: span 3 / span 3;
        }

        &--two-thirds {
            grid-column: span 2 / span 2;
        }
    }

    &__horizontal-separator {
        height: 1px;
        width: 100%;

        background-color: $pbl-border;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__meta {
            color: $pbl-text-muted;
        }

        &__actions {
            display: flex;
            gap: $spacing-xs;
        }
    }
}
</style>
