<template>
    <FormFieldWrapper
        :label="label"
        :cta-label="ctaLabel"
        @click:cta="emit('click:cta')"
    >
        <div
            v-click-outside="onCloseDropdown"
            :class="[
                {
                    'field--disabled': disabled,
                    'field--error': invalid,
                },
                'field',
            ]"
        >
            <div
                class="field__selection-display"
                @click.stop="onDropdownClick"
            >
                <span
                    :class="[
                        {
                            'field__selection-display__label--muted': isDisplayingPlaceholder,
                        },
                        'field__selection-display__label',
                    ]"
                >
                    {{ displayedLabel }}
                </span>
                <div
                    v-if="!disabled"
                    class="field__selection-display__chevron"
                >
                    <pbl-icon
                        v-if="isDropdownOpen"
                        name="chevron-up"
                    />
                    <pbl-icon
                        v-if="!isDropdownOpen"
                        name="chevron-down"
                    />
                </div>
            </div>

            <FormSingleSelectDropdown
                v-if="isDropdownOpen"
                :selected-value="modelValue as T | undefined"
                :options="options"
                class="field__selection-display__dropdown"
                @select:option="onSelectOption"
                @close="onCloseDropdown"
            >
                <template #optionTrailing="{ option }">
                    <slot
                        name="optionTrailing"
                        :option="option"
                    />
                </template>
            </FormSingleSelectDropdown>
        </div>
        <small
            v-if="invalid && errorMessage"
            class="error-message"
        >
            {{ errorMessage }}
        </small>
    </FormFieldWrapper>
</template>

<script setup lang="ts" generic="T, E">
import { SelectOption, SelectOptions } from '@/components/form-inputs/common/select-option'
import { computed, ref, watch } from 'vue'
import FormSingleSelectDropdown from '@/components/form-inputs/form-single-select-field/FormSingleSelectDropdown.vue'
import FormFieldWrapper from '@/components/form-inputs/form-field-wrapper/FormFieldWrapper.vue'

const props = defineProps<{
    modelValue?: T
    options: SelectOptions<T, E>

    disabled?: boolean
    invalid?: boolean
    errorMessage?: string

    label?: string
    placeholderText?: string
    ctaLabel?: string
}>()

const emit = defineEmits<{
    'update:modelValue': [value: T | undefined]
    'click:cta': []
}>()

const isDropdownOpen = ref(false)

const isDisplayingPlaceholder = computed(() => !props.options.find((e) => e.value === props.modelValue)?.label)

const displayedLabel = computed(
    () => props.options.find((e) => e.value === props.modelValue)?.label ?? props.placeholderText ?? '',
)

watch(
    () => props.options,
    (newOptions) => {
        if (newOptions.some((e) => e.value === props.modelValue)) {
            return
        }

        emit('update:modelValue', undefined)
    },
)

const onDropdownClick = () => {
    if (props.disabled) {
        return
    }

    isDropdownOpen.value = !isDropdownOpen.value
}

const onSelectOption = (option: SelectOption<T>) => {
    if (props.disabled) {
        return
    }

    emit('update:modelValue', option.value)
}

const onCloseDropdown = () => {
    isDropdownOpen.value = false
}
</script>

<style scoped lang="scss">
.field {
    $input-height: 38px;

    border-radius: 4px;
    border: 1px solid $pbl-border;
    background-color: $pbl-foreground;

    position: relative;
    display: flex;
    align-items: center;

    user-select: none;
    cursor: pointer;

    &--error {
        background-color: $pbl-foreground-invalid;
        border: 1px solid $pbl-invalid;
    }

    &--disabled {
        cursor: not-allowed !important;
        background-color: $pbl-foreground-muted;
    }

    &__selection-display {
        min-height: $input-height;
        padding: $spacing-2xs $spacing-xs;

        display: flex;
        align-items: center;

        width: 100%;

        &__label {
            width: 100%;

            &--muted {
                color: $pbl-text-muted;
            }
        }

        &__chevron {
            margin-top: 2px;
        }

        &__dropdown {
            position: absolute;
            top: 100%;
            left: 0;

            width: 100%;
            margin-top: $spacing-2xs;
        }
    }
}

.error-message {
    margin-top: -($spacing-2xs);
    color: $pbl-invalid;
}
</style>
