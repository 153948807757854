import { ReportParams } from '@/types/reports'
import { ComponentType } from '@/configuration/component/component-types'
import { ReportType } from '@/configuration/report/report-types'
import { TIMEFRAME_OLDEST_RECORD, TIMEFRAME_DEFAULT_END_DATE } from '@/utils/date-utils'

export const REPORTS_PARAMS_KEY = 'REPORT_PARAMS' as const

export const DEFAULT_REPORTS_PARAMS = {
    plantGroupSid: [],
    plantSid: [],
    componentType: [ComponentType.Transformer],
    reportType: ReportType.All,
    startDate: TIMEFRAME_OLDEST_RECORD,
    endDate: TIMEFRAME_DEFAULT_END_DATE,
} as const satisfies ReportParams
