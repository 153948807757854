<template>
    <div class="recommendations-page">
        <RecommendationsStaticHeaderContent :recommendation-params="recommendationParams" />
        <div class="recommendations-page__content">
            <RecommendationsContent
                v-if="isContentDisplayed"
                :key="JSON.stringify(contentKey)"
                :recommendation-params="recommendationParams"
            />
            <pbl-panel v-else>
                <div class="recommendations-page__content__empty-state">
                    <pbl-icon name="check2-square" />
                    <span>
                        {{ t('recommendations.emptyStateBody') }}
                    </span>
                </div>
            </pbl-panel>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { LocationQueryValue, useRoute } from 'vue-router'
import { router } from '@/router'
import RecommendationsContent from '@/pages/recommendations/RecommendationsContent.vue'
import RecommendationsStaticHeaderContent from '@/pages/recommendations/RecommendationsStaticHeaderContent.vue'
import { RecommendationParams } from '@/types/recommendations'
import { DEFAULT_RECOMMENDATIONS_PARAMS, RECOMMENDATIONS_PARAMS_KEY } from '@/utils/recommendations-utils'
import { compareAsc } from 'date-fns'
import { TIMEFRAME_DEFAULT_END_DATE } from '@/utils/date-utils'

const route = useRoute()

const { t } = useI18n()

const storedParamsRaw = localStorage.getItem(RECOMMENDATIONS_PARAMS_KEY)
const storedParams = storedParamsRaw && JSON.parse(storedParamsRaw)

const hasStoredParams = computed<boolean>(() => {
    return !!(
        storedParams?.plantGroupSid?.length ||
        storedParams?.plantSid?.length ||
        storedParams?.componentType?.length ||
        storedParams?.startDate ||
        storedParams?.endDate ||
        storedParams?.componentId
    )
})

const contentKey = computed(() => {
    let key = { ...recommendationParams.value }
    delete key.componentId
    return key
})

const handleArrayParam = (key: LocationQueryValue | LocationQueryValue[]) => {
    if (!key) {
        return []
    }

    return Array.isArray(key) ? key.map((e) => (e ? parseInt(e) : -1)) : [parseInt(key)]
}

const handleStringParam = (key: LocationQueryValue | LocationQueryValue[]) => {
    if (!key || Array.isArray(key)) {
        return
    }

    return key
}

const plantGroupSid = computed<number[]>(() => {
    return handleArrayParam(route.query.plantGroupSid)
})

const plantSid = computed<number[]>(() => {
    return handleArrayParam(route.query.plantSid)
})

const componentType = computed<number[]>(() => {
    return handleArrayParam(route.query.componentType)
})

const startDate = computed<string | undefined>(() => {
    return handleStringParam(route.query.startDate)
})

const endDate = computed<string | undefined>(() => {
    return handleStringParam(route.query.endDate)
})

const componentId = computed<string | undefined>(() => {
    return handleStringParam(route.query.componentId)
})

const hasQueryValues = computed<boolean>(() => {
    return !!(
        plantGroupSid.value.length ||
        plantSid.value.length ||
        componentType.value.length ||
        startDate.value ||
        endDate.value ||
        componentId.value
    )
})

const recommendationParams = computed<RecommendationParams>(() => ({
    plantGroupSid: plantGroupSid.value,
    plantSid: plantSid.value,
    componentType: componentType.value,
    startDate: startDate.value,
    endDate: endDate.value,
    componentId: componentId.value,
}))

const firstLoad = () => {
    if (hasQueryValues.value) {
        const overrideParams = {
            ...recommendationParams.value,
            endDate: TIMEFRAME_DEFAULT_END_DATE,
        }
        localStorage.setItem(RECOMMENDATIONS_PARAMS_KEY, JSON.stringify(overrideParams))
        router.replace({
            name: 'RecommendationsRoot',
            query: overrideParams,
        })
    } else if (hasStoredParams.value) {
        const overrideParams = {
            ...storedParams,
            endDate: TIMEFRAME_DEFAULT_END_DATE,
        }
        localStorage.setItem(RECOMMENDATIONS_PARAMS_KEY, JSON.stringify(overrideParams))
        router.replace({
            name: 'RecommendationsRoot',
            query: overrideParams,
        })
    } else {
        localStorage.setItem(RECOMMENDATIONS_PARAMS_KEY, JSON.stringify(DEFAULT_RECOMMENDATIONS_PARAMS))
        router.replace({
            name: 'RecommendationsRoot',
            query: DEFAULT_RECOMMENDATIONS_PARAMS,
        })
    }
}

onMounted(() => {
    firstLoad()
})

const isTimeframeValid = computed(() => {
    if (!startDate.value || !endDate.value) {
        return true
    }

    return compareAsc(new Date(startDate.value), new Date(endDate.value)) === -1
})

const isContentDisplayed = computed(
    () =>
        !!(
            (recommendationParams.value.plantGroupSid.length || recommendationParams.value.plantSid.length) &&
            recommendationParams.value.componentType.length &&
            isTimeframeValid.value &&
            recommendationParams.value.startDate &&
            recommendationParams.value.endDate
        ),
)
</script>

<style scoped lang="scss">
.recommendations-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__content {
        @include spacing-page;
        overflow-y: scroll;

        &__empty-state {
            padding: 50px 0;

            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            gap: $spacing-s;

            color: $pbl-text-muted;

            pbl-icon {
                --size: 2rem;
            }

            span {
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }
}
</style>
