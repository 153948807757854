<template>
    <div
        :class="[
            {
                'tab-item--active': isActive,
            },
            'tab-item',
        ]"
    >
        {{ t(entry.titleKey) }}
    </div>
</template>

<script setup lang="ts">
import { NavigationEntry } from '@/components/navigation/common-navigation'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
    entry: NavigationEntry
}>()

const { t } = useI18n()
const route = useRoute()

const isActive = computed(() =>
    route.matched.some((e) => typeof e.name === 'string' && props.entry.matchRouteNames.includes(e.name)),
)
</script>

<style scoped lang="scss">
.tab-item {
    user-select: none;
    cursor: pointer;

    min-height: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 $spacing-m;

    &--active {
        color: $pbl-primary;
        font-weight: 500;
        border-bottom: 1px solid $pbl-primary;
    }

    &:hover:not(&--active) {
        color: $pbl-primary;
        border-bottom: 1px solid $pbl-primary;

        @include default-transition;
    }
}
</style>
