export enum ReportType {
    All = 0,
    OilAnalysis,
    VisualInspection,
}

export const ReportTypes = [
    {
        labelKey: 'common.reportTypes.all',
        value: ReportType.All,
    },
    {
        labelKey: 'common.reportTypes.oilAnalysis',
        value: ReportType.OilAnalysis,
    },
    {
        labelKey: 'common.reportTypes.visualInspection',
        value: ReportType.VisualInspection,
    },
] as const
