<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('admin.thresholds.oilAnalyses.modal.headline')"
            click-shield
            class="threshold-dialog"
        >
            <QueryLoaderGuard
                :is-pending="isPending"
                :is-error="isError"
            >
                <div
                    v-if="data"
                    class="threshold-modal"
                >
                    <div class="threshold-modal__form">
                        <FormInputField
                            disabled
                            :model-value="measurementName"
                            :label="t('admin.thresholds.oilAnalyses.modal.labels.measurement')"
                            class="threshold-modal__field--full-width"
                        />
                        <div class="threshold-modal__field-group">
                            <div class="threshold-modal__field-group-title">
                                {{ t('admin.thresholds.oilAnalyses.modal.labels.normalLogic') }}
                            </div>
                            <VeeFormSingleSelectField
                                input-id="normalOperatorType"
                                name="normalOperatorType"
                                :options="operatorOptions"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.operator')"
                            />
                            <VeeFormInputField
                                name="normalValue"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.value')"
                            />
                        </div>
                        <div class="threshold-modal__field-group threshold-modal__field--new-row">
                            <div class="threshold-modal__field-group-title">
                                {{ t('admin.thresholds.oilAnalyses.modal.labels.inferiorLogic1') }}
                            </div>
                            <VeeFormSingleSelectField
                                input-id="inferiorOperatorType"
                                name="inferiorOperatorType"
                                :options="operatorOptions"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.operator')"
                            />
                            <FormInputField
                                :model-value="`${normalValue.value.value as string}`"
                                name="inferiorValue"
                                disabled
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.normalValue')"
                            />
                        </div>
                        <div class="threshold-modal__field-group">
                            <div class="threshold-modal__field-group-title">
                                {{ t('admin.thresholds.oilAnalyses.modal.labels.inferiorLogic2') }}
                            </div>
                            <VeeFormSingleSelectField
                                input-id="inferiorLastOperatorType"
                                name="inferiorLastOperatorType"
                                has-clear-action
                                :options="lastOperatorOptions"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.lastOperator')"
                            />
                            <VeeFormInputField
                                name="inferiorLastValue"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.value')"
                            />
                        </div>
                        <div class="threshold-modal__field-group threshold-modal__field--new-row">
                            <div class="threshold-modal__field-group-title">
                                {{ t('admin.thresholds.oilAnalyses.modal.labels.criticalLogic1') }}
                            </div>
                            <VeeFormSingleSelectField
                                input-id="criticalOperatorType"
                                name="criticalOperatorType"
                                has-clear-action
                                :options="operatorOptions"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.operator')"
                            />
                            <VeeFormInputField
                                name="criticalValue"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.value')"
                            />
                        </div>
                        <div class="threshold-modal__field-group">
                            <div class="threshold-modal__field-group-title">
                                {{ t('admin.thresholds.oilAnalyses.modal.labels.criticalLogic2') }}
                            </div>
                            <VeeFormSingleSelectField
                                input-id="criticalLastOperatorType"
                                name="criticalLastOperatorType"
                                has-clear-action
                                :options="lastOperatorOptions"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.lastOperator')"
                            />
                            <VeeFormInputField
                                name="criticalLastValue"
                                :label="t('admin.thresholds.oilAnalyses.modal.labels.value')"
                            />
                        </div>
                    </div>
                </div>
            </QueryLoaderGuard>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('admin.thresholds.oilAnalyses.modal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isMutationPending"
                :loading="isMutationPending"
                @click="onSubmit"
            >
                {{ t('transformers.conditionStatus.content.commentModal.actions.confirm') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PebbleDialog } from '@pebble/core'
import {
    provideThresholdEditModal,
    ThresholdEditOpenModalPayload,
} from '@/pages/admin/thresholds/modals/threshold/useThresholdEditModal'
import { useGetThresholdQuery } from '@/api/queries/admin/thresholds/useGetThresholdQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import FormInputField from '@/components/form-inputs/form-input-field/FormInputField.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import { useI18n } from 'vue-i18n'
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import VeeFormSingleSelectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormSingleSelectField.vue'
import { useField, useForm } from 'vee-validate'
import { thresholdEditValidationSchema } from '@/pages/admin/thresholds/modals/threshold/ThresholdEditModal.form'
import { AxiosError } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { useEditThresholdMutation } from '@/api/mutations/admin/thresholds/useEditThresholdMutation'

const { t, locale } = useI18n()
const toasts = usePebbleToasts()

const dialogRef = ref<PebbleDialog | null>(null)

const thresholdId = ref<number | undefined>(undefined)

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useGetThresholdQuery(thresholdId)
const { mutateAsync, isPending: isMutationPending } = useEditThresholdMutation()

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: thresholdEditValidationSchema,
    initialValues: {},
})

const normalValue = useField(() => 'normalValue')

const measurementName = computed(() => {
    const parameterEntry = constantsData.value?.parameters?.find((e) => e.id === data.value?.parameterId)

    if (!parameterEntry) {
        return '-'
    }

    return `${chooseLangString(locale, parameterEntry)} ${data.value?.criteriaDescription}`
})

const operatorOptions = computed<SelectOptions<number>>(() => {
    if (!constantsData.value?.thresholdOperatorTypes) {
        return []
    }

    return Object.keys(constantsData.value.thresholdOperatorTypes).map((key) => ({
        label: t(
            'admin.thresholds.oilAnalyses.modal.values.operator.' +
                (constantsData.value?.thresholdOperatorTypes?.[key] ?? '-'),
        ),
        value: parseInt(key),
    }))
})

const lastOperatorOptions = computed<SelectOptions<number>>(() => {
    if (!constantsData.value?.thresholdLastOperatorTypes) {
        return []
    }

    return Object.keys(constantsData.value.thresholdLastOperatorTypes).map((key) => ({
        label: constantsData.value?.thresholdLastOperatorTypes?.[key] ?? '-',
        value: parseInt(key),
    }))
})

const openThreshold = (payload: ThresholdEditOpenModalPayload) => {
    thresholdId.value = payload.id

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    if (!thresholdId.value) {
        return
    }

    try {
        await mutateAsync({
            id: thresholdId.value,
            data: {
                normalValue: values.normalValue !== '' ? Number(values.normalValue) : undefined,
                inferiorLastValue: values.inferiorLastValue !== '' ? Number(values.inferiorLastValue) : undefined,
                criticalValue: values.criticalValue !== '' ? Number(values.criticalValue) : undefined,
                criticalLastValue: values.criticalLastValue !== '' ? Number(values.criticalLastValue) : undefined,
                normalOperatorType: values.normalOperatorType,
                inferiorOperatorType: values.inferiorOperatorType,
                criticalOperatorType: values.criticalOperatorType,
                criticalLastOperatorType: values.criticalLastOperatorType,
                inferiorLastOperatorType: values.inferiorLastOperatorType,
            },
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

provideThresholdEditModal({
    openThreshold,
})

watch(data, (newData) => {
    if (!newData) {
        return
    }

    resetForm({
        values: {
            normalValue: newData.normalValue !== undefined ? newData.normalValue.toString() : '',
            normalOperatorType: newData.normalOperatorType,
            inferiorOperatorType: newData.inferiorOperatorType,
            criticalValue: newData.criticalValue !== undefined ? newData.criticalValue?.toString() : '',
            criticalOperatorType: newData.criticalOperatorType ?? undefined,
            criticalLastValue: newData.criticalLastValue !== undefined ? newData.criticalLastValue?.toString() : '',
            criticalLastOperatorType: newData.criticalLastOperatorType ?? undefined,
            inferiorLastValue: newData.inferiorLastValue !== undefined ? newData.inferiorLastValue?.toString() : '',
            inferiorLastOperatorType: newData.inferiorLastOperatorType ?? undefined,
        },
    })
})
</script>

<style scoped lang="scss">
.threshold-dialog {
    --width: 70vw;
}

.threshold-modal {
    &__form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacing-m;
    }

    &__field-group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacing-2xs $spacing-m;

        &-title {
            font-weight: 500;

            grid-column: span 2 / span 2;
        }
    }

    &__field {
        &--full-width {
            grid-column: span 4 / span 4;
        }

        &--half-width {
            grid-column: span 2 / span 2;
        }

        &--new-row {
            grid-column-start: 1;
        }
    }
}
</style>
