<template>
    <LoadingPanel
        :headline="t('transformers.portfolioOverview.groupStatusSummary.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <template #context>
            <div slot="context">
                <pbl-button
                    compact
                    class="criteria-button"
                    @click="onViewCriteriaClick"
                >
                    {{ t('transformers.portfolioOverview.viewStatusAssessmentCriteria') }}
                </pbl-button>
            </div>
        </template>
        <div
            v-if="data"
            class="group-summary"
        >
            <v-chart
                class="group-summary__chart"
                :option="option"
                autoresize
                @click="handleChartClick"
            />
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { use } from 'echarts/core'
import { ECElementEvent, EChartsOption } from 'echarts'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import {
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
    TitleComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { usePortfolioGroupSummaryQuery } from '@/api/queries/transformers/portfolio-overview/usePortfolioGroupSummaryQuery'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import { CHART_ICON } from '@/utils/chart-utils'
import type { CallbackDataParams } from 'echarts/types/dist/shared'
import { useStatusAssessmentCriteriaModal } from '@/pages/admin/thresholds/modals/status-assessment-criteria/useStatusAssessmentCriteriaModal'

use([
    TitleComponent,
    BarChart,
    CanvasRenderer,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DatasetComponent,
    MarkLineComponent,
    MarkPointComponent,
    ToolboxComponent,
    AriaComponent,
])

const emit = defineEmits<{
    'click:group': [value: number]
}>()

const { t } = useI18n()
const statusAssessmentModal = useStatusAssessmentCriteriaModal()

const { data, isPending, isError } = usePortfolioGroupSummaryQuery()

const plantGroupSids = computed(() => {
    if (!data.value?.plantGroupSids) {
        return []
    }

    return data.value.plantGroupSids
})

const series = computed(() => [
    {
        id: ConditionStatus.Normal,
        name: t(StatusTypes[ConditionStatus.Normal].labelKey),
        color: StatusTypes[ConditionStatus.Normal].color,
        data: data.value?.numberOfNormalTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.Inferior,
        name: t(StatusTypes[ConditionStatus.Inferior].labelKey),
        color: StatusTypes[ConditionStatus.Inferior].color,
        data: data.value?.numberOfInferiorTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.Critical,
        name: t(StatusTypes[ConditionStatus.Critical].labelKey),
        color: StatusTypes[ConditionStatus.Critical].color,
        data: data.value?.numberOfCriticalTransformers?.map((e: number) => e || '-'),
    },
    {
        id: ConditionStatus.None,
        name: t(StatusTypes[ConditionStatus.None].labelKey),
        color: StatusTypes[ConditionStatus.None].color,
        data: data.value?.numberOfNotAssessedTransformers?.map((e: number) => e || '-'),
    },
])

const option = computed((): EChartsOption => {
    const options: EChartsOption = {
        title: {
            show: false,
            text: t('transformers.portfolioOverview.groupStatusSummary.headline'),
        },
        toolbox: {
            show: true,
            itemSize: 16,
            itemGap: 14,
            showTitle: true,
            feature: {
                saveAsImage: {
                    icon: CHART_ICON.SAVE,
                    title: t('chart.action.saveAsImage'),
                },
            },
            iconStyle: {
                color: '#333',
                borderColor: 'none',
            },
            emphasis: {
                iconStyle: {
                    color: '#0178dc',
                    borderColor: 'none',
                },
            },
        },
        textStyle: {
            fontFamily: 'Roboto',
            color: '#adadad',
        },
        legend: {
            textStyle: {
                fontWeight: 500,
            },
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: plantGroupSids.value,
            axisLabel: {
                formatter: (value, index) => data.value?.plantGroups?.[index] ?? '',
            },
        },
        aria: {
            enabled: true,
            decal: {
                show: true,
                decals: [
                    {
                        symbol: 'none',
                    },
                    {
                        symbol: 'rect',
                        rotation: -0.75,
                        dashArrayX: [1, 0],
                        dashArrayY: [4, 3],
                    },
                    {
                        symbol: 'rect',
                        dashArrayX: [
                            [0, 6, 6, 0],
                            [6, 6],
                        ],
                        dashArrayY: [6, 0],
                    },
                ],
            },
        },
        series: [],
    }

    series.value.forEach((series) => {
        if (!Array.isArray(options.series)) {
            return
        }

        options.series.push({
            id: series.id,
            name: series.name,
            type: 'bar',
            stack: 'total',
            color: series.color,
            barWidth: '90%',
            barMaxWidth: 60,
            label: {
                show: true,
                color: '#4a4a4a',
                fontWeight: 'bold',
                backgroundColor: 'rgba(255, 255, 255, 1)',
                formatter: (params: CallbackDataParams) => `${params.data}`,
            },
            data: series.data,
        })
    })

    return options
})

const handleChartClick = (event: ECElementEvent) => {
    const plantGroupSid = parseInt(event.name)
    emit('click:group', plantGroupSid)
}

const onViewCriteriaClick = () => {
    statusAssessmentModal?.openModal()
}
</script>

<style scoped lang="scss">
.group-summary {
    &__chart {
        margin-top: 72px;
        width: 100%;
        height: 700px;
    }
}
</style>

<style lang="scss">
.container {
    &__name {
        font-size: $default-font-size;
        color: #666;
        font-weight: 500;
    }

    &__content {
        margin: 10px 0 0;

        &__item-div {
            margin: 10px 0 0;
            display: flex;
            gap: 16px;
            align-items: flex-start;

            &__color-div {
                display: flex;
                gap: 10px;
                align-items: center;

                &__color-circle {
                    display: inline-block;
                    border-radius: 10px;
                    width: 10px;
                    height: 10px;
                }

                &__series-name {
                    font-size: $default-font-size;
                    color: #666;
                    font-weight: 400;
                    line-height: 18px;
                }
            }

            &__tooltip {
                font-size: $default-font-size;
                color: #666;
                font-weight: 500;
                line-height: 18px;
                max-width: 200px;
                white-space: normal;
            }
        }
    }
}
</style>
