export type SelectOption<T = string, E = unknown> = {
    label: string
    value: T
    extraData?: E
}

export type SelectOptions<T = string, E = unknown> = SelectOption<T, E>[]

export const sortOptionsByLabel = <T = string, E = unknown>(options: SelectOptions<T, E>) => {
    return options.sort((a, b) => a.label.localeCompare(b.label))
}
