<template>
    <div
        :class="[
            'up-dev-tools',
            {
                'up-dev-tools--development': isDevelopmentEnvironment,
                'up-dev-tools--staging': isStagingEnvironment,
            },
        ]"
    >
        <div
            :class="[
                'up-dev-tools__environment',
                {
                    'up-dev-tools__environment--development': isDevelopmentEnvironment,
                    'up-dev-tools__environment--staging': isStagingEnvironment,
                },
            ]"
        >
            {{ currentEnvironment }}
        </div>
        <div class="up-dev-tools__urls">
            <div class="up-dev-tools__urls-item">
                <span>API Endpoint</span>
                <a
                    target="_blank"
                    :href="apiEndpointsUrl"
                    >{{ apiEndpointsUrl }}</a
                >
            </div>
            <div class="up-dev-tools__urls-item">
                <span>Swagger UI</span>
                <a
                    target="_blank"
                    :href="swaggerUrl"
                    >{{ swaggerUrl }}</a
                >
            </div>
        </div>
        <div class="up-dev-tools__quick-links">
            <button
                class="up-dev-tools__quick-links-button"
                type="button"
                @click="onCopyAccessTokenClick"
            >
                <pbl-icon name="clipboard-check" />
                Copy Access Token for Current Use-Case
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useAuthStore } from '@/store/authStore'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { isDevelopmentEnvironment, isStagingEnvironment } from '@/utils/environment'

const authStore = useAuthStore()
const accessToken = computed(() => authStore.accessToken)

const currentEnvironment = ref(import.meta.env.MODE.toUpperCase())
const apiUrl = ref(import.meta.env.VITE_THIS_USE_CASE_API_URL)

const apiEndpointsUrl = computed(() => `${apiUrl.value}/api/v1`)
const swaggerUrl = computed(() => `${apiUrl.value}/swagger`)

const toasts = usePebbleToasts()

const onCopyAccessTokenClick = async () => {
    try {
        await navigator.clipboard.writeText(accessToken.value ?? '')

        toasts?.success({
            message: 'Successfully copied access token to clipboard!',
            overrides: {
                duration: 2 * 1000,
            },
        })

        console.log("Here's your access token, you shouldn't share it though:", {
            accessToken: accessToken.value,
        })
    } catch (err) {
        toasts?.error({
            message: 'Failed to write access token to clipboard.',
            overrides: {
                duration: 2 * 1000,
            },
        })

        console.error('Failed to write to clipboard.', err)
    }
}
</script>

<style scoped lang="scss">
.up-dev-tools {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    white-space: nowrap;

    &--development {
        border: 1px solid $coode-header-dev;
    }

    &--staging {
        border: 1px solid $coode-header-uat;
    }

    &__environment {
        display: flex;
        justify-content: center;
        padding: $spacing-3xs 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;

        color: $pbl-foreground;
        font-weight: 700;
        font-size: 0.8em;

        &--development {
            background-color: $coode-header-dev;
        }

        &--staging {
            background-color: $coode-header-uat;
        }
    }

    &__urls {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: $spacing-2xs $spacing-xs;
        gap: $spacing-xs;

        &-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            & > span {
                font-weight: 600;
            }

            & > a {
                color: $pbl-primary;
            }
        }
    }

    &__quick-links {
        display: flex;
        flex-direction: column;
        justify-content: center;

        border-top: 1px solid $pbl-border;

        &-button {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: center;
            gap: $spacing-xs;

            width: 100%;
            padding: $spacing-2xs $spacing-xs;

            border: 0;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            background-color: $pbl-background;

            &:hover {
                background-color: adjust-color($pbl-background, $lightness: -10%);
            }

            &:active {
                background-color: $pbl-background;
            }
        }
    }
}
</style>
