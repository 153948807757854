<template>
    <div
        :class="[
            {
                'bookmark-item--not-draggable': disabled,
            },
            'bookmark-item',
        ]"
    >
        <div
            data-handle="true"
            class="bookmark-item__target"
        >
            <GripHorizontal />
        </div>
        <div
            class="bookmark-item__content"
            @click="emit('click:bookmark')"
        >
            {{ bookmarkName }}
        </div>
        <div
            class="bookmark-item__delete"
            @click="emit('click:delete')"
        >
            <Trash />
        </div>
    </div>
</template>

<script setup lang="ts">
import { BookmarkDto } from '@/api/generated/HCA'
import { GripHorizontal, Trash } from 'lucide-vue-next'
import {
    flattenTransformersHierarchy,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    bookmark: BookmarkDto
    disabled?: boolean
}>()

const emit = defineEmits<{
    'click:bookmark': []
    'click:delete': []
}>()

const { t } = useI18n()
const { data } = useTransformersHierarchyQuery()

const flattenedHierarchy = computed(() => flattenTransformersHierarchy(data.value?.plantGroups ?? []))

const bookmarkName = computed(() => {
    if (!props.bookmark.componentSid) {
        return '-'
    }

    const transformerRecord = flattenedHierarchy.value.find((e) => e.transformer.id === props.bookmark.componentSid)

    if (!transformerRecord) {
        return props.bookmark.componentSid
    }

    return t('dashboard.bookmarks.item', {
        plantGroup: transformerRecord.plantGroup.name,
        plant: transformerRecord.plant.name,
        componentSid: props.bookmark.componentName,
    })
})
</script>

<style scoped lang="scss">
.bookmark-item {
    background-color: $pbl-foreground-muted;
    border-radius: 4px;

    display: flex;
    gap: $spacing-xs;
    align-items: center;

    &:hover {
        .bookmark-item__delete {
            display: flex;
            border-radius: 4px;
        }
    }

    &--not-draggable {
        cursor: not-allowed !important;

        & > * {
            cursor: not-allowed !important;
        }
    }

    &__target {
        background-color: adjust-color($pbl-foreground-muted, $lightness: -10%);
        border-radius: 4px;

        padding: $spacing-xs $spacing-2xs;
        cursor: move;

        & > * {
            margin-top: 2px;
        }
    }

    &__content {
        flex: 1;

        user-select: none;
        cursor: pointer;
    }

    &__delete {
        display: none;

        height: 100%;
        padding: $spacing-xs $spacing-2xs;

        align-items: center;

        background-color: $pbl-invalid;
        cursor: pointer;

        & > * {
            height: 20px;
            width: 20px;

            color: white;
        }
    }
}
</style>
