<template>
    <LoadingPanel
        v-model:collapsed="collapsedState.comments"
        :headline="t('transformers.conditionStatus.content.comments.headline')"
        :is-pending="isPending"
        :is-error="isError"
        collapsible
    >
        <div
            v-if="data?.pages?.[0]?.total !== 0"
            class="comments-content"
        >
            <div class="comments-content__table">
                <div ref="tableRef" />
            </div>
            <pbl-button
                v-if="hasNextPage"
                compact
                :disabled="isFetchingNextPage"
                :loading="isFetchingNextPage"
                @click="fetchNextPage()"
            >
                {{ t('transformers.conditionStatus.content.comments.actions.loadComments') }}
            </pbl-button>
        </div>
        <EmptyStatePanelContent v-else />
        <template #context>
            <pbl-button
                v-if="userPermissions.canEditTransformerComments"
                slot="context"
                variant="secondary"
                compact
                @click.stop="commentModal?.openComment()"
            >
                <pbl-icon
                    slot="icon"
                    name="plus"
                ></pbl-icon>
                {{ t('transformers.conditionStatus.content.comments.actions.addComment') }}
            </pbl-button>
        </template>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useTransformerCommentsQuery } from '@/api/queries/transformers/useTransformerCommentsQuery'
import { useTransformerId } from '@/composables/useTransformerId'
import { computed, ref, toRefs, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, ColumnDefinition, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'
import { useI18n } from 'vue-i18n'
import { AnalysisTypes } from '@/configuration/analysis/analysis-types'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { createElement, PencilLine, Trash2 } from 'lucide'
import { CommentDto } from '@/api/generated/HCA'
import { useConditionStatusCommentModal } from '@/pages/transformers/condition-status/modals/comment/useConditionStatusCommentModal'
import { useGlobalConfirmationModal } from '@/composables/useGlobalConfirmationModal'
import { useTransformersCommentsDeleteMutation } from '@/api/mutations/transformers/comments/useTransformersCommentsDeleteMutation'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { chooseLangString } from '@/utils/i18n-utils'
import { StatusTypes } from '@/configuration/status/condition-status'
import { useExportStateStore } from '@/store/exportStateStore'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { storeToRefs } from 'pinia'
import { usePermissions } from '@/composables/usePermissions'

const props = defineProps<{
    preloadSize?: number
}>()

const { preloadSize } = toRefs(props)

const { t, locale } = useI18n()
const transformerId = useTransformerId()
const commentModal = useConditionStatusCommentModal()
const triggerGlobalConfirmationModal = useGlobalConfirmationModal()

const { userPermissions } = usePermissions()
const exportStateStore = useExportStateStore()
const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const { collapsedState } = storeToRefs(conditionStatusCollapseStore)

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending, isError } = useTransformerCommentsQuery(
    transformerId,
    preloadSize,
)

const { data: constantsData } = useConstantsQuery()

const { mutateAsync: mutateDeleteAsync } = useTransformersCommentsDeleteMutation()

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => data.value?.pages?.flatMap((e) => e?.comments ?? []) ?? [])

const onEditIconClick = (commentDto: CommentDto) => {
    commentModal?.openComment({
        comment: commentDto,
    })
}

const onDeleteIconClick = (commentDto: CommentDto) => {
    const commentId = commentDto.id
    const analysisType = commentDto.analysisType ?? undefined

    if (!commentId) {
        return
    }

    triggerGlobalConfirmationModal({
        headline: t('transformers.conditionStatus.content.comments.deleteConfirmation.headline'),
        content: t('transformers.conditionStatus.content.comments.deleteConfirmation.content'),
        confirmButtonText: t('transformers.conditionStatus.content.comments.deleteConfirmation.headline'),
        onConfirm: async () => {
            await mutateDeleteAsync({
                transformerId: transformerId.value,
                commentId,
                analysisType,
            })
        },
    })
}

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const columns: ColumnDefinition[] = [
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.date'),
            field: 'date',
            formatter: (cell: CellComponent) => {
                return format(new Date(cell.getValue()), DATE_FORMAT)
            },
        },
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.reportNumber'),
            field: 'reportNumber',
            minWidth: 150,
        },
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.analysisType'),
            field: 'analysisType',
            formatter: (cell: CellComponent) => {
                const analysisType = AnalysisTypes.find((e) => e.value === cell.getValue())

                if (!analysisType) {
                    return '-'
                }

                return t(analysisType.labelKey)
            },
        },
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.parameterId'),
            field: 'parameterId',
            formatter: (cell: CellComponent) => {
                const parameter = constantsData.value?.parameters?.find((e) => e.id === cell.getValue())

                if (!parameter) {
                    return '-'
                }

                return chooseLangString(locale, parameter)
            },
            widthGrow: 2,
        },
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.text'),
            field: 'text',
            formatter: (cell: CellComponent) => {
                const rowData: CommentDto = cell.getRow().getData()

                const cellDiv = document.createElement('div')
                cellDiv.classList.add('comments-text-cell')

                if (rowData.isOverride) {
                    const title = t('transformers.conditionStatus.content.oilAnalyses.overrideComment.title', {
                        from: t(StatusTypes.find((e) => e.value === rowData.previousStatus)?.labelKey ?? ''),
                        to: t(StatusTypes.find((e) => e.value === rowData.newStatus)?.labelKey ?? ''),
                    })

                    const titleSpan = document.createElement('span')
                    titleSpan.classList.add('comments-text-cell__title')
                    titleSpan.textContent = title
                    cellDiv.appendChild(titleSpan)
                }

                const textSpan = document.createElement('span')
                textSpan.classList.add('comments-text-cell__text')
                textSpan.textContent = rowData.text ?? '-'
                cellDiv.appendChild(textSpan)

                return cellDiv
            },
            widthGrow: 8,
        },
        {
            title: t('transformers.conditionStatus.content.comments.table.headers.kid'),
            field: 'kid',
        },
        {
            title: '',
            field: 'isOverride',
            visible: !!userPermissions.value.canEditTransformerComments,
            formatter: (cell: CellComponent) => {
                const isOverride = cell.getValue()

                if (isOverride) {
                    return ''
                }

                const cellDiv = document.createElement('div')
                cellDiv.classList.add('comments-action-cell')

                const editIcon = createElement(PencilLine)
                editIcon.addEventListener('click', () => onEditIconClick(cell.getRow().getData()))
                editIcon.classList.add('comments-action-cell__icon')
                cellDiv.appendChild(editIcon)

                const deleteIcon = createElement(Trash2)
                deleteIcon.addEventListener('click', () => onDeleteIconClick(cell.getRow().getData()))
                deleteIcon.classList.add('comments-action-cell__icon')
                cellDiv.appendChild(deleteIcon)

                return cellDiv
            },
        },
    ]

    const tabulatorOptions: Options = {
        layout: 'fitColumns',
        height: '100%',
        pagination: false,
        reactiveData: true,
        data: newTableRowData,
        columnDefaults: {
            headerSort: false,
            vertAlign: 'middle',
        },
        columns,
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})

watch(data, (newData) => {
    if (!newData) {
        return
    }

    exportStateStore.conditionStatusExportParameters.commentsLength = newData.pages.flatMap(
        (e) => e?.comments ?? [],
    ).length
})
</script>

<style scoped lang="scss">
.comments-content {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    &__table {
        border: 1px solid $pbl-border-muted;
    }
}
</style>

<style lang="scss">
.comments-action-cell {
    display: flex;
    gap: $spacing-2xs;

    align-items: center;

    &__icon {
        height: 18px;
        width: 18px;

        color: $pbl-primary;

        &:hover {
            color: darken($pbl-primary, 20);
        }
    }
}

.comments-text-cell {
    display: flex;
    flex-direction: column;

    gap: $spacing-3xs;
    white-space: pre-wrap;

    &__title {
        font-weight: 500;
    }
}
</style>
