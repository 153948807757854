export enum IssueType {
    TransformerConditionStatusChanged = 1,
    OilAnalysisComing,
    OilAnalysisOverdue,
}

export const IssueTypes = [
    {
        labelKey: 'transformers.portfolioOverview.flaggedTransformers.issueTypes.TransformerConditionStatusChanged',
        value: IssueType.TransformerConditionStatusChanged,
    },
    {
        labelKey: 'transformers.portfolioOverview.flaggedTransformers.issueTypes.OilAnalysisComing',
        value: IssueType.OilAnalysisComing,
    },
    {
        labelKey: 'transformers.portfolioOverview.flaggedTransformers.issueTypes.OilAnalysisOverdue',
        value: IssueType.OilAnalysisOverdue,
    },
] as const
