import { inject, InjectionKey, provide } from 'vue'

export type ConditionStatusOpenPayload = {
    status: number
    parameterId: number
    parameterName: string
    analyticalNumber: string
    analysisType: number
}

export type ConditionStatusOverrideModalActions = {
    openOverride: (payload: ConditionStatusOpenPayload) => void
}

export const ConditionStatusOverrideModalInjectionKey: InjectionKey<ConditionStatusOverrideModalActions> = Symbol(
    'ConditionStatusOverrideModalInjectionKey',
)

export const useConditionStatusOverrideModal = () => {
    return inject(ConditionStatusOverrideModalInjectionKey)
}

export const provideConditionStatusOverrideModal = (actions: ConditionStatusOverrideModalActions) =>
    provide(ConditionStatusOverrideModalInjectionKey, actions)
