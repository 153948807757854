<template>
    <LoadingPanel
        v-model:collapsed="collapsedState.recommendations"
        :headline="t('transformers.conditionStatus.content.recommendations.title')"
        :is-pending="isPending"
        :is-error="isError"
        collapsible
    >
        <div
            v-if="data?.pages?.[0]?.totalCount !== 0"
            class="recommendation-content"
        >
            <div class="recommendation-content__table recommendation-content__table--bordered">
                <div ref="tableRef" />
            </div>
            <pbl-button
                v-if="hasNextPage"
                compact
                :disabled="isFetchingNextPage"
                :loading="isFetchingNextPage"
                @click="fetchNextPage()"
            >
                {{ t('transformers.conditionStatus.content.recommendations.actions.loadMore') }}
            </pbl-button>
        </div>
        <EmptyStatePanelContent v-else />
        <template #context>
            <pbl-button
                v-if="userPermissions.canEditTransformerRecommendations"
                slot="context"
                variant="secondary"
                compact
                @click.stop="addRecommendationModal?.openAddRecommendation()"
            >
                <pbl-icon
                    slot="icon"
                    name="plus"
                ></pbl-icon>
                {{ t('transformers.conditionStatus.content.recommendations.actions.add') }}
            </pbl-button>
        </template>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { computed, ref, toRefs, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import { format } from 'date-fns'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/utils/date-utils'
import { useI18n } from 'vue-i18n'
import { useTransformerId } from '@/composables/useTransformerId'
import { useRecommendationsByComponentQuery } from '@/api/queries/recommendations/useRecommendationsByComponentQuery'
import { RecommendationCommentDto } from '@/api/generated/HCA'
import { useConditionStatusAddRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/add-recommendation/useConditionStatusAddRecommendationModal'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { chooseLangString } from '@/utils/i18n-utils'
import { createElement, Eye, PencilLine } from 'lucide'
import { useConditionStatusEditRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/edit-recommendation/useConditionStatusEditRecommendationModal'
import { useConditionStatusViewRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/view-recommendation/useConditionStatusViewRecommendationModal'
import { useExportStateStore } from '@/store/exportStateStore'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { storeToRefs } from 'pinia'
import { usePermissions } from '@/composables/usePermissions'

const props = defineProps<{
    preloadSize?: number
}>()

const { preloadSize } = toRefs(props)

const { userPermissions } = usePermissions()
const exportStateStore = useExportStateStore()
const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const { collapsedState } = storeToRefs(conditionStatusCollapseStore)

const { t, locale } = useI18n()
const transformerId = useTransformerId()
const addRecommendationModal = useConditionStatusAddRecommendationModal()
const editRecommendationModal = useConditionStatusEditRecommendationModal()
const viewRecommendationModal = useConditionStatusViewRecommendationModal()

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useRecommendationsByComponentQuery(
    transformerId,
    preloadSize,
)

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => data.value?.pages?.flatMap((e) => e?.items ?? []) ?? [])

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: 'fitColumns',
        height: '100%',
        pagination: false,
        reactiveData: true,
        data: newTableRowData,
        columnDefaults: {
            headerSort: false,
            vertAlign: 'middle',
        },
        columns: [
            {
                title: '',
                field: 'id',
                formatter: (cell: CellComponent) => {
                    const recommendationId = cell.getValue()

                    const cellDiv = document.createElement('div')

                    const viewIcon = createElement(Eye)
                    viewIcon.addEventListener(
                        'click',
                        () => viewRecommendationModal?.openViewRecommendation(recommendationId),
                    )
                    viewIcon.classList.add('recommendation-action-cell-icon')
                    cellDiv.appendChild(viewIcon)

                    return cellDiv
                },
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.date'),
                field: 'date',
                minWidth: 100,
                formatter: (cell: CellComponent) => {
                    return format(new Date(cell.getValue()), DATE_FORMAT)
                },
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.recommendationTypeId'),
                field: 'recommendationTypeId',
                widthGrow: 10,
                formatter: (cell: CellComponent) => {
                    const recommendationData = constantsData.value?.recommendationTypes?.find(
                        (e) => e.id === cell.getValue(),
                    )

                    if (cell.getValue() === OTHER_RECOMMENDATION_TYPE_ID) {
                        return cell.getRow().getData().freeText ?? '-'
                    }

                    if (!recommendationData) {
                        return '-'
                    }

                    return chooseLangString(locale, recommendationData)
                },
                cssClass: 'recommendation-type-cell',
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.comment'),
                field: 'comment',
                widthGrow: 20,
                formatter: (cell: CellComponent) => {
                    const comment: RecommendationCommentDto = cell.getValue()

                    if (!comment) {
                        return '-'
                    }

                    const cellDiv = document.createElement('div')
                    cellDiv.classList.add('recommendation-comment-cell')

                    const textSpan = document.createElement('span')
                    textSpan.classList.add('recommendation-comment-cell__text')
                    textSpan.textContent = comment.text ?? '-'
                    cellDiv.appendChild(textSpan)

                    const metaSpan = document.createElement('span')
                    metaSpan.classList.add('recommendation-comment-cell__meta')
                    metaSpan.textContent = `${format(new Date(comment.date ?? ''), DATE_TIME_FORMAT)} | KID ${
                        comment.kid ?? ''
                    }`
                    cellDiv.appendChild(metaSpan)

                    return cellDiv
                },
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.sapNotificationNumber'),
                field: 'sapNotificationNumber',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().sapNotificationUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().sapNotificationUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.pspNumber'),
                field: 'pspNumber',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().pspNumberUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().pspNumberUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.ptRiskId'),
                field: 'ptRiskId',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().ptRiskUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().ptRiskUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('transformers.conditionStatus.content.recommendations.table.headers.kid'),
                field: 'kid',
                minWidth: 100,
            },
            {
                title: '',
                field: 'id',
                visible: !!userPermissions.value.canEditTransformerRecommendations,
                formatter: (cell: CellComponent) => {
                    const recommendationId = cell.getValue()

                    const cellDiv = document.createElement('div')

                    const editIcon = createElement(PencilLine)
                    editIcon.addEventListener(
                        'click',
                        () => editRecommendationModal?.openEditRecommendation(recommendationId),
                    )
                    editIcon.classList.add('recommendation-action-cell-icon')
                    cellDiv.appendChild(editIcon)

                    return cellDiv
                },
            },
        ],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})

watch(data, (newData) => {
    if (!newData) {
        return
    }

    exportStateStore.conditionStatusExportParameters.recommendationsLength = newData.pages.flatMap(
        (e) => e?.items ?? [],
    ).length
})
</script>

<style scoped lang="scss">
.recommendation-content {
    display: flex;
    flex-direction: column;
    gap: $spacing-xs;

    &__description {
        font-size: 0.95em;
        font-weight: 500;
    }

    &__table--bordered {
        border: 1px solid $pbl-border-muted;
    }
}
</style>

<style lang="scss">
.recommendation-comment-cell {
    display: flex;
    flex-direction: column;

    gap: $spacing-3xs;

    &__text {
        white-space: pre-wrap !important;
    }

    &__meta {
        color: $pbl-text-muted;
    }
}

.recommendation-type-cell {
    white-space: pre-wrap !important;
}

.recommendation-action-cell-icon {
    height: 18px;
    width: 18px;

    color: $pbl-primary;

    &:hover {
        color: darken($pbl-primary, 20);
    }
}

.recommendation-link-cell {
    color: $pbl-primary;
    cursor: pointer;
}
</style>
