<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.recommendations.title')"
        :is-pending="isPending"
        :is-error="isError"
        :hide-content="recommendationData.length === 0"
    >
        <template
            v-if="recommendationData.length === 0"
            #context
        >
            <ColorBadge
                :color="StatusTypes[ConditionStatus.None].color"
                :text="t(StatusTypes[ConditionStatus.None].labelKey)"
                small
            />
        </template>
        <div
            v-if="recommendationData.length !== 0"
            class="recommendations-section"
        >
            <template
                v-for="(recommendation, index) in recommendationData"
                :key="recommendation.id"
            >
                <div class="recommendations-section__item-horizontal-list">
                    <div class="recommendations-section__item">
                        <div class="recommendations-section__item-title">
                            {{ t('transformers.conditionStatus.content.recommendations.table.headers.date') }}
                        </div>
                        <div class="recommendations-section__item-value">
                            {{ recommendation.date }}
                        </div>
                    </div>
                    <div class="recommendations-section__item">
                        <div class="recommendations-section__item-title">
                            {{
                                t(
                                    'transformers.conditionStatus.content.recommendations.table.headers.sapNotificationNumber',
                                )
                            }}
                        </div>
                        <div class="recommendations-section__item-value">
                            {{ recommendation.sapNotificationNumber }}
                        </div>
                    </div>
                    <div class="recommendations-section__item">
                        <div class="recommendations-section__item-title">
                            {{ t('transformers.conditionStatus.content.recommendations.table.headers.pspNumber') }}
                        </div>
                        <div class="recommendations-section__item-value">
                            {{ recommendation.pspNumber }}
                        </div>
                    </div>
                    <div class="recommendations-section__item">
                        <div class="recommendations-section__item-title">
                            {{ t('transformers.conditionStatus.content.recommendations.table.headers.ptRiskId') }}
                        </div>
                        <div class="recommendations-section__item-value">
                            {{ recommendation.ptRiskId }}
                        </div>
                    </div>
                </div>
                <div class="recommendations-section__item">
                    <div class="recommendations-section__item-title">
                        {{
                            t('transformers.conditionStatus.content.recommendations.table.headers.recommendationTypeId')
                        }}
                    </div>
                    <div class="recommendations-section__item-value">
                        {{ recommendation.recommendation }}
                    </div>
                </div>
                <div
                    v-if="recommendation.comment"
                    class="recommendations-section__item"
                >
                    <div class="recommendations-section__item-title">
                        {{ t('transformers.conditionStatus.content.recommendations.table.headers.comment') }}
                    </div>
                    <div class="recommendations-section__item-value">
                        {{ recommendation.comment.text }}
                    </div>
                    <div class="recommendations-section__item-meta">
                        {{
                            `${format(new Date(recommendation.comment.date ?? ''), DATE_TIME_FORMAT)} | KID ${
                                recommendation.comment.kid ?? ''
                            }`
                        }}
                    </div>
                </div>
                <div
                    v-if="index !== recommendationData.length - 1"
                    class="recommendations-section__separator"
                />
            </template>
        </div>
        <EmptyStatePanelContent v-else />
    </LoadingPanel>
</template>

<script setup lang="ts">
import { useTransformerId } from '@/composables/useTransformerId'
import { useI18n } from 'vue-i18n'
import { useRecommendationsByComponentQuery } from '@/api/queries/recommendations/useRecommendationsByComponentQuery'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { computed, toRefs } from 'vue'
import { CommentDto } from '@/api/generated/HCA'
import { format } from 'date-fns'
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@/utils/date-utils'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import ColorBadge from '@/components/badge/ColorBadge.vue'

type NormalizedRecommendationData = {
    id: number
    date: string
    recommendation: string
    comment?: CommentDto
    sapNotificationNumber: string
    pspNumber: string
    ptRiskId: string
    userKid: string
}

const props = defineProps<{
    preloadSize?: number
}>()

const { preloadSize } = toRefs(props)

const { t, locale } = useI18n()
const transformerId = useTransformerId()

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useRecommendationsByComponentQuery(transformerId, preloadSize)

const recommendationData = computed<NormalizedRecommendationData[]>(() => {
    if (!data.value?.pages || !constantsData.value) {
        return []
    }

    const loadedRecommendations = data.value.pages.flatMap((e) => e?.items ?? [])

    const normalizedRecommendations: NormalizedRecommendationData[] = []

    loadedRecommendations.forEach((recommendation) => {
        const recommendationEntry = constantsData.value?.recommendationTypes?.find(
            (e) => e.id === recommendation.recommendationTypeId,
        )

        if (!recommendationEntry) {
            return
        }

        const recommendationDate = format(new Date(recommendation.date ?? new Date()), DATE_FORMAT)
        let recommendationText = chooseLangString(locale, recommendationEntry)

        if (recommendation.recommendationTypeId === OTHER_RECOMMENDATION_TYPE_ID) {
            recommendationText = recommendation.freeText ?? '-'
        }

        normalizedRecommendations.push({
            id: recommendation.id ?? -1,
            date: recommendationDate,
            recommendation: recommendationText,
            comment: recommendation.comment,
            sapNotificationNumber: !recommendation.sapNotificationNumber ? '-' : recommendation.sapNotificationNumber,
            pspNumber: !recommendation.pspNumber ? '-' : recommendation.pspNumber,
            ptRiskId: !recommendation.ptRiskId ? '-' : recommendation.ptRiskId,
            userKid: recommendation.kid ?? '-',
        })
    })

    return normalizedRecommendations
})
</script>

<style scoped lang="scss">
.recommendations-section {
    display: flex;
    flex-direction: column;

    gap: $spacing-xs;

    &__item {
        break-inside: avoid;

        &-title {
            font-weight: 500;
            font-size: $default-font-size;
        }

        &-value {
            font-size: $default-font-size;
        }

        &-meta {
            font-size: $default-font-size;
            color: $pbl-text-muted;
        }
    }

    &__item-horizontal-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__separator {
        height: 1px;
        width: 100%;
        background-color: $pbl-foreground-muted;
    }
}
</style>
