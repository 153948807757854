import { inject, InjectionKey, provide } from 'vue'

export type StatusAssessmentCriteriaActions = {
    openModal: () => void
}

const StatusAssessmentCriteriaInjectionKey: InjectionKey<StatusAssessmentCriteriaActions> = Symbol(
    'StatusAssessmentCriteriaModal',
)

export const useStatusAssessmentCriteriaModal = () => {
    return inject(StatusAssessmentCriteriaInjectionKey)
}

export const provideStatusAssessmentCriteriaModal = (actions: StatusAssessmentCriteriaActions) =>
    provide(StatusAssessmentCriteriaInjectionKey, actions)
