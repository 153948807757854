<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.masterData.title')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="data"
            class="master-data"
        >
            <div class="master-data__group">
                <MasterDataItem
                    v-if="data.decommissioningDate"
                    :label="t('transformers.conditionStatus.content.masterData.labels.decommissioned')"
                    :value="format(new Date(data.decommissioningDate), DATE_FORMAT)"
                />
                <MasterDataItem
                    v-if="data.plantGroupName"
                    :label="t('transformers.conditionStatus.content.masterData.labels.plantGroup')"
                    :value="data.plantGroupName"
                />
                <MasterDataItem
                    v-if="data.plantName"
                    :label="
                        data.isReserve
                            ? t('transformers.conditionStatus.content.masterData.labels.storagePlant')
                            : t('transformers.conditionStatus.content.masterData.labels.plant')
                    "
                    :value="data.plantName"
                />
                <MasterDataItem
                    v-if="data.transformerName"
                    :label="t('transformers.conditionStatus.content.masterData.labels.transformerName')"
                    :value="data.transformerName"
                />
                <MasterDataItem
                    v-if="data.transformerSid"
                    :label="t('transformers.conditionStatus.content.masterData.labels.transformerId')"
                    :value="data.transformerSid"
                />
                <MasterDataItem
                    v-if="data.technicalLocation"
                    :label="t('transformers.conditionStatus.content.masterData.labels.technicalLocation')"
                    :value="data.technicalLocation"
                />
                <MasterDataItem
                    v-if="data.manufacturer"
                    :label="t('transformers.conditionStatus.content.masterData.labels.manufacturer')"
                    :value="data.manufacturer"
                />
                <MasterDataItem
                    v-if="data.transformerType"
                    :label="t('transformers.conditionStatus.content.masterData.labels.constructionType')"
                    :value="data.transformerType"
                />
                <MasterDataItem
                    v-if="data.constructionYear"
                    :label="t('transformers.conditionStatus.content.masterData.labels.constructionYear')"
                    :value="data.constructionYear.toString()"
                />
            </div>
            <div class="master-data__group">
                <MasterDataItem
                    v-if="data.nominalPower"
                    :label="t('transformers.conditionStatus.content.masterData.labels.nominalPower')"
                    :value="data.nominalPower.toString()"
                />
                <MasterDataItem
                    v-if="data.primaryVoltageOs"
                    :label="t('transformers.conditionStatus.content.masterData.labels.primaryVoltage')"
                    :value="data.primaryVoltageOs.toString()"
                />
                <MasterDataItem
                    v-if="data.secondaryVoltageOs"
                    :label="t('transformers.conditionStatus.content.masterData.labels.secondaryVoltage')"
                    :value="data.secondaryVoltageOs.toString()"
                />
                <MasterDataItem
                    v-if="data.coolingSystem"
                    :label="t('transformers.conditionStatus.content.masterData.labels.coolingSystem')"
                    :value="data.coolingSystem"
                />
                <MasterDataItem
                    v-if="data.oilWeight"
                    :label="t('transformers.conditionStatus.content.masterData.labels.oilWeight')"
                    :value="data.oilWeight.toString()"
                />
                <MasterDataItem
                    v-if="data.totalWeight"
                    :label="t('transformers.conditionStatus.content.masterData.labels.totalWeight')"
                    :value="data.totalWeight.toString()"
                />
            </div>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useI18n } from 'vue-i18n'
import { useTransformerDetailsQuery } from '@/api/queries/transformers/useTransformerDetailsQuery'
import { toRefs } from 'vue'
import MasterDataItem from '@/components/master-data/MasterDataItem.vue'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'

const props = defineProps<{
    transformerId: string
}>()

const { transformerId } = toRefs(props)

const { t } = useI18n()
const { data, isPending, isError } = useTransformerDetailsQuery(transformerId)
</script>

<style scoped lang="scss">
.master-data {
    display: flex;
    align-items: flex-start;
    gap: $spacing-l;

    &__group {
        display: grid;
        grid-template-columns: 1fr 1fr;

        row-gap: $spacing-2xs;
        column-gap: $spacing-xs;
    }
}
</style>
