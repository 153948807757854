import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'
import { MaybeRef, toValue } from 'vue'

export const useConditionStatusYearlyQuery = (plantSids: MaybeRef<number[]>) => {
    return useQuery({
        queryKey: ['transformers', 'portfolioTrends', 'conditionStatusYearly', plantSids],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.portfolioTrendsApi.getTransformerYearlyStatuses(toValue(plantSids), {
                signal,
            })
            return result
        },
        enabled: whenAuthenticated,
    })
}
