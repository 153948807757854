import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { AddBookmarkRequest } from '@/api/generated/HCA'

export const useBookmarksAddMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { bookmarkData: AddBookmarkRequest }) =>
            hcaClientFactory.bookmarksApi.addBookmark(payload.bookmarkData),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['bookmarks'] })
            await queryClient.invalidateQueries({ queryKey: ['transformersHierarchy'] })
        },
    })
}
