<template>
    <div
        class="item"
        @click="onTransformerClick"
    >
        {{ transformer.transformerName }}
    </div>
</template>

<script setup lang="ts">
import { FlaggedTransformerDto } from '@/api/generated/HCA'
import { useRouter } from 'vue-router'

const props = defineProps<{
    transformer: FlaggedTransformerDto
}>()

const router = useRouter()

const onTransformerClick = () => {
    if (!props.transformer.transformerSid) {
        return
    }

    router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: props.transformer.transformerSid,
        },
    })
}
</script>

<style scoped lang="scss">
.item {
    padding: $spacing-xs $spacing-s;

    border: 1px solid $pbl-border-muted;
    border-radius: 4px;

    user-select: none;
    cursor: pointer;
    color: $pbl-primary;

    &:hover:not(&:active) {
        color: adjust-color($pbl-primary, $lightness: -10%);
    }
}
</style>
