<template>
    <Transition>
        <div
            v-if="open"
            class="user-profile"
        >
            <div class="user-profile__pointer" />
            <div class="user-profile__content">
                <div class="user-profile__user-info">
                    <h3 class="user-profile__user-info-greeting">
                        {{ t('header.userProfile.greeting', { name: userName }) }}
                    </h3>
                    <h4
                        v-if="userExtraData"
                        class="user-profile__user-info-ids"
                    >
                        {{ userExtraData }}
                    </h4>
                </div>
                <HeaderUserProfileDevTools v-if="!isProductionEnvironment" />
                <div class="user-profile__button-section">
                    <button
                        type="button"
                        class="user-profile__button user-profile__button--outlined-blue"
                        @click="onUserManualClick"
                    >
                        <pbl-icon name="question-circle" />
                        {{ t('header.userProfile.userManual') }}
                    </button>
                    <div class="user-profile__button-section-group">
                        <button
                            type="button"
                            class="user-profile__button user-profile__button--outlined-red"
                            @click="onRaiseAnIncidentClick"
                        >
                            <pbl-icon name="exclamation-triangle" />
                            {{ t('header.userProfile.raiseAnIncident') }}
                        </button>
                        <button
                            type="button"
                            class="user-profile__button user-profile__button--outlined-blue"
                            @click="onSendFeedbackClick"
                        >
                            <pbl-icon name="clipboard-heart" />
                            {{ t('header.userProfile.sendFeedback') }}
                        </button>
                    </div>
                    <button
                        type="button"
                        class="user-profile__button"
                        @click="onSignOutClick"
                    >
                        {{ t('header.userProfile.signOut') }}
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import HeaderUserProfileDevTools from '@/components/header/user-profile/HeaderUserProfileDevTools.vue'
import { computed } from 'vue'
import { useUserStore } from '@/store/userStore'
import { useAuthStore } from '@/store/authStore'
import { isProductionEnvironment } from '@/utils/environment'
import { useI18n } from 'vue-i18n'
import { USER_MANUAL_URL } from '@/utils/header-user-profile-utils'

defineProps<{
    open: boolean
}>()

const { t } = useI18n()

const userStore = useUserStore()

const userName = computed(() => userStore.name)
const userExtraData = computed(() => {
    let extraData = ''

    if (userStore.userData?.kid) {
        extraData += `${userStore.userData.kid} | `
    }

    if (userStore.userData?.email) {
        extraData += userStore.userData.email
    }

    return extraData
})

const onUserManualClick = () => {
    window.open(USER_MANUAL_URL)
}

const onRaiseAnIncidentClick = () => {
    window.open(`${import.meta.env.VITE_APP_STORE_URL}incident-page/${import.meta.env.VITE_THIS_USE_CASE_ID}`)
}

const onSendFeedbackClick = () => {
    window.open(
        `${import.meta.env.VITE_APP_STORE_URL}contact-page/${import.meta.env.VITE_THIS_USE_CASE_ID}?isFeedback=true`,
    )
}

const onSignOutClick = () => {
    useAuthStore().signOut()
}
</script>

<style scoped lang="scss">
.user-profile {
    position: absolute;
    top: calc(100% + 7px);
    right: -7px;

    color: $pbl-text;
    background-color: $pbl-foreground;
    border-radius: 3px;
    box-shadow: rgba(4, 7, 22, 0.15) 0 5.625px 11.25px -1.25px;

    white-space: nowrap;

    z-index: 1;

    &__pointer {
        position: relative;
        z-index: 20;
        top: -5px;
        right: 14px;

        margin-left: auto;

        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $pbl-foreground;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;

        padding: $spacing-2xs $spacing-xs $spacing-s;
    }

    &__user-info {
        display: flex;
        flex-direction: column;
        gap: $spacing-3xs;

        &-greeting {
            font-weight: 500;
        }

        h3,
        h4 {
            margin: 0;
        }
    }

    &__button-section {
        display: flex;
        flex-direction: column;
        gap: $spacing-2xs;

        &-group {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;

            gap: $spacing-2xs;
        }
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-xs;

        cursor: pointer;
        padding: $spacing-xs $spacing-s;
        font-weight: 600;
        color: $pbl-foreground;
        background-color: $pbl-primary;
        border: none;
        border-radius: 4px;

        & pbl-icon {
            --size: 1.4em;
        }

        &:hover:not(&:active) {
            background-color: adjust-color($pbl-primary, $lightness: -10%);
        }

        &--outlined-red {
            color: $pbl-invalid;
            border: 1px solid $pbl-invalid;
            background-color: $pbl-foreground;

            &:hover:not(&:active) {
                background-color: adjust-color($pbl-foreground, $lightness: -5%);
            }
        }

        &--outlined-blue {
            color: $pbl-primary;
            border: 1px solid $pbl-primary;
            background-color: $pbl-foreground;

            &:hover:not(&:active) {
                background-color: adjust-color($pbl-foreground, $lightness: -5%);
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.3s cubic-bezier(0.42, 0.97, 0.52, 1.49);
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
