import { NavigationStructure } from '@/components/navigation/common-navigation'

export const AdminNavigationConfiguration: NavigationStructure = [
    {
        titleKey: 'admin.thresholds.title',
        destinationRouteName: 'Admin_Thresholds',
        matchRouteNames: ['Admin_Thresholds'],
    },
    {
        titleKey: 'admin.recommendationTypes.title',
        destinationRouteName: 'Admin_RecommendationTypes',
        matchRouteNames: ['Admin_RecommendationTypes'],
    },
]
