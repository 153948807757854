<template>
    <LoadingPanel
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="data"
            class="actions-section"
        >
            <ColorBadge
                v-if="reportStatus"
                :color="reportStatus.color"
                :text="t(reportStatus.labelKey)"
            />
            <ToggleControl
                v-if="data.canBeExcluded"
                :model-value="data.isExcluded ?? false"
                :label="
                    data.isExcluded
                        ? t('reports.detailsPage.excludedToggle.reinclude')
                        : t('reports.detailsPage.excludedToggle.exclude')
                "
                @update:model-value="onToggleExcludeChange"
            />
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { computed, toRefs } from 'vue'
import { useReportGetQuery } from '@/api/queries/reports/useReportGetQuery'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { StatusTypes } from '@/configuration/status/condition-status'
import { useI18n } from 'vue-i18n'
import ToggleControl from '@/components/toggle/ToggleControl.vue'
import { useGlobalConfirmationModal } from '@/composables/useGlobalConfirmationModal'
import { useReportSetExclusionMutation } from '@/api/mutations/reports/useReportSetExclusionMutation'
import { AxiosError } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'

const props = defineProps<{
    reportNumber: string
    reportType: number
}>()

const { reportNumber, reportType } = toRefs(props)

const { t } = useI18n()
const toasts = usePebbleToasts()
const triggerConfirmationModal = useGlobalConfirmationModal()

const { data, isPending, isError } = useReportGetQuery(reportNumber, reportType)
const { mutateAsync } = useReportSetExclusionMutation()

const reportStatus = computed(() => {
    if (!data) {
        return
    }

    return StatusTypes.find((e) => e.value === data.value?.status)
})

const onToggleExcludeChange = (value: boolean) => {
    triggerConfirmationModal({
        headline: value
            ? t('reports.detailsPage.excludeModal.exclude.headline')
            : t('reports.detailsPage.excludeModal.include.headline'),
        content: value
            ? t('reports.detailsPage.excludeModal.exclude.content')
            : t('reports.detailsPage.excludeModal.include.content'),
        confirmButtonText: t('reports.detailsPage.excludeModal.confirmButtonText'),
        onConfirm: async () => {
            try {
                await mutateAsync({
                    analyticalNumber: reportNumber.value,
                    isExcluded: value,
                })
            } catch (err) {
                if (err instanceof AxiosError) {
                    toasts?.error({
                        message: t('common.errors.unknown'),
                        duration: 3000,
                    })
                }
            }
        },
    })
}
</script>

<style scoped lang="scss">
.actions-section {
    display: flex;
    align-items: center;
    gap: $spacing-s;
}
</style>
