import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { RecommendationAddDto } from '@/api/generated/HCA'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useRecommendationsAddMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { componentSid: string; data: RecommendationAddDto }) =>
            hcaClientFactory.recommendationsApi.addRecommendation(payload.componentSid, payload.data),
        onSuccess: async (response, payload) => {
            await queryClient.invalidateQueries({ queryKey: ['recommendations', payload.componentSid] })
        },
    })
}
