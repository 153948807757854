export enum TransformerAgeType {
    All = 1,
    '0-30',
    '31-60',
    '61+',
}

export const TransformerAgeTypes = [
    {
        labelKey: 'common.transformerAgeTypes.all',
        value: TransformerAgeType.All,
    },
    {
        labelKey: 'common.transformerAgeTypes.0-30',
        value: TransformerAgeType['0-30'],
    },
    {
        labelKey: 'common.transformerAgeTypes.31-60',
        value: TransformerAgeType['31-60'],
    },
    {
        labelKey: 'common.transformerAgeTypes.61+',
        value: TransformerAgeType['61+'],
    },
] as const
