import '@/styles/main.scss'
import '@pebble/core'
import '@pebble/tables/dist/pebble-tables.css'
import { createApp } from 'vue'
import App from './App.vue'
import { clickOutside } from '@/directives/click-outside'
import { createPinia } from 'pinia'
import { router } from '@/router'
import { pebbleToastsPlugin } from '@/plugins/pebble-toasts-plugin'
import { i18n } from '@/utils/i18n'
import { VueQueryPlugin } from '@tanstack/vue-query'
import FloatingVue from 'floating-vue'

const app = createApp(App)

app.use(FloatingVue)
app.use(VueQueryPlugin)
app.use(i18n)
app.use(createPinia())
app.use(router)
app.use(pebbleToastsPlugin, {
    position: 'bottom-right',
    duration: 2000,
})

app.directive('click-outside', clickOutside)

app.mount('#app')
