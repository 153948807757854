<template>
    <LoadingPanel
        :headline="t('recommendations.table.headline')"
        :is-pending="isPending"
        :is-error="isError"
    >
        <div
            v-if="tableRowData.length > 0"
            class="recommendations-table"
        >
            <div ref="tableRef" />
        </div>
        <div
            v-else
            class="recommendations-table__empty-state"
        >
            <pbl-icon name="x-circle" />
            <span>{{ t('recommendations.table.noData') }}</span>
        </div>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PebbleTable } from '@pebble/tables'
import { CellComponent, Options } from 'tabulator-tables'
import { DATE_FORMAT, DATE_FORMAT_API } from '@/utils/date-utils'
import { useI18n } from 'vue-i18n'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { endOfMonth, format, parseISO, startOfMonth } from 'date-fns'
import { useRecommendationParams } from '@/composables/useRecommendationParams'
import { useRecommendationsListQuery } from '@/api/queries/recommendations/useRecommendationsListQuery'
import { router } from '@/router'
import { ComponentTypes } from '@/configuration/component/component-types'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import { flattenPlantsHierarchy, usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'
import { Eye, createElement } from 'lucide'
import { useConditionStatusViewRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/view-recommendation/useConditionStatusViewRecommendationModal'

const recommendationParams = useRecommendationParams()

const { t, locale } = useI18n()

const viewRecommendationModal = useConditionStatusViewRecommendationModal()

const startDateFormat = computed(() => {
    const startDate = recommendationParams.value.startDate
    if (!startDate) {
        return undefined
    }

    const isoDate = startOfMonth(new Date(startDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
})

const endDateFormat = computed(() => {
    const endDate = recommendationParams.value.endDate
    if (!endDate) {
        return undefined
    }

    const isoDate = endOfMonth(new Date(endDate)).toISOString()
    const parsedDate = parseISO(isoDate)
    return format(parsedDate, DATE_FORMAT_API)
})

const plantList = computed(() => {
    if (!plantsData.value?.plantGroups) {
        return []
    }

    return flattenPlantsHierarchy(plantsData.value.plantGroups)
})

const plantSids = computed(() => {
    if (recommendationParams.value.plantSid.length > 0) {
        return recommendationParams.value.plantSid
    }

    return plantList.value
        .filter((e) => recommendationParams.value.plantGroupSid.includes(e.plantGroup.sid))
        .map((e) => e.plant.sid)
})

const { data: plantsData } = usePlantsHierarchyQuery()
const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useRecommendationsListQuery(
    plantSids,
    recommendationParams.value.componentType,
    startDateFormat,
    endDateFormat,
)

const tableRef = ref<HTMLDivElement | null>(null)
const pebbleTable = ref<PebbleTable | null>(null)

const tableRowData = computed(() => {
    if (!data.value?.items || !constantsData.value) {
        return []
    }

    return data.value.items
})

watch([tableRef, tableRowData, locale], ([newTableRef, newTableRowData]) => {
    if (!newTableRef) {
        return
    }

    const tabulatorOptions: Options = {
        layout: 'fitDataTable',
        height: '100%',
        pagination: true,
        paginationSize: 20,
        reactiveData: true,
        data: newTableRowData,
        columns: [
            {
                title: '',
                field: 'recommendationId',
                headerSort: false,
                formatter: (cell: CellComponent) => {
                    const recommendationId = cell.getValue()

                    const cellDiv = document.createElement('div')

                    const viewIcon = createElement(Eye)
                    viewIcon.addEventListener(
                        'click',
                        () => viewRecommendationModal?.openViewRecommendation(recommendationId),
                    )
                    viewIcon.classList.add('recommendation-action-cell-icon')
                    cellDiv.appendChild(viewIcon)

                    return cellDiv
                },
            },
            {
                title: t('recommendations.table.headers.plantGroupName'),
                field: 'plantGroupName',
            },
            {
                title: t('recommendations.table.headers.plantName'),
                field: 'plantName',
            },
            {
                title: t('recommendations.table.headers.componentType'),
                field: 'componentType',
                formatter(cell: CellComponent) {
                    const componentType = ComponentTypes.find((e) => e.value === cell.getValue())

                    if (!componentType) {
                        return '-'
                    }

                    return t(componentType.labelKey)
                },
            },
            {
                title: t('recommendations.table.headers.componentSid'),
                field: 'componentSid',
                formatter: (cell: CellComponent) => {
                    const componentSid = cell.getValue()

                    const cellDiv = document.createElement('div')
                    cellDiv.className = 'recommendations-table-cell'

                    const linkSpan = document.createElement('span')
                    linkSpan.textContent = componentSid
                    linkSpan.classList.add('recommendations-table-cell__link')
                    linkSpan.addEventListener('click', openStatusPage)
                    cellDiv.appendChild(linkSpan)

                    return cellDiv
                },
            },
            {
                title: t('recommendations.table.headers.componentName'),
                field: 'componentName',
            },
            {
                title: t('recommendations.table.headers.date'),
                field: 'date',
                formatter: (cell: CellComponent) => {
                    return format(new Date(cell.getValue()), DATE_FORMAT)
                },
            },
            {
                title: t('recommendations.table.headers.recommendation'),
                field: 'recommendationTypeId',
                formatter: (cell: CellComponent) => {
                    const recommendationData = constantsData.value?.recommendationTypes?.find(
                        (e) => e.id === cell.getValue(),
                    )

                    if (cell.getValue() === OTHER_RECOMMENDATION_TYPE_ID) {
                        return cell.getRow().getData().freeText ?? '-'
                    }

                    if (!recommendationData) {
                        return '-'
                    }

                    return chooseLangString(locale, recommendationData)
                },
            },
            {
                title: t('recommendations.table.headers.sapNotificationNumber'),
                field: 'sapNotificationNumber',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().sapNotificationUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().sapNotificationUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('recommendations.table.headers.pspNumber'),
                field: 'pspNumber',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().pspNumberUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().pspNumberUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('recommendations.table.headers.ptRiskId'),
                field: 'ptRiskId',
                formatter: (cell: CellComponent) => {
                    const value = cell.getValue()
                    const url = cell.getRow().getData().ptRiskUrl

                    const cellDiv = document.createElement('div')
                    cellDiv.textContent = value || '-'

                    if (url) {
                        cellDiv.classList.add('recommendation-link-cell')
                    }

                    return cellDiv
                },
                cellClick: (event: UIEvent, cell: CellComponent) => {
                    const url = cell.getRow().getData().ptRiskUrl

                    if (!url) {
                        return
                    }

                    window.open(url)
                },
                minWidth: 100,
            },
            {
                title: t('recommendations.table.headers.createdByKid'),
                field: 'createdByKid',
            },
        ],
        initialSort: [{ column: 'date', dir: 'desc' }],
    }

    pebbleTable.value?.destroy()
    pebbleTable.value = new PebbleTable(newTableRef, tabulatorOptions)
})

const openStatusPage = (event: Event) => {
    const componentSid = (event.target as HTMLElement)?.innerText ?? ''
    router.push({
        name: 'Transformers_ConditionStatus',
        params: {
            transformerId: componentSid,
        },
    })
}

watch(
    [() => recommendationParams.value.componentId, pebbleTable],
    ([newComponentId, newPebbleTable]) => {
        if (!newPebbleTable) {
            return
        }

        if (!newComponentId) {
            pebbleTable.value?.clearFilter(true)
            return
        }

        pebbleTable.value?.setFilter('componentSid', 'like', newComponentId)
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.recommendations-table {
    margin: -($spacing-s);

    &__empty-state {
        height: 150px;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }

        span {
            font-size: 1rem;
        }
    }
}
</style>

<style lang="scss">
.recommendations-table-cell {
    &__link {
        color: $pbl-primary;
        cursor: pointer;
    }

    &__muted {
        color: $pbl-text-muted;
    }
}

.muted {
    color: $pbl-text-muted;
}
</style>
