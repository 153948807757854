<template>
    <LoadingPanel
        v-model:collapsed="collapsedState.oilAnalyses"
        :headline="t('transformers.conditionStatus.content.oilAnalyses.title')"
        :is-pending="isPending"
        :is-error="isError"
        collapsible
    >
        <template v-if="data">
            <div
                v-if="!isEveryAnalysisEmpty"
                class="analyses-tables"
            >
                <ConditionStatusAnalysisTable :analysis-type="AnalysisType.GasInOil" />
                <ConditionStatusAnalysisTable :analysis-type="AnalysisType.Furans" />
                <ConditionStatusAnalysisTable :analysis-type="AnalysisType.InsulatingOil" />
            </div>
            <EmptyStatePanelContent v-else />
        </template>
    </LoadingPanel>
</template>

<script setup lang="ts">
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import ConditionStatusAnalysisTable from '@/pages/transformers/condition-status/sections/analyses/ConditionStatusAnalysisTable.vue'
import { AnalysisType } from '@/configuration/analysis/analysis-types'
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useI18n } from 'vue-i18n'
import { useTransformerReportsQuery } from '@/api/queries/transformers/useTransformerReportsQuery'
import { useTransformerId } from '@/composables/useTransformerId'
import { computed } from 'vue'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { useConditionStatusCollapseStore } from '@/store/conditionStatusCollapseStore'
import { storeToRefs } from 'pinia'

const conditionStatusCollapseStore = useConditionStatusCollapseStore()
const { collapsedState } = storeToRefs(conditionStatusCollapseStore)

const { t } = useI18n()
const transformerId = useTransformerId()
const { data, isPending, isError } = useConstantsQuery()

const { data: gasInOilData } = useTransformerReportsQuery(transformerId, AnalysisType.GasInOil)
const { data: furansData } = useTransformerReportsQuery(transformerId, AnalysisType.Furans)
const { data: insulatingOilData } = useTransformerReportsQuery(transformerId, AnalysisType.InsulatingOil)

const isEveryAnalysisEmpty = computed(
    () =>
        gasInOilData.value?.reports?.length === 0 &&
        furansData.value?.reports?.length === 0 &&
        insulatingOilData.value?.reports?.length === 0,
)
</script>

<style scoped lang="scss">
.analyses-tables {
    display: flex;
    flex-direction: column;
    gap: $spacing-l;

    padding-top: $spacing-2xs;
}
</style>
