<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('admin.recommendationTypes.recommendationModal.title.edit')"
            click-shield
            class="recommendation-dialog"
        >
            <QueryLoaderGuard
                :is-pending="isPending"
                :is-error="isError"
            >
                <div class="recommendation-dialog__modal">
                    <RecommendationTypeModalStaticFormFields />
                </div>
                <pbl-button
                    slot="footer"
                    variant="secondary"
                    @click="closeModal"
                >
                    {{ t('admin.recommendationTypes.recommendationModal.actions.cancel') }}
                </pbl-button>
                <pbl-button
                    slot="footer"
                    :disabled="!meta.dirty || isEditPending"
                    :loading="isEditPending"
                    @click="onSubmit"
                >
                    {{ t('admin.recommendationTypes.recommendationModal.actions.save') }}
                </pbl-button>
            </QueryLoaderGuard>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { provideConditionStatusEditRecommendationTypeModal } from '@/pages/admin/recommendation-types/modal/edit-recommendation/useConditionStatusEditRecommendationTypeModal'
import { useI18n } from 'vue-i18n'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { ref, watch } from 'vue'
import { PebbleDialog } from '@pebble/core'
import RecommendationTypeModalStaticFormFields from '@/pages/admin/recommendation-types/modal/RecommendationTypeModalStaticFormFields.vue'
import { useField, useForm } from 'vee-validate'
import { editRecommendationTypeValidationSchema } from '@/pages/admin/recommendation-types/modal/ConditionStatusRecommendationTypeModal.form'
import { useRecommendationTypeQuery } from '@/api/queries/admin/recommendation-types/useRecommendationTypeQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { useRecommendationEditMutation } from '@/api/mutations/admin/recommendation-types/useRecommendationTypesEditMutation'
import { RecommendationTypeRequestDto } from '@/api/generated/HCA'
import { AxiosError } from 'axios'

const { t } = useI18n()
const toasts = usePebbleToasts()

const dialogRef = ref<PebbleDialog | null>(null)

const { handleSubmit, resetForm, meta } = useForm({
    validationSchema: editRecommendationTypeValidationSchema,
})

const { value: recommendationId } = useField<number>(() => 'recommendationId')

const { data, isPending, isError } = useRecommendationTypeQuery(recommendationId)
const { mutateAsync: mutateEditAsync, isPending: isEditPending } = useRecommendationEditMutation()

const openEditRecommendation = (id: number) => {
    resetForm({
        values: {
            recommendationId: id,
        },
    })

    populateForm()

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const onSubmit = handleSubmit(async (values) => {
    try {
        const data: RecommendationTypeRequestDto = {
            name: values.englishName,
            germanName: values.germanName,
        }

        await mutateEditAsync({
            id: values.recommendationId,
            data,
        })

        closeModal()
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

const populateForm = () => {
    const formData = data.value

    if (!formData || formData.id !== recommendationId.value) {
        return
    }

    resetForm({
        values: {
            recommendationId: formData.id ?? 0,
            englishName: formData.englishName ?? '',
            germanName: formData.germanName ?? '',
        },
    })
}

watch(data, (newData) => {
    if (!newData) {
        return
    }

    populateForm()
})

provideConditionStatusEditRecommendationTypeModal({
    openEditRecommendation,
})
</script>

<style scoped lang="scss">
.recommendation-dialog {
    --width: 70vw;
    &__modal {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacing-s $spacing-m;
    }
}
</style>
