import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'

export const usePortfolioGroupSummaryQuery = () => {
    return useQuery({
        queryKey: ['transformers', 'portfolioOverview', 'groupSummary'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.portfolioOverviewApi.getPlantGroupPortfolioSummary(
                {},
                {
                    signal,
                },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
