<template>
    <div class="await-selection-panel-content">
        <pbl-icon name="check2-square" />
        <span>{{ t('common.awaitSelection') }}</span>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>

<style scoped lang="scss">
.await-selection-panel-content {
    height: 200px;
    min-width: 400px;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    gap: $spacing-s;

    color: $pbl-text-muted;

    pbl-icon {
        --size: 2rem;
    }

    span {
        font-size: 1rem;
    }
}
</style>
