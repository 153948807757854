import { MaybeRef, toValue } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'

export const useReportGetQuery = (reportNumber: MaybeRef<string>, reportType: MaybeRef<number>) => {
    return useQuery({
        queryKey: ['reports', 'details', reportNumber, reportType],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.reportsApi.getReport(toValue(reportNumber), toValue(reportType), { signal })
            return result
        },
        enabled: whenAuthenticated,
    })
}
