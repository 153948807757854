import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { toValue, MaybeRef } from 'vue'

export const useRecommendationTypesListQuery = (
    pageNumber: MaybeRef<number | undefined>,
    pageSize: MaybeRef<number | undefined>,
) => {
    return useQuery({
        queryKey: ['recommendation-types', pageNumber, pageSize, 'list-recommendations'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.recommendationTypesApi.listRecommendationTypes(
                toValue(pageNumber),
                toValue(pageSize),
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        enabled: whenAuthenticated,
    })
}
