﻿<template>
    <FormFieldWrapper
        :input-id="inputId"
        :label="label"
        :required="required"
        :hide-required="hideRequired"
    >
        <pbl-multiselect
            :key="locale"
            searchable
            :disabled="isDisabled"
            @change="onPblMultiselectChange"
        >
            <pbl-option value="">{{ t('formInputs.common.placeholders.selectOption') }}</pbl-option>
            <pbl-option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
                :selected="modelValue?.includes(option.value)"
            >
                {{ option.label }}
            </pbl-option>
        </pbl-multiselect>
    </FormFieldWrapper>
</template>
<script setup lang="ts" generic="T, E">
import FormFieldWrapper from '@/components/form-inputs/form-field-wrapper/FormFieldWrapper.vue'
import { SelectOption, SelectOptions } from '@/components/form-inputs/common/select-option'
import { PebbleOption } from '@pebble/core'
import { useI18n } from 'vue-i18n'

const { t, locale } = useI18n()

defineProps<{
    modelValue?: T[]
    isDisabled?: boolean
    inputId: string
    label: string
    required?: boolean
    invalid?: boolean
    errorMessage?: string
    hideRequired?: boolean
    options: SelectOptions<T, E>
}>()

const emit = defineEmits<{
    'update:modelValue': [SelectOptions<T, E>]
}>()

const onPblMultiselectChange = (event: CustomEvent<{ targets: PebbleOption[] }>) => {
    emit('update:modelValue', event.detail.targets.map((e) => e.value) as unknown as SelectOption<T, E>[])
}
</script>
