export enum ComponentType {
    Transformer = 1,
}

export const ComponentTypes = [
    {
        labelKey: 'common.componentTypes.transformer',
        value: ComponentType.Transformer,
    },
] as const
