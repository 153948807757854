<template>
    <QueryLoaderGuard
        :is-pending="isPending"
        :is-error="isError"
    >
        <template
            v-for="analysis in analysisTypes"
            :key="analysis.analysisType"
        >
            <ThresholdsPageOilAnalysisTable
                :data="analysis"
                :hide-actions="hideActions"
            />
        </template>
    </QueryLoaderGuard>
</template>

<script setup lang="ts">
import { useListThresholdsQuery } from '@/api/queries/admin/thresholds/useListThresholdsQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { computed } from 'vue'
import ThresholdsPageOilAnalysisTable from '@/pages/admin/thresholds/sections/ThresholdsPageOilAnalysisTable.vue'

defineProps<{
    hideActions?: boolean
}>()

const { data, isPending, isError } = useListThresholdsQuery()

const analysisTypes = computed(() => data?.value?.thresholdsAnalysis ?? [])
</script>
