export enum Permission {
    // Read Permissions
    CanViewTransformerData = 'CanViewTransformerData',
    CanViewReports = 'CanViewReports',
    CanViewRecommendations = 'CanViewRecommendations',
    CanExportReports = 'CanExportReports',

    // Write Permissions
    CanEditTransformerRecommendations = 'CanEditTransformerRecommendations',
    CanEditTransformerComments = 'CanEditTransformerComments',
    CanOverrideTransformerConditionStatus = 'CanOverrideTransformerConditionStatus',
    CanExcludeReports = 'CanExcludeReports',
    CanEditThresholdsAndRecommendations = 'CanEditThresholdsAndRecommendations',
}
