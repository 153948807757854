<template>
    <LoadingPanel
        :headline="t('transformers.conditionStatus.content.visualInspections.status.label')"
        :is-pending="isPending"
        :is-error="isError"
        :hide-content="visualInspectionData.length === 0"
    >
        <div
            v-if="visualInspectionData.length !== 0"
            class="visual-inspection-section"
        >
            <template
                v-for="(visualInspection, index) in visualInspectionData"
                :key="visualInspection.id"
            >
                <div class="visual-inspection-section__report">
                    <div class="visual-inspection-section__item-horizontal-list">
                        <div class="visual-inspection-section__item">
                            <div class="visual-inspection-section__item-title">
                                {{ t('transformers.conditionStatus.content.recommendations.table.headers.date') }}
                            </div>
                            <div class="visual-inspection-section__item-value">
                                {{ visualInspection.date }}
                            </div>
                        </div>
                        <div class="visual-inspection-section__item">
                            <div class="visual-inspection-section__item-title">
                                {{ t('transformers.conditionStatus.content.comments.table.headers.reportNumber') }}
                            </div>
                            <div class="visual-inspection-section__item-value">
                                {{ visualInspection.reportNumber }}
                            </div>
                        </div>
                    </div>
                    <template
                        v-for="(parameter, parameterIndex) in visualInspection.parameters"
                        :key="parameter.id"
                    >
                        <div class="visual-inspection-section__item-inspections-list">
                            <div class="visual-inspection-section__item">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.measurement') }}
                                </div>
                                <div class="visual-inspection-section__item-value">
                                    {{ parameter.measurement }}
                                </div>
                            </div>
                            <div class="visual-inspection-section__item">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.status') }}
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Normal"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.normal') }}
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Critical"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.critical') }}
                                    <AlertCircle
                                        class="visual-inspection-section__item-icon visual-inspection-section__item-icon--critical"
                                    />
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.Inferior"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.inferior') }}
                                    <AlertTriangle
                                        class="visual-inspection-section__item-icon visual-inspection-section__item-icon--inferior"
                                    />
                                </div>
                                <div
                                    v-if="parameter.status === ConditionStatus.None"
                                    class="visual-inspection-section__item-value"
                                >
                                    {{ t('common.statusTypes.none') }}
                                </div>
                            </div>
                            <div class="visual-inspection-section__item visual-inspection-section__item--wide">
                                <div class="visual-inspection-section__item-title">
                                    {{ t('transformers.conditionStatus.content.visualInspections.table.comment') }}
                                </div>
                                <div class="visual-inspection-section__item-value">
                                    {{ parameter.comment }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="parameterIndex !== visualInspection.parameters.length - 1"
                            class="visual-inspection-section__separator--muted visual-inspection-section__separator"
                        />
                    </template>
                    <div
                        v-if="index !== visualInspectionData.length - 1"
                        class="visual-inspection-section__separator"
                    />
                </div>
            </template>
        </div>
        <EmptyStatePanelContent v-else />
        <template #context>
            <ColorBadge
                :color="reportStatus.color"
                :text="t(reportStatus.labelKey)"
                small
            />
        </template>
    </LoadingPanel>
</template>

<script setup lang="ts">
import LoadingPanel from '@/components/panel/LoadingPanel.vue'
import { useI18n } from 'vue-i18n'
import { useTransformerId } from '@/composables/useTransformerId'
import ColorBadge from '@/components/badge/ColorBadge.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { useTransformerVisualInspectionQuery } from '@/api/queries/transformers/useTransformerVisualInspectionsQuery'
import { useTransformerComponentStatusQuery } from '@/api/queries/transformers/useTransformerComponentStatusQuery'
import { computed } from 'vue'
import { AnalysisType } from '@/configuration/analysis/analysis-types'
import { ConditionStatus, StatusTypes } from '@/configuration/status/condition-status'
import EmptyStatePanelContent from '@/components/states/EmptyStatePanelContent.vue'
import { AlertTriangle, AlertCircle } from 'lucide-vue-next'
import { format } from 'date-fns'
import { DATE_FORMAT } from '@/utils/date-utils'

const MAX_REPORT_NUMBER = 1

type NormalizedVisualInspectionParameter = {
    id: number
    measurement: string
    status: ConditionStatus
    comment: string
}

type NormalizedVisualInspectionData = {
    id: number
    reportNumber: string
    date: string
    parameters: NormalizedVisualInspectionParameter[]
}

const { t } = useI18n()
const transformerId = useTransformerId()

const { data: statusData } = useTransformerComponentStatusQuery(transformerId)
const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useTransformerVisualInspectionQuery(transformerId)

const reportStatus = computed(() => {
    const status =
        statusData.value?.latestConditionStatuses?.find((e) => e.reportType === AnalysisType.VisualInspection)
            ?.status ?? ConditionStatus.None
    return StatusTypes[status ?? ConditionStatus.None]
})

const visualInspectionData = computed<NormalizedVisualInspectionData[]>(() => {
    if (!data.value?.items || !constantsData.value) {
        return []
    }

    const normalizedVisualInspections: NormalizedVisualInspectionData[] = []

    data.value.items.slice(0, MAX_REPORT_NUMBER).forEach((visualInspection) => {
        const visualInspectionParameters: NormalizedVisualInspectionParameter[] = []

        visualInspection.inspectionParameterValues?.forEach((parameter) => {
            const parameterEntry = constantsData.value?.visualInspectionParameters?.find(
                (e) => e.id === parameter.parameterId,
            )

            if (!parameterEntry) {
                return
            }

            visualInspectionParameters.push({
                id: parameter.id ?? -1,
                measurement: t(
                    `transformers.conditionStatus.content.visualInspections.table.measurementPoints.${parameterEntry.name}`,
                ),
                status: StatusTypes.find((e) => e.value === parameter.status)?.value ?? ConditionStatus.None,
                comment: parameter.comment ?? '-',
            })
        })

        normalizedVisualInspections.push({
            id: visualInspection.id ?? -1,
            reportNumber: visualInspection.analyticalNumber ?? '-',
            date: format(new Date(visualInspection.date ?? new Date()), DATE_FORMAT),
            parameters: visualInspectionParameters,
        })
    })

    return normalizedVisualInspections
})
</script>

<style scoped lang="scss">
.visual-inspection-section {
    display: flex;
    flex-direction: column;

    gap: $spacing-xs;

    &__report {
        display: flex;
        flex-direction: column;
        gap: $spacing-xs;
    }

    &__item {
        break-inside: avoid;

        &-title {
            font-weight: 500;
            font-size: $default-font-size;
        }

        &-value {
            display: flex;
            align-items: center;

            gap: $spacing-2xs;

            font-size: $default-font-size;
        }

        &-meta {
            font-size: $default-font-size;
            color: $pbl-text-muted;
        }

        &-icon {
            height: 20px;
            width: 20px;

            &--critical {
                color: $status-critical;
            }

            &--inferior {
                color: $status-inferior;
            }
        }

        &--wide {
            grid-column: span 3 / span 3;
        }
    }

    &__item-horizontal-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__item-inspections-list {
        break-inside: avoid;

        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: $spacing-l;
    }

    &__separator {
        height: 1px;
        width: 100%;
        background-color: $pbl-border-muted;

        margin: $spacing-s 0;

        &--muted {
            margin: 0;

            background-color: $pbl-foreground-muted;
        }
    }
}
</style>
