<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('reports.export.modal.headline')"
            click-shield
            class="export-dialog"
        >
            <div class="export-modal">
                <VeeFormSingleSelectField
                    name="exportType"
                    :label="t('reports.export.modal.selector.labels.exportType')"
                    :placeholder="t('formInputs.common.placeholders.selectOption')"
                    :options="exportTypeOptions"
                    :disabled="exportTypeOptions.length === 0"
                />
                <div
                    v-if="exportType"
                    :key="`${exportType}`"
                    class="export-modal__selector"
                >
                    <VeeFormSingleSelectField
                        name="groupSid"
                        :label="t('reports.export.modal.selector.labels.group')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="groupOptions"
                        :disabled="groupOptions.length === 0"
                    />
                    <div :key="`${plantOptions.toString()}`">
                        <VeeFormMultiselectField
                            v-if="isOilOrSummaryStatus"
                            name="plantSid"
                            input-id="plantSid"
                            :label="t('reports.export.modal.selector.labels.plant')"
                            :placeholder="t('formInputs.common.placeholders.selectOption')"
                            :options="plantOptions"
                            :disabled="plantOptions.length === 0 || !groupSid"
                        />
                        <VeeFormSingleSelectField
                            v-else
                            name="plantSid"
                            input-id="plantSid"
                            :label="t('reports.export.modal.selector.labels.plant')"
                            :placeholder="t('formInputs.common.placeholders.selectOption')"
                            :options="plantOptions"
                            :disabled="plantOptions.length === 0 || !groupSid"
                        />
                    </div>
                    <VeeFormSingleSelectField
                        name="componentTypes"
                        :label="t('reports.export.modal.selector.labels.componentTypes')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="componentTypeOptions"
                        :disabled="componentTypeOptions.length === 0"
                    />
                    <VeeFormSingleSelectField
                        :key="`${transformerListOptions.toString()}`"
                        name="componentSids"
                        input-id="componentSids"
                        :label="t('reports.export.modal.selector.labels.componentSids')"
                        :placeholder="t('formInputs.common.placeholders.selectOption')"
                        :options="transformerListOptions"
                        :disabled="transformerListOptions.length === 0 || isOilOrSummaryStatus"
                    />
                    <VeeFormInputField
                        name="startDate"
                        type="month"
                        :label="t('reports.export.modal.selector.labels.startDate')"
                        :disabled="isOilOrSummaryStatus"
                    />
                    <VeeFormInputField
                        name="endDate"
                        type="month"
                        :label="t('reports.export.modal.selector.labels.endDate')"
                        :disabled="isOilOrSummaryStatus"
                    />
                </div>
                <div
                    v-else
                    class="export-modal__empty-state"
                >
                    <pbl-icon name="check2-square" />
                    <span>
                        {{ t('reports.export.modal.emptyStateBody') }}
                    </span>
                </div>
            </div>
            <pbl-button
                slot="footer"
                variant="secondary"
                @click="closeModal"
            >
                {{ t('reports.export.modal.actions.cancel') }}
            </pbl-button>
            <pbl-button
                slot="footer"
                :disabled="!meta.dirty || isExportPending"
                :loading="isExportPending"
                @click="onSubmit"
            >
                {{ t('reports.export.modal.actions.export') }}
            </pbl-button>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { useField, useForm } from 'vee-validate'
import { provideReportsExportModal } from '@/pages/reports/export/useReportsExportModal'
import { exportValidationSchema } from '@/pages/reports/export/ReportsExportModal.form'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { usePlantsHierarchyQuery } from '@/api/queries/plants/usePlantsHierarchyQuery'
import VeeFormSingleSelectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormSingleSelectField.vue'
import VeeFormMultiselectField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormMultiselectField.vue'
import VeeFormInputField from '@/components/form-inputs/vee-wrapped-form-inputs/VeeFormInputField.vue'
import { ComponentType, ComponentTypes } from '@/configuration/component/component-types'
import {
    flattenTransformersHierarchy,
    transformerOptions,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import { TIMEFRAME_DEFAULT_END_DATE, endDateFormat, startDateFormat, TIMEFRAME_OLDEST_RECORD } from '@/utils/date-utils'
import {
    AllGroupsOptionExceptionForExportType,
    ReportExportType,
    ReportExportTypes,
} from '@/configuration/report/report-export-types'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'
import { useReportsExportMutation } from '@/api/queries/reports/useReportsExportQuery'
import { AxiosError, AxiosHeaders } from 'axios'
import { downloadFile, getFilename } from '@/utils/file-utils'
import { LanguageType } from '@/configuration/language/language-types'

const { t, locale } = useI18n()
const toasts = usePebbleToasts()

const dialogRef = ref<PebbleDialog | null>(null)
const exportTypeRef = ref<number | undefined>(undefined)

const ALL_ITEMS_VALUE = -1

const { handleSubmit, resetForm, meta, resetField } = useForm({
    validationSchema: computed(() => {
        return exportValidationSchema(exportTypeRef.value ?? ReportExportType.OilAnalysisData)
    }),
    initialValues: {
        exportType: undefined,
        groupSid: undefined,
        plantSid: undefined,
        componentTypes: ComponentType.Transformer,
        componentSids: undefined,
        startDate: TIMEFRAME_OLDEST_RECORD,
        endDate: TIMEFRAME_DEFAULT_END_DATE,
    },
})

const { value: exportType } = useField<number | undefined>(() => 'exportType')
const { value: groupSid } = useField<number | undefined>(() => 'groupSid')
const { value: plantSid } = useField<number | number[] | undefined>(() => 'plantSid')
const { value: componentSids } = useField<string | undefined>(() => 'componentSids')

const { data: exportedReport, mutateAsync: mutateExportAsync, isPending: isExportPending } = useReportsExportMutation()

const { data } = usePlantsHierarchyQuery()

const exportTypeOptions = computed<SelectOptions<number>>(
    () =>
        ReportExportTypes.map((e) => ({
            label: t(e.labelKey),
            value: e.value,
        })) ?? [],
)

const groupOptions = computed<SelectOptions<number>>(() => {
    if (!exportType.value || !data.value?.plantGroups) {
        return []
    }

    const groupPlants =
        data.value?.plantGroups?.map((e) => ({
            label: e.name ?? '-',
            value: e.sid ?? 0,
        })) ?? []

    const allGroupsOption = {
        label: t('reports.export.modal.selector.options.allGroups'),
        value: ALL_ITEMS_VALUE,
    }

    return AllGroupsOptionExceptionForExportType.includeAllGroupsOption.includes(exportType.value)
        ? [allGroupsOption, ...groupPlants]
        : groupPlants
})

const plantOptions = computed<SelectOptions<number>>(() => {
    if (!groupSid.value) {
        return []
    }

    let selectedPlants = data.value?.plantGroups?.find((e) => groupSid.value === e.sid)?.plants ?? []

    if (groupSid.value === ALL_ITEMS_VALUE) {
        selectedPlants = data.value?.plantGroups?.flatMap((group) => group.plants ?? []) ?? []
    }

    return selectedPlants.map((plant) => ({
        label: plant.name ?? '-',
        value: plant.sid ?? 0,
    }))
})

const componentTypeOptions = computed<SelectOptions<number>>(
    () =>
        ComponentTypes?.map((e) => ({
            label: t(e.labelKey) ?? '-',
            value: e.value ?? 0,
        })) ?? [],
)

const { data: transformersHierarchy } = useTransformersHierarchyQuery()

const transformerList = computed(() => {
    if (!transformersHierarchy.value?.plantGroups) {
        return []
    }

    return flattenTransformersHierarchy(transformersHierarchy.value.plantGroups)
})

const transformerListOptions = computed((): SelectOptions => {
    if (plantSid.value) {
        const filteredTransformerList = transformerList.value.filter((e) => e.plant.sid === plantSid.value)
        return transformerOptions(filteredTransformerList)
    }

    return transformerOptions(transformerList.value)
})

const isOilOrSummaryStatus = computed(() => {
    return (
        exportType.value === ReportExportType.OilAnalysisStatus ||
        exportType.value === ReportExportType.SummaryComponentConditionStatus
    )
})

const openExport = () => {
    resetForm({
        values: {
            exportType: undefined,
            groupSid: undefined,
            plantSid: undefined,
            componentTypes: ComponentType.Transformer,
            componentSids: undefined,
            startDate: TIMEFRAME_OLDEST_RECORD,
            endDate: TIMEFRAME_DEFAULT_END_DATE,
        },
    })

    dialogRef.value?.show()
}

const closeModal = () => {
    dialogRef.value?.hide()
}

const plantSidValue = (plantSid: number | number[]) => {
    if (isOilOrSummaryStatus.value && Array.isArray(plantSid)) {
        return plantSid.length > 0 ? plantSid : plantOptions.value.map((e) => e.value)
    }

    return Array.isArray(plantSid) ? plantSid : [plantSid]
}

const componentSidsValue = (componentSids: string | string[]) => {
    return Array.isArray(componentSids) ? componentSids : [componentSids]
}

const languageType = computed(
    () => (LanguageType[locale.value as unknown as LanguageType] as unknown as number) ?? LanguageType['en-GB'],
)

const onSubmit = handleSubmit(async (values) => {
    const exportData = {
        exportType: values.exportType,
        plantSid: values.plantSid ? plantSidValue(values.plantSid) : undefined,
        componentTypes: values.componentTypes ? [values.componentTypes] : undefined,
        componentSids: values.componentSids ? componentSidsValue(values.componentSids) : undefined,
        startDate: values.startDate ? startDateFormat(values.startDate) : undefined,
        endDate: values.endDate ? endDateFormat(values.endDate) : undefined,
        languageType: languageType.value,
    }

    try {
        await mutateExportAsync(exportData)

        if (exportedReport.value?.data && exportedReport.value.headers) {
            const filename = getFilename(exportedReport.value.headers as AxiosHeaders) ?? 'unknown'
            downloadFile(exportedReport.value.data, filename)
            closeModal()
            toasts?.success({
                message: t('reports.export.modal.toast.exportCompleted'),
                duration: 3000,
            })
        }
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
})

watch(exportType, (newExportType) => {
    exportTypeRef.value = newExportType

    resetForm({
        values: {
            exportType: newExportType,
            groupSid: undefined,
            plantSid: isOilOrSummaryStatus.value ? [] : undefined,
            componentTypes: ComponentType.Transformer,
            componentSids: undefined,
            startDate: isOilOrSummaryStatus.value ? undefined : TIMEFRAME_OLDEST_RECORD,
            endDate: isOilOrSummaryStatus.value ? undefined : TIMEFRAME_DEFAULT_END_DATE,
        },
    })
})

watch(groupSid, () => {
    if (isOilOrSummaryStatus.value) {
        resetField('plantSid', { value: [] })
    }
})

watch(componentSids, (newComponentSids) => {
    if (newComponentSids) {
        const selectedTransformer = transformerList.value.find((e) => e.transformer.id === newComponentSids)

        if (!selectedTransformer) {
            resetField('plantSid')
            resetField('groupSid')
            return
        }

        resetField('groupSid', { value: selectedTransformer.plantGroup.sid })
        resetField('plantSid', { value: selectedTransformer.plant.sid })
    }
})

provideReportsExportModal({
    openExport,
})
</script>

<style scoped lang="scss">
.export-dialog {
    --width: 70vw;
}

.export-modal {
    display: flex;
    flex-direction: column;

    gap: $spacing-m;

    &__selector {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacing-s $spacing-m;

        &__full-width {
            grid-column: span 3 / span 3;
        }
    }

    &__empty-state {
        padding: 50px 0;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        color: $pbl-text-muted;

        pbl-icon {
            --size: 2rem;
        }
    }
}
</style>
