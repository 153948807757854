import { useQuery } from '@tanstack/vue-query'
import { MaybeRef, toValue } from 'vue'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformerComponentHistoricalQuery = (
    transformerId: MaybeRef<string>,
    languageType: MaybeRef<number>,
    mustIncludeTemperature: MaybeRef<boolean>,
    parameterIds: MaybeRef<Array<number> | undefined>,
    denominatorParameterIds: MaybeRef<Array<number> | undefined>,
    startDate: MaybeRef<string | undefined>,
    endDate: MaybeRef<string | undefined>,
    enabled: MaybeRef<boolean>,
) => {
    return useQuery({
        queryKey: [
            'transformers',
            transformerId,
            'historical',
            languageType,
            mustIncludeTemperature,
            parameterIds,
            denominatorParameterIds,
            startDate,
            endDate,
        ],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.componentStatusApi.getOilHistoricalTrends(
                toValue(transformerId),
                toValue(languageType),
                toValue(mustIncludeTemperature),
                toValue(parameterIds),
                toValue(denominatorParameterIds),
                toValue(startDate),
                toValue(endDate),
                { signal },
            )
            return result
        },
        enabled,
    })
}
