import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { ConditionStatusScrollState } from '@/configuration/internal-redirect-states/condition-status-scroll-state'

export const useConditionStatusCollapseStore = defineStore('conditionStatusCollapseStore', () => {
    const collapsedState = reactive({
        recommendations: true,
        oilAnalyses: true,
        comments: true,
        oilHistoricalTrends: true,
        visualInspections: true,
    })

    const setCollapseScrollState = (state: ConditionStatusScrollState) => {
        switch (state) {
        case ConditionStatusScrollState.OilAnalysis:
            collapsedState.oilAnalyses = false
            break
        case ConditionStatusScrollState.Comments:
            collapsedState.comments = false
            break
        case ConditionStatusScrollState.Recommendations:
            collapsedState.recommendations = false
            break
        case ConditionStatusScrollState.VisualInspection:
            collapsedState.visualInspections = false
            break
        case ConditionStatusScrollState.Default:
            break
        }
    }

    const resetCollapsedState = () => {
        collapsedState.recommendations = true
        collapsedState.oilAnalyses = true
        collapsedState.comments = true
        collapsedState.oilHistoricalTrends = true
        collapsedState.visualInspections = true
    }

    return {
        collapsedState,
        setCollapseScrollState,
        resetCollapsedState,
    }
})
