<template>
    <slot v-if="!isPending && !isError" />
    <div
        v-else-if="isPending"
        class="loader-guard__loading"
    >
        <pbl-spinner />
        <span>{{ t('loading.loading') }}</span>
    </div>
    <div
        v-else-if="isError"
        class="loader-guard__error"
    >
        <pbl-icon name="exclamation-triangle" />
        <span>{{ errorMessage ?? t('common.errors.unknown') }}</span>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

defineProps<{
    isPending?: boolean
    isError?: boolean
    errorMessage?: string
}>()

const { t } = useI18n()
</script>

<style scoped lang="scss">
.loader-guard {
    &__loading {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        min-height: 150px;
    }

    &__error {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        gap: $spacing-s;

        min-height: 150px;

        pbl-icon {
            --size: 2.5rem;
            color: $pbl-invalid;
        }

        span {
            color: $pbl-invalid-contrast;
        }
    }
}
</style>
