import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { CommentAddDto } from '@/api/generated/HCA'

export const useTransformersCommentsAddMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { transformerSid: string; commentData: CommentAddDto }) =>
            hcaClientFactory.commentsApi.addComment(payload.transformerSid, payload.commentData),
        onSuccess: async (response, payload) => {
            if (payload.commentData.analysisType) {
                await queryClient.invalidateQueries({
                    queryKey: ['transformers', payload.transformerSid, 'reports', payload.commentData.analysisType],
                })
            }

            await queryClient.invalidateQueries({ queryKey: ['transformers', payload.transformerSid, 'comments'] })
        },
    })
}
