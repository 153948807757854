import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { whenAuthenticated } from '@/utils/query-utils'
import { MaybeRef, toValue } from 'vue'

export const usePortfolioPlantStatusSummaryQuery = (plantGroupSid: MaybeRef<number>) => {
    return useQuery({
        queryKey: ['transformers', 'portfolioOverview', 'plantStatusSummary', plantGroupSid],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.portfolioOverviewApi.getPlantPortfolioSummary(toValue(plantGroupSid), { signal })
            return result
        },
        enabled: whenAuthenticated,
    })
}
