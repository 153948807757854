import { useQuery } from '@tanstack/vue-query'
import { whenAuthenticated } from '@/utils/query-utils'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useBookmarksListQuery = () => {
    return useQuery({
        queryKey: ['bookmarks'],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.bookmarksApi.listBookmarks({}, { signal })
            return result
        },
        enabled: whenAuthenticated,
    })
}
