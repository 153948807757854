<template>
    <div class="field-wrapper">
        <div class="field-wrapper__before">
            <span
                v-if="label"
                class="field-wrapper__before-label"
            >
                {{ label }}
            </span>
            <div class="field-wrapper__before-spacer" />
            <div
                v-if="ctaLabel"
                class="field-wrapper__before-button"
                @click="emit('click:cta')"
            >
                {{ ctaLabel }}
            </div>
        </div>
        <slot />
    </div>
</template>

<script setup lang="ts">
defineProps<{
    label?: string
    ctaLabel?: string
}>()

const emit = defineEmits<{
    'click:cta': []
}>()
</script>

<style scoped lang="scss">
.field-wrapper {
    display: flex;
    flex-direction: column;

    gap: $spacing-2xs;

    &__label {
        padding-left: 4px;
    }

    &__before {
        display: flex;
        align-items: baseline;
        gap: $spacing-2xs;

        &-spacer {
            flex-grow: 1;
        }

        &-button {
            font-size: 0.9em;
            color: $pbl-primary;
            cursor: pointer;
            user-select: none;

            &:hover {
                color: $pbl-primary-contrast;
            }
        }
    }
}
</style>
