<template>
    <div
        v-if="data"
        class="component-selector"
    >
        <FormSingleSelectField
            v-model="plantGroupSid"
            :label="t('transformers.conditionStatus.selector.labels.plantGroup')"
            :placeholder-text="t('transformers.conditionStatus.selector.placeholders.plantGroup')"
            :options="plantGroupOptions"
            class="component-selector__field"
        />
        <FormSingleSelectField
            v-model="plantSid"
            :label="t('transformers.conditionStatus.selector.labels.plant')"
            :placeholder-text="t('transformers.conditionStatus.selector.placeholders.plant')"
            :options="plantOptions"
            :disabled="!plantGroupSid"
            class="component-selector__field"
        />
        <FormSingleSelectField
            :model-value="modelValue"
            :label="t('transformers.conditionStatus.selector.labels.transformer')"
            :placeholder-text="t('transformers.conditionStatus.selector.placeholders.transformer')"
            :options="transformerOptions"
            :disabled="!plantSid"
            class="component-selector__field"
            @update:model-value="onUpdateTransformerValue"
        >
            <template #optionTrailing="{ option }">
                <pbl-icon
                    v-if="option.extraData?.isBookmarked"
                    class="component-selector__trailing-icon"
                    name="star-fill"
                />
            </template>
        </FormSingleSelectField>
        <div class="component-selector__buttons">
            <HorizontalButton
                :text="t('transformers.conditionStatus.selector.actions.reset')"
                :disabled="!isAbleToReset"
                icon-name="arrow-counterclockwise"
                @click="onSelectionReset"
            />
            <HorizontalButton
                v-if="modelValue"
                :text="t('transformers.conditionStatus.selector.actions.favourite')"
                icon-name="star-fill"
                :variant="!!bookmarkId ? 'primary' : 'secondary'"
                :loading="isPending || isAddPending || isDeletePending"
                @click="onFavouriteClicked"
            />
        </div>
    </div>
    <div
        v-else-if="isPending"
        class="loading-state"
    >
        <pbl-spinner />
        <span>
            {{ t('transformers.conditionStatus.selector.loading') }}
        </span>
    </div>
    <div
        v-else-if="isError"
        class="error-state"
    >
        <pbl-icon name="exclamation-triangle" />
        <span>
            {{ t('transformers.conditionStatus.selector.error') }}
        </span>
    </div>
</template>

<script setup lang="ts">
import {
    flattenTransformersHierarchy,
    useTransformersHierarchyQuery,
} from '@/api/queries/transformers/useTransformersHierarchyQuery'
import FormSingleSelectField from '@/components/form-inputs/form-single-select-field/FormSingleSelectField.vue'
import { computed, ref, watch } from 'vue'
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { useI18n } from 'vue-i18n'
import HorizontalButton from '@/components/buttons/HorizontalButton.vue'
import { useBookmarksAddMutation } from '@/api/mutations/bookmarks/useBookmarksAddMutation'
import { ComponentType } from '@/configuration/component/component-types'
import { useBookmarksDeleteMutation } from '@/api/mutations/bookmarks/useBookmarksDeleteMutation'
import { AxiosError } from 'axios'
import { usePebbleToasts } from '@/composables/pebble/usePebbleToasts'

const props = defineProps<{
    modelValue?: string
}>()

const emit = defineEmits<{
    'update:modelValue': [payload: string | undefined]
}>()

const toasts = usePebbleToasts()
const { t } = useI18n()

const { data, isPending, isError } = useTransformersHierarchyQuery()

const { mutateAsync: mutateAddAsync, isPending: isAddPending } = useBookmarksAddMutation()
const { mutateAsync: mutateDeleteAsync, isPending: isDeletePending } = useBookmarksDeleteMutation()

const plantGroupSid = ref<number | undefined>(undefined)
const plantSid = ref<number | undefined>(undefined)

const bookmarkId = computed(
    () => transformerList.value.find((e) => e.transformer.id === props.modelValue)?.transformer.bookmarkId,
)

const plantGroupOptions = computed<SelectOptions<number>>(
    () =>
        data.value?.plantGroups?.map((e) => ({
            label: e.name ?? '-',
            value: e.sid ?? 0,
        })) ?? [],
)

const plantOptions = computed<SelectOptions<number>>(() => {
    if (!plantGroupSid.value) {
        return []
    }

    const selectedPlantGroup = data.value?.plantGroups?.find((e) => e.sid === plantGroupSid.value)

    if (!selectedPlantGroup?.plants) {
        return []
    }

    return selectedPlantGroup.plants.map((e) => ({
        label: e.name ?? '-',
        value: e.sid ?? 0,
    }))
})

const transformerOptions = computed<SelectOptions<string, { isBookmarked: boolean }>>(() => {
    if (!plantSid.value) {
        return []
    }

    const selectedPlant = data.value?.plantGroups?.flatMap((e) => e.plants ?? [])?.find((e) => e.sid === plantSid.value)

    if (!selectedPlant?.transformers) {
        return []
    }

    const plantList = selectedPlant.transformers.map((e) => ({
        label:
            !e.functionalLocation || e.transformerSid === e.functionalLocation
                ? `${e.transformerSid} / -`
                : `${e.transformerSid} / ${e.functionalLocation}`,
        value: e.transformerSid ?? '',
        extraData: {
            isBookmarked: !!e.bookmarkId,
        },
    }))

    return plantList.toSorted((a) => {
        return a.extraData.isBookmarked ? -1 : 1
    })
})

const transformerList = computed(() => {
    if (!data.value?.plantGroups) {
        return []
    }

    return flattenTransformersHierarchy(data.value.plantGroups)
})

const isAbleToReset = computed(() => !!plantGroupSid.value || !!plantSid.value || !!props.modelValue)

const onUpdateTransformerValue = (value: string | undefined) => {
    emit('update:modelValue', value)
}

const onSelectionReset = () => {
    onUpdateTransformerValue(undefined)

    plantSid.value = undefined
    plantGroupSid.value = undefined
}

const onFavouriteClicked = async () => {
    if (isAddPending.value || isDeletePending.value) {
        return
    }

    try {
        if (bookmarkId.value) {
            await mutateDeleteAsync({
                bookmarkId: bookmarkId.value,
            })
        } else {
            await mutateAddAsync({
                bookmarkData: {
                    componentSid: props.modelValue,
                    componentType: ComponentType.Transformer,
                },
            })
        }
    } catch (err) {
        if (err instanceof AxiosError) {
            toasts?.error({
                message: t('common.errors.unknown'),
                duration: 3000,
            })
        }
    }
}

watch(
    [() => props.modelValue, transformerList],
    ([newModelValue, newTransformerList]) => {
        if (!newModelValue || newTransformerList.length === 0) {
            return
        }

        const selectedTransformer = transformerList.value.find((e) => e.transformer.id === newModelValue)

        if (!selectedTransformer) {
            onUpdateTransformerValue(undefined)
            return
        }

        plantGroupSid.value = selectedTransformer.plantGroup.sid
        plantSid.value = selectedTransformer.plant.sid
    },
    { immediate: true },
)
</script>

<style scoped lang="scss">
.component-selector {
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: $spacing-s;

    &__field {
        min-width: 300px;
    }

    &__buttons {
        display: flex;
        gap: $spacing-s;

        margin-top: 1.6rem;
        margin-left: $spacing-2xs;
    }

    &__trailing-icon {
        color: $pbl-primary;
    }
}

.loading-state {
    display: flex;

    align-items: center;
    gap: $spacing-xs;

    pbl-spinner {
        --size: 1.5rem;
    }
}

.error-state {
    display: flex;

    align-items: center;
    gap: $spacing-2xs;

    color: $pbl-invalid;

    pbl-icon {
        --size: 1.5rem;

        color: $pbl-invalid-contrast;
    }
}
</style>
