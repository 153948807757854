import { useQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'
import { computed, MaybeRef, toValue } from 'vue'
import { whenAuthenticatedAnd } from '@/utils/query-utils'

export const usePortfolioFlaggedTransformers = (
    issueType: MaybeRef<number | undefined>,
    plantSids: MaybeRef<number[]>,
) => {
    return useQuery({
        queryKey: ['transformers', 'portfolioOverview', 'flaggedTransformers', issueType, plantSids],
        queryFn: async ({ signal }) => {
            const {
                data: { result },
            } = await hcaClientFactory.portfolioOverviewApi.listFlaggedTransformers(
                toValue(plantSids) ?? [],
                toValue(issueType) ?? -1,
                0,
                0,
                undefined,
                undefined,
                { signal },
            )

            return result
        },
        enabled: whenAuthenticatedAnd(computed(() => !!toValue(issueType) && toValue(plantSids).length > 0)),
    })
}
