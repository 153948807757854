<template>
    <div class="main-layout">
        <GlobalHeader />
        <div class="main-layout__page-wrapper">
            <SidebarNavigation :navigation-structure="navigationStructure" />
            <div class="main-layout__page-wrapper__page">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import GlobalHeader from '@/components/header/GlobalHeader.vue'
import SidebarNavigation from '@/components/navigation/sidebar/SidebarNavigation.vue'
import { useSidebarNavigationConfiguration } from '@/configuration/navigation/sidebar-navigation-configuration'

const { navigationStructure } = useSidebarNavigationConfiguration()
</script>

<style scoped lang="scss">
.main-layout {
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    &__page-wrapper {
        max-height: calc(100vh - $navbar-height);

        flex: 1;
        display: flex;

        &__page {
            overflow: hidden;
            max-height: calc(100vh - $navbar-height);

            flex: 1;
        }
    }
}
</style>
