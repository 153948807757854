<template>
    <slot />
    <Teleport to="body">
        <pbl-dialog
            ref="dialogRef"
            :headline="t('transformers.conditionStatus.content.recommendationModal.title.view')"
            click-shield
            class="recommendation-dialog"
        >
            <QueryLoaderGuard
                :is-pending="isPending"
                :is-error="isError"
            >
                <div class="recommendation-modal">
                    <div class="recommendation-modal__grid">
                        <TextDataItem
                            :label="t('transformers.conditionStatus.content.recommendationModal.labels.powerPlant')"
                            :value="data?.powerPlant || '-'"
                        />
                        <TextDataItem
                            :label="t('transformers.conditionStatus.content.recommendationModal.labels.transformerId')"
                            :value="data?.componentSid ?? '-'"
                        />
                        <TextDataItem
                            class="recommendation-modal__grid__field--full-width"
                            :label="
                                t(
                                    'transformers.conditionStatus.content.recommendationModal.labels.recommendationTypeId',
                                )
                            "
                            :value="
                                recommendationTypeLabel(data?.recommendationTypeId ?? -1, data?.freeText ?? undefined)
                            "
                        />
                        <TextDataItem
                            :label="
                                t(
                                    'transformers.conditionStatus.content.recommendationModal.labels.sapNotificationNumber',
                                )
                            "
                            :value="data?.sapNotificationNumber || '-'"
                            :link="data?.sapNotificationUrl"
                        />
                        <TextDataItem
                            :label="t('transformers.conditionStatus.content.recommendationModal.labels.pspNumber')"
                            :value="data?.pspNumber || '-'"
                            :link="data?.pspNumberUrl"
                        />
                        <TextDataItem
                            :label="t('transformers.conditionStatus.content.recommendationModal.labels.ptRiskId')"
                            :value="data?.ptRiskId || '-'"
                            :link="data?.ptRiskUrl"
                        />
                        <TextDataItem
                            class="recommendation-modal__grid__field--full-width"
                            :label="t('transformers.conditionStatus.content.recommendationModal.labels.comment')"
                            :value="data?.comments?.[0]?.text || '-'"
                            :note="commentNote(data?.comments?.[0])"
                        />
                    </div>
                    <ContentPanel
                        v-if="data?.sapNotificationNumber"
                        :headline="
                            t('transformers.conditionStatus.content.recommendationModal.title.sapNotificationDetails')
                        "
                    >
                        <div
                            v-if="data.sapNotifcation"
                            class="recommendation-modal__grid"
                        >
                            <TextDataItem
                                :label="
                                    t(
                                        'transformers.conditionStatus.content.recommendationModal.labels.sapNotification.creationDate',
                                    )
                                "
                                :value="formatDate(data.sapNotifcation.creationDate)"
                            />
                            <TextDataItem
                                :label="
                                    t(
                                        'transformers.conditionStatus.content.recommendationModal.labels.sapNotification.author',
                                    )
                                "
                                :value="data.sapNotifcation.author || '-'"
                            />
                            <TextDataItem
                                :label="
                                    t(
                                        'transformers.conditionStatus.content.recommendationModal.labels.sapNotification.status',
                                    )
                                "
                                :value="data.sapNotifcation.systemStatusTextDe || '-'"
                            >
                                <template #context>
                                    <span class="recommendation-modal__grid__icon">
                                        <Info @click="systemStatusDescriptionModal?.openSystemStatusDescription()" />
                                    </span>
                                </template>
                            </TextDataItem>
                            <TextDataItem
                                class="recommendation-modal__grid__field--full-width"
                                :label="
                                    t(
                                        'transformers.conditionStatus.content.recommendationModal.labels.sapNotification.description',
                                    )
                                "
                                :value="data.sapNotifcation.notificationDescription || '-'"
                            />
                        </div>
                        <div
                            v-else
                            class="recommendation-modal__no-notification"
                        >
                            {{
                                t(
                                    'transformers.conditionStatus.content.recommendationModal.labels.sapNotification.noNotification',
                                )
                            }}
                        </div>
                    </ContentPanel>
                </div>
                <div
                    slot="footer"
                    class="recommendation-modal__footer"
                >
                    <span
                        v-if="!!data?.date && !!data.kid"
                        class="recommendation-modal__footer__meta"
                    >
                        {{ t('transformers.conditionStatus.content.recommendationModal.metadata.lastUpdated') }}:
                        {{ `${formatDate(data.date)} | KID ${data.kid}` }}
                    </span>
                </div>
            </QueryLoaderGuard>
        </pbl-dialog>
    </Teleport>
</template>

<script setup lang="ts">
import { provideConditionStatusViewRecommendationModal } from '@/pages/transformers/condition-status/modals/recommendation/view-recommendation/useConditionStatusViewRecommendationModal'
import { useI18n } from 'vue-i18n'
import ContentPanel from '@/components/panel/ContentPanel.vue'
import { ref } from 'vue'
import { PebbleDialog } from '@pebble/core'
import { useRecommendationQuery } from '@/api/queries/recommendations/useRecommendationQuery'
import QueryLoaderGuard from '@/components/guards/QueryLoaderGuard.vue'
import { RecommendationCommentDto } from '@/api/generated/HCA'
import { DATE_TIME_FORMAT } from '@/utils/date-utils'
import { format } from 'date-fns'
import TextDataItem from '@/components/text-data/TextDataItem.vue'
import { useConstantsQuery } from '@/api/queries/general/useConstantsQuery'
import { chooseLangString } from '@/utils/i18n-utils'
import { OTHER_RECOMMENDATION_TYPE_ID } from '@/configuration/recommendation/recommendation-constants'
import { Info } from 'lucide-vue-next'
import { useSystemStatusDescriptionModal } from '@/pages/transformers/condition-status/modals/recommendation/system-status-description/useSystemStatusDescriptionModal'

const { t, locale } = useI18n()

const dialogRef = ref<PebbleDialog | null>(null)

const systemStatusDescriptionModal = useSystemStatusDescriptionModal()

const recommendationId = ref<number>(0)

const { data: constantsData } = useConstantsQuery()
const { data, isPending, isError } = useRecommendationQuery(recommendationId)

const recommendationTypeLabel = (id: number, freeText: string | undefined) => {
    if (id === OTHER_RECOMMENDATION_TYPE_ID && freeText) {
        return freeText
    }

    const recommendationData = constantsData.value?.recommendationTypes?.find(
        (recommendation) => recommendation.id === id,
    )

    if (!recommendationData) {
        return '-'
    }

    return chooseLangString(locale, recommendationData)
}

const commentNote = (comment: RecommendationCommentDto | undefined) => {
    if (!comment) {
        return ''
    }

    if (!comment.date) {
        return comment.kid ?? ''
    }

    const formattedDate = format(new Date(comment.date), DATE_TIME_FORMAT)

    return `${formattedDate} ${comment.kid}`
}

const formatDate = (date: string | undefined | null) => {
    if (!date) {
        return '-'
    }

    return format(new Date(date), DATE_TIME_FORMAT)
}

const openViewRecommendation = (id: number) => {
    recommendationId.value = id

    dialogRef.value?.show()
}

provideConditionStatusViewRecommendationModal({
    openViewRecommendation,
})
</script>

<style scoped lang="scss">
.recommendation-dialog {
    --width: 70vw;
}

.recommendation-modal {
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: $spacing-s $spacing-m;

        &__subtitle {
            font-weight: 500;
        }

        &__field {
            &--full-width {
                grid-column: span 3 / span 3;
            }

            &--two-thirds {
                grid-column: span 2 / span 2;
            }
        }

        &__icon {
            > * {
                cursor: pointer;
                width: 1em;
                height: 1.5em;
            }
        }
    }

    &__no-notification {
        color: $pbl-text-muted;
    }

    &__footer {
        width: 100%;
        display: flex;
        justify-content: flex-start;

        &__meta {
            color: $pbl-text-muted;
        }
    }
}
</style>
