<template>
    <Transition>
        <div
            v-if="open"
            class="language"
        >
            <div class="language__pointer" />
            <div class="language__content">
                <template
                    v-for="option in SUPPORTED_LOCALES_OPTIONS"
                    :key="option.value"
                >
                    <button @click="onSetLanguageClick(option.value)">
                        <span>{{ option.label }}</span>
                    </button>
                </template>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { HYDRO_LANG, SUPPORTED_LOCALES_OPTIONS } from '@/utils/i18n'
import { useQueryClient } from '@tanstack/vue-query'

defineProps<{
    open: boolean
}>()

const emit = defineEmits<{
    close: []
}>()

const queryClient = useQueryClient()

const { locale } = useI18n()

const onSetLanguageClick = (newLang: string) => {
    localStorage.setItem(HYDRO_LANG, newLang)
    locale.value = newLang
    queryClient.invalidateQueries()

    emit('close')
}
</script>

<style scoped lang="scss">
.language {
    position: absolute;
    top: calc(100% + 7px);
    right: -7px;

    color: $pbl-text;
    background-color: $pbl-foreground;
    border-radius: 3px;
    box-shadow: rgba(4, 7, 22, 0.15) 0 5.625px 11.25px -1.25px;

    white-space: nowrap;

    z-index: 10;

    &__pointer {
        position: relative;
        z-index: 20;
        top: -5px;
        right: 14px;

        margin-left: auto;

        width: 0;
        height: 0;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $pbl-foreground;
    }

    &__content {
        display: flex;
        flex-direction: column;

        button {
            font-family: $pbl-font-family;
            text-align: left;

            color: $pbl-text;
            background-color: $pbl-foreground;

            border: none;
            padding: var(--pbl-variable-spacing-2xs) var(--pbl-spacing-s);

            cursor: pointer;

            & > span {
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.5rem;
            }

            &:hover {
                background-color: $pbl-foreground-active;
            }

            &:nth-last-child(1) {
                border-radius: 0 0 3px 3px;
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: all 0.3s cubic-bezier(0.42, 0.97, 0.52, 1.49);
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
