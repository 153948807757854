import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'

export const commentValidationSchema = toTypedSchema(
    zod.object({
        commentId: zod.number().optional(),
        reportNumber: zod
            .string({ required_error: 'formInputs.validation.requiredSelect' })
            .min(1, 'formInputs.validation.requiredSelect'),
        analysisType: zod.number().optional(),
        measurement: zod.number().optional(),
        text: zod.string().min(1, 'formInputs.validation.requiredText'),
    }),
)
