<template>
    <FormMultiselectField
        v-model="value"
        :input-id="inputId"
        :label="label"
        :options="options"
        :is-disabled="disabled"
        :invalid="!meta.valid && meta.validated"
        :error-message="errorMessage ? t(errorMessage) : undefined"
    />
</template>

<script setup lang="ts" generic="T">
import { SelectOptions } from '@/components/form-inputs/common/select-option'
import { useField } from 'vee-validate'
import FormMultiselectField from '@/components/form-inputs/form-multiselect-field/FormMultiselectField.vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
    name: string
    inputId: string

    label: string
    options: SelectOptions<T>

    disabled?: boolean
}>()

const { t } = useI18n()
const { value, errorMessage, meta } = useField<T[]>(() => props.name)
</script>
