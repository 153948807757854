import { MaybeRef, MaybeRefOrGetter, toValue } from 'vue'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

const DEFAULT_PAGE_SIZE = 3

export const useTransformerCommentsQuery = (
    transformerId: MaybeRefOrGetter<string>,
    preloadSize: MaybeRef<number | undefined>,
) => {
    return useInfiniteQuery({
        queryKey: ['transformers', transformerId, 'comments'],
        queryFn: async ({ signal, pageParam }) => {
            const preloadPageSize = toValue(preloadSize)

            const {
                data: { result },
            } = await hcaClientFactory.commentsApi.listComments(
                toValue(transformerId),
                pageParam,
                preloadPageSize ?? DEFAULT_PAGE_SIZE,
                undefined,
                undefined,
                { signal },
            )
            return result
        },
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages, lastPageParam) => {
            if (toValue(preloadSize) !== undefined) {
                return undefined
            }

            if (lastPage?.total === undefined) {
                return 1
            }

            if (lastPage.total <= lastPageParam * DEFAULT_PAGE_SIZE) {
                return undefined
            } else {
                return lastPageParam + 1
            }
        },
        getPreviousPageParam: () => undefined,
    })
}
