<template>
    <div class="reports-page">
        <ReportsStaticHeaderContent :report-params="reportParams" />
        <div class="reports-page__content">
            <ReportsContent
                v-if="isContentDisplayed"
                :key="JSON.stringify(contentKey)"
                :report-params="reportParams"
            />
            <pbl-panel v-else>
                <div class="reports-page__content__empty-state">
                    <pbl-icon name="check2-square" />
                    <span>
                        {{ t('reports.emptyStateBody') }}
                    </span>
                </div>
            </pbl-panel>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { LocationQueryValue, useRoute } from 'vue-router'
import { router } from '@/router'
import ReportsContent from '@/pages/reports/view-all-reports-page/ReportsContent.vue'
import ReportsStaticHeaderContent from '@/pages/reports/view-all-reports-page/ReportsStaticHeaderContent.vue'
import { ReportParams } from '@/types/reports'
import { compareAsc } from 'date-fns'
import { DEFAULT_REPORTS_PARAMS, REPORTS_PARAMS_KEY } from '@/utils/reports-utils'
import { TIMEFRAME_DEFAULT_END_DATE } from '@/utils/date-utils'

const route = useRoute()

const { t } = useI18n()

const storedParamsRaw = localStorage.getItem(REPORTS_PARAMS_KEY)
const storedParams = storedParamsRaw && JSON.parse(storedParamsRaw)

const hasStoredParams = computed<boolean>(() => {
    return !!(
        storedParams?.plantGroupSid?.length ||
        storedParams?.plantSid?.length ||
        storedParams?.componentType?.length ||
        storedParams?.reportType ||
        storedParams?.startDate ||
        storedParams?.endDate ||
        storedParams?.componentId
    )
})

const contentKey = computed(() => {
    let key = { ...reportParams.value }
    delete key.componentId
    return key
})

const handleArrayParam = (key: LocationQueryValue | LocationQueryValue[]) => {
    if (!key) {
        return []
    }

    return Array.isArray(key) ? key.map((e) => (e ? parseInt(e) : -1)) : [parseInt(key)]
}

const handleStringParam = (key: LocationQueryValue | LocationQueryValue[]) => {
    if (!key || Array.isArray(key)) {
        return
    }

    return key
}

const handleNumberParam = (key: LocationQueryValue | LocationQueryValue[]) => {
    if (!key || Array.isArray(key)) {
        return
    }

    return parseInt(key)
}

const plantGroupSid = computed<number[]>(() => {
    return handleArrayParam(route.query.plantGroupSid)
})

const plantSid = computed<number[]>(() => {
    return handleArrayParam(route.query.plantSid)
})

const componentType = computed<number[]>(() => {
    return handleArrayParam(route.query.componentType)
})

const reportType = computed<number | undefined>(() => {
    return handleNumberParam(route.query.reportType)
})

const startDate = computed<string | undefined>(() => {
    return handleStringParam(route.query.startDate)
})

const endDate = computed<string | undefined>(() => {
    return handleStringParam(route.query.endDate)
})

const componentId = computed<string | undefined>(() => {
    return handleStringParam(route.query.componentId)
})

const hasQueryValues = computed<boolean>(() => {
    return !!(
        plantGroupSid.value.length ||
        plantSid.value.length ||
        componentType.value.length ||
        (typeof reportType.value === 'number' && !Number.isNaN(reportType.value)) ||
        startDate.value ||
        endDate.value ||
        componentId.value
    )
})

const reportParams = computed<ReportParams>(() => ({
    plantGroupSid: plantGroupSid.value,
    plantSid: plantSid.value,
    componentType: componentType.value,
    reportType: reportType.value,
    startDate: startDate.value,
    endDate: endDate.value,
    componentId: componentId.value,
}))

const firstLoad = () => {
    if (hasQueryValues.value) {
        const overrideParams = {
            ...reportParams.value,
            endDate: TIMEFRAME_DEFAULT_END_DATE,
        }
        localStorage.setItem(REPORTS_PARAMS_KEY, JSON.stringify(overrideParams))
        router.replace({
            name: 'Reports_ViewAll',
            query: overrideParams,
        })
    } else if (hasStoredParams.value) {
        const overrideParams = {
            ...storedParams,
            endDate: TIMEFRAME_DEFAULT_END_DATE,
        }
        localStorage.setItem(REPORTS_PARAMS_KEY, JSON.stringify(overrideParams))
        router.replace({
            name: 'Reports_ViewAll',
            query: overrideParams,
        })
    } else {
        localStorage.setItem(REPORTS_PARAMS_KEY, JSON.stringify(DEFAULT_REPORTS_PARAMS))
        router.replace({
            name: 'Reports_ViewAll',
            query: DEFAULT_REPORTS_PARAMS,
        })
    }
}

onMounted(() => {
    firstLoad()
})

const isReportTypeSelected = computed(
    () => typeof reportParams.value.reportType === 'number' && !Number.isNaN(reportParams.value.reportType),
)

const isTimeframeValid = computed(() => {
    if (!reportParams.value.startDate || !reportParams.value.endDate) {
        return true
    }

    return compareAsc(new Date(reportParams.value.startDate), new Date(reportParams.value.endDate)) === -1
})

const isContentDisplayed = computed(
    () =>
        !!(
            (reportParams.value.plantGroupSid.length || reportParams.value.plantSid.length) &&
            reportParams.value.componentType.length &&
            isReportTypeSelected.value &&
            isTimeframeValid.value &&
            reportParams.value.startDate &&
            reportParams.value.endDate
        ),
)
</script>

<style scoped lang="scss">
.reports-page {
    display: flex;
    flex-direction: column;

    max-height: calc(100vh - $navbar-height * 2);

    &__content {
        @include spacing-page;
        overflow-y: scroll;

        &__empty-state {
            padding: 50px 0;

            display: flex;
            flex-direction: column;

            align-items: center;
            justify-content: center;
            gap: $spacing-s;

            color: $pbl-text-muted;

            pbl-icon {
                --size: 2rem;
            }

            span {
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }
}
</style>
