import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { hcaClientFactory } from '@/api/factories/HcaClientFactory'

export const useTransformersCommentsDeleteMutation = () => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: async (payload: { transformerId: string; commentId: number; analysisType?: number }) =>
            hcaClientFactory.commentsApi.deleteComment(payload.commentId),
        onSuccess: async (response, payload) => {
            if (payload.analysisType) {
                await queryClient.invalidateQueries({
                    queryKey: ['transformers', payload.transformerId, 'reports', payload.analysisType],
                })
            }

            await queryClient.invalidateQueries({ queryKey: ['transformers', payload.transformerId, 'comments'] })
        },
    })
}
