import { ReportExportType } from '@/configuration/report/report-export-types'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'

export const exportValidationSchema = (exportType: ReportExportType) =>
    toTypedSchema(zod.object(validationSchema[exportType]))

const validationSchema = {
    [ReportExportType.OilAnalysisData]: {
        exportType: zod.number(),
        groupSid: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        plantSid: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        componentTypes: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        componentSids: zod.string({ required_error: 'formInputs.validation.requiredSelect' }),
        startDate: zod.string().min(1, 'formInputs.validation.requiredDate'),
        endDate: zod.string().min(1, 'formInputs.validation.requiredDate'),
    },
    [ReportExportType.OilAnalysisStatus]: {
        exportType: zod.number(),
        groupSid: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        plantSid: zod.number().array().optional(),
        componentTypes: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        componentSids: zod.string().optional(),
        startDate: zod.string().optional(),
        endDate: zod.string().optional(),
    },
    [ReportExportType.SummaryComponentConditionStatus]: {
        exportType: zod.number(),
        groupSid: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        plantSid: zod.number().array().optional(),
        componentTypes: zod.number({ required_error: 'formInputs.validation.requiredSelect' }),
        componentSids: zod.string().optional(),
        startDate: zod.string().optional(),
        endDate: zod.string().optional(),
    },
}
